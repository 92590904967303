import * as React from "react"
import {RefObject} from "react"
import ResizeObserver from "resize-observer-polyfill"
import * as MTU from "../MainThreadUtils"

interface State {
    width: number
    height: number
}

/***
 * HOC that adds width and height props to the component changing on client resize
 * @param Component the target component
 * @param countHeight should the height be taken into account or not
 */
export function make (Component:typeof React.Component, countHeight=true, fullSize=false) {
    return class SizeAwareComponent extends React.PureComponent<any, State> {
        static readonly RESIZE_DEBOUNCE_TIME = 10
        protected observer: ResizeObserver | null = null
        protected readonly containerRef: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>()
        override readonly state = {width: 0, height: 0}

        updateSizes() {
            if (this.containerRef && this.containerRef.current) {
                this.setState({
                    width: this.containerRef.current.clientWidth,
                    height: countHeight ? this.containerRef.current.clientHeight : 0
                });
            }
        }

        override componentDidMount() {
            this.observer = new ResizeObserver(MTU.debounce(this.updateSizes.bind(this), SizeAwareComponent.RESIZE_DEBOUNCE_TIME).bind(this));
            this.observer.observe(this.containerRef.current as Element);
        }

        override componentWillUnmount() {
            if (this.observer) {
                this.observer.disconnect();
            }
        }

        override render() {
            return (
                <div ref={this.containerRef} style={fullSize ? {position: "fixed", inset: 0} : undefined}>
                    <Component width={this.state.width} height={this.state.height} {...this.props}/>
                </div>
            )
        }
    }
}