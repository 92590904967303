/*
LOCHASH FORMAT
--------------
byte 0 = format
    bits 0,1:
        0 = 4 bits per column id
        1 = 8 bits per column id
        2 = 16 bits per column id
    bit 2:
        1 = last 4 bytes => workspace id
    bit 3:
        1 = there are vis settings after column ids
byte 1 = byte 5 => visualizer type id
byte 2 = number of column ids
bytes 3...n = column ids
if there are vis settings (see bit 3 of byte 0)
    byte n+1 = number of vis settings
    byte n+2 = vis setting id
    byte n+3:
        bits 7:
            1 = bits 0-6 specify the very value of the setting (integer 0-127)
            0 = bits 0-6 specify the length of string setting in bytes following this byte (n+3)
    if bit 7 of byte n+3 == 0:
        bytes n+4...n+m = bytes of a string value (UFT8)
    byte n+m+1 is the same as byte n+2
    byte n+m+2 is the same as byte n+3
    and so on (byte n+1) times...
if there are workspace id (see bit 2 of byte 0)
    4 bytes = workspace id (if present)
*/

import * as React from 'react'
import {WorkspaceId} from "./Workspace"
import {ColumnId} from "../Concepts/Basic"
import {VisualizerType} from "../Concepts/Visualizer"
import {VisSettingId, VisSettingValue} from "../Concepts/VisSettings"
import {History} from 'history'
import {Link as RouterLink} from "react-router-dom"
import * as U from "../Utils"
import event from "../EventSender/Events"

type ColumnIdFormat = 0 | 1 | 2

export type SettingValue = {
    id: VisSettingId,
    value: VisSettingValue
}

interface LinkProps {
    columnIds: ColumnId[]
    visType?: VisualizerType
    settings?: (SettingValue | undefined)[]
    workspaceId?: WorkspaceId
}

export class WorkspaceLocation {
    readonly lochash: string
    static readonly reNum = /^\d+$/;

    static moveTo(history: History, columnIds: ColumnId[], visType= VisualizerType.Default, settings?: SettingValue[], workspaceId: WorkspaceId = 0): WorkspaceLocation {
        const location = new WorkspaceLocation(columnIds, visType, settings, workspaceId)
        event.app.location(location)
        history.push('/w/' + location.lochash)
        return location
    }

    static fromLochash(lochash: string): WorkspaceLocation {
        try {
            const bytes = Uint8Array.from(atob(lochash), c => c.charCodeAt(0)),
                columnIdFormat = (bytes[0] & 3) as ColumnIdFormat,
                hasWorkspaceId = (bytes[0] & 4) > 0,
                hasSettings = (bytes[0] & 8) > 0,
                visType = bytes[1],
                columnIdCount = bytes[2],
                columnIdsLength = Math.ceil(columnIdCount * ([0.5, 1, 2][columnIdFormat])),
                columnIds: ColumnId[] = []

            let settings: SettingValue[] | undefined

            let workspaceId: WorkspaceId = 0,
                curPos = 3

            switch (columnIdFormat) {
                case 0:
                    for (let b = 0; b < columnIdsLength; b++) {
                        columnIds.push(ColumnId(bytes[curPos] >> 4))
                        if (columnIds.length < columnIdCount) {
                            columnIds.push(ColumnId(bytes[curPos] & 15))
                        }
                        curPos += 1
                    }
                    break
                case 1:
                    for (let b = 0; b < columnIdsLength; b++) {
                        columnIds.push(ColumnId(bytes[curPos]))
                        curPos += 1
                    }
                    break
                case 2:
                    for (let b = 0; b < columnIdsLength; b += 2) {
                        columnIds.push(ColumnId(bytes[curPos] * 256 + bytes[curPos + 1]))
                        curPos += 2
                    }
                    break
            }

            if (hasSettings) {
                settings = []
                const count = bytes[curPos++]
                for (let i = 0; i < count; i++) {
                    const id = bytes[curPos++],
                        byte0 = bytes[curPos++],
                        isNumber = byte0 & 128
                    settings.push({
                        id,
                        value: isNumber
                            ? (byte0 & 127).toString()
                            : new TextDecoder().decode(bytes.subarray(curPos, curPos + byte0))
                    })
                    curPos += isNumber ? 0 : byte0
                }
            }

            if (hasWorkspaceId) {
                workspaceId = ((bytes[curPos] << 24) | (bytes[curPos + 1] << 16) | (bytes[curPos + 2] << 8) | bytes[curPos + 3]) as WorkspaceId
            }

            return new WorkspaceLocation(columnIds, visType, settings, workspaceId, lochash)
        } catch {
            return new WorkspaceLocation([], 0, undefined, 0, lochash)
        }
    }

    protected constructor(readonly columnIds: ColumnId[] = [],
                          readonly visType= VisualizerType.Default,
                          readonly settings?: SettingValue[],
                          readonly workspaceId: WorkspaceId = 0,
                          lochash?: string
    ) {
        this.lochash = lochash ?? WorkspaceLocation.buildLochash(columnIds, visType, settings, workspaceId)
    }

    protected static getSettingsBytes = (settings: SettingValue[]): Uint8Array =>
        U.concatByteArrays(...settings.map(sv => {
            if (sv.id > 255 || sv.id < 0) {
                throw new Error("Setting id greater than 255 or less than 0 is not suported")
            }
            if (WorkspaceLocation.reNum.test(sv.value) && U.parseIntNotNan(sv.value) < 128) {
                return new Uint8Array([sv.id, U.parseIntNotNan(sv.value) + 128])
            } else {
                const textBytes = new TextEncoder().encode(sv.value)
                if (textBytes.byteLength > 127) {
                    throw new Error("Setting values longer that 127 bytes of UTF-8 are not suported")
                }
                const array = new Uint8Array(textBytes.byteLength + 2)
                array[0] = sv.id
                array[1] = textBytes.byteLength
                array.subarray(2).set(textBytes)
                return array
            }
        }))

    static buildLochash(columnIds: ColumnId[], visType: VisualizerType, settings?: SettingValue[], workspaceId: WorkspaceId = 0): string {
        if (settings && settings.length > 255) {
            throw new Error("Array with more than 255 settings is not suported")
        }
        if (columnIds.length > 255) {
            throw new Error("Array with more than 255 column ids is not suported")
        }
        if (visType > 255 || visType < 0) {
            throw new Error("Visualizer type greater than 255 or less than zero is not suported")
        }
        const maxColId = Math.max(...columnIds),
            columnIdFormat: ColumnIdFormat = maxColId > 255 ? 2 : (maxColId > 15 ? 1 : 0),
            columnIdsLength = Math.ceil(columnIds.length * ([0.5, 1, 2][columnIdFormat])),
            settingsArray = settings ? WorkspaceLocation.getSettingsBytes(settings) : new Uint8Array(0),
            buffer = new ArrayBuffer(1 + 2 + columnIdsLength + (settings ? settingsArray.byteLength + 1 : 0) + (workspaceId ? 4 : 0)),
            bytes = new Uint8Array(buffer)

        let curPos = 0
        bytes[curPos++] = columnIdFormat + (workspaceId ? 4 : 0) + (settings ? 8 : 0)
        bytes[curPos++] = visType
        bytes[curPos++] = columnIds.length

        switch (columnIdFormat) {
            case 0:
                for (let b = 0; b < columnIdsLength; b++) {
                    bytes[curPos++] = (columnIds[b * 2] << 4) | (columnIds[b * 2 + 1] & 15)
                }
                break
            case 1:
                columnIds.forEach((id) => bytes[curPos++] = id)
                break
            case 2:
                columnIds.forEach((id) => {
                    bytes[curPos++] = id >> 8
                    bytes[curPos++] = id & 255
                })
                break
        }

        if (settings) {
            bytes[curPos++] = settings.length
            bytes.set(settingsArray, curPos)
            curPos += settingsArray.byteLength
        }

        if (workspaceId) {
            bytes[curPos++] = workspaceId >> 24
            bytes[curPos++] = (workspaceId >> 16) & 255
            bytes[curPos++] = (workspaceId >> 8) & 255
            bytes[curPos++] = workspaceId & 255
        }

        return btoa(String.fromCharCode(...bytes))
    }
}

export const Link: React.FunctionComponent<LinkProps> = props =>
    props.columnIds.length
        ? <RouterLink to={
            '/w/' + WorkspaceLocation.buildLochash(
                props.columnIds,
                props.visType ?? 0,
                props.settings?.filter(sv => sv) as (SettingValue[] | undefined),
                props.workspaceId
            )
        }
                      style={{textDecoration: "none", color: "black"}}>
            {props.children}
        </RouterLink>
        : <React.Fragment>
            {props.children}
        </React.Fragment>


//console.log (WorkspaceLocation.buildLochash([1,2], 3, [{id:100, value:'Привет'}], 999))