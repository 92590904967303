import * as React from 'react'
import {ReactElement} from 'react'
import {ColumnId} from "../Concepts/Basic"
import * as U from "../Utils"
import {
    GetActionsEvent,
    GetActionsEventType,
    IVisualizer,
    VisCtrlValues,
    VisualizerEventType
} from "../Concepts/Visualizer"
import {IDataTable} from "../Concepts/DataTable";
import $t from "../i18n/i18n";

export abstract class Visualizer extends IVisualizer {

    public override icon (width:number, height:number):ReactElement {
        return <img src={this.getIconUrl()} alt={this.getTitle()} title={this.getTitle()} width={width} height={height} />
    }

    protected getCurrentControlValues (event:GetActionsEvent, controlValues:VisCtrlValues):VisCtrlValues {
        const cv = new Map (controlValues)
        if (event.type === GetActionsEventType.ControlValueChangedByUser) {
            cv.set (event.controlId, event.newControlValue)
        }
        return cv
    }

    protected getColumnIdsTitleFooter (dataTable:IDataTable, titleHint?:string | ((columnList:string)=>string)):{columnIds:ColumnId[], title:string, footer:string | undefined} {
        const columnIds = dataTable
            .selectedColumns
            .sort((a,b) => (a.selectionTime?.getTime() ?? 0) - (b.selectionTime?.getTime() ?? 0))
            .map (col => col.id)
        const nullColumns = dataTable.filteredColumnsByUiIndex (col => col.selected && col.summary.nullCount === col.summary.valueCount)
        const columnList = U.joinWithAnd(columnIds.map (id => dataTable.getColumnInfo(id).title), true)
        const title = typeof titleHint === 'function' ? titleHint(columnList) : (titleHint ?? '') + ' ' + columnList
        const footer = nullColumns.length ? `${U.joinWithAnd(nullColumns.map (col => col.title), true)} ${nullColumns.length > 1 ? $t('visualizer.all_nulls.plur') : $t('visualizer.all_nulls.sing')}` : undefined
        return {columnIds, title, footer}
    }

    protected sendColoringEvent (categoryColumnId:ColumnId|string|null, byColumnColors= true, excludeColumnIds?:ColumnId[]) {
        this.eventHandler (categoryColumnId
            ? {
                type: VisualizerEventType.ColoredByCategoricalColumn,
                columnId: typeof categoryColumnId === "string" ? ColumnId(U.parseIntNotNan(categoryColumnId)) : categoryColumnId
            }
            : {type: byColumnColors ? VisualizerEventType.ColoredByColumnColors : VisualizerEventType.NotColored, excludeColumnIds}
        )
    }
}