import React from 'react'
import * as U from "../Utils"
import css from './SideMenu.module.scss'
import logo32 from '../Images/logo.svg'
import {MenuAction} from "../Concepts/Basic"
import {SideMenuItem, SideMenuItemInfo} from "./SideMenuItem"
import $t from "../i18n/i18n"

interface MenuProps {
    onAction: (action: MenuAction) => void
    disabledActions?: (MenuAction | undefined)[]
}

interface State {
    dropdown:{anchor:HTMLElement, action:MenuAction} | null,
    active: boolean
}

export default class SideMenu extends React.PureComponent<MenuProps, State> {
    protected static readonly menuItems: SideMenuItemInfo[] = [
        {
            text: $t('menu.open.tooltip'),
            icon: 'folder-open',
            action: MenuAction.OpenFileAuto,
            options: [
                {text: $t('menu.open.auto.tooltip'), action: MenuAction.OpenFileAuto},
                {text: $t('menu.open.manual.tooltip'), action: MenuAction.OpenFileManual}
            ]
        },
        {
            text: $t('menu.paste.tooltip'),
            icon: 'clipboard',
            action: MenuAction.PasteAuto,
            hide: !navigator.clipboard,
            options: [
                {text: $t('menu.paste.auto.tooltip'), action: MenuAction.PasteAuto},
                {text: $t('menu.paste.manual.tooltip'), action: MenuAction.PasteManual}
            ]
        },
        {
            text: $t('menu.download.tooltip'),
            icon: 'floppy-disk',
            action: MenuAction.SaveWorkspace
        },
        {
            text: $t('menu.presets.tooltip'),
            icon: 'books',
            action: MenuAction.LoadPreset
        },
        {
            text: $t('menu.settings.tooltip'),
            icon: 'gear',
            action: MenuAction.Settings
        },
        {
            text: $t('menu.telegram.tooltip'),
            icon: 'telegram',
            action: MenuAction.Telegram
        }
    ]

    protected readonly containerRef:React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>()

    override readonly state:State = {dropdown: null, active: false}

    protected handleDropdownShow = (action: MenuAction, anchor: HTMLElement) => {this.setState({dropdown:{action, anchor}})}

    protected handleDropdownClose = () => {this.setState({active:false, dropdown: null})}

    protected handleMouseEnter = () => {this.setState({active: true})}

    protected handleMouseMove = (evt:React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const container = this.containerRef.current
        this.setState({active: container !== null && container.clientWidth >= evt.clientX})
    }

    protected handleMouseLeave = () => {this.setState({active: false})}

    override render() {
        return <div ref={this.containerRef}
                    className={U.cls(css.container, true, css.active, this.state.active || Boolean(this.state.dropdown))}
                    onMouseEnter={this.handleMouseEnter}
                    onMouseMove={this.handleMouseMove}
                    onMouseLeave={this.handleMouseLeave}>
                <img className={css.logo} src={logo32} alt={"DATAISCLEAR"}
                     title={$t('menu.logo_hint')}/>
                {
                    SideMenu.menuItems.filter(item => !item.hide).map((item, i) =>
                        <SideMenuItem key={i}
                                      item={item}
                                      disabled={(this.props.disabledActions ?? []).indexOf(item.action) >= 0}
                                      onAction={this.props.onAction}
                                      dropdownMenuAnchor={this.state.dropdown && item.action === this.state.dropdown.action ? this.state.dropdown.anchor : undefined}
                                      onDropdownShow={this.handleDropdownShow}
                                      onDropdownClose={this.handleDropdownClose}
                        />
                    )
                }
                <div style={{
                    bottom: 3,
                    position: "absolute",
                    textAlign: "center",
                    width: "100%",
                    fontSize: 10,
                    color: "darkgrey"
                }}>{process.env.REACT_APP_VERSION}</div>
            </div>
    }
}
