import React, {PureComponent} from 'react';
import {Autocomplete, AutocompleteChangeReason, AutocompleteInputChangeReason, TextField} from '@material-ui/core';

export interface Option {
    name:string,
    value:string
}

interface Props {
    options: Option[],
    onChange: (value:string)=>void,
    value: string,
    label: string
}

interface State {
    inputValue: string
}

export class CharacterSelector extends PureComponent<Props, State> {
    protected textField:React.RefObject<HTMLInputElement> = React.createRef();
    static readonly navKeys = ["ArrowUp", "ArrowDown", "PageUp", "PageDown", "Home", "End"]

    override readonly state:State = {
        inputValue: ''
    }

    protected extractName = (option:Option):string => {
        return option.name
    }

    protected handleChange = (event:React.ChangeEvent<unknown>, value:Option|string, reason: AutocompleteChangeReason) => {
        if (reason === "selectOption" && typeof value === "object") {
            this.props.onChange(value.value)
        } else if (reason === "createOption" && typeof value === "string") {
            this.props.onChange(value)
        }
    }

    protected handleInputChange = (event:unknown, value:string, reason: AutocompleteInputChangeReason) => {
        if (reason==="input" && value) {
            this.props.onChange(value[0]);
            ((event as React.SyntheticEvent).target as HTMLInputElement).blur()
        }
    }

    protected handleTextKeyDown = (event:React.KeyboardEvent<HTMLInputElement>)=>{
        if (CharacterSelector.navKeys.indexOf(event.key)<0) {
            (event.target as HTMLInputElement).value = ''
        }
    }

    override render () {
        const options:Option[] = this.props.options.map (o=>o)
        let curValue = options.filter(o => o.value === this.props.value)[0]
        if (curValue === undefined) {
            curValue = {value:this.props.value, name:this.props.value}
            options.push(curValue)
        }
        return (
            <Autocomplete
                disableClearable
                blurOnSelect
                handleHomeEndKeys
                getOptionLabel={this.extractName}
                options={options}
                inputValue={curValue.name}
                value={curValue}
                onChange={this.handleChange}
                onInputChange={this.handleInputChange}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        ref={this.textField}
                        label={this.props.label}
                        onKeyDown={this.handleTextKeyDown}
                        margin="normal"
                        variant="standard" />}
            />
        );
    }
}



