const PHI_RATIO = 2 / (1 + Math.sqrt(5));

// Taken from https://github.com/scijs/minimize-golden-section-1d

/**
 @converged true if algorithm succeeded
 @iterations number of iterations
 @minimum minimum value of the function achieved before exiting, whether the algorithm converged or not
 @argmin best guess of argmin achieved before exiting, whether the algorithm converged or not.
 */
interface Status {
    iterations: number
    argmin: number
    minimum: number
    converged: boolean
}

/**
 * Searches for a local minimum of the function using "golden section search"
 * @param f The function to be minimized
 * @param xL Lower bound of the search interval
 * @param xU Upper bound of the search interval
 * @param tol Convergence tolerance. Algorithm continues until search interval is smaller than this value
 * @param maxIterations Maximum number of iterations for either bracketing or search phase
 * @param status Status information
 * @return The value of the argument that minimizes the function
 */
export default function goldenSectionMinimize (f:(x:number)=>number, xL=-Infinity, xU=Infinity, tol=1e-8, maxIterations=100, status?:Status):number {
    let iteration = 0;
    let x1 = xU - PHI_RATIO * (xU - xL);
    let x2 = xL + PHI_RATIO * (xU - xL);

    // Initial bounds:
    let f1 = f(x1);
    let f2 = f(x2);

    // Store these values so that we can return these if they're better.
    // This happens when the minimization falls *approaches* but never
    // actually reaches one of the bounds
    const f10 = f(xL),
        f20 = f(xU),
        xL0 = xL,
        xU0 = xU

    // Simple, robust golden section minimization:
    while (++iteration < maxIterations && Math.abs(xU - xL) > tol) {
        if (f2 > f1) {
            xU = x2;
            x2 = x1;
            f2 = f1;
            x1 = xU - PHI_RATIO * (xU - xL);
            f1 = f(x1);
        } else {
            xL = x1;
            x1 = x2;
            f1 = f2;
            x2 = xL + PHI_RATIO * (xU - xL);
            f2 = f(x2);
        }
    }

    const xF = 0.5 * (xU + xL), fF = 0.5 * (f1 + f2);

    if (status) {
        status.iterations = iteration;
        status.argmin = xF;
        status.minimum = fF;
        status.converged = true;
    }

    if (Number.isNaN(f2) || Number.isNaN(f1) || iteration === maxIterations) {
        if (status) {
            status.converged = false;
        }
        return NaN;
    }

    if (f10 < fF) {
        return xL0;
    } else if (f20 < fF) {
        return xU0;
    } else {
        return xF;
    }
}