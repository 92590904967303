import {alias, custom, list, object, optional, primitive, serializable} from "serializr"
import {INumberSummary, NumericalCategories} from "../Concepts/Basic"
import {Point} from "../Concepts/Geometry"
import ValueDistribution from "./ValueDistribution"


// serialization decodator
function point() {
    return custom(
        (value: any) => value ? [(value as Point).x, (value as Point).y] : {SKIP: true},
        (value: any) => value.SKIP === true ? undefined : new Point(value[0], value[1])
    )
}

export default class NumberSummary implements INumberSummary {
    @serializable(alias('min')) readonly min: number
    @serializable(alias('max')) readonly max: number
    @serializable(alias('wmin')) readonly whiskerMin: number
    @serializable(alias('wmax')) readonly whiskerMax: number
    @serializable(alias('q1')) readonly q1: number
    @serializable(alias('q3')) readonly q3: number
    @serializable(alias('iqr')) readonly iqr: number
    @serializable(alias('mean')) readonly mean: number
    @serializable(alias('median')) readonly median: number
    @serializable(alias('std')) readonly std: number
    @serializable(alias('outliers', list(primitive()))) readonly outliers: number[]
    @serializable(alias('nulls')) readonly nullCount: number
    @serializable(alias('distr', optional(object(ValueDistribution)))) protected _distribution: ValueDistribution|undefined
    @serializable(alias('kde', optional(list(point())))) protected _kde: Point[]|undefined
    @serializable(alias('categories', optional(object(NumericalCategories)))) readonly categories: NumericalCategories|undefined

    constructor(
        min: number,
        max: number,
        whiskerMin: number,
        whiskerMax: number,
        q1: number,
        q3: number,
        iqr: number,
        mean: number,
        median: number,
        std: number,
        outliers: number[],
        nullCount: number,
        distribution: ValueDistribution|undefined,
        kde: Point[]|undefined,
        categories: NumericalCategories|undefined
    ) {
        [this.min, this.max, this.whiskerMin, this.whiskerMax, this.q1, this.q3, this.iqr, this.mean, this.median, this.std, this.outliers, this.nullCount, this._distribution, this._kde, this.categories] =
            [min, max, whiskerMin, whiskerMax, q1, q3, iqr, mean, median, std, outliers, nullCount, distribution, kde, categories]
    }

    static isComparable(a: INumberSummary, b: INumberSummary): boolean {
        return (
            Math.abs(a.mean - b.mean) < Math.min(a.iqr, b.iqr) * 10
            && Math.max(a.iqr, b.iqr) / Math.min(a.iqr, b.iqr) < 10
        )
    }

    get distribution() {
        if (this._distribution === undefined) {
            throw Error ("Valued distribution is not defined in number summary")
        } else {
            return this._distribution
        }
    }

    get kde() {
        if (this._kde === undefined) {
            throw Error ("KDE is not defined in number summary")
        } else {
            return this._kde
        }
    }

}
