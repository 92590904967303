import * as Colors from "./Colors"
import {DataValue} from "./Concepts/Basic"
import {CustomTimeFormatter} from "./Concepts/DateTime"
import {TimeFormatter} from "./DateTime/DateTime"
import $t from "./i18n/i18n";

export const nullText = 'NULL'
export const ellipsis = '...'
export const sqrt2PI = Math.sqrt(2 * Math.PI)
export const nbsp = "\u00A0"

class Settings {
    protected _locale:string|undefined
    protected _timeZone:string|undefined
    protected _uuid = ''
    protected _numToCat = Colors.seriesColorCount

    protected _numberFormatter:Intl.NumberFormat|undefined
    protected _timeFormatter:CustomTimeFormatter|undefined
    protected _dateTimeFormatter:Intl.DateTimeFormat|undefined
    protected _dateFormatter:Intl.DateTimeFormat|undefined

    rebuildFormatters = () => {
        this._numberFormatter = new Intl.NumberFormat(this.locale)
        this._timeFormatter = new TimeFormatter()
        this._dateFormatter = this.buildDateTimeFormat (true, true, true, false, false, false, 'UTC')
        this._dateTimeFormatter = this.buildDateTimeFormat (true, true, true, true, true, true)
    }

    buildDateTimeFormat = (useYear:boolean, useMonth:boolean, useDay:boolean, useHour:boolean, useMinute:boolean, useSecond:boolean, timeZone?:string):Intl.DateTimeFormat =>
        new Intl.DateTimeFormat(this.locale, {
            year: useYear ? 'numeric' : undefined,
            month: useMonth ? (useDay && useYear ? '2-digit' : 'short') : undefined,
            day: useDay ? '2-digit' : undefined,
            hour: useHour ? '2-digit' : undefined,
            minute: useMinute ? '2-digit' : undefined,
            second: useSecond ? '2-digit' : undefined,
            hour12: false,
            timeZone: timeZone ?? this.timeZone
        })

    get uuid () {
        return this._uuid
    }
    set uuid (value:string) {
        this._uuid = value
    }
    get locale () {
        return def(this._locale)
    }
    set locale (value:string) {
        this._locale = value
    }
    get timeZone () {
        return def(this._timeZone)
    }
    set timeZone (value:string) {
        this._timeZone = value
    }
    get numToCat () {
        return this._numToCat
    }
    set numToCat (value:number) {
        this._numToCat = value
    }

    get numberFormatter () {
        return def(this._numberFormatter)
    }
    get dateFormatter () {
        return def(this._dateFormatter)
    }
    get timeFormatter () {
        return def(this._timeFormatter)
    }
    get dateTimeFormatter () {
        return def(this._dateTimeFormatter)
    }
}

export const settings = new Settings()

export function formatNumber (value:number):string {
    return Math.abs(value) > 0.1 || value === 0
        ? settings.numberFormatter.format(value)
        : (Math.abs(value) > 0.0001
                ? (value.toPrecision(3)).toString().replace(/0+$/g, "").replace(/\.$/g, "")
                : value.toExponential(2)
        );
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function nope():void {}

export function log<T>(val:T, ...args:any[]):T {
    let color
    switch (args[0]) {
        case 'r': color='red';break;
        case 'g': color='green';break;
        case 'b': color='blue';break;
    }
    if (color) {
        console.log ('%c'+val,'color:'+color);
    } else {
        console.log(...[val, ...args])
    }
    return val
}

export function glog<T>(val:T):T {
    //console.clear()
    return log(val, 'g')
}

export function deNull(str:string|number|null):string {
    return str === null ? nullText : str.toString()
}

export function sleeper(ms:number) {
    return function(x:any) {
        return new Promise(resolve => setTimeout(() => resolve(x), ms));
    };
}

export function assert (mustBe:boolean, message = 'assertion error') {
    if (!mustBe) throw new Error (message)
}

export function shallowCopy<T> (value:T):T {
    return Object.assign({}, value) as T
}

export function mustNotBeNull<T> (arg: T): arg is Exclude<T, null> {
    if (arg !== null) {
        return true
    } else {
        throw new Error('Unexpected null value')
    }
}

export function mustNotBeNullNorUndefined<T> (arg: T): arg is Exclude<Exclude<T, null>, undefined> {
    if (arg !== undefined && arg !== null) {
        return true
    } else {
        throw new Error('The value should not be null nor undefined')
    }
}

export function mustBeDefined<T> (arg: T): arg is Exclude<T, undefined> {
    if (arg !== undefined) {
        return true
    } else {
        throw new Error('The value should not be undefined')
    }
}

// use it when you a sure that the argument will never be undefined at runtime
export function def<T> (arg: T|undefined): T {
    if (arg !== undefined) {
        return arg
    } else {
        throw new Error('Hmm, undefined is not supposed to be here!')
    }
}

// use it when you a sure that the argument will never be null at runtime
export function notNull<T> (arg: T|null): T {
    if (arg !== null) {
        return arg
    } else {
        throw new Error('Hmm, null is not supposed to be here!')
    }
}

// use it when you a sure that the argument will never be undefined or null at runtime
export function defNotNull<T> (arg: T|null|undefined): T {
    if (arg !== undefined && arg !== null) {
        return arg
    } else {
        throw new Error(`Hmm, ${arg === null ? 'null' : 'undefined'} is not supposed to be here!`)
    }
}

// use it for getting a value from a map if you are sure that it is there
export function get<T,U> (map:Map<T,U>, key: T): U {
    const val = map.get(key)
    if (val !== undefined) {
        return val
    } else {
        throw new Error(`It is expected for "${key}" to be in the map`)
    }
}

export function shouldBeNumber (value: any):number {
    if (typeof value === "number") {
        return value
    } else {
        throw new Error(value + ' should be a number')
    }
}

export function omit<T extends Record<string, any>, U extends keyof T> (value:T, props:U[]):Omit<T, U> {
    props.forEach(prop => delete value[prop])
    return value
}

export function cls (...args:Array<boolean | string>) {
    const classes = [];
    for (let i=0;i<args.length;i+=2)
        if (args[i+1]) {
            classes.push(args[i]);
        }
    return classes.join(' ');
}

export function areEqual (a:number[], b:number[]):boolean {
    return !(a.length !== b.length || a.filter(x => b.indexOf(x) < 0).length || b.filter(x => a.indexOf(x) < 0).length)
}

export function fround(x:number) {
    const p = Math.ceil(Math.log10(Math.abs(x)))
    if (p > 10) {
        return Math.round(x)
    } else {
        const k = Math.pow(10, 10 - Math.max(p, -5))
        return Math.round(x * k) / k
    }
}

export function equal(x:number, y:number) {
    return Math.abs(x - y) < Number.EPSILON
}

export function digitsAfterDecimal (value:number) {
    return notNull(value.toFixed(11).match(/^\d+\.(\d*?)0*$/))[1].length
}

export function* range (start:number, stop?:number, step = 1) {
    const a = stop === undefined ? 0 : start,
        b = stop === undefined ? start : stop

    if ((a>b && step>=0) || (a<=b && step<=0)) {
        throw Error (`Iteration from ${a} to ${b} having step=${step} threatens to loop forever`)
    }

    for (let i=a; a < b ? i<b : i>b; i+=step) {
        yield fround(i)
    }
}

export function fromTo<T> (from:number, toNotIncluding:number, callback:(i:number)=>T):T[] {
    const dir = Math.sign(toNotIncluding - from),
        steps = Math.abs(toNotIncluding - from),
        result = Array(steps)
    for (let i=0; i<steps; i++) {
        result[i]=callback(from + i * dir)
    }
    return result
}

export function repeat<T> (times:number, value:T):T[] {
    return new Array(times).fill(value)
}

// calls the callback several times passing the iteration number as a parameter
// and returns the array of callback's results
export function times<T> (times:number, callback:(i:number)=>T):T[] {
    return fromTo (0, times, callback)
}

// breaks [<start>;<stop>] range to <parts> parts and returns <parts>+1 values
export function* splitRange (start:number, stop:number, parts:number) {
    const step = (stop - start) / parts
    yield fround(start)
    for (let i=1; i<parts; i++) {
        yield start + step * i
    }
    yield fround(stop)
}

export function first<T> (list:T[], callback:(element:T)=>boolean):T|undefined {
    for (let i=0;i<list.length;i++) {
        if (callback(list[i])) {
            return list[i]
        }
    }
    return undefined
}

export function allDefined<T> (list:(T|undefined)[]):T[] {
    return list.filter (x => x !== undefined) as T[]
}

export function findIndexOrThrow<T> (array:T[], value:T):number {
    const index = array.findIndex(x => x === value)
    if (index < 0) {
        console.log ('looking for', value)
        console.log ('in', array)
        throw Error (`There is no ${value} in the given array. See console for details.`)
    }
    return index
}

export function quoteMultiWord (str:string, quoteAlways=false):string {
    return quoteAlways || str.indexOf(' ') >=0 ? `"${str.trim()}"` : str.trim()
}

export function joinWithAnd (parts:string[], quoteAlways=false):string {
    switch (parts.length) {
        case 0:
            return ''
        case 1:
            return quoteMultiWord(parts[0], quoteAlways)
        default:
            let result = quoteMultiWord(parts[0], quoteAlways)
            for (let i=1; i<parts.length; i++) {
                result = result + (i === parts.length - 1 ? ' ' + $t('utils.and') + ' ' : ', ') + quoteMultiWord(parts[i], quoteAlways)
            }
            return result
    }
}

export function excludeNullOrUndefined<T> (parts:(T|undefined|null)[]):T[] {
    return parts.filter(p => p !== null && p !== undefined) as T[]
}

export function parseIntNotNan (str:string):number {
    const number = parseInt(str)
    if (Number.isNaN(number)) {
        throw Error (`"${str}" can't be parsed as integer`)
    }
    return  number
}

export function firstLetterUp(string:string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function removeSuffix (s: string, suffix: string) {
    const index = s.lastIndexOf(suffix)
    return index < 0 ? s : s.slice(0, index)
}

export function betweenIncluding (value:number, min:number, max:number):boolean {
    return value >= min && value <= max
}

export function isFileNameValid (name:string) {
    return name.trim().length && !/[/<>:"\\|?*]/.test(name);
}

export class FormatError extends Error {
    constructor(message='') {
        super(message)
        this.name = "FormatError"
    }
}

export function concatByteArrays (...arrays:Uint8Array[]) {
    const result = new Uint8Array(arrays.reduce((sum, array) => sum + array.byteLength, 0))
    let offset = 0
    for (let i=0;i<arrays.length;i++) {
        result.set (arrays[i], offset)
        offset += arrays[i].byteLength
    }
    return result
}

export const max = (values:number[], firstValue?:number) =>
    values.reduce((result, value) => result > value ? result : value, firstValue ?? -Infinity)

export const min = (values:number[], firstValue?:number) =>
    values.reduce((result, value) => result < value ? result : value, firstValue ?? Infinity)

export const sum = (values:number[], firstValue?:number) =>
    values.reduce((result, value) => result + value, firstValue ?? 0)

const startTime:number[] = []

export function start(id=0) {
    startTime[id] = Date.now()
}

export function stop(name='', id=0) {
    console.log (`${name}${name?': ':''}${Date.now()-startTime[id]} ms`)
}

export function profile(a:()=>void, b?:()=>void, loops=1000000) {
    start(-1)
    for (let i=0;i<loops;i++) a()
    stop('A', -1)

    if (b) {
        start(-1)
        for (let i = 0; i < loops; i++) b()
        stop('B', -1)
    }
}

export function transpose (input:DataValue[][]):DataValue[][] {
    const rowCount = max(input.map (a => a.length))
    const output:DataValue[][] = []
    for (let r=0; r<rowCount; r++)
        output.push (input.map (a => a[r] === undefined ? null : a[r]))
    return output
}

/*
export function generateUniform (min:number, max:number, count:number) {
    const sample = []
    for (let i=0;i<count;i++) sample[i] = Math.round((min + Math.random() * (max - min)) * 10000) / 10000
    return sample
}
*/

export function isSameClasses (obj1:Record<string, any>, obj2:Record<string, any>, level=0):boolean {
    for (const fieldName in obj1) {
        if (obj1.hasOwnProperty(fieldName) && obj2.hasOwnProperty(fieldName)) {
            const field1 = obj1[fieldName], field2 = obj2[fieldName]
            if (field1 !== typeof field2) {
                return false
            } if ((field1 === null && field2 !== null) || (field1 !== null && field2 === null)) {
                return false
            } else if (typeof field1 === "object" && field1 !== null && field2 !== null) {
                if (field1.constructor.name !== field2.constructor.name || !isSameClasses(field1, field2, level + 1)) {
                    return false
                }
            }
        }
    }
    return true
}

export function doNothing () {
    // simply does nothing
}

// allows to call functions that don't receive undefined, returing passed undefined right away
export function aun<T, U> (fun:(x:T)=>U, arg:T|undefined):U|undefined {
    return arg === undefined ? undefined : fun(arg)
}

// pass switch's argument to this function in the default section to check that you covered all the cases
export function shouldNeverGetHere(x:never):never {
    throw new Error ("This function should never be called. "+x)
}

// used in unreachable branches of conditional expressions returning functions
export function stub():any {
    throw new Error ("This stub is not expected to be called. ")
}