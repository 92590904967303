import {DataValue, IColumnSummary, ITableColumn} from "../../Basic"
import {SemanticType} from "../../SemanticType"
import {StandardColor} from "../../Colors"

export interface LineChartSeries {
    name:string
    color:StandardColor
}

export interface LineChartDataEntry {
    x: number,
    series: DataValue[]
}

export interface LineChartInfo {
    serInfo: LineChartSeries[]
    serValues: DataValue[][]
    stype: SemanticType,
    summary: IColumnSummary,
    units: string | null,
}

export enum AxisType {
    ExistingColumn = "ExistingColumn",
    NaturalNumbers = "NaturalNumbers"
}

export type XAxisInfo =
    {axisType: AxisType.ExistingColumn, column: ITableColumn}
    | {axisType: AxisType.NaturalNumbers, count: number}
