import {Aggregation, AggregationType} from "../Aggregation"
import {ITableColumn} from "../Basic"

export type SingPlur = {sing:string, plur:string}

export interface VisData {
    aggrResult: Aggregation<any>
    hasNegatives: boolean
    hasPositives: boolean
    aggrPrefix: SingPlur
    aggrType: AggregationType
    hasNULLs: boolean
    hasNonNULLs: boolean
    title:string
}

export interface VisColData {
    numericalColumns:ITableColumn[]
    breakColumn:ITableColumn
    footer: string | undefined
}

export enum VisControls {
    stacked = 1,
    sumPosAndNeg = 2,
    aggrType = 3,
    sortBy = 4,
    sortDir = 5
}

export enum SortBy {
    breakingColumn = "col",
    positiveValues = "pos",
    negativeValues = "neg"
}

export enum SortOrder {
    ascending = "asc",
    descending = "desc"
}

export enum BarType {
    separated = "sep",
    stacked = "stack"
}

export enum PosNeg {
    summed = "sum",
    separated = "sep"
}

