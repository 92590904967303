import {
    GetActionsEvent,
    GetActionsEventType,
    MatchDegree,
    TooltipsById, UserActionInfo, UserActionResponse,
    VisContent,
    VisCtrlAction,
    VisCtrlActionType,
    VisCtrlId,
    VisCtrlInfo,
    VisCtrlType,
    VisCtrlValues,
    VisualizerColumnData,
    VisualizerData,
    VisualizerType
} from "../Concepts/Visualizer"
import {Visualizer} from "./Visualizer"
import {render as renderSPlot} from './Renderers/ScatterPlot'
import {render as renderNumHist} from './Renderers/NumFreqHistSharedX'
import {render as renderCatHist} from './Renderers/CategoryHistorgam'
import * as VC from "./VisCommon"
import Icon from './VisIcons/ScatterPlotMatrix.svg'
import * as Colors from "../Colors"
import * as U from '../Utils'
import css from "./Visualizer.module.scss"
import * as React from "react"
import {Link} from "../Workspace/WorkspaceLocation"
import * as ScatterPlot from "../Concepts/Visualizers/ScatterPlot"
import * as STT from "../StypeTools"
import {addSamplingMessage} from "../Data/DataSampling"
import {ColumnId, ITableColumn, Percentage} from "../Concepts/Basic"
import {SemanticType} from "../Concepts/SemanticType"
import {VisColData, VisControls, VisData} from "../Concepts/Visualizers/ScatterPlotMatrix"
import {IDataTable} from "../Concepts/DataTable";
import $t from "../i18n/i18n";

export default class ScatterPlotMatrix extends Visualizer {

    override get type () {return VisualizerType.ScatterPlotMatrix}

    matchDegree(columns:ITableColumn[]): MatchDegree {
        return columns.length > 2
                && columns.every(c => STT.isOrdinal(c.stype) || STT.isCategorical(c.stype) || STT.isContinuous(c.stype))
                && columns.filter(c => c.summary.nullCount < c.summary.valueCount).length > 2
            ? (columns.length < 4 ? MatchDegree.AsALastResort : (columns.length > 5 ? MatchDegree.BestFit : MatchDegree.Ordinary))
            : MatchDegree.NoMatch
    }

    getTitle() {
        return $t('splotmatrix.name')
    }

    getIconUrl(): string {
        return Icon
    }

    getColumnData(dataTable:IDataTable): VisColData {
        const colData = this.getColumnIdsTitleFooter(dataTable, $t('splotmatrix.title'))
        return {
            ...colData,
            categoricalColumnIds: colData.columnIds.filter(id => STT.isCategorical(dataTable.getStype(id)))
        }
    }

    getControls(dataTable:IDataTable, columnData: VisualizerColumnData): Map<VisCtrlId, VisCtrlInfo> {
        const cd = columnData as VisColData
        return new Map<VisCtrlId, VisCtrlInfo>([
            [VisControls.categoriesColumn, {
                title: $t('splotmatrix.ctrl.colorby'),
                type: VisCtrlType.Select,
                options: new Map([['', $t("ctrl.value.none")] as [string, string]].concat(
                    cd.categoricalColumnIds
                        .map(id => [id.toString(), dataTable.getColumnInfo(id).title])
                )),
                defaultValue: '',
            }]
        ])
    }

    getCategoryColumnId = (controlValues:VisCtrlValues) =>
        U.get(controlValues, VisControls.categoriesColumn)
            ? ColumnId(U.parseIntNotNan(U.get(controlValues, VisControls.categoriesColumn)))
            : null

    getActionsOnControls (event:GetActionsEvent, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues):VisCtrlAction[] {
        const cd = columnData as VisColData,
            actions:VisCtrlAction[] = []

        if (event.type === GetActionsEventType.DataLoadedWithUserControlValues
            || event.type === GetActionsEventType.DataLoadedWithSuggestedControlValues) {
            let catColumn = this.getCategoryColumnId(controlValues)

            // clear the category column if it doesn't exist in a column set
            if (catColumn !== null && cd.columnIds.indexOf(catColumn) < 0) {
                actions.push({action: VisCtrlActionType.SetValue, controlId: VisControls.categoriesColumn, controlValue: ''})
                catColumn = null
            }

            // automatically assign the first categorical column if not assigned yet
            if (event.type === GetActionsEventType.DataLoadedWithSuggestedControlValues
                && catColumn === null
                && cd.categoricalColumnIds.length > 0) {

                catColumn = cd.categoricalColumnIds[0]
                actions.push({
                    action: VisCtrlActionType.SetValue,
                    controlId: VisControls.categoriesColumn,
                    controlValue: catColumn.toString()
                })
            }

            if (cd.categoricalColumnIds.length === 0) {
                actions.push({action: VisCtrlActionType.HideControl, controlId: VisControls.categoriesColumn})
            }

            this.sendColoringEvent (catColumn)

        } else if (event.type === GetActionsEventType.ControlValueChangedByUser
                   && event.controlId === VisControls.categoriesColumn) {
            this.sendColoringEvent (event.newControlValue)
        }

        return actions
    }

    getData (requestId:number, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues):Promise<{data:VisData, requestId:number}> {
        const cd = columnData as VisColData
        return dataTable.getTuples(cd.columnIds).then (data => ({data:{data}, requestId}))
    }

    getContent (data:VisualizerData, width:number, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues, tooltips:TooltipsById):VisContent {
        const d = data as VisData,
            cd = columnData as VisColData,
            categoryColumnId = this.getCategoryColumnId(controlValues),
            columnIds = cd.columnIds.filter(id => id !== categoryColumnId),
            titles = columnIds.map (id => dataTable.getColumnInfo(id).title),
            axesFontSize = Math.min(VC.maxLabelFontSize, Math.max (VC.minFontSize, width / 40)),
            plotWidth = (width - axesFontSize) / columnIds.length,
            histogramColor = (xColumnId:ColumnId) => categoryColumnId === null ? dataTable.getColumnInfo(xColumnId).color : Colors.defaultPlotColor

        const renderPlot = (xColumnId:ColumnId, yColumnId:ColumnId, xStype:SemanticType) => xColumnId === yColumnId
            ? STT.isContinuous(xStype)
                ? renderNumHist(
                    new Map ([[xColumnId, dataTable.getColumnInfo(xColumnId).numberSummary]]),
                    {from:Percentage(0), to:Percentage(100)},
                    12,
                    plotWidth,
                    true,
                    '',
                    new Map ([[xColumnId, histogramColor(xColumnId)]]),
                    null,
                    '',
                    xStype,
                    tooltips,
                    false,
                    xColumnId+'_'+yColumnId,
                    true)
                : renderCatHist(
                    dataTable.getColumnInfo(xColumnId).categoryDistribution,
                    plotWidth,
                    true,
                    '',
                    histogramColor(xColumnId),
                    tooltips,
                    xColumnId+'_'+yColumnId,
                    true
                )
            : renderSPlot(
                d.data,
                {
                    x: dataTable.getColumnInfo(xColumnId),
                    y: dataTable.getColumnInfo(yColumnId),
                    color: categoryColumnId === null ? null : dataTable.getColumnInfo(categoryColumnId),
                    size: null, time: null
                },
                plotWidth,
                null,
                0.5,
                tooltips,
                false,
                {uniqueId: xColumnId+'_'+yColumnId, plotOnly: true}
                )

        const rows:(JSX.Element|undefined)[][] = []
        let totalRowHeight = 0, samplingApplied = false
        for (const yColumnId of columnIds) {
            let rowHeight = 0, histDrawn = false
            const row = []
            for (const xColumnId of columnIds) {
                if (histDrawn) {
                    row.push(undefined)
                } else {
                    const stype = dataTable.getStype(xColumnId),
                        renderResult = renderPlot(xColumnId, yColumnId, stype)
                    samplingApplied = samplingApplied || (renderResult.samplingApplied ?? false)
                    rowHeight = Math.max(rowHeight, renderResult.height)
                    row.push(<Link columnIds={categoryColumnId ? [xColumnId, yColumnId, categoryColumnId] : [xColumnId, yColumnId]}
                                   visType={
                                       xColumnId === yColumnId
                                           ? STT.isCategorical(stype)
                                                ? VisualizerType.PieBarChart
                                                : VisualizerType.NumFreqHistSharedX
                                           : VisualizerType.ScatterPlot
                                   }
                                   settings={
                                       xColumnId !== yColumnId
                                           ? [
                                               {id:ScatterPlot.VisControls.xAxis, value:xColumnId.toString()},
                                               {id:ScatterPlot.VisControls.yAxis, value:yColumnId.toString()},
                                               categoryColumnId
                                                   ? {id:ScatterPlot.VisControls.colorAxis, value:categoryColumnId.toString()}
                                                   : undefined
                                           ]
                                           : undefined
                                   }
                    > {renderResult.svg} </Link>)
                }
                if (xColumnId === yColumnId) {
                    histDrawn = true
                }
            }
            rows.push (row)
            totalRowHeight += rowHeight
        }

        return {
            node: <table cellPadding={0} cellSpacing={0} className={css.chartGridTable}>
                <tbody>
                    <React.Fragment>
                    {
                        rows.map((row, rowNumber) =>
                            <tr key={`row${rowNumber}`}>{
                                row.map((chart, colNumber) =>
                                    [colNumber === 0
                                        ? <td key={`yt${rowNumber}`}
                                              style={{verticalAlign: "middle", fontSize:axesFontSize, fontWeight:"normal"}}>
                                            <Link columnIds={[columnIds[rowNumber]]}
                                                  visType={STT.isCategorical(dataTable.getColumnInfo(columnIds[rowNumber]).stype) ? VisualizerType.PieBarChart : VisualizerType.NumFreqHistSharedX}>
                                                <div style={{width:axesFontSize, writingMode: "vertical-rl", textOrientation: "mixed"}}>{titles[rowNumber]}</div>
                                            </Link>
                                        </td>
                                        : undefined,
                                        <td key={`col${colNumber}`} className={css.chartGridCell}>
                                            {chart ?? <div/>}
                                        </td>
                                    ])
                            }</tr>
                        )
                    }
                    <tr key={`xtitles`}>{
                        [
                            <td key={`stub`} className={css.chartGridTitleCell}>&nbsp;</td>,
                            ...titles.map((title, index) =>
                                <td key={`xt${index}`}
                                    style={{textAlign: "center", fontSize:axesFontSize, fontWeight:"normal"}}>
                                    <Link columnIds={[columnIds[index]]}
                                          visType={STT.isCategorical(dataTable.getColumnInfo(columnIds[index]).stype) ? VisualizerType.PieBarChart : VisualizerType.NumFreqHistSharedX}>
                                        {title}
                                    </Link>
                                </td>)
                        ]
                    }</tr>
                    </React.Fragment>
                </tbody>
            </table>,
            title: $t('splotmatrix.title')
                + ' '
                + U.joinWithAnd(titles, true)
                + (categoryColumnId ? ` ${$t('splotmatrix.title.coloredby')} "${dataTable.getColumnInfo(categoryColumnId).title}"`: ''),
            footer: addSamplingMessage (cd.footer, samplingApplied, " in one or more charts"),
            height: totalRowHeight
        }
    }

    handleUserAction (userActionInfo: UserActionInfo, data:VisualizerData, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues):UserActionResponse|undefined {
        return undefined
    }
}