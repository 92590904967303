import React from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, TextField} from "@material-ui/core"
import {TransitionProps} from '@material-ui/core/transitions'
import * as U from '../../Utils'
import event from "../../EventSender/Events"
import $t from "../../i18n/i18n"

interface Props {
    open: boolean
    fileName: string
    onCancel: ()=>void
    onSave: (fileName:string)=>number
}

interface State {
    open: boolean
    fileName: string
}

export class SaveDialog extends React.PureComponent<Props, State> {
    override readonly state:State = {
        open: false,
        fileName: ''
    }

    protected readonly transition = React.forwardRef(function Transition(
        props: TransitionProps & { children?: React.ReactElement<any, any> },
        ref: React.Ref<unknown>,
    ) {
        return <Slide direction="right" ref={ref} {...props} />;
    })

    static getDerivedStateFromProps(props:Props, state:State):State | null {
        return props.open &&  !state.open ? {
            open: true,
            fileName: props.fileName
        } : null
    }

    protected handleCancel = () => {
        event.ui.dialog.close()
        this.props.onCancel()
    }

    protected handleSave = () => {
        if (U.isFileNameValid(this.state.fileName)) {
            event.menu.sidebar.save.done(this.props.onSave(this.state.fileName))
        }
    }

    protected handleFileNameChange = (event:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        this.setState({fileName: event.target.value.trim()})
    }

    protected handleInputKey = (event:React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            this.handleSave()
        }
    }

    override render () {
        const valid = U.isFileNameValid(this.state.fileName)
        return (
            <Dialog open={this.props.open}
                    onClose={this.handleCancel}
                    aria-labelledby="save-dialog-title"
                    TransitionComponent={this.transition}
                    fullWidth={true}
                    maxWidth={'xs'}
            >
                <DialogTitle id="save-dialog-title">{$t('savedialog.title')}</DialogTitle>
                <DialogContent>
                    <TextField
                        required={true}
                        error={!valid}
                        autoFocus
                        label={$t('savedialog.file_name.label')}
                        fullWidth
                        value={this.state.fileName}
                        onChange={this.handleFileNameChange}
                        onKeyDown={this.handleInputKey}
                        variant="standard" />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel}>
                        {$t('dialog.button.cancel')}
                    </Button>
                    <Button onClick={this.handleSave} color="primary" disabled={!valid}>
                        {$t('savedialog.button.save')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
