import * as React from 'react'
import {
    GetActionsEvent,
    GetActionsEventType,
    MatchDegree,
    TooltipsById, UserActionInfo, UserActionResponse,
    VisContent,
    VisCtrlAction,
    VisCtrlId,
    VisCtrlInfo,
    VisCtrlValues,
    VisualizerColumnData,
    VisualizerData,
    VisualizerType
} from "../Concepts/Visualizer"
import {Visualizer} from "./Visualizer"
import CategoryDistribution from "../Data/CategoryDistribution"
import {render as renderNum} from "./Renderers/NumFreqHistSharedX"
import {render as renderCat} from "./Renderers/CategoryHistorgam"
import css from './Visualizer.module.scss'
import * as U from '../Utils'
import * as STT from "../StypeTools"
import {ColumnId, INumberSummary, ITableColumn, Percentage} from "../Concepts/Basic"
import {SemanticType} from "../Concepts/SemanticType"
import {AggregationByCategory, AggregationType} from "../Concepts/Aggregation"
import {CategoryToColor} from "../Concepts/Colors"
import Icon from "./VisIcons/FreqDistr.svg"
import {userSettings} from "../LocalSettings"
import {Link} from "../Workspace/WorkspaceLocation"
import {VisColData, VisData} from "../Concepts/Visualizers/FreqDistrGrid"
import {IDataTable} from "../Concepts/DataTable";
import $t from "../i18n/i18n";

export default class FreqDistrGrid extends Visualizer {
    readonly MIN_CHART_WIDTH = 300

    override get type () {return VisualizerType.FreqDistrGrid}

    matchDegree(columns: ITableColumn[]): MatchDegree {
        return columns.length > 1 && !columns.some(c => !STT.isContinuous(c.stype) && !STT.isCategorical(c.stype))
            ? MatchDegree.AsALastResort
            : MatchDegree.NoMatch
    }

    getTitle () {
        return $t('freqdistrgrid.name')
    }

    getIconUrl ():string {
        return Icon
    }

    getColumnData (dataTable:IDataTable):VisColData {
        return this.getColumnIdsTitleFooter (dataTable, $t('freqdistrgrid.title'))
    }

    getControls(dataTable:IDataTable, columnData:VisualizerColumnData):Map<VisCtrlId, VisCtrlInfo> {
        return new Map ()
    }

    getData (requestId:number, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues):Promise<{data:VisData, requestId:number}> {
        const cd = columnData as VisColData,
            catToColorByColId:Map<ColumnId, CategoryToColor> = new Map (),
            numCategoriesByColId:Map<ColumnId, number> = new Map (),
            stypeByColId:Map<ColumnId, SemanticType> = new Map (),
            getAggrResultForColumn = (dataTable:IDataTable, columnInfo:ITableColumn):Promise<{aggrResult: AggregationByCategory, colInfo: ITableColumn}> =>
                dataTable.aggregateByCategory(AggregationType.Sum, columnInfo.id).then (res => ({aggrResult: res, colInfo: columnInfo})),
            promises:Promise<void | AggregationByCategory>[] = [],
            data: Map<ColumnId, INumberSummary | AggregationByCategory> = new Map ()

        for (const colId of cd.columnIds) {
            const colInfo = dataTable.getColumnInfo(colId)
            stypeByColId.set (colId, colInfo.stype)
            if (STT.isCategorical(colInfo.stype)) {
                promises.push(getAggrResultForColumn(dataTable, colInfo).then(d => {
                    catToColorByColId.set(d.colInfo.id, d.colInfo.categories)
                    data.set (d.colInfo.id, d.aggrResult)
                }))
            } else {
                // save categories number to identify cases when we can plot histogram with one bin for a value
                const numSum = dataTable.getColumnInfo(colId).numberSummary,
                    valueBins = numSum.categories ? Math.round((numSum.max - numSum.min) / numSum.categories.valueGridStep) + 1 : 0
                    numCategoriesByColId.set (colId, valueBins && valueBins <= userSettings.getNumber("histBins") ? valueBins : 0)
                    data.set (colId, numSum)
            }
        }
        return Promise.all(promises).then (() => ({data:{data, catToColorByColId, numCategoriesByColId, stypeByColId}, requestId}))
    }

    getActionsOnControls (event:GetActionsEvent, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues):VisCtrlAction[] {
        if (event.type === GetActionsEventType.DataLoadedWithSuggestedControlValues
            || event.type === GetActionsEventType.DataLoadedWithUserControlValues) {
            this.sendColoringEvent(null)
        }
        return []
    }

    getContent (data:VisualizerData, width:number, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues, tooltips:TooltipsById):VisContent {
        const d = data as VisData,
            cd = columnData as VisColData,
            colIds = [...d.data.keys()],
            columns = Math.max(1, Math.min(Math.floor(width / this.MIN_CHART_WIDTH), colIds.length)),
            chartWidth = width / columns,
            rows = Math.ceil(colIds.length / columns),
            charts: JSX.Element[][] = [],
            titles: string[][] = [],
            colId = (row:number, col:number) => colIds[row * columns + col]

        let totalRowHeight = 0
        for (let row = 0; row < rows; row++) {
            const elementsInRow = [], titlesInRow = []
            let rowHeight = 0
            for (let col = 0; col < columns; col++) {
                const columnId = colId(row, col)
                if (columnId !== undefined) {
                    const colInfo = dataTable.getColumnInfo(columnId)
                    titlesInRow.push(colInfo.title)
                    const renderResult = STT.isCategorical (colInfo.stype)
                    ? renderCat(
                            CategoryDistribution.fromAggrResult(d.data.get(columnId) as AggregationByCategory),
                            chartWidth,
                            false,
                            '',
                            colInfo.color,
                            tooltips)
                        : renderNum(new Map ([[columnId, d.data.get(columnId) as INumberSummary]]),
                            {from:Percentage(0), to:Percentage(100)},
                            d.numCategoriesByColId.get(columnId) ? U.get(d.numCategoriesByColId, columnId) : Math.round(userSettings.getNumber("histBins") / 2),
                            chartWidth,
                            false,
                            '',
                            new Map ([[columnId, colInfo.color]]),
                            null,
                            colInfo.units || '',
                            U.get(d.stypeByColId, columnId),
                            tooltips,
                            U.get(d.numCategoriesByColId, columnId) > 0)

                    elementsInRow.push(renderResult.svg)
                    rowHeight = Math.max (rowHeight, renderResult.height)
                }
            }
            charts.push(elementsInRow)
            titles.push(titlesInRow)
            totalRowHeight += rowHeight
        }

        return {
            node: <table className={css.chartGridTable}>
                    <tbody>
                        {
                            U.times(rows, row =>
                                <React.Fragment key={row}>
                                    <tr key={`t${row}`}>{U.times(columns, column =>
                                        <td key={column} className={css.chartGridTitleCell}>
                                            {titles[row][column] ?? ''}
                                        </td>)
                                    }</tr>
                                    <tr key={`c${row}`}>{U.times(columns, column =>
                                        <td key={column} className={css.chartGridCell}>
                                            {
                                                charts[row][column]
                                                    ?   <Link columnIds={[colId(row, column)]}
                                                              visType={
                                                                  STT.isCategorical(dataTable.getColumnInfo(colId(row, column)).stype)
                                                                      ? VisualizerType.PieBarChart
                                                                      : VisualizerType.NumFreqHistSharedX
                                                              }>
                                                            {charts[row][column]}
                                                        </Link>
                                                    : <div/>
                                            }
                                        </td>)
                                    }</tr>
                                </React.Fragment>
                            )
                        }
                    </tbody>
                  </table>,
            title: cd.title,
            footer: cd.footer,
            height: totalRowHeight
        }
    }

    handleUserAction (userActionInfo: UserActionInfo, data:VisualizerData, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues):UserActionResponse|undefined {
        return undefined
    }
}