export enum SemanticType {
    Skip = "Skip",
    Number = "Number",
    Category = "Category",
    Text = "Text",
    DateTime = "DateTime",
    Date = "Date",
    Time = "Time",
    Identifier = "Identifier",
    OrdinalNumber = "OrdinalNumber"
}

export enum ParsedType {
    Null = "Null",
    Text = "Text",
    Number = "Number",
    Date = "Date",
    Time = "Time",
    DateTime = "DateTime"
}