// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react'
import {SemanticType} from "../Concepts/SemanticType"
import {Point} from "../Concepts/Geometry"
import {AggregationType} from "../Concepts/Aggregation"
import * as U from "../Utils"
import * as STT from "../StypeTools"
import {ITooltip, ITooltipRow} from "../Concepts/Visualizer"

export const chartFontFamily = "Roboto"
export const minFontSize = 10
export const minHorizXLabelFontSize = 12
export const maxLabelFontSize = 15

export class TooltipRow implements ITooltipRow {
    constructor(protected readonly value: number|string|null,
                protected readonly name?: string|null,
                protected readonly stype?: SemanticType,
                protected readonly units?: string ) {
    }

    get html () {
        return (this.name !== undefined ? `<b>${U.deNull(this.name)}:</b> ` : '') +
            escHTML(this.stype
                ? STT.formatTooltipValue(this.stype, this.value)
                : typeof this.value === "number"
                    ? STT.formatTooltipValue(SemanticType.Number, this.value)
                    : U.deNull(this.value)) +
            (this.units === undefined || this.value === null ? '' : ' ' + escHTML(this.units))
    }
}

export class Tooltip implements ITooltip {
    constructor(readonly pivot:Point, readonly rows:(ITooltipRow|undefined)[]) {
    }

    get html() {
        return this.rows.filter(row => row).map (row => U.def(row).html).join('<br/>')
    }
}

export function escHTML (str:string):string {
    return str
        .replace('&', '&amp;')
        .replace('<', '&lt;')
        .replace('>', '&gt;')
        .replace('"', '&quot;')
        .replace('\'', '&#39;')
        .replace(' ', '&nbsp;')
}

export function getAxisUnitsByAggrType (units:string | null, type:AggregationType | undefined):string {
    return type === AggregationType.NullCount || type === AggregationType.NonNullCount ? '' : units ?? ''
}