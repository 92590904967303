import React from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide} from "@material-ui/core"
import {TransitionProps} from '@material-ui/core/transitions'
import $t from "../../i18n/i18n"

interface Props {
    open: boolean
    title?: string
    message: string
    onClose: ()=>void
}

export class MessageBox extends React.PureComponent<Props> {

    protected readonly transition = React.forwardRef(function Transition(
        props: TransitionProps & { children?: React.ReactElement<any, any> },
        ref: React.Ref<unknown>,
    ) {
        return <Slide direction="down" ref={ref} {...props} />;
    })

    override render () {
        return (
            <Dialog open={this.props.open}
                    onClose={this.props.onClose}
                    aria-labelledby={this.props.title ? "msg-dialog-title" : undefined}
                    TransitionComponent={this.transition}
                    fullWidth={true}
                    maxWidth={'xs'}
            >
                {this.props.title && <DialogTitle id="msg-dialog-title">{this.props.title}</DialogTitle>}
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.props.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onClose} color="primary">
                        {$t("dialog.button.cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}
