import {SemanticType} from "./SemanticType"
import {OptionalString} from "./Basic"

export type MsSinceEpoch = number

export interface DateAndTime {
    year: string
    month: string
    day: string
    hour: string
    minute: string
    second: string
}

export enum TimeIntervalType {
    Minute="minute",
    Hour="hour",
    Day="day",
    Week="week",
    Month="month",
    Quarter="quarter",
    Year="year"
}

export interface ITimeInterval {
    readonly type:TimeIntervalType
    getFirstMoment (value:MsSinceEpoch, stype:SemanticType):FirstMoment
    addInterval (moment:FirstMoment, stype:SemanticType):FirstMoment
    formatLabels(values:IterableIterator<FirstMoment|null>, stype:SemanticType, commonPart?:OptionalString):Map<FirstMoment|null, string|null>
}


export interface CustomTimeFormatter {
    format: (value:MsSinceEpoch) => string
    formatToParts: (value:MsSinceEpoch) => Intl.DateTimeFormatPart[]
}

//<editor-fold desc="FirstMoment">

// the first point in time of some concrete time interval (ex. "XXXX-01-01 00:00:00" if time interval is year)
export type FirstMoment = MsSinceEpoch & {readonly brand: unique symbol}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export function FirstMoment (time: number) {
    return time as FirstMoment
}

//</editor-fold>
