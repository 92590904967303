import * as React from 'react'
import * as U from "../Utils"
import {ITableColumn, ZeroColumnId} from "../Concepts/Basic"
import {AggregationType} from "../Concepts/Aggregation"
import {PieChartData} from "../Concepts/Visualizers/Renderers/PieChart"
import {
    GetActionsEvent,
    GetActionsEventType,
    TooltipsById, UserActionInfo, UserActionResponse,
    VisContent,
    VisCtrlAction,
    VisCtrlActionType,
    VisCtrlId,
    VisCtrlInfo,
    VisCtrlValues,
    VisualizerColumnData,
    VisualizerData,
    VisualizerType
} from "../Concepts/Visualizer"
import {VisColData, VisData} from "../Concepts/Visualizers/PieBarChart"
import {render as renderBars} from './Renderers/BarChart'
import {render as renderPie} from './Renderers/PieChart'
import Icon from './VisIcons/PieBarChart.svg'
import * as VC from "./VisCommon"
import * as Colors from "../Colors"
import * as TimeIntervals from "../Concepts/Visualizers/TimeIntervals"
import * as STT from "../StypeTools"
import AbstractNumColumnAggregator from "./AbstractNumColumnAggregator"
import * as NumColumnAggregator from "../Concepts/Visualizers/NumColumnAggregator"
import {BarType, PosNeg, SortBy} from "../Concepts/Visualizers/NumColumnAggregator"
import {BarChartData} from "../Concepts/Visualizers/Renderers/BarChart"
import {IDataTable} from "../Concepts/DataTable"
import $t from "../i18n/i18n";

export default class PieBarChart extends AbstractNumColumnAggregator<VisColData, VisData> {

    override get type () {return VisualizerType.PieBarChart}

    protected doesFitForBreakColumn (info: ITableColumn):boolean {
        return STT.isCategorical(info.stype)
    }

    protected isProperBreakColumn (info: ITableColumn):boolean {
        return true
    }

    getTitle () {
        return $t('piebarchart.name')
    }

    getIconUrl ():string {
        return Icon
    }

    getColumnData (dataTable:IDataTable):VisColData {
        const {numericalColumns, breakColumn} = U.notNull(this.recognizeColumns(dataTable.selectedColumns)),
            footer = this.getColumnIdsTitleFooter (dataTable).footer

        return {numericalColumns, breakColumn, footer}
    }

    getControls (dataTable:IDataTable, columnData:VisualizerColumnData):Map<VisCtrlId, VisCtrlInfo> {
        const cd = columnData as VisColData
        return new Map (this.getCommonControls(cd.breakColumn.title, cd.numericalColumns.length))
    }

    getData (requestId:number, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues):Promise<{data:VisData, requestId:number}> {
        const cd = columnData as VisColData,
            numColumnIds = cd.numericalColumns.map (c => c.id),
            aggrType = this.extractAggrType (controlValues, cd)

        return dataTable.aggregateByCategory(aggrType, cd.breakColumn.id, numColumnIds)
            .then ((aggrResult) => {
                const {hasNegatives, hasPositives} = this.determineNegPos(aggrResult),

                    {hasNULLs, hasNonNULLs} = this.determineNulls(cd.numericalColumns),

                    {title, aggrPrefix} = this.buildTitle(aggrType, cd.numericalColumns, cd.breakColumn),

                    rawChartData: BarChartData = new Map([...aggrResult.entries()].map (entry =>
                        [
                            entry[0],
                            entry[1] === null
                                ? null
                                : [...entry[1].keys()].map(valColId => {
                                    const valColumn = cd.numericalColumns.filter(c => c.id === valColId)[0]
                                    return {
                                        name: valColumn?.title ?? entry[0],
                                        color: cd.numericalColumns.length > 1
                                               ? valColumn.color
                                               : cd.breakColumn.categories.get(entry[0]) ?? Colors.noValueColor,
                                        value: U.get(U.notNull(entry[1]), valColId),
                                        columnId: valColumn?.id
                                    }
                                })
                        ]
                    ))

                let pieData:PieChartData | null = null
                if (cd.numericalColumns.length < 2
                    && aggrType !== AggregationType.Mean
                    && aggrType !== AggregationType.Min
                    && aggrType !== AggregationType.Max) {
                    const columnId = cd.numericalColumns.length === 0 ? ZeroColumnId : cd.numericalColumns[0].id
                    const total = [...aggrResult.values()].map (arr => arr?.get(columnId) ?? 0).reduce((sum, val) => sum + val, 0)
                    if (total > 0 && (!hasPositives || !hasNegatives)) {
                        pieData = []
                        for (const [category, data] of aggrResult.entries()) {
                            if (data !== null) {
                                const color = U.get(cd.breakColumn.categories, category),
                                    value = U.get(data, columnId)
                                pieData.push({
                                    category: {value: category, color},
                                    percent: value / total,
                                    value
                                })
                            }
                        }
                    }
                }

                return {
                    data: {
                        chartData: rawChartData,
                        aggrResult,
                        categoryToColor: cd.breakColumn.categories,
                        hasNegatives,
                        hasPositives,
                        aggrPrefix: aggrPrefix,
                        aggrType,
                        pieData,
                        hasNULLs,
                        hasNonNULLs,
                        title
                    },
                    requestId
                }
            })
    }

    getActionsOnControls (event:GetActionsEvent, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues):VisCtrlAction[] {
        const cd = columnData as VisColData,
            cv = this.getCurrentControlValues (event, controlValues),
            actions: VisCtrlAction[] = this.buildActionsOnControls(event, cd)

        // send coloring event
        if (event.type === GetActionsEventType.DataLoadedWithSuggestedControlValues || event.type === GetActionsEventType.DataLoadedWithUserControlValues) {
            this.sendColoringEvent(
                cd.numericalColumns.length < 2 ? cd.breakColumn.id : null,
                true,
                cd.numericalColumns.length > 1 ? [cd.breakColumn.id] : [])
        }

        if (event.type !== GetActionsEventType.BeforeDataLoaded) {
            const d = event.data as TimeIntervals.VisData
            // change visibility of sepPosNegValue
            actions.push ({
                action: cv.get(NumColumnAggregator.VisControls.stacked) === BarType.separated
                || !d.hasNegatives || !d.hasPositives
                    ? VisCtrlActionType.HideControl
                    : VisCtrlActionType.ShowControl,
                controlId: NumColumnAggregator.VisControls.sumPosAndNeg,
                controlValue: PosNeg.separated
            })
        }

        // reload data if user changed the aggregation type
        if (event.type === GetActionsEventType.ControlValueChangedByUser
            && event.controlId === NumColumnAggregator.VisControls.aggrType) {
            actions.push({action: VisCtrlActionType.ReloadData})
        }

        return actions
    }

    getContent (data:VisualizerData, width:number, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues, tooltips:TooltipsById):VisContent {
        const cd = columnData as VisColData,

            d = data as VisData,

            {aggrType, sumPosAndNeg, sortAscending, sortBy} = this.extractCommonParameters(controlValues, cd),

            sortedChartData = sortBy === SortBy.breakingColumn
                ? new Map ([...d.chartData.entries()].sort((a, b) =>
                    ((sortAscending ? a[0] : b[0]) ?? '').localeCompare((sortAscending ? b[0] : a[0]) ?? '')))
                : this.sortByValues (d.chartData,sortBy === SortBy.positiveValues, sortAscending, sumPosAndNeg),

            barResult = renderBars(
                cd.breakColumn,
                cd.numericalColumns,
                sortedChartData,
                aggrType,
                d.aggrPrefix.sing,
                width,
                controlValues.get(NumColumnAggregator.VisControls.stacked) === BarType.stacked,
                sumPosAndNeg,
                tooltips),

            pieResult = d.pieData
                ? renderPie(
                    d.pieData,
                    width,
                    cd.numericalColumns.length === 0 ? cd.breakColumn.units ?? '' : VC.getAxisUnitsByAggrType(cd.numericalColumns[0].units, aggrType),
                    cd.numericalColumns.length === 0 ? `${cd.breakColumn.title} = ` : $t('piebarchart.category_prefix', {aggregation: d.aggrPrefix.sing, aggrcol: cd.numericalColumns[0].title, breakcol: cd.breakColumn.title}) + ' ',
                    tooltips)
                : null

        return {
            node:<div>{barResult.svg}<div style={{marginTop:width/30+10}}>{pieResult?.svg}</div></div>,
            title: d.title,
            footer: cd.footer,
            height: barResult.height + (pieResult?.height ?? 0)
        }
    }

    handleUserAction (userActionInfo: UserActionInfo, data:VisualizerData, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues):UserActionResponse|undefined {
        return undefined
    }
}