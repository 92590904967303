// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react'
import * as U from '../../Utils'
import * as RC from './RendererCommon'
import * as Bricks from './Bricks'
import {BrickWithLabels, LabelData, LabelDataType} from './Bricks'
import * as STT from '../../StypeTools'
import {TooltipRow} from '../VisCommon'
import * as Colors from "../../Colors"
import {ColumnSummaryType, IColumnSummary} from "../../Concepts/Basic"
import {SemanticType} from "../../Concepts/SemanticType"
import {CategoryToColor} from "../../Concepts/Colors"
import {AxisType, LineChartDataEntry, LineChartSeries, XAxisInfo} from "../../Concepts/Visualizers/Renderers/LineChart"
import {TM, UniqueTestMarker} from "../../@testing/TestMarker"
import {RenderResult, TooltipsById} from "../../Concepts/Visualizer";
import $t from "../../i18n/i18n";


export function render (
    data: LineChartDataEntry[],
    xInfo: XAxisInfo,
    series: LineChartSeries[],
    yStype: SemanticType,
    ySummary: IColumnSummary,
    yUnits: string | null,
    yCatToColor: CategoryToColor | null,
    width: number,
    tooltips: TooltipsById,
    uniqueId=''):RenderResult {

    const

        bp = new Bricks.Params(tooltips,'lch' + uniqueId),

        axisXTitle = xInfo.axisType === AxisType.ExistingColumn
            ? xInfo.column.title + (xInfo.column.units ? ', ' + xInfo.column.units : '')
            : $t('linechart.row_number.nom'),

        axisYTitle = series.length === 1 ? series[0].name + (yUnits ? ', ' + yUnits : '') : (yUnits ?? ''),

        plotHeight = width * 0.618,

        yLabelData:LabelData = ySummary.type === ColumnSummaryType.Category
            ? {
                type: LabelDataType.Categories,
                values: [...ySummary.categoryDistribution.map(vf => vf.value, false)],
                stype: SemanticType.Category
            }
            : ySummary.nullCount === ySummary.valueCount || ySummary.type === ColumnSummaryType.Text // Text inserted here as a type guard
                ? {
                    type: LabelDataType.NumericalCategories,
                    values: [],
                    stype: SemanticType.Number,
                    includesNull: true
                }
                : ySummary.numberSummary.categories
                    ? {
                        type: LabelDataType.NumericalCategories,
                        values: ySummary.numberSummary.categories.axisCategories,
                        stype: yStype,
                        includesNull: ySummary.numberSummary.nullCount > 0
                    }
                    : {
                        type: LabelDataType.NumericalRange,
                        values: [...U.splitRange(ySummary.numberSummary.min, ySummary.numberSummary.max, BrickWithLabels.numAxisPartsBySize(plotHeight))],
                        stype: yStype
                    },

        yAxis = new Bricks.YAxis (bp.append('y'), yLabelData, plotHeight, axisYTitle),

        plotX = yAxis.width,

        plotWidth = width - plotX,

        legend = series.length > 1
            ? new Bricks.CategoricalHorizontalLegend(bp.append('hl'), {
                    type: LabelDataType.Categories,
                    stype: SemanticType.Category,
                    values: series.map (s => s.name)
                }, plotWidth, series.map (s => s.color))
            : null,

        legendY = 0,

        legendElements = legend ? legend.render(plotX, legendY) : [],

        plotY = legend ? legend.height : legendY,

        yAxisElements = yAxis.render (0, plotY, plotHeight),

        xLabelData:LabelData = xInfo.axisType === AxisType.ExistingColumn
            ? xInfo.column.numberSummary.categories
                ? {
                    type: LabelDataType.NumericalCategories,
                    values: xInfo.column.numberSummary.categories.axisCategories,
                    stype: xInfo.column.stype ?? SemanticType.Number,
                    includesNull: false
                }
                : {
                    type: LabelDataType.NumericalRange,
                    values: [...U.splitRange(xInfo.column.numberSummary.min, xInfo.column.numberSummary.max, BrickWithLabels.numAxisPartsBySize(plotWidth))],
                    stype: xInfo.column.stype ?? SemanticType.Number
                }
            : {
                type: LabelDataType.NumericalRange,
                values: [...U.splitRange(0, xInfo.count - 1, BrickWithLabels.numAxisPartsBySize(plotWidth))].map (n => Math.round(n)),
                stype: SemanticType.Number
            },

        xAxis:Bricks.XAxis = new Bricks.XAxis (bp.append('x'), xLabelData, plotWidth, axisXTitle),

        xAxisElements = xAxis.render (plotX, plotY + plotHeight, plotWidth),

        height = plotHeight + xAxis.height + (legend?.height ?? 0),

        yCategorical = STT.isCategorical(yStype) || (ySummary.type === ColumnSummaryType.Number && ySummary.numberSummary.categories) || ySummary.nullCount === ySummary.valueCount,

        testMarker = new UniqueTestMarker (TM.chartElementWithTooltip),

        dots = series.map ((series, serIndex) => data
            .filter (entry => yCategorical || entry.series[serIndex] !== null)
            .map (entry => ({
                dx: xAxis.valueToOffset(entry.x),
                dy: yAxis.valueToOffset(entry.series[serIndex]),
                radius: plotWidth / 300 + 1,
                color: yCatToColor !== null
                    ? (yCatToColor.get(entry.series[serIndex] as (string | null)) ?? series.color).normal
                    : series.color.normal,
                tooltipRows: [
                    new TooltipRow(
                        entry.x,
                        xInfo.axisType === AxisType.ExistingColumn ? xInfo.column.title : "row number",
                        xInfo.axisType === AxisType.ExistingColumn ? xInfo.column.stype : SemanticType.Number,
                        xInfo.axisType === AxisType.ExistingColumn ? xInfo.column.units ?? undefined : undefined
                    ),
                    new TooltipRow(entry.series[serIndex], series.name, yStype, yUnits ?? undefined)
                ],
                testMarker: testMarker.value
            }))),

        scatterPlotElements = series.map ((ser, i) =>
            new Bricks.ScatterPlot(
                bp.append('p'+i),
                plotWidth,
                plotHeight,
                dots[i],
                1.0,
                [{dotIndices:[...U.range (dots[i].length)], color:yCatToColor === null ? ser.color : Colors.defaultPlotColor}],
                true).render(plotX, plotY)
        ).flat(),

        gridElements = new Bricks.Grid(bp.append('gr'), xAxis.tickOffsets, yAxis.tickOffsets, plotWidth, plotHeight, false).render(plotX, plotY)

    return RC.getRenderResult (width, height, [...gridElements, ...scatterPlotElements, ...yAxisElements, ...xAxisElements, ...legendElements], bp.defs)
}