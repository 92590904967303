import {
    GetActionsEvent,
    GetActionsEventType,
    TooltipsById, UserActionInfo, UserActionResponse,
    VisContent,
    VisCtrlAction,
    VisCtrlActionType,
    VisCtrlId,
    VisCtrlInfo,
    VisCtrlType,
    VisCtrlValues,
    VisualizerColumnData,
    VisualizerData,
    VisualizerType
} from "../Concepts/Visualizer"
import AbstractNumColumnAggregator from "./AbstractNumColumnAggregator"
import * as NumColumnAggregator from "../Concepts/Visualizers/NumColumnAggregator"
import {BarType, PosNeg, SortBy} from "../Concepts/Visualizers/NumColumnAggregator"
import {render} from './Renderers/BarChart'
import Icon from './VisIcons/TimeIntervals.svg'
import * as U from '../Utils'
import * as STT from "../StypeTools"
import TimeInterval from "../DateTime/TimeInterval"
import {ITableColumn, OptionalString} from "../Concepts/Basic"
import {ITimeInterval, TimeIntervalType} from "../Concepts/DateTime"
import {VisColData, VisControls, VisData} from "../Concepts/Visualizers/TimeIntervals"
import {Duration, DurationUnit} from "luxon"
import {IDataTable} from "../Concepts/DataTable"
import $t, {$tIntervalTypeName, IntervalTypeNameForm} from "../i18n/i18n"

export default class TimeIntervals extends AbstractNumColumnAggregator<VisColData, VisData> {

    protected doesFitForBreakColumn (info: ITableColumn):boolean {
        return STT.isPointInTime(info.stype)
    }

    protected isProperBreakColumn (info: ITableColumn):boolean {
        return Duration.fromMillis(info.numberSummary.max - info.numberSummary.min).as('minutes') > 1
    }

    protected extractTimeInterval (controlValues:VisCtrlValues):ITimeInterval {
        return new TimeInterval(controlValues.get(VisControls.interval) as TimeIntervalType)
    }

    override get type () {return VisualizerType.TimeIntervals}

    getTitle () {
        return $t('timeintervals.name')
    }

    getIconUrl ():string {
        return Icon
    }

    getColumnData (dataTable:IDataTable):VisColData {
        const {numericalColumns, breakColumn} = U.notNull(this.recognizeColumns(dataTable.selectedColumns)),
            footer = this.getColumnIdsTitleFooter (dataTable).footer

        const period = Duration.fromMillis(breakColumn.numberSummary.max - breakColumn.numberSummary.min),
            intervals = Object.values(TimeIntervalType)
                .filter (type => U.betweenIncluding(Math.ceil(period.as((type + 's') as DurationUnit)), 2, 100))

        return {numericalColumns, breakColumn, intervals, footer}
    }

    getControls (dataTable:IDataTable, columnData:VisualizerColumnData):Map<VisCtrlId, VisCtrlInfo> {
        const cd = columnData as VisColData,
            controls = this.getCommonControls($t('timeintervals.breakcolsort'), cd.numericalColumns.length)
        controls.splice(0, 0, [VisControls.interval, {
            title: $t('timeintervals.ctrl.breakdown'),
            type: VisCtrlType.Select,
            options: new Map(cd.intervals.map (type => [type, U.firstLetterUp($tIntervalTypeName(type, IntervalTypeNameForm.PlurDat))])),
            defaultValue: cd.intervals[0],
        }])
        return new Map (controls)
    }

    getData (requestId:number, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues):Promise<{data:VisData, requestId:number}> {
        const cd = columnData as VisColData,
            numColumnIds = cd.numericalColumns.map (c => c.id),
            aggrType = this.extractAggrType (controlValues, cd),
            interval = this.extractTimeInterval (controlValues)

        return dataTable.aggregateByInterval(aggrType, interval, cd.breakColumn.id, numColumnIds)
            .then ((aggrResult) => {
                const {hasNegatives, hasPositives} = this.determineNegPos(aggrResult),

                    {hasNULLs, hasNonNULLs} = this.determineNulls(cd.numericalColumns),

                    {title, aggrPrefix} = this.buildTitle(aggrType, cd.numericalColumns, cd.breakColumn, $tIntervalTypeName(interval.type, IntervalTypeNameForm.PlurDat)),

                    yLabelCommonPart = new OptionalString(),

                    yLabels = interval.formatLabels(aggrResult.keys(), cd.breakColumn.stype, yLabelCommonPart),

                    rawChartData = new Map([...aggrResult.entries()].map (entry =>
                        [
                            entry[0],
                            entry[1] === null
                                ? null
                                : [...entry[1].entries()].map(colValueEntry => {
                                    const valColumn = cd.numericalColumns.filter(c => c.id === colValueEntry[0])[0]
                                    return {
                                        name: valColumn?.title ?? U.get(yLabels, entry[0]),
                                        color: (cd.numericalColumns.length > 0 ? valColumn : cd.breakColumn).color,
                                        value: colValueEntry[1],
                                        columnId: valColumn?.id
                                    }
                                })
                        ]
                    ))

                    return {
                    data: {
                        aggrResult,
                        chartData: rawChartData,
                        yLabels,
                        yLabelCommonPart: yLabelCommonPart.value,
                        hasNegatives,
                        hasPositives,
                        aggrPrefix: aggrPrefix,
                        aggrType,
                        hasNULLs,
                        hasNonNULLs,
                        title
                    },
                    requestId
                }
            })
    }

    getActionsOnControls (event:GetActionsEvent, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues):VisCtrlAction[] {
        const cd = columnData as VisColData,
            cv = this.getCurrentControlValues (event, controlValues),
            actions: VisCtrlAction[] = this.buildActionsOnControls(event, cd),
            interval = this.extractTimeInterval(cv)

        // fix some control values before data loaded
        if (event.type === GetActionsEventType.BeforeDataLoaded) {
            if (cd.intervals.indexOf(interval.type)<0) {
                actions.push({action: VisCtrlActionType.SetValue, controlId: VisControls.interval, controlValue: cd.intervals[0]})
            }
        }

        // send coloring event
        if (event.type === GetActionsEventType.DataLoadedWithSuggestedControlValues || event.type === GetActionsEventType.DataLoadedWithUserControlValues) {
            this.sendColoringEvent(
                null,
                true,
                cd.numericalColumns.length > 0 ? [cd.breakColumn.id] : [])
        }

        if (event.type !== GetActionsEventType.BeforeDataLoaded) {
            const d = event.data as VisData
            // change visibility of sepPosNegValue
            actions.push ({
                action: cv.get(NumColumnAggregator.VisControls.stacked) === BarType.separated
                || !d.hasNegatives || !d.hasPositives
                    ? VisCtrlActionType.HideControl
                    : VisCtrlActionType.ShowControl,
                controlId: NumColumnAggregator.VisControls.sumPosAndNeg,
                controlValue: PosNeg.separated
            })
        }

        // reload data if user changed the aggregation type
        if (event.type === GetActionsEventType.ControlValueChangedByUser
            && (event.controlId === VisControls.interval || event.controlId === NumColumnAggregator.VisControls.aggrType)) {
            actions.push({action: VisCtrlActionType.ReloadData})
        }

        return actions
    }

    getContent (data:VisualizerData, width:number, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues, tooltips:TooltipsById):VisContent {
        const cd = columnData as VisColData,

            d = data as VisData,

            {aggrType, sumPosAndNeg, sortAscending, sortBy} = this.extractCommonParameters(controlValues, cd),

            sortedChartData = sortBy === SortBy.breakingColumn
                ? new Map ([...d.chartData.entries()]
                    .sort((a, b) =>
                        (a[0] ?? 0) > (b[0] ?? 0) ? (sortAscending ? 1 : -1) : (a[0] === b[0] ? 0 : (sortAscending ? -1 : 1)))
                    .map (entry => [U.get(d.yLabels, entry[0]), entry[1]])
                )
                : this.sortByValues (new Map ([...d.chartData.entries()].map (entry => [U.get(d.yLabels, entry[0]), entry[1]])),
                    sortBy === SortBy.positiveValues, sortAscending, sumPosAndNeg),

            result = render(
                cd.breakColumn,
                cd.numericalColumns,
                sortedChartData,
                aggrType,
                d.aggrPrefix.sing,
                width,
                controlValues.get(NumColumnAggregator.VisControls.stacked) === BarType.stacked,
                controlValues.get(NumColumnAggregator.VisControls.sumPosAndNeg) === PosNeg.summed,
                tooltips,
                cd.breakColumn.title + (d.yLabelCommonPart ? ', ' + d.yLabelCommonPart : ''))

        return {
            node: result.svg,
            title: d.title,
            footer: cd.footer,
            height: result.height
        }
    }

    handleUserAction (userActionInfo: UserActionInfo, data:VisualizerData, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues):UserActionResponse|undefined {
        return undefined
    }
}