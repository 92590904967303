import DataColumn from "./DataColumn"
import {ICategoryDistribution, ZeroColumnId} from "../Concepts/Basic"
import * as U from "../Utils"
import {createModelSchema} from "serializr"
import ValueDistribution from "./ValueDistribution"
import {AggregationByCategory} from "../Concepts/Aggregation"
import {CatFreq} from "../Concepts/FrequencyOfValue"

export default class CategoryDistribution extends ValueDistribution implements ICategoryDistribution {

    static override fromDataColumn(column: DataColumn) {
        return new CategoryDistribution(CategoryDistribution.getCategoricalEntries(column))
    }

    static override fromAggrResult(aggr: AggregationByCategory) {
        return new CategoryDistribution([...aggr.keys()].map(cat => new CatFreq(cat, U.get(U.defNotNull(aggr.get(cat)), ZeroColumnId))))
    }

    constructor(catEntries?: CatFreq[]) {
        super(undefined, undefined, catEntries)
    }

    get entries(): CatFreq[] {
        if (this.catEntries) {
            return this.catEntries
        } else {
            throw Error("CategoryDistribution class doesn't support distribution types other than DistributionType.Categories")
        }
    }

    map<T>(f: (value: CatFreq, index: number, array: CatFreq[]) => T, excludeNulls= false): T[] {
        if (this.catEntries) {
            return excludeNulls
                ? this.catEntries.filter (entry => entry.value !== null).map(f)
                : this.catEntries.map(f)
        } else {
            throw Error("CategoryDistribution class doesn't support distribution types other than DistributionType.Categories")
        }
    }
}

// the following lines are necessary since some classes don't own any @serializable field that could make them visible for serializr
createModelSchema(CategoryDistribution, {})
