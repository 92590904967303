import {ReactElement, ReactNode} from "react"
import {ColumnId, ITableColumn, Percentage} from "./Basic"
import {IDataTable} from "./DataTable"
import {Point} from "./Geometry"

export enum VisualizerType {
    // The default (last selected by user) visualizer type for a certain column set
    Default = 0,
    ColumnProfile = 1,
    NumFreqHistSharedX = 2,
    FreqDistrGrid = 3,
    PieBarChart = 4,
    ScatterPlot = 5,
    ScatterPlotMatrix = 6,
    LineChart = 7,
    TimeIntervals = 8
}

export function visTypeToString (type: VisualizerType): string {
    switch (type) {
        case VisualizerType.Default: return "DefaultVisualizer"
        case VisualizerType.ColumnProfile: return "ColumnProfile"
        case VisualizerType.NumFreqHistSharedX: return "NumFreqHistSharedX"
        case VisualizerType.FreqDistrGrid: return "FreqDistrGrid"
        case VisualizerType.PieBarChart: return "PieBarChart"
        case VisualizerType.ScatterPlot: return "ScatterPlot"
        case VisualizerType.ScatterPlotMatrix: return "ScatterPlotMatrix"
        case VisualizerType.LineChart: return "LineChart"
        case VisualizerType.TimeIntervals: return "TimeIntervals"
        default:
            const x: never = type
            throw new Error ("This function should never be called. " + x + x)
    }
}

export type VisualizerData = unknown
export type VisualizerColumnData = unknown

export interface VisContent {
    node: ReactNode
    title?: string
    footer?: string
    height: number
}

export enum MatchDegree {
    NoMatch = 0,
    AsALastResort = 1,
    Ordinary = 2,
    BestFit = 3,
    MustBeFirst = 999
}

export type RenderResult = {
    svg:JSX.Element,
    height:number,
    samplingApplied?:boolean,
    xSVGToUnitsMapper?: (svgX:number)=>number|undefined
    ySVGToUnitsMapper?: (svgY:number)=>number|undefined
}

export enum LabelType {
    Legend,
    YAxis,
    XAxis,
    XAxisRotated,
}

export type PercRange = {from:Percentage, to:Percentage}

//<editor-fold desc="Actions & Events">

export enum GetActionsEventType {
    BeforeDataLoaded = "BeforeDataLoaded",
    DataLoadedWithUserControlValues = "DataLoadedWithUserControlValues",
    DataLoadedWithSuggestedControlValues = "DataLoadedWithSuggestedControlValues",
    ControlValueChangedByUser = "ControlValueChangedByUser"
}

export type GetActionsEvent =
    {type: GetActionsEventType.BeforeDataLoaded}
    | {type: GetActionsEventType.DataLoadedWithUserControlValues, data:VisualizerData}
    | {type: GetActionsEventType.DataLoadedWithSuggestedControlValues, data:VisualizerData}
    | {type: GetActionsEventType.ControlValueChangedByUser, data:VisualizerData, controlId:VisCtrlId, newControlValue:VisCtrlValue}

export enum VisualizerEventType {
    NotColored,
    ColoredByColumnColors,
    ColoredByCategoricalColumn
}

export type VisualizerEvent =
    {type: VisualizerEventType.NotColored}
    | {type: VisualizerEventType.ColoredByColumnColors, excludeColumnIds?:ColumnId[]}
    | {type: VisualizerEventType.ColoredByCategoricalColumn, columnId:ColumnId}


export enum UserActionType {
    MouseDown,
    MouseUp,
    MouseMove,
    Click,
    RightClick,
    DoubleClick,
    Wheel,
    TouchStart,
    TouchEnd,
    TouchCancel,
    TouchMove
}

export interface UserActionInfo {
    type: UserActionType,
    svgX: number,
    svgY: number,
    svgX2?: number,
    svgY2?: number,
    wheelDelta?: number
}

export interface UserActionResponse {
    visControlActions: VisCtrlAction[]
}

//</editor-fold>

//<editor-fold desc="Visualizer Controls">

export type VisCtrlValue = string

export type VisCtrlId = number

type VisCtrlState = {
    visible: boolean
    enabled: boolean
    hiddenOptions: Set<string>
}

export type VisCtrlValues = Map <VisCtrlId, VisCtrlValue>
export type VisCtrlStates = Map <VisCtrlId, VisCtrlState>

export enum VisCtrlType {
    Slider,
    Radio,
    Select,
    RangeSlider
}

interface CommonVisCtrlInfo {
    title: string
    defaultValue: VisCtrlValue
    // Actions returned by this method are saved in controlStates, so they are permanent.
    // VisControlActionType.SelectOption should be used here
    // onValueChanged?: (value: VisControlValue, controlValues:VisControlValues) => Promise<Action[]>
}

export interface SliderInfo {
    type: VisCtrlType.Slider | VisCtrlType.RangeSlider
    marks: {value: number}[]
    max: number,
    min?: number,
    step?: number,
    valueLabelDisplay?: 'on' | 'auto' | 'off'
}

export interface RadioInfo {
    type: VisCtrlType.Radio
    options: Map<string, string>
}

export interface SelectInfo {
    type: VisCtrlType.Select
    options: Map<string, string>
}

export type VisCtrlInfo = CommonVisCtrlInfo & (SliderInfo | RadioInfo | SelectInfo)

export enum VisCtrlActionType {
    DisableControl,
    EnableControl,
    HideControl,
    ShowControl,
    HideOption,
    ShowOption,
    SetValue,
    SetSliderMarks,
    SetSliderMinMax,
    ReloadData,
    SetVisCursor
}

export interface VisCtrlAction {
    action: VisCtrlActionType
    controlId?: VisCtrlId
    controlValue?: VisCtrlValue
    sliderMarks?: {value: number}[]
    sliderMinMax?: [number, number]
    cursor?: CursorType
}

//</editor-fold>

//<editor-fold desc="Tooltips">

export interface ITooltipRow {
    html: string
}

export interface ITooltip {
    html: string
    readonly pivot: Point
    readonly rows: (ITooltipRow|undefined)[]
}

export type TooltipsById = Map<string, ITooltip>

export type CursorType = "default" | "pointer" | "zoom-in" | "grabbing"

//</editor-fold>

export abstract class IVisualizer {
    constructor(protected eventHandler:(event:VisualizerEvent)=>void) {}

    // returns an unique visualizer type id
    abstract get type ():VisualizerType

    // returns the title of the visualizer
    abstract getTitle ():string

    // returns visualizer icon's url
    abstract getIconUrl ():string

    // returns true if the visualizer is able to visualize given column configuration
    abstract matchDegree (columns: ITableColumn[]):MatchDegree

    // returns any summary information produced based on column configuration, this data will be available to the visualiser as "columnData" parameter
    abstract getColumnData (dataTable:IDataTable):VisualizerColumnData

    // returns the list of requeried UI controls mapped to their keys
    abstract getControls(dataTable:IDataTable, columnData:VisualizerColumnData):Map<VisCtrlId, VisCtrlInfo>

    // asyncroniously gathers and returns all data necessary for building the chart, this data will be available to the visualiser as "data" parameter
    abstract getData (requestId:number, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues):Promise<{data:VisualizerData, requestId:number}>

    // returns a list of actions on UI controls in response to some event
    abstract getActionsOnControls (event:GetActionsEvent, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues):VisCtrlAction[]

    // builds and returns the chart itself along with some supplimentary information
    abstract getContent (data:VisualizerData, width:number, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues, tooltips:TooltipsById):VisContent

    // returns an icon signifing the type of the visualizer
    public abstract icon (width:number, height:number):ReactElement

    // handles user actions on the chart
    public abstract handleUserAction (userActionInfo: UserActionInfo, data:VisualizerData, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues):UserActionResponse|undefined
}