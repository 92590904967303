import * as U from "./Utils"
import * as DT from "./DateTime/DateTime"
import * as SS from "simple-statistics"
import {CategoryValue, DataValue, OptionalString} from "./Concepts/Basic"
import {ParsedType, SemanticType} from "./Concepts/SemanticType"
import $t from "./i18n/i18n";

export function semanticTypeFromParsedType (ptype: ParsedType):SemanticType {
    switch (ptype) {
        case ParsedType.Null:
            throw Error ("Can't map ParsedType.Null to any semantic type")
        case ParsedType.Text:
            return SemanticType.Text
        case ParsedType.Number:
            return SemanticType.Number
        case ParsedType.Date:
            return SemanticType.Date
        case ParsedType.Time:
            return SemanticType.Time
        case ParsedType.DateTime:
            return SemanticType.DateTime
        default:
            U.shouldNeverGetHere(ptype)
    }
}

export function getPossibleAlternatives (type: SemanticType, includeItself=true, includeSkip=true):SemanticType[] {
    const set:Set<SemanticType> = new Set ([SemanticType.Text, SemanticType.Identifier])
    if (includeItself) {
        set.add(type)
    }
    if (includeSkip) {
        set.add(SemanticType.Skip)
    }
    if (isNumeric(type)) {
        set.add (SemanticType.OrdinalNumber)
        set.add (SemanticType.Number)
    }
    return [...set]
}

// the 1st level of classification

export function isContinuous(stype:SemanticType): boolean {
    return isNumeric(stype) || isPointInTime(stype)
}

export function isCategorical(stype:SemanticType): boolean {
    return stype === SemanticType.Category
}

export function isTextual(stype:SemanticType): boolean {
    return stype === SemanticType.Text || stype === SemanticType.Identifier
}

// the 2nd level of classification

export function isNumeric(stype:SemanticType): boolean {
    return stype === SemanticType.Number || stype === SemanticType.OrdinalNumber
}

export function isDateOrTime(stype:SemanticType): boolean {
    return isDateAndTime(stype) || isDateOnly(stype) || isTimeOnly(stype)
}

export function isPointInTime(stype:SemanticType): boolean {
    return isDateAndTime(stype) || isDateOnly(stype) || isTimeOnly(stype)
}

export function isOrdinal(stype:SemanticType): boolean {
    return isPointInTime(stype) || stype === SemanticType.OrdinalNumber
}

// the 3rd level of classification

export function isSkip(stype:SemanticType): boolean {
    return stype === SemanticType.Skip
}

export function isDateAndTime(stype:SemanticType): boolean {
    return stype === SemanticType.DateTime
}

export function isDateOnly(stype:SemanticType): boolean {
    return stype === SemanticType.Date
}

export function isTimeOnly(stype:SemanticType): boolean {
    return stype === SemanticType.Time
}

export function isText(stype:SemanticType): boolean {
    return stype === SemanticType.Text
}

export function isIdentifier(stype:SemanticType): boolean {
    return stype === SemanticType.Identifier
}

// auxiliary methods

export function uiName(stype:SemanticType): string {
    switch (stype) {
        case SemanticType.Skip:
            return $t('stype.skip')
        case SemanticType.Number:
            return $t('stype.number')
        case SemanticType.Category:
            return $t('stype.category')
        case SemanticType.Text:
            return $t('stype.text')
        case SemanticType.DateTime:
            return $t('stype.datetime')
        case SemanticType.Date:
            return $t('stype.date')
        case SemanticType.Time:
            return $t('stype.time')
        case SemanticType.Identifier:
            return $t('stype.identifier')
        case SemanticType.OrdinalNumber:
            return $t('stype.ordinal_number')
        default:
            U.shouldNeverGetHere(stype)
    }
}

// formatting methods

export function formatTooltipValue(stype:SemanticType, value: number | string | null): string {
    return format(stype, value)
}

export function formatFullValue(stype:SemanticType, value: number | string | null): string {
    return format(stype, value, false)
}

export function formatTableCellValue(stype:SemanticType, value: number | string | null): string {
    return format(stype, value)
}

export function formatAxisLabels(stype:SemanticType, data: (number | string | null)[], commonPart?: OptionalString): string[] {
    switch (stype) {
        case SemanticType.Skip:
            throw Error("Can't format axis labels for the Skip type")
        case SemanticType.Number:
        case SemanticType.OrdinalNumber:
            return formatNumericalAxisLabels(data as number[])
        case SemanticType.Time:
            return formatDateTimeAxisLabels(data as number[], false, true, commonPart)
        case SemanticType.Date:
            return formatDateTimeAxisLabels(data as number[], true, false, commonPart)
        case SemanticType.DateTime:
            return formatDateTimeAxisLabels(data as number[], true, true, commonPart)
        case SemanticType.Identifier:
        case SemanticType.Text:
        case SemanticType.Category:
            return trimCategories(data as CategoryValue[])
    }
}

// inner methods

function format(stype:SemanticType, value: DataValue, compact = true): string {
    if (value === null) {
        return U.nullText
    } else {
        switch (stype) {
            case SemanticType.Skip:
                throw Error("Can't format the Skip type")
            case SemanticType.Number:
            case SemanticType.OrdinalNumber:
                return compact ? U.formatNumber(U.shouldBeNumber(value)) : value.toString()
            case SemanticType.Time:
                return DT.formatTime(U.shouldBeNumber(value))
            case SemanticType.Date:
                return DT.formatDate(U.shouldBeNumber(value))
            case SemanticType.DateTime:
                return DT.formatDateTime(U.shouldBeNumber(value))
            case SemanticType.Identifier:
            case SemanticType.Text:
            case SemanticType.Category:
                return value.toString()
        }
    }
}

function formatNumericalAxisLabels(values: number[]): string[] {
    if (values.length === 1) {
        return [U.formatNumber(values[0])]
    }

    let minDiff = Infinity, maxAbs = -Infinity
    for (let i = 1; i < values.length; i++) {
        const diff = Math.abs(values[i] - values[i - 1])
        if (minDiff > diff) {
            minDiff = diff
        }
        if (Math.abs(values[i]) > maxAbs) {
            maxAbs = Math.abs(values[i])
        }
    }

    if (minDiff >= 1e12) {
        return values.map(v => v.toExponential(1))
    } else {
        const minDiffFraction = minDiff - Math.floor(minDiff)
        let digits = Math.ceil(Math.log10(maxAbs)),
            fractionDigits = minDiff < 1 && minDiffFraction > 0 ? Math.ceil(-Math.log10(minDiffFraction)) + 3 : 0,
            postfix = '', divider = 1

        if (minDiff >= 1e9) {
            postfix = 'B'
            divider = 1e9
            digits -= 9
        } else if (minDiff >= 1e6) {
            postfix = 'M'
            divider = 1e6
            digits -= 6
        } else if (minDiff >= 1000) {
            postfix = 'K'
            divider = 1000
            digits -= 3
        } else if (minDiff >= 100 && maxAbs > 1000) {
            postfix = 'K'
            divider = 1000
            fractionDigits = 1
            digits -= 3
        } else if (minDiff >= 10 && maxAbs > 1000) {
            postfix = 'K'
            divider = 1000
            fractionDigits = 2
            digits -= 3
        }

        return values.map(v => {
            let text = parseFloat((v / divider).toPrecision(digits + fractionDigits > 0 ? digits + fractionDigits : 3)).toString()
            if (fractionDigits > 0 && v / divider !== 0) {
                text = text.replace(/0+$/g, "")
            }
            text += (v !== 0 ? postfix : '')
            return text.length > 5 + fractionDigits + (v < 0 ? 1 : 0) ?
                v.toExponential(fractionDigits).replace(/e\+3$/, 'K').replace(/e\+6$/, 'M').replace(/e\+12$/, 'B')
                : text;
        });
    }
}

function formatDateTimeAxisLabels(values: number[], useDate: boolean, useTime: boolean, commonPart?: OptionalString): string[] {
    if (useTime && !useDate) {
        // format time values as is
        return values.map(value => DT.formatTime(value))
    } else {
        const
            formatter = useDate ? (useTime ? DT.formatDateTime : DT.formatDate) : (useTime ? U.stub : DT.formatDateTime),
            breaker = useDate ? (useTime ? DT.breakDateTime : DT.breakDate) : (useTime ? U.stub : DT.breakDateTime),
            dateTimes = values.map(v => DT.dateTimeFormatPartsToObject(breaker(v))),
            sameYear = !useDate || dateTimes.length <= 1 ? true : dateTimes.every(dt => dt.year === dateTimes[0].year),
            sameMonth = !useDate || dateTimes.length <= 1 ? true : (sameYear ? dateTimes.every(dt => dt.month === dateTimes[0].month) : false),
            sameDay = !useDate || dateTimes.length <= 1 ? true : (sameMonth ? dateTimes.every(dt => dt.day === dateTimes[0].day) : false),
            someDaysRepeat = sameDay || new Set(dateTimes.map(dt => dt.day)).size !== dateTimes.length,
            sameHour = !useTime,   // !useTime || dateTimes.length <= 1 ? true : (sameDay ? dateTimes.every(dt => dt.hour === dateTimes[0].hour) : false),
            sameMinute = !useTime, // !useTime || dateTimes.length <= 1 ? true : (sameHour ? dateTimes.every(dt => dt.minute === dateTimes[0].minute) : false),
            sameSecond = !useTime  // !useTime || dateTimes.length <= 1 ? true : (sameMinute ? dateTimes.every(dt => dt.second === dateTimes[0].second) : false)

        if (commonPart) {
            commonPart.value = DT.formatDateTimeByParts(
                values[0],
                useDate && sameYear,
                useDate && sameMonth,
                useDate && sameDay,
                useTime && sameHour,
                useTime && sameMinute,
                false,
                useTime && !useDate)
        }

        // if everything the same show full labels
        return sameYear && sameMonth && sameDay && sameHour && sameMinute && sameSecond
            ? values.map(value => formatter(value))
            : values.map(value => DT.formatDateTimeByParts(
                value,
                !sameYear,
                !sameMonth,
                !sameDay,
                someDaysRepeat && !sameHour,
                someDaysRepeat && !sameMinute,
                someDaysRepeat && useTime,
                !(useTime && useDate)))
    }
}

function trimCategories(categories: CategoryValue[]): string[] {
    const names = categories.map(cat => U.deNull(cat)),
        maxAllowedLength = names.length > 0 ? Math.max(10, SS.mean(names.map(name => name.length)) * 2) : 0,
        ellipsisLength = U.ellipsis.length
    return names.map(name => name.length > maxAllowedLength + ellipsisLength
        ? name.substr(0, maxAllowedLength) + U.ellipsis
        : name
    )
}
