import {PieChartData} from "./Renderers/PieChart"
import {ITableColumn} from "../Basic"

export enum ProfileType {
    Numerical,
    Categorical,
    Time,
    Identifiers,
    Text
}

export type VisData = {
    type: ProfileType.Numerical
} | {
    type: ProfileType.Categorical
    pieData: PieChartData
} | {
    type: ProfileType.Time
} | {
    type: ProfileType.Identifiers
} | {
    type: ProfileType.Text
}

export interface VisColData {
    info: ITableColumn
}
