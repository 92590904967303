import {
    GetActionsEvent,
    GetActionsEventType,
    MatchDegree,
    RenderResult,
    TooltipsById,
    UserActionInfo,
    UserActionResponse,
    UserActionType,
    VisContent,
    VisCtrlAction,
    VisCtrlActionType,
    VisCtrlId,
    VisCtrlInfo,
    VisCtrlType,
    VisCtrlValues,
    VisualizerColumnData,
    VisualizerData,
    VisualizerType
} from "../Concepts/Visualizer"
import {Visualizer} from "./Visualizer"
import {ColumnId, INumberSummary, ITableColumn, NumInclusiveRange, Percentage} from "../Concepts/Basic"
import {render} from './Renderers/NumFreqHistSharedX'
import {userSettings} from "../LocalSettings"
import Icon from './VisIcons/HistSharedX.svg'
import * as U from "../Utils"
import * as STT from "../StypeTools"
import {VisColData, VisControls, VisData, YAxisType} from "../Concepts/Visualizers/NumFreqHistSharedX"
import {IDataTable} from "../Concepts/DataTable"
import $t from "../i18n/i18n";
import event from "../EventSender/Events";
import {ymGoal} from "../YM";

interface DragInfo {
    x:number
    range:NumInclusiveRange
    unitMapper:(svgX:number)=>number|undefined
    lastRange:NumInclusiveRange
    method:string
}

interface PinchInfo {
    distance:number
    rangeCenter:number
    rangeLength:number
    lastRangeLength:number
}

export default class NumFreqHistSharedX extends Visualizer {
    protected renderResult?: RenderResult
    protected shownAsCategories = false
    protected dragInfo?:DragInfo
    protected pinchInfo?:PinchInfo

    override get type () {return VisualizerType.NumFreqHistSharedX}

    matchDegree(columns:ITableColumn[]): MatchDegree {
        const oneOrMoreContinuousColumns = columns.length > 0 && !columns.some(c => !STT.isContinuous(c.stype))
        const allColumnsOfTheSameType = columns.length <= 1 || columns.every(c => c.stype === columns[0].stype)
        const atLeastOneMeaningfulColumn = columns.some(c => c.summary.nullCount < c.summary.valueCount)
        if (oneOrMoreContinuousColumns && atLeastOneMeaningfulColumn && allColumnsOfTheSameType) {
            const min = Math.min (...columns.map (col => col.numberSummary.min)),
                max = Math.max (...columns.map (col => col.numberSummary.max)),
                coef = columns
                    .filter (col => col.numberSummary.iqr > 0)
                    .map (col => (max - min) / col.numberSummary.iqr)
                    .reduce ((x, sum) => x + sum, 0) / columns.length,
                degree = coef < 10 ? MatchDegree.BestFit : (coef < 100 ? MatchDegree.Ordinary : MatchDegree.AsALastResort)

            return userSettings.getNumber("unitsMatter") === 0
                    || columns.every(c => c.units === columns[0].units)
                ? degree
                : MatchDegree.NoMatch
        }
        return MatchDegree.NoMatch
    }

    getTitle () {
        return $t('numfreqhistsharedx.name')
    }

    getIconUrl ():string {
        return Icon
    }

    getColumnData (dataTable:IDataTable):VisColData {
        const ctf = this.getColumnIdsTitleFooter (dataTable, $t('numfreqhistsharedx.title'))
        return {
            ...ctf,
            stype:dataTable.getStype(ctf.columnIds[0])
        }
    }

    getControls(dataTable:IDataTable, columnData:VisualizerColumnData):Map<VisCtrlId, VisCtrlInfo> {
        const maxBinCount = userSettings.getNumber("histBins")
        return new Map ([
            [VisControls.bins, {
                title: $t('numfreqhistsharedx.ctrl.bins.title'),
                type: VisCtrlType.Slider,
                marks: [],
                min: 2,
                max: maxBinCount,
                defaultValue: (maxBinCount/2).toString(),
            }],
            [VisControls.probability, {
                title: $t('numfreqhistsharedx.ctrl.yaxis.title'),
                type: VisCtrlType.Radio,
                options: new Map ([[YAxisType.frequency, $t("numfreqhistsharedx.ctrl.yaxis.freq")], [YAxisType.probability, $t("numfreqhistsharedx.ctrl.yaxis.probab")]]),
                defaultValue: YAxisType.probability
            }],
            [VisControls.range, {
                title: $t('numfreqhistsharedx.ctrl.range.title'),
                type: VisCtrlType.RangeSlider,
                marks: [],
                min: 0,
                max: 1000,
                step: 1,
                defaultValue: "0;1000",
                valueLabelDisplay: 'off'
            }]

        ])
    }

    getData (requestId:number, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues):Promise<{data:VisData, requestId:number}> {
        const maxBinCount = userSettings.getNumber("histBins"),
            cd = columnData as VisColData,
            data:Map<ColumnId, INumberSummary> = new Map (cd.columnIds.map (colId => {
                return [colId, dataTable.getColumnInfo(colId).numberSummary]
        }))

        let gridStep:number|null|undefined = undefined, gridMin = Infinity, gridMax = -Infinity
        for (const colId of cd.columnIds) {
            const sum = U.get(data, colId)
            gridMin = Math.min (gridMin, sum.min)
            gridMax = Math.max (gridMax, sum.max)
            if (gridStep === undefined) {
                gridStep = sum.categories?.valueGridStep ?? null
            } else if (gridStep !== sum.categories?.valueGridStep) {
                gridStep = null
            }
        }

        const valueBins = gridStep ? (gridMax - gridMin) / gridStep + 1 : 0,
            categories = valueBins && valueBins <= maxBinCount ? valueBins : 0

        return Promise.resolve({data: {data, categories, gridMin, gridMax}, requestId})
    }

    getActionsOnControls (event:GetActionsEvent, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues):VisCtrlAction[] {
        const maxBinCount = userSettings.getNumber("histBins"),
            actions:VisCtrlAction[] = []

        if (event.type === GetActionsEventType.DataLoadedWithUserControlValues
            || event.type === GetActionsEventType.DataLoadedWithSuggestedControlValues
            || event.type === GetActionsEventType.ControlValueChangedByUser) {

            const d = event.data as VisData
            const bins = U.parseIntNotNan(U.get(controlValues, VisControls.bins))

            actions.push({
                action: d.categories && d.categories === bins ? VisCtrlActionType.DisableControl : VisCtrlActionType.EnableControl,
                controlId: VisControls.range
            })

            actions.push({
                action: VisCtrlActionType.SetVisCursor,
                cursor: d.categories && d.categories === bins ? "default" : "zoom-in"
            })

            if (event.type !== GetActionsEventType.ControlValueChangedByUser) {
                if (d.categories) {
                    actions.push({
                        action: VisCtrlActionType.SetSliderMarks,
                        controlId: VisControls.bins,
                        sliderMarks: [{value: d.categories}]
                    })
                }


                if (event.type === GetActionsEventType.DataLoadedWithSuggestedControlValues) {
                    // if user hasn't changed the bin count value try to set an optimal value
                    actions.push({
                        action: VisCtrlActionType.SetValue,
                        controlId: VisControls.bins,
                        controlValue: (d.categories ? d.categories : maxBinCount / 2).toString()
                    })
                }

                this.sendColoringEvent(null)
            }
        }

        return actions
    }

    getContent (data:VisualizerData, width:number, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues, tooltips:TooltipsById):VisContent {
        const cd = columnData as VisColData,
            d = data as VisData,
            bins = U.parseIntNotNan(U.get(controlValues, VisControls.bins)),
            range = U.get(controlValues, VisControls.range).split(';').map (x => U.parseIntNotNan(x) / 10.0)

        this.shownAsCategories = d.categories === bins

        this.renderResult = render(
                d.data,
                {from:Percentage(range[0]), to:Percentage(range[1])},
                bins,
                width,
                controlValues.get(VisControls.probability) === YAxisType.probability,
                controlValues.get(VisControls.probability) === YAxisType.probability ? $t("numfreqhistsharedx.ctrl.yaxis.probab") : $t("numfreqhistsharedx.ctrl.yaxis.freq"),
                new Map(cd.columnIds.map(colId => [colId, dataTable.getColumnInfo(colId).color])),
                cd.columnIds.length > 1 ? new Map(cd.columnIds.map(colId => [colId, dataTable.getColumnInfo(colId).title])) : null,
                dataTable.getColumnInfo(cd.columnIds[0]).units || '',
                cd.stype,
                tooltips,
                this.shownAsCategories
            )

        return {
            node: this.renderResult.svg,
            height: this.renderResult.height,
            title: cd.title,
            footer: cd.footer
        }
    }

    protected setNewRange (from:number, to:number, actions: VisCtrlAction[]) {
        if (to - from > 1) {
            actions.push({
                action: VisCtrlActionType.SetValue,
                controlId: VisControls.range,
                controlValue: `${from};${to}`
            })
        }
    }

    protected startDragging (x:number, range:NumInclusiveRange, actions:VisCtrlAction[], method:string) {
        if (this.dragInfo === undefined && this.renderResult?.xSVGToUnitsMapper) {
            this.dragInfo = {
                x,
                unitMapper: this.renderResult?.xSVGToUnitsMapper,
                range,
                lastRange:range,
                method
            }
            actions.push({action: VisCtrlActionType.SetVisCursor, cursor: "grabbing"})
        }
    }

    protected processDragging (action:UserActionInfo, d:VisData, actions: VisCtrlAction[]) {
        if (this.dragInfo) {
            const dragCurrentX = this.dragInfo.unitMapper(action.svgX)
            if (dragCurrentX !== undefined) {
                const fromRangePoint = Math.round((this.dragInfo.x - d.gridMin) / (d.gridMax - d.gridMin) * 1000)
                const toRangePoint = Math.round((dragCurrentX - d.gridMin) / (d.gridMax - d.gridMin) * 1000)
                const newMin = this.dragInfo.range.min + fromRangePoint - toRangePoint
                const newMax = this.dragInfo.range.max + fromRangePoint - toRangePoint
                if (newMin >= 0 && newMax <= 1000) {
                    this.setNewRange(newMin, newMax, actions)
                    this.dragInfo.lastRange = {min:newMin, max:newMax}
                }
            }
        }
    }

    protected stopDragging (actions: VisCtrlAction[]) {
        if (this.dragInfo !== undefined) {
            if (this.dragInfo.range.min !== this.dragInfo.lastRange.min || this.dragInfo.range.max !== this.dragInfo.lastRange.max) {
                event.analytics.visualizer.chart.pan(`${Math.round((this.dragInfo.range.max - this.dragInfo.range.min)/2 + this.dragInfo.range.min)} -> ${Math.round((this.dragInfo.lastRange.max - this.dragInfo.lastRange.min)/2 + this.dragInfo.lastRange.min)}`, this.dragInfo.method)
            }
            this.dragInfo = undefined
            actions.push({
                action: VisCtrlActionType.SetVisCursor,
                cursor: this.shownAsCategories ? "default" : "zoom-in"
            })
        }
    }

    protected processPinching (action:UserActionInfo, actions: VisCtrlAction[]) {
        if (this.pinchInfo && action.svgX2 !== undefined) {
            const diff = Math.sqrt(action.svgX**2 + action.svgX2**2) - this.pinchInfo.distance,
                from = Math.max(0, Math.round(this.pinchInfo.rangeCenter - (this.pinchInfo.rangeLength - diff * 7) / 2)),
                to = Math.min(1000, Math.round(this.pinchInfo.rangeCenter + (this.pinchInfo.rangeLength - diff * 7) / 2))
            this.setNewRange(from, to, actions)
            this.pinchInfo.lastRangeLength = to - from
        }
    }

    protected startPinching (action:UserActionInfo, rangeCenter:number, rangeLength:number) {
        if (this.pinchInfo === undefined && action.svgX2 !== undefined) {
            this.pinchInfo = {
                distance: Math.sqrt(action.svgX**2 + action.svgX2**2),
                rangeCenter,
                rangeLength,
                lastRangeLength: rangeLength
            }
        }
    }

    protected stopPinching () {
        if (this.pinchInfo !== undefined) {
            if (this.pinchInfo.rangeLength !== this.pinchInfo.lastRangeLength) {
                event.analytics.visualizer.chart.zoom(`${this.pinchInfo.rangeLength} -> ${this.pinchInfo.lastRangeLength}`, 'touch')
                ymGoal('ChartZoom')
            }
            this.pinchInfo = undefined
        }
    }

    handleUserAction (action: UserActionInfo, data:VisualizerData, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues):UserActionResponse|undefined {
        if (!this.shownAsCategories) {
            const actions:VisCtrlAction[] = [],
                d = data as VisData,
                x = this.renderResult?.xSVGToUnitsMapper ? this.renderResult?.xSVGToUnitsMapper(action.svgX) : undefined,
                [rangeMin, rangeMax] = U.get(controlValues, VisControls.range).split(';').map(x => U.parseIntNotNan(x)),
                rangeLength = rangeMax - rangeMin,
                rangeCenter = rangeMin + rangeLength / 2

            switch (action.type) {

                case UserActionType.Wheel:
                    if (action.wheelDelta) {
                        const from = Math.max(0, rangeCenter - (rangeLength + action.wheelDelta / 2) / 2),
                            to = Math.min(1000, rangeCenter + (rangeLength + action.wheelDelta / 2) / 2)
                        this.setNewRange(from, to, actions)
                        event.analytics.visualizer.chart.zoom(`${rangeLength} -> ${to - from}`, 'wheel')
                        ymGoal('ChartZoom')
                    }
                    break

                case UserActionType.DoubleClick:
                    if (x !== undefined) {
                        const percX = Math.round ((x - d.gridMin) / (d.gridMax - d.gridMin) * 1000),
                            from = Math.max(0, Math.round(percX - (rangeLength - 100) / 2)),
                            to = Math.min(1000, Math.round(percX + (rangeLength - 100) / 2))
                        this.setNewRange(from, to, actions)
                        event.analytics.visualizer.chart.zoom(`${rangeMin};${rangeMax} -> ${from};${to}`, 'dblclk')
                        ymGoal('ChartZoom')
                    }
                    break

                case UserActionType.TouchStart:
                    if (action.svgX2 !== undefined) {
                        this.stopDragging (actions)
                        const x2 = this.renderResult?.xSVGToUnitsMapper ? this.renderResult?.xSVGToUnitsMapper(action.svgX2) : undefined
                        if (x !== undefined && x2 !== undefined) {
                            this.startPinching (action, rangeCenter, rangeLength)
                        }
                    }
                    else if (x !== undefined) {
                        this.startDragging(x, {min: rangeMin, max: rangeMax}, actions, 'touch')
                    }
                    break

                case UserActionType.MouseDown:
                    if (x !== undefined) {
                        this.startDragging(x, {min: rangeMin, max: rangeMax}, actions, 'mouse')
                    }
                    break

                case UserActionType.TouchMove:
                case UserActionType.MouseMove:
                    this.processPinching (action, actions)
                    this.processDragging (action, d, actions)
                    break

                case UserActionType.TouchCancel:
                case UserActionType.TouchEnd:
                case UserActionType.MouseUp:
                    this.stopPinching ()
                    this.stopDragging (actions)
                    break

                default:
                    break
            }
            return actions.length ? {visControlActions: actions} : undefined
        }
    }
}