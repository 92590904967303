import {alias, object, optional, serializable} from "serializr"
import CategoryDistribution from "./CategoryDistribution"
import NumberSummary from "./NumberSummary"
import {ColumnSummaryType, ICategoryDistribution, IColumnSummary, INumberSummary} from "../Concepts/Basic"

export default class ColumnSummary implements IColumnSummary {
    @serializable(alias('type')) readonly type: ColumnSummaryType
    // includes null values
    @serializable(alias('vals')) readonly valueCount: number
    @serializable(alias('nulls')) readonly nullCount: number
    @serializable(alias('numsum', optional(object(NumberSummary)))) readonly _numberSummary: INumberSummary | undefined
    @serializable(alias('catdistr', optional(object(CategoryDistribution)))) readonly _categoryDistribution: ICategoryDistribution | undefined

    constructor(type: ColumnSummaryType, valueCount: number, nullCount: number, numberSummary?: INumberSummary, categoryDistribution?: ICategoryDistribution) {
        this.type = type
        this.valueCount = valueCount
        this.nullCount = nullCount
        this._numberSummary = numberSummary
        this._categoryDistribution = categoryDistribution

        if ((type === ColumnSummaryType.Number || type === ColumnSummaryType.Text) && numberSummary === undefined) {
            throw new Error(`numberSummary should be defined for ${this.type} type`)
        }

        if ((type === ColumnSummaryType.Category) && categoryDistribution === undefined) {
            throw new Error(`categoryDistribution should be defined for ${this.type} type`)
        }
    }

    get numberSummary(): INumberSummary {
        if (this._numberSummary !== undefined) {
            return this._numberSummary
        } else {
            throw new Error(`numberSummary is not defined for ${this.type} type`)
        }
    }

    get categoryDistribution(): ICategoryDistribution {
        if (this._categoryDistribution !== undefined) {
            return this._categoryDistribution
        } else {
            throw new Error(`categoryDistribution is not defined for ${this.type} type`)
        }
    }
}

