// taken from https://github.com/umpirsky/locale-list
export const locales:{[index:string]: string} = {
    'af':
        'Afrikaans',
    'af-NA':
        'Afrikaans (Namibia)',
    'af-ZA':
        'Afrikaans (South Africa)',
    'ak':
        'Akan',
    'ak-GH':
        'Akan (Ghana)',
    'sq':
        'Albanian',
    'sq-AL':
        'Albanian (Albania)',
    'sq-XK':
        'Albanian (Kosovo)',
    'sq-MK':
        'Albanian (Macedonia)',
    'am':
        'Amharic',
    'am-ET':
        'Amharic (Ethiopia)',
    'ar':
        'Arabic',
    'ar-DZ':
        'Arabic (Algeria)',
    'ar-BH':
        'Arabic (Bahrain)',
    'ar-TD':
        'Arabic (Chad)',
    'ar-KM':
        'Arabic (Comoros)',
    'ar-DJ':
        'Arabic (Djibouti)',
    'ar-EG':
        'Arabic (Egypt)',
    'ar-ER':
        'Arabic (Eritrea)',
    'ar-IQ':
        'Arabic (Iraq)',
    'ar-IL':
        'Arabic (Israel)',
    'ar-JO':
        'Arabic (Jordan)',
    'ar-KW':
        'Arabic (Kuwait)',
    'ar-LB':
        'Arabic (Lebanon)',
    'ar-LY':
        'Arabic (Libya)',
    'ar-MR':
        'Arabic (Mauritania)',
    'ar-MA':
        'Arabic (Morocco)',
    'ar-OM':
        'Arabic (Oman)',
    'ar-PS':
        'Arabic (Palestinian Territories)',
    'ar-QA':
        'Arabic (Qatar)',
    'ar-SA':
        'Arabic (Saudi Arabia)',
    'ar-SO':
        'Arabic (Somalia)',
    'ar-SS':
        'Arabic (South Sudan)',
    'ar-SD':
        'Arabic (Sudan)',
    'ar-SY':
        'Arabic (Syria)',
    'ar-TN':
        'Arabic (Tunisia)',
    'ar-AE':
        'Arabic (United Arab Emirates)',
    'ar-EH':
        'Arabic (Western Sahara)',
    'ar-YE':
        'Arabic (Yemen)',
    'hy':
        'Armenian',
    'hy-AM':
        'Armenian (Armenia)',
    'as':
        'Assamese',
    'as-IN':
        'Assamese (India)',
    'az':
        'Azerbaijani',
    'az-AZ':
        'Azerbaijani (Azerbaijan)',
    'az-Cyrl-AZ':
        'Azerbaijani (Cyrillic, Azerbaijan)',
    'az-Cyrl':
        'Azerbaijani (Cyrillic)',
    'az-Latn-AZ':
        'Azerbaijani (Latin, Azerbaijan)',
    'az-Latn':
        'Azerbaijani (Latin)',
    'bm':
        'Bambara',
    'bm-Latn-ML':
        'Bambara (Latin, Mali)',
    'bm-Latn':
        'Bambara (Latin)',
    'eu':
        'Basque',
    'eu-ES':
        'Basque (Spain)',
    'be':
        'Belarusian',
    'be-BY':
        'Belarusian (Belarus)',
    'bn':
        'Bengali',
    'bn-BD':
        'Bengali (Bangladesh)',
    'bn-IN':
        'Bengali (India)',
    'bs':
        'Bosnian',
    'bs-BA':
        'Bosnian (Bosnia & Herzegovina)',
    'bs-Cyrl-BA':
        'Bosnian (Cyrillic, Bosnia & Herzegovina)',
    'bs-Cyrl':
        'Bosnian (Cyrillic)',
    'bs-Latn-BA':
        'Bosnian (Latin, Bosnia & Herzegovina)',
    'bs-Latn':
        'Bosnian (Latin)',
    'br':
        'Breton',
    'br-FR':
        'Breton (France)',
    'bg':
        'Bulgarian',
    'bg-BG':
        'Bulgarian (Bulgaria)',
    'my':
        'Burmese',
    'my-MM':
        'Burmese (Myanmar (Burma))',
    'ca':
        'Catalan',
    'ca-AD':
        'Catalan (Andorra)',
    'ca-FR':
        'Catalan (France)',
    'ca-IT':
        'Catalan (Italy)',
    'ca-ES':
        'Catalan (Spain)',
    'zh':
        'Chinese',
    'zh-CN':
        'Chinese (China)',
    'zh-HK':
        'Chinese (Hong Kong SAR China)',
    'zh-MO':
        'Chinese (Macau SAR China)',
    'zh-Hans-CN':
        'Chinese (Simplified, China)',
    'zh-Hans-HK':
        'Chinese (Simplified, Hong Kong SAR China)',
    'zh-Hans-MO':
        'Chinese (Simplified, Macau SAR China)',
    'zh-Hans-SG':
        'Chinese (Simplified, Singapore)',
    'zh-Hans':
        'Chinese (Simplified)',
    'zh-SG':
        'Chinese (Singapore)',
    'zh-TW':
        'Chinese (Taiwan)',
    'zh-Hant-HK':
        'Chinese (Traditional, Hong Kong SAR China)',
    'zh-Hant-MO':
        'Chinese (Traditional, Macau SAR China)',
    'zh-Hant-TW':
        'Chinese (Traditional, Taiwan)',
    'zh-Hant':
        'Chinese (Traditional)',
    'kw':
        'Cornish',
    'kw-GB':
        'Cornish (United Kingdom)',
    'hr':
        'Croatian',
    'hr-BA':
        'Croatian (Bosnia & Herzegovina)',
    'hr-HR':
        'Croatian (Croatia)',
    'cs':
        'Czech',
    'cs-CZ':
        'Czech (Czech Republic)',
    'da':
        'Danish',
    'da-DK':
        'Danish (Denmark)',
    'da-GL':
        'Danish (Greenland)',
    'nl':
        'Dutch',
    'nl-AW':
        'Dutch (Aruba)',
    'nl-BE':
        'Dutch (Belgium)',
    'nl-BQ':
        'Dutch (Caribbean Netherlands)',
    'nl-CW':
        'Dutch (Curaçao)',
    'nl-NL':
        'Dutch (Netherlands)',
    'nl-SX':
        'Dutch (Sint Maarten)',
    'nl-SR':
        'Dutch (Suriname)',
    'dz':
        'Dzongkha',
    'dz-BT':
        'Dzongkha (Bhutan)',
    'en':
        'English',
    'en-AS':
        'English (American Samoa)',
    'en-AI':
        'English (Anguilla)',
    'en-AG':
        'English (Antigua & Barbuda)',
    'en-AU':
        'English (Australia)',
    'en-BS':
        'English (Bahamas)',
    'en-BB':
        'English (Barbados)',
    'en-BE':
        'English (Belgium)',
    'en-BZ':
        'English (Belize)',
    'en-BM':
        'English (Bermuda)',
    'en-BW':
        'English (Botswana)',
    'en-IO':
        'English (British Indian Ocean Territory)',
    'en-VG':
        'English (British Virgin Islands)',
    'en-CM':
        'English (Cameroon)',
    'en-CA':
        'English (Canada)',
    'en-KY':
        'English (Cayman Islands)',
    'en-CX':
        'English (Christmas Island)',
    'en-CC':
        'English (Cocos (Keeling) Islands)',
    'en-CK':
        'English (Cook Islands)',
    'en-DG':
        'English (Diego Garcia)',
    'en-DM':
        'English (Dominica)',
    'en-ER':
        'English (Eritrea)',
    'en-FK':
        'English (Falkland Islands)',
    'en-FJ':
        'English (Fiji)',
    'en-GM':
        'English (Gambia)',
    'en-GH':
        'English (Ghana)',
    'en-GI':
        'English (Gibraltar)',
    'en-GD':
        'English (Grenada)',
    'en-GU':
        'English (Guam)',
    'en-GG':
        'English (Guernsey)',
    'en-GY':
        'English (Guyana)',
    'en-HK':
        'English (Hong Kong SAR China)',
    'en-IN':
        'English (India)',
    'en-IE':
        'English (Ireland)',
    'en-IM':
        'English (Isle of Man)',
    'en-JM':
        'English (Jamaica)',
    'en-JE':
        'English (Jersey)',
    'en-KE':
        'English (Kenya)',
    'en-KI':
        'English (Kiribati)',
    'en-LS':
        'English (Lesotho)',
    'en-LR':
        'English (Liberia)',
    'en-MO':
        'English (Macau SAR China)',
    'en-MG':
        'English (Madagascar)',
    'en-MW':
        'English (Malawi)',
    'en-MY':
        'English (Malaysia)',
    'en-MT':
        'English (Malta)',
    'en-MH':
        'English (Marshall Islands)',
    'en-MU':
        'English (Mauritius)',
    'en-FM':
        'English (Micronesia)',
    'en-MS':
        'English (Montserrat)',
    'en-NA':
        'English (Namibia)',
    'en-NR':
        'English (Nauru)',
    'en-NZ':
        'English (New Zealand)',
    'en-NG':
        'English (Nigeria)',
    'en-NU':
        'English (Niue)',
    'en-NF':
        'English (Norfolk Island)',
    'en-MP':
        'English (Northern Mariana Islands)',
    'en-PK':
        'English (Pakistan)',
    'en-PW':
        'English (Palau)',
    'en-PG':
        'English (Papua New Guinea)',
    'en-PH':
        'English (Philippines)',
    'en-PN':
        'English (Pitcairn Islands)',
    'en-PR':
        'English (Puerto Rico)',
    'en-RW':
        'English (Rwanda)',
    'en-WS':
        'English (Samoa)',
    'en-SC':
        'English (Seychelles)',
    'en-SL':
        'English (Sierra Leone)',
    'en-SG':
        'English (Singapore)',
    'en-SX':
        'English (Sint Maarten)',
    'en-SB':
        'English (Solomon Islands)',
    'en-ZA':
        'English (South Africa)',
    'en-SS':
        'English (South Sudan)',
    'en-SH':
        'English (St. Helena)',
    'en-KN':
        'English (St. Kitts & Nevis)',
    'en-LC':
        'English (St. Lucia)',
    'en-VC':
        'English (St. Vincent & Grenadines)',
    'en-SD':
        'English (Sudan)',
    'en-SZ':
        'English (Swaziland)',
    'en-TZ':
        'English (Tanzania)',
    'en-TK':
        'English (Tokelau)',
    'en-TO':
        'English (Tonga)',
    'en-TT':
        'English (Trinidad & Tobago)',
    'en-TC':
        'English (Turks & Caicos Islands)',
    'en-TV':
        'English (Tuvalu)',
    'en-UM':
        'English (U.S. Outlying Islands)',
    'en-VI':
        'English (U.S. Virgin Islands)',
    'en-UG':
        'English (Uganda)',
    'en-GB':
        'English (United Kingdom)',
    'en-US':
        'English (United States)',
    'en-VU':
        'English (Vanuatu)',
    'en-ZM':
        'English (Zambia)',
    'en-ZW':
        'English (Zimbabwe)',
    'eo':
        'Esperanto',
    'et':
        'Estonian',
    'et-EE':
        'Estonian (Estonia)',
    'ee':
        'Ewe',
    'ee-GH':
        'Ewe (Ghana)',
    'ee-TG':
        'Ewe (Togo)',
    'fo':
        'Faroese',
    'fo-FO':
        'Faroese (Faroe Islands)',
    'fi':
        'Finnish',
    'fi-FI':
        'Finnish (Finland)',
    'fr':
        'French',
    'fr-DZ':
        'French (Algeria)',
    'fr-BE':
        'French (Belgium)',
    'fr-BJ':
        'French (Benin)',
    'fr-BF':
        'French (Burkina Faso)',
    'fr-BI':
        'French (Burundi)',
    'fr-CM':
        'French (Cameroon)',
    'fr-CA':
        'French (Canada)',
    'fr-CF':
        'French (Central African Republic)',
    'fr-TD':
        'French (Chad)',
    'fr-KM':
        'French (Comoros)',
    'fr-CG':
        'French (Congo - Brazzaville)',
    'fr-CD':
        'French (Congo - Kinshasa)',
    'fr-CI':
        'French (Côte d’Ivoire)',
    'fr-DJ':
        'French (Djibouti)',
    'fr-GQ':
        'French (Equatorial Guinea)',
    'fr-FR':
        'French (France)',
    'fr-GF':
        'French (French Guiana)',
    'fr-PF':
        'French (French Polynesia)',
    'fr-GA':
        'French (Gabon)',
    'fr-GP':
        'French (Guadeloupe)',
    'fr-GN':
        'French (Guinea)',
    'fr-HT':
        'French (Haiti)',
    'fr-LU':
        'French (Luxembourg)',
    'fr-MG':
        'French (Madagascar)',
    'fr-ML':
        'French (Mali)',
    'fr-MQ':
        'French (Martinique)',
    'fr-MR':
        'French (Mauritania)',
    'fr-MU':
        'French (Mauritius)',
    'fr-YT':
        'French (Mayotte)',
    'fr-MC':
        'French (Monaco)',
    'fr-MA':
        'French (Morocco)',
    'fr-NC':
        'French (New Caledonia)',
    'fr-NE':
        'French (Niger)',
    'fr-RE':
        'French (Réunion)',
    'fr-RW':
        'French (Rwanda)',
    'fr-SN':
        'French (Senegal)',
    'fr-SC':
        'French (Seychelles)',
    'fr-BL':
        'French (St. Barthélemy)',
    'fr-MF':
        'French (St. Martin)',
    'fr-PM':
        'French (St. Pierre & Miquelon)',
    'fr-CH':
        'French (Switzerland)',
    'fr-SY':
        'French (Syria)',
    'fr-TG':
        'French (Togo)',
    'fr-TN':
        'French (Tunisia)',
    'fr-VU':
        'French (Vanuatu)',
    'fr-WF':
        'French (Wallis & Futuna)',
    'ff':
        'Fulah',
    'ff-CM':
        'Fulah (Cameroon)',
    'ff-GN':
        'Fulah (Guinea)',
    'ff-MR':
        'Fulah (Mauritania)',
    'ff-SN':
        'Fulah (Senegal)',
    'gl':
        'Galician',
    'gl-ES':
        'Galician (Spain)',
    'lg':
        'Ganda',
    'lg-UG':
        'Ganda (Uganda)',
    'ka':
        'Georgian',
    'ka-GE':
        'Georgian (Georgia)',
    'de':
        'German',
    'de-AT':
        'German (Austria)',
    'de-BE':
        'German (Belgium)',
    'de-DE':
        'German (Germany)',
    'de-LI':
        'German (Liechtenstein)',
    'de-LU':
        'German (Luxembourg)',
    'de-CH':
        'German (Switzerland)',
    'el':
        'Greek',
    'el-CY':
        'Greek (Cyprus)',
    'el-GR':
        'Greek (Greece)',
    'gu':
        'Gujarati',
    'gu-IN':
        'Gujarati (India)',
    'ha':
        'Hausa',
    'ha-GH':
        'Hausa (Ghana)',
    'ha-Latn-GH':
        'Hausa (Latin, Ghana)',
    'ha-Latn-NE':
        'Hausa (Latin, Niger)',
    'ha-Latn-NG':
        'Hausa (Latin, Nigeria)',
    'ha-Latn':
        'Hausa (Latin)',
    'ha-NE':
        'Hausa (Niger)',
    'ha-NG':
        'Hausa (Nigeria)',
    'he':
        'Hebrew',
    'he-IL':
        'Hebrew (Israel)',
    'hi':
        'Hindi',
    'hi-IN':
        'Hindi (India)',
    'hu':
        'Hungarian',
    'hu-HU':
        'Hungarian (Hungary)',
    'is':
        'Icelandic',
    'is-IS':
        'Icelandic (Iceland)',
    'ig':
        'Igbo',
    'ig-NG':
        'Igbo (Nigeria)',
    'id':
        'Indonesian',
    'id-ID':
        'Indonesian (Indonesia)',
    'ga':
        'Irish',
    'ga-IE':
        'Irish (Ireland)',
    'it':
        'Italian',
    'it-IT':
        'Italian (Italy)',
    'it-SM':
        'Italian (San Marino)',
    'it-CH':
        'Italian (Switzerland)',
    'ja':
        'Japanese',
    'ja-JP':
        'Japanese (Japan)',
    'kl':
        'Kalaallisut',
    'kl-GL':
        'Kalaallisut (Greenland)',
    'kn':
        'Kannada',
    'kn-IN':
        'Kannada (India)',
    'ks':
        'Kashmiri',
    'ks-Arab-IN':
        'Kashmiri (Arabic, India)',
    'ks-Arab':
        'Kashmiri (Arabic)',
    'ks-IN':
        'Kashmiri (India)',
    'kk':
        'Kazakh',
    'kk-Cyrl-KZ':
        'Kazakh (Cyrillic, Kazakhstan)',
    'kk-Cyrl':
        'Kazakh (Cyrillic)',
    'kk-KZ':
        'Kazakh (Kazakhstan)',
    'km':
        'Khmer',
    'km-KH':
        'Khmer (Cambodia)',
    'ki':
        'Kikuyu',
    'ki-KE':
        'Kikuyu (Kenya)',
    'rw':
        'Kinyarwanda',
    'rw-RW':
        'Kinyarwanda (Rwanda)',
    'ko':
        'Korean',
    'ko-KP':
        'Korean (North Korea)',
    'ko-KR':
        'Korean (South Korea)',
    'ky':
        'Kyrgyz',
    'ky-Cyrl-KG':
        'Kyrgyz (Cyrillic, Kyrgyzstan)',
    'ky-Cyrl':
        'Kyrgyz (Cyrillic)',
    'ky-KG':
        'Kyrgyz (Kyrgyzstan)',
    'lo':
        'Lao',
    'lo-LA':
        'Lao (Laos)',
    'lv':
        'Latvian',
    'lv-LV':
        'Latvian (Latvia)',
    'ln':
        'Lingala',
    'ln-AO':
        'Lingala (Angola)',
    'ln-CF':
        'Lingala (Central African Republic)',
    'ln-CG':
        'Lingala (Congo - Brazzaville)',
    'ln-CD':
        'Lingala (Congo - Kinshasa)',
    'lt':
        'Lithuanian',
    'lt-LT':
        'Lithuanian (Lithuania)',
    'lu':
        'Luba-Katanga',
    'lu-CD':
        'Luba-Katanga (Congo - Kinshasa)',
    'lb':
        'Luxembourgish',
    'lb-LU':
        'Luxembourgish (Luxembourg)',
    'mk':
        'Macedonian',
    'mk-MK':
        'Macedonian (Macedonia)',
    'mg':
        'Malagasy',
    'mg-MG':
        'Malagasy (Madagascar)',
    'ms':
        'Malay',
    'ms-BN':
        'Malay (Brunei)',
    'ms-Latn-BN':
        'Malay (Latin, Brunei)',
    'ms-Latn-MY':
        'Malay (Latin, Malaysia)',
    'ms-Latn-SG':
        'Malay (Latin, Singapore)',
    'ms-Latn':
        'Malay (Latin)',
    'ms-MY':
        'Malay (Malaysia)',
    'ms-SG':
        'Malay (Singapore)',
    'ml':
        'Malayalam',
    'ml-IN':
        'Malayalam (India)',
    'mt':
        'Maltese',
    'mt-MT':
        'Maltese (Malta)',
    'gv':
        'Manx',
    'gv-IM':
        'Manx (Isle of Man)',
    'mr':
        'Marathi',
    'mr-IN':
        'Marathi (India)',
    'mn':
        'Mongolian',
    'mn-Cyrl-MN':
        'Mongolian (Cyrillic, Mongolia)',
    'mn-Cyrl':
        'Mongolian (Cyrillic)',
    'mn-MN':
        'Mongolian (Mongolia)',
    'ne':
        'Nepali',
    'ne-IN':
        'Nepali (India)',
    'ne-NP':
        'Nepali (Nepal)',
    'nd':
        'North Ndebele',
    'nd-ZW':
        'North Ndebele (Zimbabwe)',
    'se':
        'Northern Sami',
    'se-FI':
        'Northern Sami (Finland)',
    'se-NO':
        'Northern Sami (Norway)',
    'se-SE':
        'Northern Sami (Sweden)',
    'no':
        'Norwegian',
    'no-NO':
        'Norwegian (Norway)',
    'nb':
        'Norwegian Bokmål',
    'nb-NO':
        'Norwegian Bokmål (Norway)',
    'nb-SJ':
        'Norwegian Bokmål (Svalbard & Jan Mayen)',
    'nn':
        'Norwegian Nynorsk',
    'nn-NO':
        'Norwegian Nynorsk (Norway)',
    'or':
        'Oriya',
    'or-IN':
        'Oriya (India)',
    'om':
        'Oromo',
    'om-ET':
        'Oromo (Ethiopia)',
    'om-KE':
        'Oromo (Kenya)',
    'os':
        'Ossetic',
    'os-GE':
        'Ossetic (Georgia)',
    'os-RU':
        'Ossetic (Russia)',
    'ps':
        'Pashto',
    'ps-AF':
        'Pashto (Afghanistan)',
    'fa':
        'Persian',
    'fa-AF':
        'Persian (Afghanistan)',
    'fa-IR':
        'Persian (Iran)',
    'pl':
        'Polish',
    'pl-PL':
        'Polish (Poland)',
    'pt':
        'Portuguese',
    'pt-AO':
        'Portuguese (Angola)',
    'pt-BR':
        'Portuguese (Brazil)',
    'pt-CV':
        'Portuguese (Cape Verde)',
    'pt-GW':
        'Portuguese (Guinea-Bissau)',
    'pt-MO':
        'Portuguese (Macau SAR China)',
    'pt-MZ':
        'Portuguese (Mozambique)',
    'pt-PT':
        'Portuguese (Portugal)',
    'pt-ST':
        'Portuguese (São Tomé & Príncipe)',
    'pt-TL':
        'Portuguese (Timor-Leste)',
    'pa':
        'Punjabi',
    'pa-Arab-PK':
        'Punjabi (Arabic, Pakistan)',
    'pa-Arab':
        'Punjabi (Arabic)',
    'pa-Guru-IN':
        'Punjabi (Gurmukhi, India)',
    'pa-Guru':
        'Punjabi (Gurmukhi)',
    'pa-IN':
        'Punjabi (India)',
    'pa-PK':
        'Punjabi (Pakistan)',
    'qu':
        'Quechua',
    'qu-BO':
        'Quechua (Bolivia)',
    'qu-EC':
        'Quechua (Ecuador)',
    'qu-PE':
        'Quechua (Peru)',
    'ro':
        'Romanian',
    'ro-MD':
        'Romanian (Moldova)',
    'ro-RO':
        'Romanian (Romania)',
    'rm':
        'Romansh',
    'rm-CH':
        'Romansh (Switzerland)',
    'rn':
        'Rundi',
    'rn-BI':
        'Rundi (Burundi)',
    'ru':
        'Russian',
    'ru-BY':
        'Russian (Belarus)',
    'ru-KZ':
        'Russian (Kazakhstan)',
    'ru-KG':
        'Russian (Kyrgyzstan)',
    'ru-MD':
        'Russian (Moldova)',
    'ru-RU':
        'Russian (Russia)',
    'ru-UA':
        'Russian (Ukraine)',
    'sg':
        'Sango',
    'sg-CF':
        'Sango (Central African Republic)',
    'gd':
        'Scottish Gaelic',
    'gd-GB':
        'Scottish Gaelic (United Kingdom)',
    'sr':
        'Serbian',
    'sr-BA':
        'Serbian (Bosnia & Herzegovina)',
    'sr-Cyrl-BA':
        'Serbian (Cyrillic, Bosnia & Herzegovina)',
    'sr-Cyrl-XK':
        'Serbian (Cyrillic, Kosovo)',
    'sr-Cyrl-ME':
        'Serbian (Cyrillic, Montenegro)',
    'sr-Cyrl-RS':
        'Serbian (Cyrillic, Serbia)',
    'sr-Cyrl':
        'Serbian (Cyrillic)',
    'sr-XK':
        'Serbian (Kosovo)',
    'sr-Latn-BA':
        'Serbian (Latin, Bosnia & Herzegovina)',
    'sr-Latn-XK':
        'Serbian (Latin, Kosovo)',
    'sr-Latn-ME':
        'Serbian (Latin, Montenegro)',
    'sr-Latn-RS':
        'Serbian (Latin, Serbia)',
    'sr-Latn':
        'Serbian (Latin)',
    'sr-ME':
        'Serbian (Montenegro)',
    'sr-RS':
        'Serbian (Serbia)',
    'sh':
        'Serbo-Croatian',
    'sh-BA':
        'Serbo-Croatian (Bosnia & Herzegovina)',
    'sn':
        'Shona',
    'sn-ZW':
        'Shona (Zimbabwe)',
    'ii':
        'Sichuan Yi',
    'ii-CN':
        'Sichuan Yi (China)',
    'si':
        'Sinhala',
    'si-LK':
        'Sinhala (Sri Lanka)',
    'sk':
        'Slovak',
    'sk-SK':
        'Slovak (Slovakia)',
    'sl':
        'Slovenian',
    'sl-SI':
        'Slovenian (Slovenia)',
    'so':
        'Somali',
    'so-DJ':
        'Somali (Djibouti)',
    'so-ET':
        'Somali (Ethiopia)',
    'so-KE':
        'Somali (Kenya)',
    'so-SO':
        'Somali (Somalia)',
    'es':
        'Spanish',
    'es-AR':
        'Spanish (Argentina)',
    'es-BO':
        'Spanish (Bolivia)',
    'es-IC':
        'Spanish (Canary Islands)',
    'es-EA':
        'Spanish (Ceuta & Melilla)',
    'es-CL':
        'Spanish (Chile)',
    'es-CO':
        'Spanish (Colombia)',
    'es-CR':
        'Spanish (Costa Rica)',
    'es-CU':
        'Spanish (Cuba)',
    'es-DO':
        'Spanish (Dominican Republic)',
    'es-EC':
        'Spanish (Ecuador)',
    'es-SV':
        'Spanish (El Salvador)',
    'es-GQ':
        'Spanish (Equatorial Guinea)',
    'es-GT':
        'Spanish (Guatemala)',
    'es-HN':
        'Spanish (Honduras)',
    'es-MX':
        'Spanish (Mexico)',
    'es-NI':
        'Spanish (Nicaragua)',
    'es-PA':
        'Spanish (Panama)',
    'es-PY':
        'Spanish (Paraguay)',
    'es-PE':
        'Spanish (Peru)',
    'es-PH':
        'Spanish (Philippines)',
    'es-PR':
        'Spanish (Puerto Rico)',
    'es-ES':
        'Spanish (Spain)',
    'es-US':
        'Spanish (United States)',
    'es-UY':
        'Spanish (Uruguay)',
    'es-VE':
        'Spanish (Venezuela)',
    'sw':
        'Swahili',
    'sw-KE':
        'Swahili (Kenya)',
    'sw-TZ':
        'Swahili (Tanzania)',
    'sw-UG':
        'Swahili (Uganda)',
    'sv':
        'Swedish',
    'sv-AX':
        'Swedish (Åland Islands)',
    'sv-FI':
        'Swedish (Finland)',
    'sv-SE':
        'Swedish (Sweden)',
    'tl':
        'Tagalog',
    'tl-PH':
        'Tagalog (Philippines)',
    'ta':
        'Tamil',
    'ta-IN':
        'Tamil (India)',
    'ta-MY':
        'Tamil (Malaysia)',
    'ta-SG':
        'Tamil (Singapore)',
    'ta-LK':
        'Tamil (Sri Lanka)',
    'te':
        'Telugu',
    'te-IN':
        'Telugu (India)',
    'th':
        'Thai',
    'th-TH':
        'Thai (Thailand)',
    'bo':
        'Tibetan',
    'bo-CN':
        'Tibetan (China)',
    'bo-IN':
        'Tibetan (India)',
    'ti':
        'Tigrinya',
    'ti-ER':
        'Tigrinya (Eritrea)',
    'ti-ET':
        'Tigrinya (Ethiopia)',
    'to':
        'Tongan',
    'to-TO':
        'Tongan (Tonga)',
    'tr':
        'Turkish',
    'tr-CY':
        'Turkish (Cyprus)',
    'tr-TR':
        'Turkish (Turkey)',
    'uk':
        'Ukrainian',
    'uk-UA':
        'Ukrainian (Ukraine)',
    'ur':
        'Urdu',
    'ur-IN':
        'Urdu (India)',
    'ur-PK':
        'Urdu (Pakistan)',
    'ug':
        'Uyghur',
    'ug-Arab-CN':
        'Uyghur (Arabic, China)',
    'ug-Arab':
        'Uyghur (Arabic)',
    'ug-CN':
        'Uyghur (China)',
    'uz':
        'Uzbek',
    'uz-AF':
        'Uzbek (Afghanistan)',
    'uz-Arab-AF':
        'Uzbek (Arabic, Afghanistan)',
    'uz-Arab':
        'Uzbek (Arabic)',
    'uz-Cyrl-UZ':
        'Uzbek (Cyrillic, Uzbekistan)',
    'uz-Cyrl':
        'Uzbek (Cyrillic)',
    'uz-Latn-UZ':
        'Uzbek (Latin, Uzbekistan)',
    'uz-Latn':
        'Uzbek (Latin)',
    'uz-UZ':
        'Uzbek (Uzbekistan)',
    'vi':
        'Vietnamese',
    'vi-VN':
        'Vietnamese (Vietnam)',
    'cy':
        'Welsh',
    'cy-GB':
        'Welsh (United Kingdom)',
    'fy':
        'Western Frisian',
    'fy-NL':
        'Western Frisian (Netherlands)',
    'yi':
        'Yiddish',
    'yo':
        'Yoruba',
    'yo-BJ':
        'Yoruba (Benin)',
    'yo-NG':
        'Yoruba (Nigeria)',
    'zu':
        'Zulu',
    'zu-ZA':
        'Zulu (South Africa)',
}