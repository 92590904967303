import {OptionalString} from "../Concepts/Basic"
import {SemanticType} from "../Concepts/SemanticType"
import * as STT from "../StypeTools"
import {FirstMoment, ITimeInterval, MsSinceEpoch, TimeIntervalType} from "../Concepts/DateTime"
import {DateObjectUnits, DateTime} from "luxon"
import * as U from "../Utils"
import $t, {$tIntervalTypeName, $tQuarter, IntervalTypeNameForm} from "../i18n/i18n"

const year = (dt:DateTime) => dt.year.toString(),
    shortDate = (dt:DateTime) => dt.toLocaleString(DateTime.DATE_SHORT),
    monthDay = (dt:DateTime) => dt.toLocaleString({ month: 'short', day: '2-digit' }),
    monthYear = (dt:DateTime) => dt.toLocaleString({ month: 'short', year: 'numeric' }),
    hour = (dt:DateTime) => dt.hour.toString().padStart(2, '0'),
    hourMinute = (dt:DateTime) => dt.toLocaleString({hour: '2-digit', minute:'2-digit'}),
    day = (dt:DateTime) => dt.day.toString().padStart(2, '0')


export default class TimeInterval implements ITimeInterval {

    constructor(readonly type:TimeIntervalType) {}

    protected static zoneFromStype (stype:SemanticType) {
        return STT.isTimeOnly(stype) || STT.isDateOnly(stype) ? "UTC" : U.settings.timeZone
    }

    getFirstMoment (value:MsSinceEpoch, stype:SemanticType):FirstMoment {
        const dt = DateTime.fromMillis(value).setZone(TimeInterval.zoneFromStype(stype)).startOf(this.type)
        // in luxon weeks always start on Mondays
        return FirstMoment(dt.toMillis())
    }

    addInterval (moment:FirstMoment, stype:SemanticType):FirstMoment {
        return FirstMoment(DateTime.fromMillis(moment).setZone(TimeInterval.zoneFromStype(stype)).plus ({[this.type]:1}).toMillis())
    }

    formatLabels(values:IterableIterator<FirstMoment|null>, stype:SemanticType, commonPart?:OptionalString):Map<FirstMoment|null, string|null> {
        if (STT.isTimeOnly(stype)) {
            const divider = ((type:TimeIntervalType):number => {
                    switch (type) {
                        case TimeIntervalType.Year: return 1000 * 60 * 60 * 24 * 365
                        case TimeIntervalType.Quarter: return 1000 * 60 * 60 * 24 * 365 / 4
                        case TimeIntervalType.Month: return 1000 * 60 * 60 * 24 * 365 / 12
                        case TimeIntervalType.Week: return 1000 * 60 * 60 * 24 * 7
                        case TimeIntervalType.Day: return 1000 * 60 * 60 * 24
                        case TimeIntervalType.Hour: return 1000 * 60 * 60
                        case TimeIntervalType.Minute: return 1000 * 60
                        default:
                            U.shouldNeverGetHere(type)
                    }
                })(this.type)

            const moments = [...values],
                hasNull = moments.some (m => m === null),
                hasNegative = moments.some (m => m !== null && m < 0),
                result = new Map <FirstMoment|null, string|null> (hasNull ? [[null, null]] : [])

            for (const moment of moments) {
                if (moment !== null) {
                    const num = Math.floor(Math.abs(moment) / divider),
                        interval = num < 1 ? `< 1 ${$tIntervalTypeName(this.type, IntervalTypeNameForm.SingGen)}` : `${num}-${num+1} ${$tIntervalTypeName(this.type, IntervalTypeNameForm.PlurGen)}`
                    result.set (moment, interval + (hasNegative ? ' ' + (moment < 0 ? $t('timeinterval.before') : $t('timeinterval.after')) : '')
                    )
                }
            }
            return result
        } else {
            const dts:DateTime[] = []
            let hasNull = false
            for (const value of values) {
                if (value === null) {
                    hasNull = true
                } else {
                    dts.push(DateTime.fromMillis(value).setZone(TimeInterval.zoneFromStype(stype)).setLocale(U.settings.locale))
                }
            }

            // DateTime object units to be tested for difference
            const unitsToTest: readonly (keyof DateObjectUnits)[] = ['day', 'month', 'year'] as const

            // DateTime object units differing among all values
            const diff = new Set<keyof DateObjectUnits>(dts.map(dt => unitsToTest.filter(unit => dt[unit] !== dts[0][unit])).flat()),
                result = new Map <FirstMoment|null, string|null> (hasNull ? [[null,null]] : []),
                process = (toString: (dt: DateTime) => string) => dts.forEach(dt => result.set(FirstMoment(dt.toMillis()), toString(dt))),
                makeCommon = (toString: (dt?: DateTime) => string | undefined) => {
                    if (commonPart) commonPart.value = toString(dts[0])
                }

            switch (this.type) {
                case TimeIntervalType.Year:
                    process(dt => year(dt))
                    break

                case TimeIntervalType.Quarter:
                    if (diff.has('year')) {
                        process(dt => $tQuarter(dt.quarter, dt.year))
                    } else {
                        process(dt => $tQuarter(dt.quarter))
                        makeCommon(dt => U.aun(year, dt))
                    }
                    break

                case TimeIntervalType.Month:
                    if (diff.has('year')) {
                        process(dt => `${dt.monthShort} ${dt.year}`)
                    } else {
                        process(dt => `${dt.monthShort}`)
                        makeCommon(dt => U.aun(year, dt))
                    }
                    break

                case TimeIntervalType.Week:
                    if (diff.has('year')) {
                        process(dt => $t('timeintervals.week', {week: dt.weekNumber, start: shortDate(dt), end: shortDate(dt.plus({day:6}))}))
                    } else {
                        process(dt => $t('timeintervals.week', {week: dt.weekNumber, start: monthDay(dt), end: monthDay(dt.plus({day:6}))}))
                        makeCommon(dt => U.aun(year, dt))
                    }
                    break

                case TimeIntervalType.Day:
                    if (diff.has('year')) {
                        process(dt => `${shortDate(dt)}`)
                    } else {
                        if (diff.has('month')) {
                            process(dt => `${monthDay(dt)}`)
                            makeCommon(dt => U.aun(year, dt))
                        } else {
                            process(dt => `${day(dt)}`)
                            makeCommon(dt => U.aun(monthYear, dt))
                        }
                    }
                    break

                case TimeIntervalType.Hour:
                    if (!STT.isTimeOnly(stype) && (diff.has('month') || diff.has('day'))) {
                        if (diff.has('year')) {
                            process(dt => `${shortDate(dt)} ${hour(dt)}:00 - ${hour(dt)}:59`)
                        } else {
                            process(dt => `${monthDay(dt)} ${hour(dt)}:00 - ${hour(dt)}:59`)
                            makeCommon(dt => U.aun(year, dt))
                        }
                    } else {
                        process(dt => `${hour(dt)}:00 - ${hour(dt)}:59`)
                        makeCommon(dt => STT.isTimeOnly(stype) ? undefined : U.aun(shortDate, dt))
                    }
                    break

                case TimeIntervalType.Minute:
                    if (!STT.isTimeOnly(stype) && (diff.has('year') || diff.has('month') || diff.has('day'))) {
                        process(dt => `${shortDate(dt)} ${hourMinute(dt)}`)
                    } else {
                        process(dt => `${hourMinute(dt)}`)
                        makeCommon(dt => STT.isTimeOnly(stype) ? undefined : U.aun(shortDate, dt))
                    }
                    break

                default:
                    U.shouldNeverGetHere(this.type)
            }

            return result
        }
    }
}
