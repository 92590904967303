import {alias, serializable} from "serializr"

export class Point {
    @serializable(alias('x')) public x: number
    @serializable(alias('y')) public y: number

    constructor(x: number, y: number) {
        [this.x, this.y] = [x, y]
    }
}

export interface Size {
    height:number
    width:number
}

export class Rect {
    constructor(readonly x:number, readonly y:number, readonly width:number, readonly height:number) {}
    get xCenter() { return this.x + this.width / 2 }
    get yCenter() { return this.y + this.height / 2 }
    get left() { return this.x }
    get right() { return this.x + this.width }
    get top() { return this.y }
}
