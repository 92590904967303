import {PureComponent} from 'react'
import {Route, RouteComponentProps} from 'react-router-dom'

const ymEnabled = !window.location.host.startsWith('localhost')
const ymID = 86807486

export type YMGoal = 'ImportedData' | 'SelectedColumn' | 'SelectedVisualizer' | 'ChartZoom'

export function ymGoal (goal:YMGoal) {
    if (ymEnabled) {
        window.ym(ymID, 'reachGoal', goal)
    }
}

function ymHit (url:string) {
    if (ymEnabled) {
        window.ym(ymID, 'hit', url)
    }
}

type Props = RouteComponentProps<any>

export class Tracker extends PureComponent<Props> {
    protected static _queryString = ''

    static getQueryString() {
        return Tracker._queryString
    }

    override componentDidMount () {
        this.logPageChange(this.props.location.pathname, this.props.location.search)
    }

    override componentDidUpdate (prevProps:Props) {
        const location = this.props.location
        if (location.pathname !== prevProps.location.pathname || location.search !== prevProps.location.search) {
            this.logPageChange(location.pathname, location.search)
        }
    }

    logPageChange (pathname:string, search:string) {
        const page = pathname + search
        if (search && Tracker._queryString !== search) {
            Tracker._queryString = search
        }
        ymHit (window.location.origin + page)
    }

    override render () {
        return null
    }
}

export const RouteTracker = () => <Route component={Tracker} />