import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import {BrowserRouter as Router} from "react-router-dom"
import {Workspace} from "./Workspace/Workspace"
import {isSafari} from 'react-device-detect'
import $t from "./i18n/i18n"

ReactDOM.render(
        <Router>
                {
                isSafari
                    ? <div id="notsupported">
                        <h1>{$t('index.not_supported_line1')}</h1>
                        <h3>{$t('index.not_supported_line2')}
                            <a href={"https://www.google.com/chrome/"} target={"_blank"} rel={"noreferrer"}>Chrome</a>,
                            <a href={"https://www.mozilla.org/en-US/firefox/new/"} target={"_blank"} rel={"noreferrer"}>Firefox</a>,
                            <a href={"https://www.microsoft.com/en-us/edge"} target={"_blank"} rel={"noreferrer"}>Edge</a>
                            {$t('index.not_supported_line3')}
                            <a href={"https://www.opera.com/en/download"} target={"_blank"} rel={"noreferrer"}>Opera</a>
                            {$t('index.not_supported_line4')}
                        </h3>
                    </div>
                    : <App />
            }
        </Router>
    ,document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({onUpdate: Workspace.handleSWUpdate})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
