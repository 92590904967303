import {CategoryValue, DataValue, IValueDistribution, ZeroColumnId} from "../Concepts/Basic"
import {CatFreq, NumFreq, RangeFreq} from "../Concepts/FrequencyOfValue"
import {AggregationByCategory} from "../Concepts/Aggregation"
import {alias, custom, list, optional, serializable} from "serializr"
import DataColumn from "./DataColumn"
import * as U from "../Utils"
import * as STT from "../StypeTools"

function serializer(sourcePropertyValue: any) {
    const obj = sourcePropertyValue as { value: number | string | null | [number, number], freq: number }
    if (obj) {
        if (typeof obj.value === 'object' && obj.value !== null) {
            return [obj.value[0], obj.value[1], obj.freq]
        }
        return [obj.value, obj.freq]
    } else {
        return {SKIP: true}
    }
}

// serialization decodators
function numfreq() {
    return custom(serializer, (jsonValue: any) => jsonValue.SKIP === true ? undefined : new NumFreq(jsonValue[0], jsonValue[1]))
}

function catfreq() {
    return custom(serializer, (jsonValue: any) => jsonValue.SKIP === true ? undefined : new CatFreq(jsonValue[0], jsonValue[1]))
}

function rangefreq() {
    return custom(serializer, (jsonValue: any) => jsonValue.SKIP === true ? undefined : new RangeFreq(jsonValue[0], jsonValue[1], jsonValue[2]))
}

function sort<T> (vf:{value:T, freq:number}[]) {
    return vf.sort((a, b) => {
        if (typeof a.value === "number" && typeof b.value === "number") {
            return a.value - b.value
        } else if (typeof a.value === "string" && typeof b.value === "string") {
            return a.value < b.value ? -1 : (a.value > b.value ? 1 : 0)
        } else if (a.value === null) {
            return -1
        } else if (b.value === null) {
            return 1
        } else {
            return 0
        }
    })
}

function resort (callback: (err: any, value?: CatFreq[]) => void, error: any, list: CatFreq[]) {
    if (error) {
        callback (error)
    } else {
        callback (null, sort(list))
    }
}

type ValueDistributionResult<T> = {distr:{value: T, freq: number}[], tooManyValues:false}|{tooManyValues:true}

export default class ValueDistribution implements IValueDistribution {
    public static readonly MAX_ENTRY_NUMBER = 1000
    protected _meanValFreq: NumFreq[] | undefined
    @serializable(alias('num', optional(list(numfreq())))) protected readonly numEntries: NumFreq[] | undefined
    @serializable(alias('range', optional(list(rangefreq())))) protected readonly rangeEntries: RangeFreq[] | undefined
    @serializable(alias('cat', optional(list(catfreq(), {afterDeserialize:resort})))) protected readonly catEntries: CatFreq[] | undefined

    static fromDataColumn(column: DataColumn) {
        if (STT.isContinuous(column.stype)) {
            const result = ValueDistribution.getValueDistribution<number>(column, NumFreq)
            if (result.tooManyValues) {
                return new ValueDistribution(undefined, ValueDistribution.getRangeDistribution(column), undefined)
            } else {
                return new ValueDistribution(result.distr)
            }
        } else {
            return new ValueDistribution(
                undefined,
                undefined,
                ValueDistribution.getCategoricalEntries(column)
            )
        }
    }

    static fromAggrResult(aggr: AggregationByCategory) {
        return new ValueDistribution(
            undefined,
            undefined,
            [...aggr.keys()].map(cat => ({
                value: cat,
                freq: U.get(U.defNotNull(aggr.get(cat)), ZeroColumnId)
            }))
        )
    }

    get meanValueFreq(): NumFreq[] {
        if (this.rangeEntries) {
            if (this._meanValFreq === undefined) {
                this._meanValFreq = this.rangeEntries.map(entry => new NumFreq((entry.b - entry.a) / 2 + entry.a, entry.freq))
            }
            return this._meanValFreq
        } else if (this.numEntries) {
            return this.numEntries
        } else {
            throw new Error("You can't call meanValuesFreq if distribution type is not numerical")
        }
    }

    get length() {
        return U.def(this.numEntries ?? this.rangeEntries ?? this.catEntries).length
    }

    constructor(numEntries?: NumFreq[], rangeEntries?: RangeFreq[], catEntries?: CatFreq[]) {
        [this.numEntries, this.rangeEntries, this.catEntries] = [numEntries, rangeEntries, catEntries];
        if ([!numEntries, !rangeEntries, !catEntries].filter(x => !x).length > 1) {
            throw Error("Only one entry set should be given")
        }
    }

    // protected members
    protected static getValueDistribution<T extends DataValue>(column: DataColumn, ctor: new (value: T, freq: number) => { value: T, freq: number }): ValueDistributionResult<T> {
        const vf: { value: T, freq: number }[] = []
        for (const value of column.nonNullValues) {
            let found = false
            const v = value // typeof value === "number" ? U.fround(value) : value ???
            for (const i of vf) {
                if (i.value === v) {
                    found = true
                    i.freq += 1
                    break
                }
            }
            if (!found) {
                vf.push(new ctor(v as T, 1))
                if (typeof v === "number" && vf.length > ValueDistribution.MAX_ENTRY_NUMBER) {
                    return {tooManyValues: true}
                }
            }
        }

        return {
            distr: sort(vf),
            tooManyValues: false
        }
    }

    protected static getRangeDistribution(column: DataColumn): RangeFreq[] {
        const min = U.min(column.numbers),
            max = U.max(column.numbers),
            rf: RangeFreq[] = []

        // build ranges
        let prevVal: number | null = null
        for (const val of U.splitRange(min, max, ValueDistribution.MAX_ENTRY_NUMBER)) {
            if (prevVal !== null) {
                rf.push(new RangeFreq(prevVal, val, 0))
            }
            prevVal = val
        }

        // calculate frequencies
        column.numbers.forEach(value => {
            const v = U.fround(value)
            for (const [i, r] of rf.entries()) {
                if (v >= U.fround(r.a) && (v < U.fround(r.b) || (i === rf.length - 1 && v === U.fround(r.b)))) {
                    r.freq += 1
                    break
                }
            }
        })

        return rf
    }

    protected static getCategoricalEntries(column: DataColumn):{value: CategoryValue, freq: number}[] {
        if (STT.isCategorical(column.stype)) {
            const result = ValueDistribution.getValueDistribution<CategoryValue>(column, CatFreq)
            if (!result.tooManyValues) {
                if (column.nullCount) {
                    result.distr.splice(0, 0, new CatFreq(null, column.nullCount))
                }
                return result.distr
            } else {
                throw Error("Too many entries for a categorical column is really not expected here")
            }
        } else {
            throw Error("Categorical column expected")
        }
    }
}