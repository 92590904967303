import React, {FunctionComponent} from 'react'
import {List, ListItemButton, ListItemIcon, ListItemText} from "@material-ui/core"
import {MenuAction} from "../Concepts/Basic"
import {Icon, IconType} from "../Icons"
import {TestMarker, TM} from "../@testing/TestMarker"
import DropZone from "./DropZone"
import $t from "../i18n/i18n"

interface Props {
    onAction: (action:MenuAction, ctrlKey:boolean)=>void,
    onDataDropped: (file:File|string)=>void
}

interface MenuItemProps {
    text: string
    icon: JSX.Element
    action?: MenuAction
    onAction?: (action:MenuAction, ctrlKey:boolean)=>void
    testMarker?: TestMarker
    disabled?:boolean
}

const MenuItem = (props:MenuItemProps) => {
    const c = [<ListItemIcon key='icon'>{props.icon}</ListItemIcon>, <ListItemText key='text' primary={props.text}/>],
        p:{
            onClick: ((evt: React.MouseEvent<HTMLDivElement>)=>void)|undefined,
            disabled?: boolean
    } = {
            onClick: props.disabled
                ? undefined
                : (evt: React.MouseEvent<HTMLDivElement>) => {
                    if (props.onAction && props.action) {
                        props.onAction (props.action, evt.ctrlKey)
                    }
                },
            disabled: props.disabled,
    }

    return <ListItemButton {...p} data-test={props.testMarker}>{c}</ListItemButton>
}

const icon = (type:IconType) => <Icon style={{width:"24px"}} type={type} />

export const ImportPanel:FunctionComponent<Props> = (props:Props) =>
    <DropZone onDataDropped={props.onDataDropped}>
        <div style={{padding:20, margin: "100px auto", maxWidth: 400}}>
            <h1>{$t('importpanel.title')}</h1>
            <List component="nav">
                <MenuItem key='open' text={$t('importpanel.menu.open')} icon={icon('folder-open')} onAction={props.onAction} action={MenuAction.OpenFileAuto} />
                <MenuItem key='drop' text={$t('importpanel.menu.drop')} icon={icon('hand')} disabled={true} />
                <MenuItem key='paste' text={$t('importpanel.menu.paste')} icon={icon ('clipboard')} onAction={props.onAction} action={MenuAction.PasteAuto} testMarker={TM.importPanelPaste}/>
                <MenuItem key='load' text={$t('importpanel.menu.presets')} icon={icon ('books')} onAction={props.onAction} action={MenuAction.LoadPreset} />
            </List>
        </div>
    </DropZone>