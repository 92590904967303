import * as U from "../Utils"
import {CustomTimeFormatter, DateAndTime, MsSinceEpoch} from "../Concepts/DateTime"

export function formatTime (value:MsSinceEpoch):string {
    return U.settings.timeFormatter.format(value)
}

export function formatDate (value:MsSinceEpoch):string {
    return U.settings.dateFormatter.format(value)
}

export function formatDateTime (value:MsSinceEpoch):string {
    return U.settings.dateTimeFormatter.format(value)
}

export function formatDateTimeByParts (value:MsSinceEpoch, useYear:boolean, useMonth:boolean, useDay:boolean, useHour:boolean, useMinute:boolean, useSecond:boolean, inUTC=false):string {
    return useYear || useMonth || useDay || useHour || useMinute || useSecond
        ? U.settings.buildDateTimeFormat(useYear, useMonth, useDay, useHour, useMinute, useSecond, inUTC ? "UTC" : undefined).format(value)
        : ''
}

export function breakTime (value:MsSinceEpoch):Intl.DateTimeFormatPart[] {
    return U.settings.timeFormatter.formatToParts(value)
}

export function breakDate (value:MsSinceEpoch):Intl.DateTimeFormatPart[] {
    return U.settings.dateFormatter.formatToParts(value)
}

export function breakDateTime (value:MsSinceEpoch):Intl.DateTimeFormatPart[] {
    return U.settings.dateTimeFormatter.formatToParts(value)
}

export function dateTimeFormatPartsToObject (parts:Intl.DateTimeFormatPart[]):DateAndTime {
    const dateTime = {year:'', month:'', day:'', hour:'', minute:'', second:''}
    parts.forEach(p => {
        switch (p.type) {
            case "year": dateTime.year = p.value;break;
            case "month": dateTime.month = p.value;break;
            case "day": dateTime.day = p.value;break;
            case "hour": dateTime.hour = p.value;break;
            case "minute": dateTime.minute = p.value;break;
            case "second": dateTime.second = p.value;break;
        }
    })
    return dateTime
}

export class TimeFormatter implements CustomTimeFormatter {

    format(value: MsSinceEpoch): string {
        return this.formatToParts(value).map(p => p.value).join("")
    }

    formatToParts(value: MsSinceEpoch): Intl.DateTimeFormatPart[] {
        const seconds = Math.abs(Math.floor (value / 1000)),
            minutes = Math.floor (seconds / 60),
            hours = Math.floor (seconds / 3600)
        return [
            {type: "hour", value: (value < 0 ? '-' : '') + (hours < 10 ? '0' : '') + hours},
            {type: "literal", value: ':'},
            {type: "minute", value: ('0' + (minutes % 60)).slice(-2)},
            {type: "literal", value: ':'},
            {type: "second", value: ('0' + (seconds % 60)).slice(-2)}
        ]
    }
}
