import React from 'react'
import css from './TableGrid.module.scss'
import * as U from '../Utils'
import {ColumnUIIndex, ITableColumn} from "../Concepts/Basic"
import $t from "../i18n/i18n";

const PinIcon = (props:any) => <svg {...props} viewBox={"0 0 12 12"}><path d="M 9.0000001,5.3785312 V 1.4463277 H 9.7499999 V 0.13559319 H 2.2500003 V 1.4463277 h 0.75 v 3.9322035 l -1.5,1.3107347 v 1.3107344 h 3.9000001 v 3.9322037 h 1.2 V 8.0000003 H 10.5 V 6.6892659 Z" /></svg>
const ChevronLeftIcon = (props:any) => <svg {...props} viewBox={"0 0 12 12"}><path d="M 9.5480085,10.385932 5.1620762,6 9.5480085,1.6044915 8.1977542,0.25423721 2.4519915,6 8.1977542,11.745763 Z" /></svg>
const ChevronRightIcon = (props:any) => <svg {...props} viewBox={"0 0 12 12"}><path d="M 2.4519915,10.385932 6.8379238,6 2.4519915,1.6044915 3.8022458,0.25423721 9.5480085,6 3.8022458,11.745763 Z" /></svg>

const WIDTH_DRAG_DEBOUNCE_TIME = 100,
      TOUCH_DRAG_MARGIN = 5,
      COLUMN_SWAP_DELAY = 100,
      TOUCH_RECOGNITION_DELAY = 300,
      MIN_COLUMN_WIDTH = 16;

interface Props {
    columns: ITableColumn[]
    hoveredColumn: number | null
    scrollColumns: number
    columnWidthChange: {column: number | null, width: number}
    onColumnHover: (columnIndex:ColumnUIIndex | null) => void
    onColumnSelect: (columnIndex:ColumnUIIndex, exclusive:boolean) => void
    onFitColumnWidth: (columnIndex:ColumnUIIndex) => void
    nonPinnedColumns: number
    showPager: boolean
    onWidthDragDone: () => void
    onWidthDragging: (columnIndex:ColumnUIIndex, width:number) => void
    onColumnScroll: (delta:number) => void
    onColumnMove: (columnIndex:ColumnUIIndex, delta:1 | -1) => void
    onColumnPin: (columnIndex:ColumnUIIndex) => void
    pinnedColumns: number
    listenKeyboard: boolean,
    userSettingVersion: number
}

interface State {
    hoveredHeadColumn: ColumnUIIndex | null
}

export default class TableGridHeader extends React.PureComponent<Props, State> {
    override readonly state:State = {hoveredHeadColumn: null}
    protected headerRef = React.createRef<HTMLDivElement>()
    protected widthDragStartX = 0
    protected widthDragColumn: ColumnUIIndex | null = null
    protected widthDragLastTime = 0
    protected touchStartTime = 0
    protected touchEndTime: number | null = null
    protected columnActionTime = 0

    protected getDataColumn = (target: HTMLElement): ColumnUIIndex | null => {
        const dataColumn = target.getAttribute('data-colindex')
        return dataColumn !== null
            ? ColumnUIIndex(U.parseIntNotNan(dataColumn))
            : target.parentElement
                ? this.getDataColumn(target.parentElement)
                : null
    }

    protected handleColumnDividerMouseDown = (event:React.MouseEvent | React.TouchEvent) => {
        this.widthDragColumn = this.getDataColumn (event.target as HTMLElement)
        if (this.widthDragColumn !== null) {
            this.widthDragStartX = event.nativeEvent instanceof TouchEvent ? (event as React.TouchEvent).touches[0].clientX : (event as React.MouseEvent).clientX
            this.widthDragLastTime = Date.now()
            this.props.onWidthDragging(this.widthDragColumn, this.props.columns[this.widthDragColumn].width)
        }
    }

    protected handleColumnDividerDoubleClick = (event:React.MouseEvent) => {
        const col = this.getDataColumn(event.target as HTMLElement)
        if (col || col === 0) {
            this.widthDragColumn = null
            this.props.onWidthDragDone()
            this.props.onFitColumnWidth(col)
        }
    }

    protected handleCellTouchStart = (event:React.TouchEvent) => {
        this.touchStartTime = Date.now()
        const rect = (event.target as HTMLDivElement).getBoundingClientRect(),
            x = event.touches[0].clientX,
            col = this.getDataColumn(event.target as HTMLElement)
        if (col || col === 0) {
            this.widthDragColumn = rect.right >= x && rect.right - x <= TOUCH_DRAG_MARGIN
                                   ? col
                                   : rect.left <= x && x - rect.left <= TOUCH_DRAG_MARGIN && col > 0
                                        ? ColumnUIIndex(col - 1)
                                        : null
            if (this.widthDragColumn !== null) {
                this.widthDragStartX = x
                this.widthDragLastTime = Date.now()
                this.props.onWidthDragging(this.widthDragColumn, this.props.columns[this.widthDragColumn].width)
            }
            else {
                this.setState({hoveredHeadColumn: col === this.state.hoveredHeadColumn ? null : col})
            }
        }
    }

    protected handleCellClick = (event:React.MouseEvent) => {
        if (Date.now() - this.touchStartTime > TOUCH_RECOGNITION_DELAY) {
            const col = this.getDataColumn(event.target as HTMLElement)
            if (col || col === 0) {
                this.props.onColumnSelect(col, !event.ctrlKey && !event.shiftKey)
            }
        }
    }

    protected handleDocumentMouseOut = (event:MouseEvent) => {
        // clear column hovering if the mouse is out the window
        if (event.clientX<0 || event.clientY<0 || event.clientX > document.body.clientWidth || event.clientY > document.body.clientHeight) {
            this.setState({hoveredHeadColumn: null})
            this.props.onColumnHover(null);
        }
    }

    protected handleDocumentMouseMove = (event:MouseEvent | TouchEvent) => {
        if (event instanceof MouseEvent && event.clientX) {
            if (U.mustNotBeNull(this.headerRef.current)) {
                const head = this.headerRef.current.getBoundingClientRect()
                let hoveredColumn:ColumnUIIndex|null = null
                if (event.clientX > head.left && event.clientX < head.right && event.clientY > head.top) {
                    const element = document.elementFromPoint(event.clientX, head.top);
                    if (element !== null) {
                        const cell = element.closest('.' + css.headerCell);
                        const dataColum = cell !== null ? cell.getAttribute('data-colindex') : null;
                        hoveredColumn = dataColum !== null ? ColumnUIIndex(U.parseIntNotNan(dataColum)) : null;
                    }
                }
                const isTouch = Date.now() - this.touchStartTime < TOUCH_RECOGNITION_DELAY
                if (!isTouch || hoveredColumn === null) {
                    this.setState({hoveredHeadColumn: hoveredColumn});
                }
                this.props.onColumnHover(isTouch ? null : hoveredColumn);
            }
        }

        this.touchEndTime = null;
        if (this.widthDragColumn !== null) {
            if (event instanceof MouseEvent && event.buttons !== 1) {
                this.widthDragColumn = null;
                this.props.onWidthDragDone();
            }
            else if (event instanceof MouseEvent || (event instanceof TouchEvent && event.touches)) {
                const clientX = (event instanceof TouchEvent ? event.touches[0] : event).clientX;
                if (event instanceof MouseEvent) {
                    event.preventDefault();
                }
                event.stopPropagation();
                if (Date.now() - this.widthDragLastTime > WIDTH_DRAG_DEBOUNCE_TIME) {
                    this.widthDragLastTime = Date.now();
                    const width = Math.max(MIN_COLUMN_WIDTH, this.props.columns[this.widthDragColumn].width + clientX - this.widthDragStartX);
                    this.props.onWidthDragging(this.widthDragColumn,  width);
                }
            }
        }
    }

    protected handleTouchStart = () => {
        this.touchStartTime = Date.now();
    }

    protected handleTouchEnd = () => {
        if (this.widthDragColumn !== null) {
            this.widthDragColumn = null;
            this.props.onWidthDragDone();
        }
        this.props.onColumnHover(null);
        this.touchEndTime = Date.now();
    }

    protected handleKeyDown = (event:KeyboardEvent) => {
        if (this.props.listenKeyboard && !event.altKey && !event.ctrlKey && !event.shiftKey) {
            if (this.props.showPager && (event.key === 'ArrowLeft' || event.key === 'ArrowRight')) {
                event.preventDefault();
                event.stopPropagation();
                (event.key === 'ArrowLeft' ? this.handlePagerLeft : this.handlePagerRight)();
            }
        }
    }

    protected movePinColumn = (event:React.MouseEvent, direction: -1 | 0 | 1) => {
        if (event.target !== null) {
            event.preventDefault()
            event.stopPropagation()
            const cell = (event.target as HTMLElement).closest('.' + css.headerCell)
            if (U.mustNotBeNull(cell)) {
                const dataColumn = cell.getAttribute('data-colindex')
                if (U.mustNotBeNull(dataColumn)) {
                    if (direction) {
                        this.props.onColumnMove(ColumnUIIndex(U.parseIntNotNan(dataColumn)), direction)
                    } else {
                        this.props.onColumnPin(ColumnUIIndex(U.parseIntNotNan(dataColumn)))
                    }
                    this.columnActionTime = Date.now();
                }
            }
        }
    }

    protected handleColMoveLeft = (event: React.MouseEvent) => this.movePinColumn(event, -1)

    protected handleColMoveRight = (event:React.MouseEvent) => this.movePinColumn(event, 1)

    protected handleColPin = (event:React.MouseEvent) => this.movePinColumn(event, 0)

    protected handlePagerLeft = () => this.props.onColumnScroll(-1);

    protected handlePagerRight = () => this.props.onColumnScroll(1);

    override componentDidMount() {
        document.addEventListener('mousemove', this.handleDocumentMouseMove, true);
        document.addEventListener('mouseout', this.handleDocumentMouseOut, true);
        document.addEventListener("touchmove", this.handleDocumentMouseMove, false);
        document.addEventListener("touchstart", this.handleTouchStart, false);
        document.addEventListener("touchend", this.handleTouchEnd, false);
        document.addEventListener('keydown', this.handleKeyDown, true);
    }

    override componentWillUnmount() {
        document.removeEventListener('mousemove', this.handleDocumentMouseMove, true);
        document.removeEventListener('mouseout', this.handleDocumentMouseOut, true);
        document.removeEventListener('touchmove', this.handleDocumentMouseMove, true);
        document.removeEventListener("touchstart", this.handleTouchStart, false);
        document.removeEventListener("touchend", this.handleTouchEnd, false);
        document.removeEventListener('keydown', this.handleKeyDown, true);
    }

    override render () {
        const headerCells:JSX.Element[] = []
        let colsToScroll = this.props.scrollColumns, nonPinned=0
        for (const [i, col] of this.props.columns.entries()) {
            const isColumnHovered = i === this.props.hoveredColumn && this.props.columnWidthChange.column === null
            const isHeadColHovered = i === this.state.hoveredHeadColumn && this.props.columnWidthChange.column === null
            if ((col.pinned || (colsToScroll-=1)<0) && nonPinned <= this.props.nonPinnedColumns) {
                nonPinned += col.pinned ? 0 : 1
                headerCells.push(
                    <div key={col.id}
                         className={U.cls(
                             css.headerCell, true,
                             css.selectedColumn, col.selected && !isColumnHovered,
                             css.hoveredSelectedColumn, col.selected && isColumnHovered,
                             css.hoveredColumn, isColumnHovered,
                             css.draggedHeaderCell, this.widthDragColumn !== null
                         )}
                         style={{
                             width: this.props.columnWidthChange.column === i ? this.props.columnWidthChange.width : col.width
                         }}
                         data-colindex={i}
                         onTouchStart={this.handleCellTouchStart}
                         onClick={this.handleCellClick}
                    >
                        <span title={col.title}>
                            {col.title}
                        </span>
                        <div className={css.headerCellControl}
                             style={{opacity: isHeadColHovered && (Date.now() - this.columnActionTime > COLUMN_SWAP_DELAY) ? '1' : '0'}}
                        >
                            <ChevronLeftIcon
                                title={$t('tablegrid.header.move.left')}
                                className={U.cls (
                                    css.controlItem, true,
                                    css.controlItemDisabled, i === 0 || (!col.pinned && this.props.columns[i-1].pinned)
                                )}
                                onClick={this.handleColMoveLeft}/>

                            <PinIcon
                                title={col.pinned ? $t('tablegrid.header.unpin') : $t('tablegrid.header.pin')}
                                className={U.cls(css.controlItem, true, css.controlItemOn, col.pinned)}
                                onClick={this.handleColPin}/>

                            <ChevronRightIcon
                                title={$t('tablegrid.header.move.right')}
                                className={U.cls(
                                    css.controlItem, true,
                                    css.controlItemDisabled, i === this.props.columns.length - 1
                                      || (col.pinned && !this.props.columns[i+1].pinned)
                                )}
                                onClick={this.handleColMoveRight}/>
                        </div>
                    </div>
                )

                const fullHeightColumnDivider = this.props.columnWidthChange.column === i
                    || (col.pinned && i < this.props.columns.length - 1 && !this.props.columns[i + 1].pinned);

                headerCells.push(<div key={col.id + '_'}
                                      className={U.cls(
                                          css.columnDivider, true,
                                          css.draggedColumnDivider, this.widthDragColumn === i,
                                          css.fullHeightColDivider, fullHeightColumnDivider
                                      )}
                                      data-colindex={i}
                                      onMouseDown={this.handleColumnDividerMouseDown}
                                      onTouchStart={this.handleColumnDividerMouseDown}
                                      onDoubleClick={this.handleColumnDividerDoubleClick}
                >&nbsp;</div>)
            }
        }

        return <div ref={this.headerRef} className={css.header + ' ' + css.nonSelectable}>
            {headerCells}
            <div className={css.pager} style={{opacity: this.props.showPager ? '1' : '0'}}>
                <ChevronLeftIcon
                    title={$t('tablegrid.header.scroll.left')}
                    className={U.cls(css.pagerItem, true, css.pagerItemDisabled, !this.props.scrollColumns)}
                    onClick={this.handlePagerLeft}/>
                <ChevronRightIcon
                    title={$t('tablegrid.header.scroll.right')}
                    className={U.cls(
                        css.pagerItem, true,
                        css.pagerItemDisabled, this.props.pinnedColumns + this.props.scrollColumns
                        + this.props.nonPinnedColumns >= this.props.columns.length
                    )}
                    onClick={this.handlePagerRight}/>
            </div>
        </div>
    }
}