import * as React from 'react'
import * as U from "../../Utils"
import * as RC from './RendererCommon'
import * as Bricks from './Bricks'
import {LabelDataType} from './Bricks'
import * as STT from "../../StypeTools"
import {SemanticType} from "../../Concepts/SemanticType"
import {TooltipRow} from '../VisCommon'
import {CategoryValue} from "../../Concepts/Basic"
import {PieChartData} from "../../Concepts/Visualizers/Renderers/PieChart"
import {ITooltipRow, RenderResult, TooltipsById} from "../../Concepts/Visualizer"

export function render(
    data: PieChartData,
    width: number,
    units: string,
    categoryPrefix: string,
    tooltips: TooltipsById): RenderResult {

    const
        maxChartDiameter = 600,

        legendMargin = width / 30,

        bp = new Bricks.Params(tooltips,'pc'),

        categoryTooltips: Map<CategoryValue, ITooltipRow[]> = new Map(data.map(d => [d.category.value, [new TooltipRow(
            `${parseFloat((d.percent * 100).toPrecision(3))}% (${STT.formatTooltipValue(SemanticType.Number, d.value)}${units ? ' ' + units : ''})`,
            categoryPrefix + U.deNull(d.category.value)
        )]])),

        legend = new Bricks.VerticalLegend (bp.append('l'), {
            type: LabelDataType.Categories,
            stype: SemanticType.Category,
            values: data.map(d => d.category.value)
        }, undefined, undefined, data.map(d => d.category.color), categoryTooltips),

        legendElements = legend.render(0,0),

        pieChart = new Bricks.PieChart (bp.append('p'), data, categoryTooltips),

        diameter = Math.min (maxChartDiameter, (width - legend.width - legendMargin)),

        pieChartElements = pieChart.render(legend.x + legend.width + legendMargin, 0, diameter),

        height = Math.max(legend.height, pieChart.height)

    return RC.getRenderResult(width, height, [
        <g key={'pir-conteiner'} transform={`translate(${(width - legendMargin - pieChart.width - legend.width) / 2},0)`}>
            {[...legendElements, ...pieChartElements]}
        </g>
    ])
}
