import {ColumnId} from "./Basic"
import {IWorkspaceStore} from "./DataBase"

// numerical representation of visualizer type
export type VisTypeId = number

// What kinds the visualizer view settings can be
export type VisSettingId = number

export const DEFAULT_SETTING_ID: VisSettingId = 1 // todo: meaning?

// Data type of all visualizer view settings
export type VisSettingValue = string

// Visualizer view settings in context of some column set
export type VisSettingsForColumnSet = Map<VisSettingId, VisSettingValue>

// User congirable settings defining how a chart looks like in the context of (visualizer type + column set)
export interface IVisSettings {
    putToStore(wsStore:IWorkspaceStore):Promise<void>
    availableFor (visTypeId: VisTypeId, columnIds: ColumnId[]): boolean
    get (visTypeId: VisTypeId, columnIds: ColumnId[], settingId: VisSettingId, defVal: VisSettingValue): VisSettingValue
    set (visTypeId: VisTypeId, columnIds: ColumnId[], settingId: VisSettingId, value: VisSettingValue): void
}

// Visualizer settings for specific visualizer and column set
export interface ISpecifiedVisSettings {
    get (setting: VisSettingId, defVal: VisSettingValue): VisSettingValue
    set (setting: VisSettingId, value: VisSettingValue): void

    // true if these settings are not stored in the db but suggested
    isSuggested: boolean
}