// CSV specs: https://tools.ietf.org/html/rfc4180#page-2
// Para Parse: https://www.papaparse.com/docs#config

import React from 'react'
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Slide,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Toolbar,
    Typography
} from "@material-ui/core"
import {TransitionProps} from '@material-ui/core/transitions'
import * as U from '../../Utils'
import {
    actualColumnType,
    actualHeaderRows,
    ColumnParams,
    defaultColumnTitle,
    Encoding,
    encodings,
    ImportedColumnType,
    ImportParams,
    TypeDetector,
    UniversalEOL
} from "../../DataParser/DataParsing"
import {AutocompleteChangeReason} from '@material-ui/core/Autocomplete'
import {CharacterSelector, Option} from "./CharacterSelector"
import DataParser from "../../DataParser"
import {Workspace} from "../Workspace"
import event from "../../EventSender/Events"
import * as STT from "../../StypeTools"
import {userSettings} from "../../LocalSettings"
import {timeZones} from "./Timezones"
import {locales} from "./Locales"
import {Icon, innerTypeIcon} from "../../Icons"
import {colors} from "../../Colors"
import {SemanticType} from "../../Concepts/SemanticType"
import $t from "../../i18n/i18n";

enum Mode {
    Normal=0,
    LoadingNewData=1
}

interface Props {
    open: boolean
    dataParser: DataParser
    onCancel: ()=>void
    onImport: (importParams:ImportParams, redetectColumnTypes:boolean)=>void
}

interface State extends ImportParams {
    open: boolean
    page: number
    titles: string[]
    rows: string[][]
    mode: Mode
}

const controlWidth = 180,
    controlMargin = 10,
    headerHeight = 50,
    rowHeight = 40,
    skipColor = colors.grey.c600,
    rowsPerPage = 4,
    maxColumnCount = 50

export class ImportDialog extends React.PureComponent<Props, State> {
    protected rowCount:number|undefined
    protected pageCount = 0
    protected encodings = encodings.map(e => e)

    protected readonly lineSeparators:Option[] = [
        {value:"\r", name:"CR"},
        {value:"\n", name:"LF"},
        {value:UniversalEOL, name:"CR, LF, CRLF"},
        {value:"\t", name:$t('importdialog.tab')},
        {value:" ", name:$t('importdialog.space')}
    ]

    protected readonly valueSeparators:Option[] = [
        {value:",", name:$t('importdialog.comma')},
        {value:";", name:$t('importdialog.semicolon')},
        {value:":", name:$t('importdialog.colon')},
        {value:"|", name:$t('importdialog.vertbar')},
        {value:"\t", name:$t('importdialog.tab')},
        {value:" ", name:$t('importdialog.space')}
    ]

    protected readonly textQualifiers:Option[] = [
        {value:"", name:$t('importdialog.none')},
        {value:"\"", name:$t('importdialog.dblquot')},
        {value:"'", name:$t('importdialog.singquot')}
    ]

    protected readonly escapeCharacters:Option[] = [
        {value:"", name:$t('importdialog.none')},
        {value:"\"", name:$t('importdialog.dblquot')},
        {value:"'", name:$t('importdialog.singquot')},
        {value:"\\", name:$t('importdialog.backslash')}
    ]

    protected static closedState:State = {
        open: false,
        lineSeparator: UniversalEOL,
        valueSeparator: ',',
        textQualifier: '"',
        escapeChar: '"',
        encoding: "utf-8",
        confident: false,
        guessedHeaderRows: 0,
        manualHeaderRows: undefined,
        bomByteCount: 0,
        columnTypes: [],
        columnAffixes: new Map (),
        titles: [],
        rows: [],
        page: 0,
        mode: Mode.Normal
    }

    override readonly state:State = ImportDialog.closedState

    protected readonly transition = React.forwardRef(function Transition(
        props: TransitionProps & { children?: React.ReactElement<any, any> },
        ref: React.Ref<unknown>,
    ) {
        return <Slide direction="down" ref={ref} {...props} />;
    })

    static getDerivedStateFromProps(props:Props, state:State):State | null {
        if (props.open && !state.open) {
            const newState = Object.assign ({}, ImportDialog.closedState)
            newState.mode = Mode.LoadingNewData
            event.import.dialog.open()
            return newState
        } else if (!props.open && state.open) {
            event.ui.dialog.close()
            return ImportDialog.closedState
        }
        return null
    }

    async onImportParamsChanged (importParams: ImportParams):Promise<Pick<State, "titles" | "page" | "rows" | "columnTypes" | "guessedHeaderRows">> {
        this.rowCount = await this.props.dataParser.getRowCount(importParams)
        this.pageCount = Math.ceil(this.rowCount / rowsPerPage)
        const page = Math.max(0, Math.min(this.pageCount - 1, this.state.page)),
            columnParams = (await this.props.dataParser.getColumnParams(importParams, userSettings.getString("importLocale"), userSettings.getString("importTimezone"),  importParams.manualHeaderRows) as ColumnParams),
            newImportParams = U.shallowCopy(importParams)

        // copy current manual column type values into the new parameters
        if (importParams.columnTypes.length === columnParams.columnTypes.length) {
            for (let i=0; i<importParams.columnTypes.length; i++) {
                const manual = importParams.columnTypes[i].manual
                if (manual && columnParams.columnTypes[i].possible.indexOf(manual)>=0) {
                    columnParams.columnTypes[i].manual = manual
                }
            }
        }

        newImportParams.columnTypes = columnParams.columnTypes
        newImportParams.guessedHeaderRows = columnParams.guessedHeaderRows

        const titles = await this.props.dataParser.getHeader(newImportParams),
            rows = await this.getPageRows(newImportParams, titles, page)

        return Promise.resolve({titles, page, rows, ...columnParams})
    }

    async getPageRows (importParams:ImportParams, header:string[], page:number):Promise<string[][]> {
        const rows = await this.props.dataParser.getRows(importParams, page * rowsPerPage, rowsPerPage, 100),
            columnCount = Math.max(header.length, ...rows.map(row => row.length))

        rows.forEach(row => {
            // replace empty values with nonb-breakable space
            for (let i = 0; i < row.length; i++) {
                if (row[i] === '') {
                    row[i] = U.nbsp
                }
            }

            // add stub values
            while (row.length < columnCount) {
                row.push(U.nbsp)
            }
        })

        // add stub lines
        while (rows.length < rowsPerPage) {
            rows.push(U.repeat(columnCount, U.nbsp))
        }

        return Promise.resolve(rows)
    }

    override async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (this.state.mode === Mode.LoadingNewData) {
            Workspace.backdrop($t('importdialog.backdrop.preparing'))
            const importParams = await this.props.dataParser.getImportParams() as ImportParams,
                useGuessedColumnTypes = importParams.columnTypes.length > 0,
                params = await this.onImportParamsChanged(importParams)
            Workspace.backdrop(null)
            this.setState({
                open: true,
                mode: Mode.Normal,
                ...(!useGuessedColumnTypes ? params : U.omit(params, ["columnTypes", "guessedHeaderRows"])),
                ...(useGuessedColumnTypes ? importParams : U.omit(importParams, ["columnTypes", "guessedHeaderRows"]))
            })
        }
    }

    protected handleCancel = () => {
        this.props.onCancel()
    }

    protected handleImport = () => this.props.onImport(this.state,true)

    protected goToPage = (page:number) => {
        event.import.dialog.page (page)
        this.getPageRows(this.state, this.state.titles, page).then(rows => this.setState({page, rows}))
    }

    protected setImportParam = (param:keyof ImportParams, value:string, setter:(params:ImportParams)=>void) => {
        event.import.dialog.setting(param, value)
        const importParams = U.shallowCopy (this.state)
        setter (importParams)
        Workspace.backdrop($t('importdialog.backdrop.updating'))
        this.onImportParamsChanged(importParams).then (params=>{
            this.setState(params)
            Workspace.backdrop(null)
        })
        this.setState(importParams)
    }

    protected handleFirstPage = () => this.goToPage(0)

    protected handlePrevPage = () => this.goToPage(Math.max (0, this.state.page - 1))

    protected handleNextPage = () => this.goToPage(Math.max (0, Math.min (this.pageCount - 1, (this.state.page ?? 0) + 1)))

    protected handleLastPage = () => this.goToPage(Math.max (0, this.pageCount - 1))

    protected handleMouseWheel = (evt:React.WheelEvent<HTMLDivElement>) => (evt.deltaY > 0 ? this.handleNextPage : this.handlePrevPage)()

    protected handleLineSeparatorChange = (value:string) => this.setImportParam ("lineSeparator", value, p => p.lineSeparator = value)

    protected handleValueSeparatorChange = (value:string) => this.setImportParam ("valueSeparator", value,p => p.valueSeparator = value)

    protected handleTextQualifierChange = (value:string) => this.setImportParam ("textQualifier", value,p => p.textQualifier = value)

    protected handleEscapeCharacterChange = (value:string) => this.setImportParam ("escapeChar", value,p => p.escapeChar = value)

    protected handleEncodingChange = (event:React.ChangeEvent<unknown>, value:string|null, reason: AutocompleteChangeReason) => {
        if (reason === "selectOption" && value !== null) {
            this.setImportParam ("encoding", U.deNull(value), p => p.encoding = value as Encoding)
        }
    }

    protected handleTimezoneChange = (evt:React.ChangeEvent<unknown>, value:string|null) => {
        if (value) {
            event.import.dialog.setting("timezone", value)
            userSettings.set("importTimezone", value)
            this.forceUpdate()
        }
    }

    protected handleLocaleChange = (evt:React.ChangeEvent<unknown>, value:string|null) => {
        if (value) {
            event.import.dialog.setting("locale", value)
            userSettings.set("importLocale", value)
            this.forceUpdate()
        }
    }

    protected handleHeaderRowsChanged = (evt:SelectChangeEvent<number>) => {
        const value = U.parseIntNotNan(evt.target.value as string)
        this.setImportParam ("manualHeaderRows", value.toString(), p => p.manualHeaderRows = value)
    }

    protected handleColumnTypeChanged = (evt:SelectChangeEvent) => {
        const columnIndex = U.parseIntNotNan(evt.target.name ?? ''),
            selectedType = SemanticType[evt.target.value as keyof typeof SemanticType],
            newColumnTypes = this.state.columnTypes.map (t => U.shallowCopy(t))
        if (selectedType) {
            newColumnTypes[columnIndex].manual = selectedType
            event.import.dialog.column.type.change(
                columnIndex,
                actualColumnType(this.state.columnTypes[columnIndex]),
                selectedType)
            this.setState({columnTypes: newColumnTypes})
        } else {
            throw Error (`Column type change handler got an unexpected inner type value = ${evt.target.value}`)
        }
    }

    override render () {
        const locale = userSettings.getString("importLocale"),
            timezone = userSettings.getString("importTimezone"),
            pagerIconStyle = {width:20, height:20}
        return (
            <React.Fragment>
                <Dialog open={this.state.open}
                        onClose={this.handleCancel}
                        aria-labelledby="import-dialog-title"
                        TransitionComponent={this.transition}
                        fullWidth={true}
                        maxWidth={'md'}
                >
                    <DialogTitle id="import-dialog-title">Import data</DialogTitle>
                    <DialogContent>
                        <Box mb={3} display="flex" flexDirection="row" flexWrap="wrap" justifyContent="space-evenly">
                            <Box style={{width:controlWidth, marginRight:controlMargin}}>
                                <CharacterSelector label={$t('importdialog.ctrl.row_separator')}
                                                   options = {this.lineSeparators}
                                                   value={this.state.lineSeparator}
                                                   onChange={this.handleLineSeparatorChange} />
                            </Box>
                            <Box style={{width:controlWidth, marginRight:controlMargin}}>
                                <CharacterSelector label={$t('importdialog.ctrl.column_separator')}
                                                   options = {this.valueSeparators}
                                                   value={this.state.valueSeparator}
                                                   onChange={this.handleValueSeparatorChange} />
                            </Box>
                            <Box style={{width:controlWidth, marginRight:controlMargin}}>
                                <CharacterSelector label={$t('importdialog.ctrl.text_qualifier')}
                                                   options = {this.textQualifiers}
                                                   value={this.state.textQualifier}
                                                   onChange={this.handleTextQualifierChange} />
                            </Box>
                            <Box style={{width:controlWidth, marginRight:controlMargin}}>
                                <CharacterSelector label={$t('importdialog.ctrl.escape_character')}
                                                   options = {this.escapeCharacters}
                                                   value={this.state.escapeChar}
                                                   onChange={this.handleEscapeCharacterChange} />
                            </Box>
                            <Autocomplete
                                style={{width:controlWidth, marginRight:controlMargin}}
                                disableClearable
                                blurOnSelect
                                options = {this.encodings}
                                value={this.state.encoding}
                                onChange = {this.handleEncodingChange}
                                renderInput={(params) => <TextField {...params} label={$t('importdialog.ctrl.encoding')} margin="normal" variant="standard" />}
                            />
                            <Autocomplete
                                style={{width:controlWidth, marginRight:controlMargin}}
                                disableClearable
                                blurOnSelect
                                options={Object.keys(locales)}
                                value={locale}
                                onChange={this.handleLocaleChange}
                                getOptionLabel={(option) => locales[option]}
                                renderInput={(params) => <TextField {...params} label={$t('importdialog.ctrl.locale')} margin="normal" variant="standard" />}
                            />
                            <Autocomplete
                                style={{width:controlWidth, marginRight:controlMargin}}
                                disableClearable
                                blurOnSelect
                                options={timeZones}
                                value={timezone}
                                onChange={this.handleTimezoneChange}
                                renderInput={(params) => <TextField {...params} label={$t('importdialog.ctrl.time_zone')} margin="normal" variant="standard" />}
                            />
                            <FormControl
                                style={{width:controlWidth, marginRight:controlMargin}}
                                margin="normal"
                                variant="standard">
                                <InputLabel id="consdel-label">{$t('importdialog.ctrl.header_lines')}</InputLabel>
                                <Select<number>
                                    labelId="consdel-label"
                                    id="consdel-select"
                                    value={actualHeaderRows(this.state)}
                                    onChange={this.handleHeaderRowsChanged}
                                    variant="standard">
                                    <MenuItem value={0}>{$t("importdialog.none")}</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        {this.rowCount === 0
                            ? <Box textAlign='center' mt={6} mb={6}><h1>{$t('importdialog.nothing_to_import')}</h1></Box>
                            : <TableContainer
                                onWheel={this.handleMouseWheel}
                                component={Paper}>
                                <Table size="small">
                                    <PreviewTableHeader
                                        columnTypes={this.state.columnTypes}
                                        columnTitles={this.state.titles}
                                        onColumnTypeChanged={this.handleColumnTypeChanged}
                                    />
                                    <PreviewTableBody values={this.state.rows} columnTypes={this.state.columnTypes} locale={locale} timezone={timezone} />
                                </Table>
                            </TableContainer>
                        }
                        <Toolbar>
                            <Typography variant="body2" style={{flexShrink: 0, marginRight: "1em"}}>{$t('importdialog.rows_of', {start: this.state.page * rowsPerPage + 1, end: (this.state.page + 1) * rowsPerPage, total: this.rowCount ?? 0})}</Typography>
                            <IconButton
                                aria-label="first-page"
                                onClick={this.handleFirstPage}
                                disabled={(this.state.page ?? 0) === 0}
                                size="large">
                                <Icon type="arrow-left-to-line" style={pagerIconStyle} />
                            </IconButton>
                            <IconButton
                                aria-label="prev-page"
                                onClick={this.handlePrevPage}
                                disabled={(this.state.page ?? 0) === 0}
                                size="large">
                                <Icon type="arrow-left" style={pagerIconStyle} />
                            </IconButton>
                            <IconButton
                                aria-label="next-page"
                                onClick={this.handleNextPage}
                                disabled={(this.state.page ?? 0) >= this.pageCount - 1}
                                size="large">
                                <Icon type="arrow-right" style={pagerIconStyle} />
                            </IconButton>
                            <IconButton
                                aria-label="last-page"
                                onClick={this.handleLastPage}
                                disabled={(this.state.page ?? 0) >= this.pageCount - 1}
                                size="large">
                                <Icon type="arrow-right-to-line" style={pagerIconStyle} />
                            </IconButton>
                        </Toolbar>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleImport} color="primary" disabled={this.rowCount === 0}>
                            {$t('dialog.button.import')}
                        </Button>
                        <Button onClick={this.handleCancel}>
                            {$t('dialog.button.cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

interface PreviewTableHeaderProps {
    columnTypes: ImportedColumnType[],
    columnTitles: string[],
    onColumnTypeChanged: (evt: SelectChangeEvent)=>void
}

class PreviewTableHeader extends React.PureComponent<PreviewTableHeaderProps> {

    protected skipToTop = (types: SemanticType[]): SemanticType[] => [
        types.indexOf(SemanticType.Skip) < 0 ? undefined : SemanticType.Skip,
        ...types.filter(t => t !== SemanticType.Skip)
    ].filter(t => t) as SemanticType[]

    protected handleTypeSelectorOpened = () => {
        event.import.dialog.column.type.open()
    }

    protected handleTypeSelectorClosed = () => {
        event.import.dialog.column.type.close()
    }

    override render() {
        const columnTypes = this.props.columnTypes.map(t => actualColumnType(t))
        return (
            <TableHead>
                <TableRow style={{backgroundColor: colors.grey.c100}}>
                    {
                        this.props.columnTypes.map((typeInfo, i) => {
                                const title = this.props.columnTitles[i] ?? defaultColumnTitle(i, columnTypes)
                                return i <= maxColumnCount
                                    ? <TableCell key={i} align="left"
                                                 style={{height: headerHeight, verticalAlign: "top"}}>
                                        <FormControl style={{marginBottom: 8, width: "100%"}} variant="standard">
                                            <Select<string>
                                                style={{
                                                    color: actualColumnType(typeInfo) === SemanticType.Skip ? skipColor : colors.pink.c500,
                                                    fontWeight: actualColumnType(typeInfo) === typeInfo.recommended ? 500 : 400,
                                                    width: "100%"
                                                }}
                                                value={actualColumnType(typeInfo)}
                                                name={i.toString()}
                                                onChange={this.props.onColumnTypeChanged}
                                                onOpen={this.handleTypeSelectorOpened}
                                                onClose={this.handleTypeSelectorClosed}
                                                variant="standard">
                                                {
                                                    this.skipToTop(typeInfo.possible).map(type =>
                                                        <MenuItem key={type} value={type} style={
                                                            {
                                                                fontWeight: type === typeInfo.recommended ? 500 : 400,
                                                                color: type === SemanticType.Skip ? skipColor : "inherit"
                                                            }
                                                        }>
                                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                                {[
                                                                    innerTypeIcon (type, {width:16, height:16, color:colors.pink.c600}),
                                                                    <div key={type}
                                                                         style={{marginLeft: 10}}>{STT.uiName(type).toUpperCase()}</div>
                                                                ]}
                                                            </div>
                                                        </MenuItem>
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                        <div key={title}
                                             style={{
                                                 maxHeight: headerHeight,
                                                 overflow: "hidden",
                                                 color: i < maxColumnCount ? (actualColumnType(typeInfo) === SemanticType.Skip ? skipColor : "inherit") : "red",
                                                 minWidth: i < maxColumnCount ? "inherit" : 200
                                             }}>
                                            {
                                                i < maxColumnCount
                                                    ? title
                                                    : $t('importdialog.columns_more', {count: this.props.columnTitles.length - maxColumnCount})
                                            }
                                        </div>
                                    </TableCell>
                                    : undefined;
                            }
                        )
                    }
                </TableRow>
            </TableHead>
        );
    }
}

interface PreviewTableBodyProps {
    values: string[][]
    columnTypes: ImportedColumnType[]
    locale: string,
    timezone: string
}

class PreviewTableBody extends React.PureComponent<PreviewTableBodyProps> {
    override render() {
        const columnTypes = this.props.columnTypes.map (ct => actualColumnType(ct))
        return <TableBody>
            {
                this.props.values.map((line, n) => (
                    <TableRow key={n} style={{backgroundColor: colors.grey.c100}}>
                        {
                            line.map((value, i) =>
                                i <= maxColumnCount
                                    ? <TableCell key={i} align="left" style={{height: rowHeight}}>
                                        <div style={{
                                            maxHeight: rowHeight,
                                            overflow: "hidden",
                                            color: i < maxColumnCount ? (STT.isSkip(columnTypes[i]) ? skipColor : "inherit") : "red",
                                            minWidth: i < maxColumnCount ? "inherit" : 200
                                        }} title={i < maxColumnCount ? $t('importdialog.original', {value: value}) : undefined}>
                                            {
                                                i < maxColumnCount
                                                    ? (value === U.nbsp || STT.isSkip(columnTypes[i]) ? U.nbsp : STT.formatTableCellValue(columnTypes[i], TypeDetector.parseValue(value, columnTypes[i], [this.props.locale], this.props.timezone).value))
                                                    : $t('importdialog.columns_more', {count: line.length - maxColumnCount})
                                            }
                                        </div>
                                    </TableCell>
                                    : null
                            )
                        }
                    </TableRow>))
            }
        </TableBody>
    }
}

