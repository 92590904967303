import * as React from 'react'
import css from "./Workspace.module.scss"
import DataTable from "../Data/DataTable"
import {BackdropResponse, ColumnId, ColumnUIIndex, MenuAction} from "../Concepts/Basic"
import SplitterLayout from "react-splitter-layout"
import './Splitter.scss' // https://www.npmjs.com/package/react-splitter-layout
import {ColumnColoring, ColumnColoringMode, TableGrid} from "../TableGrid/TableGrid"
import * as U from "../Utils"
import * as MTU from "../MainThreadUtils"
import AnalyticsPane from "../Visualizers/AnalyticsPane"
import {DEFAULT_SETTING_ID, IVisSettings} from "../Concepts/VisSettings"
import {VisSettings} from "../Visualizers/VisSettings"
import NumFreqHistSharedX from "../Visualizers/NumFreqHistSharedX"
import FreqDistrGrid from "../Visualizers/FreqDistrGrid"
import PieBarChart from "../Visualizers/PieBarChart"
import ScatterPlot from "../Visualizers/ScatterPlot"
import ScatterPlotMatrix from "../Visualizers/ScatterPlotMatrix"
import LineChart from "../Visualizers/LineChart"
import TimeIntervals from "../Visualizers/TimeIntervals"
import ColumnProfile from "../Visualizers/ColumnProfile"
import SideMenu from "../Menu/SideMenu"
import {LocalSettings, userSettings} from "../LocalSettings"
import {SaveDialog} from "./Dialogs/SaveDialog"
import {MessageBox} from "./Dialogs/MessageBox"
import {SettingsDialog} from "./Dialogs/SettingsDialog"
import {PresetsDialog} from "./Dialogs/PresetsDialog"
import {RouteComponentProps} from 'react-router-dom'
import {WorkspaceLocation} from "./WorkspaceLocation"
import {ImportDialog} from "./Dialogs/ImportDialog"
import {Button, IconButton, Slide, Snackbar} from "@material-ui/core"
import {serialize} from "serializr"
import {WorkspaceStore} from "../Database"
import {dataParser} from "../WebWorkers"
import * as Comlink from "comlink"
import {ImportParams} from "../DataParser/DataParsing"
import ProgressRing from "../Components/ProgressRing"
import event from "../EventSender/Events"
import {ImportPanel} from "../TableGrid/ImportPanel"
import {TransitionProps} from "@material-ui/core/transitions"
import {Icon} from "../Icons"
import {colors} from "../Colors"
import {WorkspaceBackdrop} from "./Backdrop"
import {IVisualizer, MatchDegree, VisualizerEvent, VisualizerEventType, VisualizerType} from "../Concepts/Visualizer"
import {IDataTable} from "../Concepts/DataTable"
import {WorkspaceStoreId} from "../Concepts/DataBase"
import {ymGoal} from "../YM"
import $t from "../i18n/i18n"

export const Loader = (props:{size?:number}) =>
    <div style={{display:"flex", justifyContent:"center", alignItems:"center", color:colors.grey.c200, height:"100%"}}><ProgressRing color={colors.grey.c200} radius={props.size ?? 50}/></div>

export type WorkspaceId = 0

enum Dialog {
    Message,
    Error = 1,
    Save,
    Settings,
    Import,
    Presets
}

interface Props extends RouteComponentProps<any> {
    width: number
}

interface SnackbarInfo {
    text: string
    button?: string
    onButton?: () => void
    autoHideDuration?: number
}

interface State {
    columnConfigSnapshot: string,
    columnColoring: ColumnColoring,
    // "null" means no data available, "undefined" availability of data is still undetermined
    dataTable: IDataTable | null | undefined,
    visSettings: IVisSettings | null
    selectedVisType: VisualizerType | null,
    dialog: Dialog | null,
    location: WorkspaceLocation | null,
    backdrop: {message: string, percent: number|undefined, cancelable: boolean} | null,
    presetToLoad: string|undefined
    disableSave: boolean,
    snackbar: SnackbarInfo | null,
    currentTabIndex?: 0|1
}

export type WorkspaceSetting =
    // splitter position
    "splitter"
    // workspace name
    | "name"
    // workspace store id
    | "storeid"

export interface SerializedWorkspace {
    dicws: number,
    vissettings: any,
    data: any
}

const snackbarSlideTransition = (props: TransitionProps) => <Slide {...props} direction="right" />

const PanelTab: React.FunctionComponent<{label:string, selected:boolean, onClick:()=>void}> = props =>
    <div
        className={css.panelTab}
        style={{
            width: "50%",
            textAlign: "center",
            padding: 7,
            margin: 7,
            borderRadius: 4,
            fontWeight: 500,
            fontSize: 20,
            backgroundColor: props.selected ? colors.amber.c500 : colors.grey.c100,
            cursor: props.selected ? "inherit" : "pointer",
        }}
        key={props.label}
        onClick={props.selected ? undefined : props.onClick}
    >
        {props.label}
    </div>

const TabbedPanels: React.FunctionComponent<{currentTabIndex:0|1, onTabChanged:()=>void, panels:[JSX.Element, JSX.Element]}> = props =>
    <div className={css.tabbedPanels}>
        <div className={css.navigation}>
            <PanelTab label={$t('workspace.tab.data')} selected={props.currentTabIndex === 0} onClick={props.onTabChanged} />
            <PanelTab label={$t('workspace.tab.analytics')} selected={props.currentTabIndex === 1} onClick={props.onTabChanged}/>
        </div>
        <div className={css.content}>
            {props.panels[props.currentTabIndex]}
        </div>
    </div>

export class Workspace extends React.PureComponent<Props, State> {
    protected static instance:Workspace|undefined
    protected static reportedStartupPreset: string | undefined
    protected message = ""
    protected visualizers:IVisualizer[] = []
    protected settings = new LocalSettings(new Map<WorkspaceSetting, number|string> ([
        ["splitter", 500],
        ["name", "workspace"],
        ["storeid", 0]
    ]), "w_")
    protected waitingWorker: ServiceWorker | null = null
    protected backdropCancelled = false
    protected shiftKeyPressed = false
    protected presetIsLoading = false

    override readonly state:State = {
        columnConfigSnapshot: '',
        columnColoring: {mode: ColumnColoringMode.None},
        dataTable: undefined,
        visSettings: null,
        selectedVisType:null,
        dialog: null,
        location: null,
        backdrop: null,
        disableSave: true,
        snackbar: null,
        presetToLoad: undefined
    }

    constructor(props:Props) {
        super(props)
        Workspace.instance = this
        this.visualizers = [
            new NumFreqHistSharedX(this.handleVisualizerEvent),
            new FreqDistrGrid(this.handleVisualizerEvent),
            new PieBarChart(this.handleVisualizerEvent),
            new ScatterPlot(this.handleVisualizerEvent),
            new ScatterPlotMatrix(this.handleVisualizerEvent),
            new LineChart(this.handleVisualizerEvent),
            new TimeIntervals(this.handleVisualizerEvent),
            new ColumnProfile(this.handleVisualizerEvent),
        ]
        U.assert(!this.visualizers.some ((vis, index) => index > 0 && vis.type === this.visualizers[0].type), "Some visualizer has a duplicated type")
    }

    static backdrop = (message?:string|null, percent?:number):BackdropResponse => Workspace.instance ? Workspace.instance.backdrop (message, percent) : {cancelled:false}

    static handleSWUpdate = (registration: ServiceWorkerRegistration) => {
        return Workspace.instance ? Workspace.instance.handleSWUpdate (registration) : undefined
    }

    static unselectAllColumns = () => Workspace.instance ? Workspace.instance.unselectAllColumns () : undefined

    protected static getVisTypeFromSettings = (visSettings:IVisSettings, selectedColumnIds:ColumnId[]):VisualizerType | null => {
        const visType = parseInt(visSettings.get(VisualizerType.Default, selectedColumnIds, DEFAULT_SETTING_ID, ''))
        return visType ? visType : null
    }

    protected readonly reloadSW = () => {
        this.waitingWorker?.postMessage({ type: 'SKIP_WAITING' })
        this.setState({snackbar: null})
        window.location.reload()
    }

    protected handleSWUpdate (registration: ServiceWorkerRegistration) {
        this.waitingWorker = registration.waiting
        this.showSnackbar ($t('workspace.new_version_available'), $t('workspace.button.update_new_version'), this.reloadSW)
    }

    protected showSnackbar (text: string, button?: string, onButton?:()=>void) {
        event.app.snackbar.show(text, button)
        this.setState({snackbar: {text, button, onButton: ()=>{event.app.snackbar.button();if (onButton) onButton()}}})
    }

    static getDerivedStateFromProps(props:Props, state:State):State | null {
        const preset = props.match.params['preset']

        if (preset && Workspace.reportedStartupPreset !== preset) {
            event.app.preset.startup(preset)
            Workspace.reportedStartupPreset = preset
        }

        const lochash = props.match.params['lochash']
        if (lochash !== state.location?.lochash) {
            let newLocation = lochash ? WorkspaceLocation.fromLochash(lochash) : null
            if (state.dataTable) {
                if (newLocation) {
                    event.app.location(newLocation)
                    state.dataTable.selectColumns(newLocation.columnIds)
                    if (newLocation.settings && newLocation.visType && state.visSettings) {
                        for (const sv of newLocation.settings) {
                            state.visSettings.set(newLocation.visType, newLocation.columnIds, sv.id, sv.value)
                        }
                    }
                } else {
                    newLocation = WorkspaceLocation.moveTo(
                        props.history,
                        state.dataTable.selectedColumnIds,
                        state.selectedVisType
                            ? state.selectedVisType
                            : (state.visSettings
                                ? Workspace.getVisTypeFromSettings(state.visSettings, state.dataTable.selectedColumnIds) ?? undefined
                                : undefined))
                }
            }

            return {
                location: newLocation,
                dataTable: state.dataTable,
                visSettings: state.visSettings,
                dialog: state.dialog,
                columnConfigSnapshot: state.dataTable?.visualConfigSnapshot ?? '',
                columnColoring: state.columnColoring,
                selectedVisType: newLocation?.visType ?? null,
                backdrop: null,
                disableSave: state.disableSave,
                snackbar: state.snackbar,
                currentTabIndex: state.currentTabIndex,
                presetToLoad: preset ? preset : undefined
            }
        } else {
            return Object.assign(Object.assign({}, state), {presetToLoad: preset ? preset : undefined})
        }
    }

    protected async resetWorkspace (storeId: number) {
        await WorkspaceStore.delete(storeId)
        this.settings.set("storeid", 0)
        this.setState({
            location: WorkspaceLocation.moveTo(this.props.history, []),
            dataTable: null
        })
    }

    protected loadPreset (preset: string) {
        if (!this.presetIsLoading) {
            this.presetIsLoading = true
            event.app.preset.load(preset)
            fetch (window.location.origin + `/presets/${preset}.json`, {cache: 'no-cache'})
                .then(response => response.arrayBuffer())
                .then(buffer => {
                    this.startDataImport(new Uint8Array(buffer), false)
                    this.presetIsLoading = false
                })
                .catch(reason => {
                    this.backdrop(null)
                    this.errorBox($t('workspace.error.preset', {preset}), reason.toString())
                    this.presetIsLoading = false
                })
            this.backdrop($t('workspace.backdrop.loading_preset'))
        }
    }

    override componentDidMount () {
        document.addEventListener ('paste', this.handlePaste)
        document.addEventListener ('keydown', this.handleKeyDown)
        document.addEventListener ('keyup', this.handleKeyUp)

        userSettings.subscribe(this.handleUserSettingsChanged)

        const storeId = this.settings.getNumber("storeid")
        const preset = this.state.presetToLoad
        if (preset) {
            this.setState({presetToLoad: undefined})
            this.loadPreset(preset)
        }
        else if (storeId) {
            this.loadFromIDB(storeId)
                .then(loaded => {
                    if (!loaded) {
                        event.app.error.handled("Warkspace failed to load")
                        this.resetWorkspace(storeId).then()
                    }
                })
                .catch(reason => {
                    event.app.error.handled("Can't open the workspace: " + reason)
                    this.resetWorkspace(storeId).then()
                })
        } else {
            this.setState({
                location: WorkspaceLocation.moveTo(this.props.history, []),
                dataTable: null
            })
        }
    }

    override componentWillUnmount() {
        document.removeEventListener ('paste', this.handlePaste)
        document.removeEventListener ('keydown', this.handleKeyDown)
        document.removeEventListener ('keyup', this.handleKeyUp)
        userSettings.unsubscribe(this.handleUserSettingsChanged)
    }

    override componentDidUpdate(prevProps:Readonly<Props>, prevState:Readonly<State>) {
        /*
        const preset = this.state.presetToLoad

        if (preset && !this.presetIsLoading) {
            this.presetIsLoading = true
            this.setState({presetToLoad: undefined})
            fetch (`https://dataisclear.com/presets/${preset}.json`, {cache: 'no-cache'})
                .then(response => response.arrayBuffer())
                .then(buffer => {
                    this.startDataImport(new Uint8Array(buffer), false)
                })
                .catch(reason => {
                    this.backdrop(null)
                    this.errorBox($t('workspace.error.preset', {preset}), reason.toString())
                })
            this.backdrop($t('workspace.backdrop.loading_preset'))
        }
        */
    }

    backdrop = (message?:string|null, percent?:number, cancelable=false):BackdropResponse => {
        if (this.state.backdrop === null && message !== null) {
            this.backdropCancelled = false
        }
        this.setState({
            backdrop: message === null
                ? null
                : {
                    message: message ?? (this.state.backdrop?.message ?? ''),
                    percent,
                    cancelable
                }
        })
        return {cancelled: this.backdropCancelled}
    }

    asyncBackdrop = (message?:string, percent?:number, cancelable=false):BackdropResponse => {
        if (this.state.backdrop !== null) {
            return this.backdrop(message, percent, cancelable)
        }
        return {cancelled: false}
    }

    errorBox = (intro:string, error='') => {
        this.message = error ? `${intro}: ${error}` : intro
        event.app.error.box(this.message)
        this.backdrop(null)
        this.setState({dialog: Dialog.Error})
    }

    protected handleUserSettingsChanged = () => {
        this.forceUpdate()
    }

    protected handleVisualizerEvent = (event:VisualizerEvent) => {
        let columnColoring:ColumnColoring|null = null
        switch (event.type) {
            case VisualizerEventType.NotColored:
                columnColoring = {mode:ColumnColoringMode.None}
                break
            case VisualizerEventType.ColoredByColumnColors:
                columnColoring = {mode:ColumnColoringMode.ByColumnColor, excludeColumnIds:event.excludeColumnIds}
                break
            case VisualizerEventType.ColoredByCategoricalColumn:
                columnColoring = {mode:ColumnColoringMode.ByCategoryColor, categoryColumnId:event.columnId}
                break
        }
        if (columnColoring !== null) {
            this.setState({columnColoring})
        }
    }

    protected handleColumnWidthChange = (columnIndex:ColumnUIIndex, width:number) => {
        const dt = this.state.dataTable
        if (U.mustNotBeNullNorUndefined(dt)) {
            const columnId = dt.columnIdByUIIndex(columnIndex),
                currentWidth = dt.getColumnInfo(columnId).width
            if (width !== currentWidth) {
                event.table.column.width(columnId, width)
                this.setState({columnConfigSnapshot: dt.changeColumnWidth(columnIndex, width)})
            }
        }
    }

    protected handleColumnMove = (columnIndex:ColumnUIIndex, delta:1 | -1) => {
        const dt = this.state.dataTable
        if (U.mustNotBeNullNorUndefined(dt)) {
            event.table.column.move (dt.columnIdByUIIndex(columnIndex), delta < 0)
            this.setState({columnConfigSnapshot: dt.swapColumns(columnIndex, delta)})
        }
    }

    protected handleColumnPin = (columnIndex:ColumnUIIndex) => {
        const dt = this.state.dataTable
        if (U.mustNotBeNullNorUndefined(dt)) {
            const result = dt.toggleColumnPinning(columnIndex)
            event.table.column.pin(dt.columnIdByUIIndex(columnIndex), result.pinned)
            this.setState({columnConfigSnapshot: result.snapshot})
        }
    }

    protected handleColumnSelect = (columnIndex:ColumnUIIndex, exclusive:boolean) => {
        const dt = this.state.dataTable
        if (U.mustNotBeNullNorUndefined(dt) && U.mustNotBeNull(this.state.visSettings)) {
            const columnId = dt.columnIdByUIIndex(columnIndex),
                columnConfigSnapshot = dt.selectColumn(columnIndex, exclusive)
            event.table.column.select(columnId, dt.getColumnInfo(columnId).stype, dt.selectedColumnIds)
            ymGoal ('SelectedColumn')
            const selectedVisType = Workspace.getVisTypeFromSettings(this.state.visSettings, dt.selectedColumnIds),
                location = WorkspaceLocation.moveTo(this.props.history, dt.selectedColumnIds, selectedVisType ?? undefined)
            this.setState({columnConfigSnapshot, selectedVisType, location})
        }
    }

    protected unselectAllColumns = () => {
        const dt = this.state.dataTable
        if (U.mustNotBeNullNorUndefined(dt) && U.mustNotBeNull(this.state.visSettings)) {
            const columnConfigSnapshot = dt.selectColumns([])
            event.table.column.select(ColumnId(-1), null, [])
            const selectedVisType = Workspace.getVisTypeFromSettings(this.state.visSettings, dt.selectedColumnIds),
                location = WorkspaceLocation.moveTo(this.props.history, dt.selectedColumnIds, selectedVisType ?? undefined)
            this.setState({columnConfigSnapshot, selectedVisType, location})
        }
    }

    protected handleSplitterSizeChange = (size:number):number => {
        this.settings.set ("splitter", size)
        return size
    }

    protected handleSplitterDragEnd = () => {
        event.ui.splitter(this.settings.getNumber("splitter"))
    }

    protected handleVisSelected = (selectedVisType: VisualizerType) => {
        if (this.state.visSettings !== null && U.mustNotBeNullNorUndefined(this.state.dataTable)) {
            const location = WorkspaceLocation.moveTo(this.props.history, this.state.dataTable.selectedColumnIds, selectedVisType)
            this.state.visSettings.set(VisualizerType.Default, this.state.dataTable.selectedColumnIds, DEFAULT_SETTING_ID, selectedVisType.toString())
            this.setState({selectedVisType, location})
        } else {
            throw new Error('handleVisSelected is called while some crucial data is null')
        }
    }

    protected handleMenuAction = (isStartMenu:boolean, action:MenuAction) => {
        const menuEvent = isStartMenu ? event.menu.start : event.menu.sidebar
        switch (action) {
            case MenuAction.OpenFileAuto:
            case MenuAction.OpenFileManual:
                menuEvent.open()
                return this.openFile(action === MenuAction.OpenFileManual)
            case MenuAction.PasteAuto:
            case MenuAction.PasteManual:
                menuEvent.paste()
                return this.handlePaste(undefined, action === MenuAction.PasteManual)
            case MenuAction.SaveWorkspace:
                if (this.state.dataTable && this.state.visSettings) {
                    event.menu.sidebar.save.open()
                    this.setState({dialog: Dialog.Save})
                }
                return
            case MenuAction.Settings:
                return this.setState({dialog: Dialog.Settings})
            case MenuAction.Telegram:
                event.menu.sidebar.support()
                const fbWindow = window.open("https://t.me/dataisclear_support", '_blank', 'noopener,noreferrer')
                if (fbWindow) fbWindow.opener = null
                return
            case MenuAction.LoadPreset:
                menuEvent.presets()
                return this.setState({dialog: Dialog.Presets})
            default:
                U.shouldNeverGetHere(action)
        }
    }

    protected handleSidebarMenuAction = this.handleMenuAction.bind(this, false)

    protected handleStartMenuAction = this.handleMenuAction.bind(this, true)

    protected handleDataDropped = (data:File|string) => {
        event.dragndrop()
        if (typeof data === "string") {
            this.startDataImport(new TextEncoder().encode(data), false)
        } else {
            this.loadFile(data).then(result => {
                this.startDataImport(result as Uint8Array, false)
            })
        }
    }

    protected handleSnackbarClose = (evt?: React.SyntheticEvent, reason?: string) => {
        if (reason !== 'clickaway') {
            event.app.snackbar.close()
            this.setState({snackbar: null})
        }
    }

    protected openFile = (forceDialog:boolean) => {
        const handleFileSelect = () => {
            if (fileInput.files && fileInput.files.length) {
                this.loadFile(fileInput.files[0])
                    .then(result => this.startDataImport (result as Uint8Array, forceDialog))
                    .catch(reason => {
                        if (reason) {
                            this.errorBox($t('workspace.error.file_load'), reason.message ?? reason.toString())
                        }
                    })
            }
        }

        const fileInput = document.createElement("input") as HTMLInputElement
        fileInput.setAttribute("type", "file")
        //fileInput.setAttribute("accept", "text/plain,text/csv,application/json,text/tsv")
        fileInput.addEventListener('change', handleFileSelect, false)
        fileInput.click();
    }

    protected handlePaste = (evt:ClipboardEvent|undefined, forceDialog=false) => {
        if (!evt || this.state.dialog === null) {
            if (evt) {
                evt.preventDefault()
            }
            if (navigator.clipboard) {
                navigator.clipboard.readText()
                    .then(text => {
                        if (text.trim().length) {
                            this.startDataImport(new TextEncoder().encode(text), forceDialog || (evt !== undefined && this.shiftKeyPressed))
                        } else {
                            this.errorBox($t('workspace.error.empty_clipboard'))
                        }
                    }).catch(err => this.errorBox($t('workspace.error.read_clipboard'),
                            err instanceof DOMException && err.name === "NotAllowedError"
                                ? $t('workspace.allow_clipboard')
                                : err.toString()
                        )
                    )
            } else if (evt && evt.clipboardData) {
                const text = evt.clipboardData.getData('text/plain')
                if (text) {
                    this.startDataImport (new TextEncoder().encode(text), forceDialog)
                }
            }
        }
    }

    protected handleKeyDown = (evt:KeyboardEvent) => {
        let handled = false
        if (evt.shiftKey) {
            this.shiftKeyPressed = true
        }
        if (evt.ctrlKey) {
            switch (evt.key) {
                case 'o':
                    this.handleMenuAction(false, this.shiftKeyPressed ? MenuAction.OpenFileManual : MenuAction.OpenFileAuto)
                    handled = true
                    break
                case 's':
                    this.handleMenuAction(false, MenuAction.SaveWorkspace)
                    handled = true
                    break
            }
        }
        if (handled) {
            evt.preventDefault()
        }
    }

    protected handleKeyUp = (evt:KeyboardEvent) => {
        if (evt.shiftKey) {
            this.shiftKeyPressed = false
        }
    }

    protected async loadFile (file:File):Promise<Uint8Array> {
        return new Promise<Uint8Array>((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.onprogress = (event) => {
                if (event.lengthComputable) {
                    this.backdrop(undefined, Math.round(event.loaded * 100 / event.total))
                }
            }
            fileReader.onloadend = () => {
                this.backdrop(null)
                resolve(new Uint8Array(fileReader.result as ArrayBuffer))
            }
            fileReader.onerror = () => reject(fileReader.error)
            fileReader.onabort = () => reject()
            this.backdrop($t('workspace.backdrop.file_loading', {file: file.name}))
            fileReader.readAsArrayBuffer(file)
        })
    }

    protected handleSave = (fileName:string):number => {
        this.settings.set("name", U.removeSuffix (fileName, '.json'))
        this.setState({dialog: null})
        if (this.state.dataTable && this.state.visSettings) {
            const serializedWorkspace:SerializedWorkspace = {
                dicws: 1,
                vissettings: serialize(this.state.visSettings),
                data: serialize(this.state.dataTable)
            }
            const data = JSON.stringify(serializedWorkspace)
            MTU.downloadJsonAsFile(data, this.settings.getString("name"))
            return data.length
        }
        return 0
    }

    protected handleDialogCancel = () => this.setState({dialog: null})

    protected handleBackdropCancel = () => {
        this.backdropCancelled = true
        if (this.state.backdrop !== null) {
            this.setState({backdrop: {message: $t('workspace.backdrop.cancelling'), percent: undefined, cancelable: false}})
        }
    }

    protected startDataImport (data:Uint8Array, forceDialog:boolean) {
        const dataSize = data.length,
            started = Date.now()
        if (data.length) {
            this.backdrop($t('workspace.backdrop.loading_data'), undefined)
            dataParser.init(
                Comlink.transfer(data, [data.buffer]),
                userSettings.getString("importLocale"),
                userSettings.getString("importTimezone"),
                Comlink.proxy(this.asyncBackdrop)
            ).then((importParams: ImportParams) => {
                this.backdrop(null)
                if ((importParams.confident && !forceDialog) || importParams.workspace) {
                    if (importParams.workspace) {
                        event.import.workspace(dataSize)
                    } else {
                        event.import.sepval(importParams, dataSize, Date.now() - started)
                    }
                    this.finishDataImport(importParams)
                } else {
                    event.import.sepval(importParams, dataSize, Date.now() - started)
                    this.setState({dialog: Dialog.Import})
                }
            }).catch(reason => this.errorBox($t('workspace.error.init_parser'), reason.message ?? reason.toString()))
        }
    }

    protected finishDataImport = (importParams:ImportParams) => {
        this.backdrop("")
        this.setState({dialog: null})
        dataParser
            .saveToWorkspaceStoreAndCleanup(importParams, userSettings.getString("importLocale"), userSettings.getString("importTimezone"))
            .then (result => {
                if (result.error !== undefined) {
                    this.errorBox ($t('workspace.error.import'), result.error)
                } else if (result.wsStoreId !== undefined) {
                    const storeId = result.wsStoreId
                    this.backdrop($t('workspace.backdrop.new_workspace'))
                    this.loadFromIDB (storeId, true)
                        .then (()=>{
                            const oldStoreId = this.settings.getNumber("storeid")
                            this.settings.set("storeid", storeId)
                            WorkspaceStore.delete(oldStoreId).then()
                            this.settings.set("name", "workspace")
                            this.backdrop(null)
                            ymGoal ('ImportedData')
                        })
                        .catch(reason => {
                            this.errorBox ($t('workspace.error.open_imported_workspace'), reason.message ?? reason.toString())
                        })

                }
            })
            .catch (reason => this.errorBox($t('workspace.error.save_imported_data'), reason.message ?? reason.toString()))
    }

    protected setDataTable (dataTable:IDataTable, visSettings:IVisSettings, clearLocation:boolean) {
        let location = clearLocation ? null : this.state.location
        if (location) {
            dataTable.selectColumns(location.columnIds)
            if (location.settings && location.visType) {
                for (const sv of location.settings) {
                    visSettings.set(location.visType, location.columnIds, sv.id, sv.value)
                }
            }
        }

        const selectedVisType = location?.visType ?? Workspace.getVisTypeFromSettings(visSettings, dataTable.selectedColumnIds)
        if (!location) {
            location = WorkspaceLocation.moveTo(this.props.history, dataTable.selectedColumnIds, selectedVisType ?? undefined)
        }

        this.setState({
            location,
            dataTable,
            visSettings,
            selectedVisType,
            columnConfigSnapshot: dataTable.visualConfigSnapshot,
            disableSave: false
        })
    }

    protected async loadFromIDB (wsStoreId:WorkspaceStoreId, clearLocation=false):Promise<boolean> {
        try {
            const wsStore = WorkspaceStore.get(wsStoreId)
            const dataTable = await DataTable.loadFromStore(wsStore)
            if (dataTable.rowCount) {
                await this.setDataTable(dataTable, await VisSettings.loadFromStore(wsStore), clearLocation)
                return true
            } else {
                return false
            }
        } catch (e) {
            return Promise.reject<boolean>(e)
        }
    }

    protected handleTabChange = () => {
        const newTabIndex = this.state.currentTabIndex ? 0 : 1
        this.setState ({currentTabIndex: newTabIndex})
        event.ui.tab(newTabIndex)
    }

    protected handlePresetSelection = (preset: string) => {
        this.setState({dialog: null})
        this.loadPreset(preset)
    }

    override render () {
        const selectedColumnInfos = this.state.dataTable ? this.state.dataTable.selectedColumns : [],
            matchedVisualizers = this.visualizers
                .map (vis => [vis, vis.matchDegree(selectedColumnInfos)] as [IVisualizer, MatchDegree])
                .filter (tupple => tupple[1] !== MatchDegree.NoMatch)
                .sort ((a, b) => b[1] - a[1])
                .map (tupple => tupple[0])
        let selectedVisType:VisualizerType | null =  matchedVisualizers[0]?.type ?? null
        for (const vis of matchedVisualizers) {
            if (vis.type === this.state.selectedVisType) {
                selectedVisType = this.state.selectedVisType
                break
            }
        }

        const panels:[left:JSX.Element, right:JSX.Element] = this.state.dataTable !== undefined
            ? [
                this.state.dataTable
                    ? <TableGrid key="TableGrid"
                                 columnConfigSpanshot={this.state.columnConfigSnapshot}
                                 columnColoring={this.state.columnColoring}
                                 dataTable={this.state.dataTable}
                                 onColumnWidthChange={this.handleColumnWidthChange}
                                 onColumnMove={this.handleColumnMove}
                                 onColumnPin={this.handleColumnPin}
                                 onColumnSelect={this.handleColumnSelect}
                                 onDataDropped={this.handleDataDropped}
                                 listenKeyboard={this.state.dialog === null}
                    />
                    : <ImportPanel key="ImportPanel"
                                   onDataDropped={this.handleDataDropped}
                                   onAction={this.handleStartMenuAction} />,
                <AnalyticsPane key="AnalyticsPane" selectedColumnsSnapshot={(this.state.dataTable?.selectedColumnIds ?? []).join(',')}
                               dataTable={this.state.dataTable}
                               visSettings={this.state.visSettings}
                               visualizers={matchedVisualizers}
                               selectedVisType={selectedVisType}
                               onVisSelected={this.handleVisSelected}
                />
            ]
            : [
                <Loader key="1" />,
                <Loader key="2" />
            ]

        const main = this.props.width > 768
            ? <SplitterLayout percentage={false}
                          customClassName={css.mainSplitter}
                          secondaryInitialSize={this.settings.getNumber("splitter")}
                          primaryIndex={0}
                          secondaryMinSize={250}
                          onSecondaryPaneSizeChange={this.handleSplitterSizeChange}
                          onDragEnd={this.handleSplitterDragEnd}
            >
                {panels}
            </SplitterLayout>
            : <TabbedPanels currentTabIndex={this.state.currentTabIndex ?? 1}
                         onTabChanged={this.handleTabChange}
                         panels={panels}
            />

        return (
            <React.Fragment>
                    <Snackbar open={this.state.snackbar !== null}
                              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                              autoHideDuration={this.state.snackbar?.autoHideDuration}
                              TransitionComponent={snackbarSlideTransition}
                              onClose={this.handleSnackbarClose}
                              message={this.state.snackbar?.text}
                              action={
                                  this.state.snackbar?.button
                                      ? <React.Fragment>
                                          <Button variant="outlined"
                                                  size="small"
                                                  color="primary"
                                                  style={{color:colors.amber.c500, borderColor:colors.amber.c500}}
                                                  onClick={this.state.snackbar?.onButton}>
                                              {this.state.snackbar?.button}
                                          </Button>
                                          <IconButton
                                              aria-label="close"
                                              color="inherit"
                                              style={{paddingLeft: 10, color:colors.grey.c500}}
                                              onClick={this.handleSnackbarClose}
                                              size="large">
                                              <Icon type="xmark" style={{width:16, height:16}} />
                                          </IconButton>
                                      </React.Fragment>
                                      : undefined
                              }
                    />
                    <SaveDialog
                        open={this.state.dialog === Dialog.Save}
                        fileName={this.settings.getString("name")}
                        onCancel={this.handleDialogCancel}
                        onSave={this.handleSave}
                    />
                    <MessageBox
                        open={this.state.dialog === Dialog.Message || this.state.dialog === Dialog.Error}
                        message={this.message}
                        onClose={this.handleDialogCancel}
                    />
                    <SettingsDialog
                        open={this.state.dialog === Dialog.Settings}
                        workspaceSettings={this.settings}
                        onClose={this.handleDialogCancel}
                    />
                    <PresetsDialog
                        open={this.state.dialog === Dialog.Presets}
                        onSelection={this.handlePresetSelection}
                        onClose={this.handleDialogCancel}
                    />
                    <ImportDialog
                        open={this.state.dialog === Dialog.Import}
                        dataParser={dataParser}
                        onCancel={this.handleDialogCancel}
                        onImport={this.finishDataImport}
                    />
                    {main}
                    <SideMenu
                        onAction={this.handleSidebarMenuAction}
                        disabledActions={[this.state.disableSave ? MenuAction.SaveWorkspace : undefined]}
                    />
                    <WorkspaceBackdrop visible={this.state.backdrop !== null}
                                       percent={this.state.backdrop?.percent}
                                       message={this.state.backdrop?.message}
                                       onCancel={this.state.backdrop?.cancelable ? this.handleBackdropCancel : undefined}
                    />
            </React.Fragment>
        );
    }
}