import {ScatterPlotRawData} from "./Renderers/ScatterPlot"
import {ColumnId} from "../Basic"

export interface VisData {
    data: ScatterPlotRawData
    approvedSizeColumnIds: Set<number>
    approvedTimeColumnIds: Set<number>
}

export interface VisColData {
    columnIds: ColumnId[]
    footer: string | undefined
}

export enum VisControls {
    xAxis = 1,
    yAxis = 2,
    colorAxis = 3,
    sizeAxis = 4,
    timeAxis = 5,
    dotSize = 6,
    timeLineMode = 7,
    // uncomment for dot opacity UI // dotOpacity = 8
}

export enum TimeLineMode {
    single = "sin",
    byCategory = "cat"
}

