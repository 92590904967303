/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import * as React from 'react';
import ScrollBar from "../ScrollBar/ScrollBar";
import * as SizeAware from '../Components/SizeAware'
import {IVisualizer, visTypeToString, VisualizerType} from "../Concepts/Visualizer"
import {AppBar, Tab, Tabs} from "@material-ui/core";
import {VisContainer} from "./VisContainer";
import {SpecifiedVisSettings} from "./VisSettings"
import dataImportPicture from "../Images/pixeltrue-data-analysis-bw.svg" // taken from https://icons8.com/illustrations/style--pixeltrue
import notFoundPicture from "../Images/pixeltrue-error.svg" // taken from https://icons8.com/illustrations/style--pixeltrue
import selectColumnPicture from "../Images/pixeltrue-plan.svg"
import * as U from '../Utils'
import event from "../EventSender/Events"
import {colors} from "../Colors"
import {TM} from "../@testing/TestMarker"
import {IDataTable} from "../Concepts/DataTable"
import {ISpecifiedVisSettings, IVisSettings} from "../Concepts/VisSettings"
import {ymGoal} from "../YM"
import $t from "../i18n/i18n";

interface Props {
    selectedColumnsSnapshot: string
    dataTable: IDataTable | null
    visualizers: IVisualizer[]
    selectedVisType: VisualizerType | null
    onVisSelected: (visType: VisualizerType)=>void
    visSettings: IVisSettings | null
}

interface State {
    contentHeight: number
}

enum PaneState {
    NoData = "NoData",
    NoColumn = "NoColumn",
    NoSettings = "NoSettings",
    NoVisualizer = "NoVisualizer",
    VisualizerShown = "VisualizerShown"
}

class PaneStateReporter {
    protected prevPaneState:PaneState|undefined
    protected prevVisType:VisualizerType|undefined
    protected prevColumnSnapshot:string|undefined

    report (state:PaneState, columnSnapshot:string, tabJustChanged:boolean, visualizer?:IVisualizer):void {
        if (state !== this.prevPaneState
            || (state === PaneState.VisualizerShown
                && (visualizer?.type !== this.prevVisType || columnSnapshot !== this.prevColumnSnapshot))) {
            switch (state) {
                case PaneState.NoData:
                    event.analytics.nodata()
                    break
                case PaneState.NoColumn:
                    event.analytics.nocolumn()
                    break
                case PaneState.NoSettings:
                    event.analytics.nosettings()
                    break
                case PaneState.NoVisualizer:
                    event.analytics.visualizer.nothing()
                    break
                case PaneState.VisualizerShown:
                    if (U.mustNotBeNullNorUndefined(visualizer)) {
                        (tabJustChanged ? event.analytics.visualizer.change : event.analytics.visualizer.default)(visTypeToString(visualizer.type))
                        if (tabJustChanged) {
                            ymGoal ('SelectedVisualizer')
                        }
                    }
                    break
                default:
                    U.shouldNeverGetHere(state)
            }
        }
        this.prevPaneState = state
        this.prevVisType = visualizer?.type
        this.prevColumnSnapshot = columnSnapshot
    }
}

function MessageContainer (props:React.PropsWithChildren<unknown>) {
    return <div style={{margin: "100px auto", maxWidth: 400, textAlign: "center"}}>{props.children}</div>
}

export default class AnalyticsPane extends React.PureComponent<Props, State> {

    static readonly SizeAwareVisContainer = SizeAware.make (VisContainer, true)

    static readonly tabStyle = css`
      .MuiTab-root {
        min-width: 64px; 
        padding: 6px 12px;
      }
      .MuiTabs-scroller {
        border-bottom: ${colors.grey.c300} 1px solid;
      }
    `

    override state = {contentHeight: 0}

    protected readonly reporter = new PaneStateReporter()
    protected lastColumnIds:number[] = []
    protected lastVisType:VisualizerType|undefined
    protected lastViewSettings: IVisSettings | null = null
    protected visSettings:ISpecifiedVisSettings | null = null
    protected tabJustChanged = false

    protected handleTabChange = (evt:React.ChangeEvent<unknown>, newTabIndex:number) => {
        this.tabJustChanged = true
        this.props.onVisSelected(this.props.visualizers[newTabIndex].type)
    }

    protected handleContentHeightChange = (height: number) => {
        this.setState({contentHeight: height})
    }

    protected handleScrollPosChange = (scrollPos:number, contentHeight:number, containerHeight:number) => {
        event.analytics.scroll(scrollPos, contentHeight, containerHeight)
    }

    protected report (state:PaneState, visualizer?:IVisualizer):void {
        this.reporter.report (state, this.props.selectedColumnsSnapshot, this.tabJustChanged, visualizer)
        this.tabJustChanged = false
    }

    override render() {
        let visualizer, tabIndex
        for (let i = 0; i < this.props.visualizers.length; i++) {
            if (this.props.visualizers[i].type === this.props.selectedVisType) {
                visualizer = this.props.visualizers[i]
                tabIndex = i
                break
            }
        }

        if (this.props.dataTable) {
            if (!this.props.visSettings) {
                this.report(PaneState.NoSettings)
                return <h1>{$t('analytics.message.nosettings')}</h1>
            } else if (this.props.dataTable.selectedColumnIds.length === 0) {
                this.report(PaneState.NoColumn)
                return <MessageContainer>
                        <h1>{$t('analytics.message.colsel1')}</h1>
                        <h3>{$t('analytics.message.colsel2')}</h3>
                        <img style={{width:"100%", maxWidth: 500}} src={selectColumnPicture} alt={$t('analytics.imgalt.colsel')} />
                    </MessageContainer>
            } else if (visualizer) {
                // SpecifiedVisConfig memoization
                if (this.visSettings === null
                    || this.props.visSettings !== this.lastViewSettings
                    || visualizer.type !== this.lastVisType
                    || !U.areEqual(this.props.dataTable.selectedColumnIds, this.lastColumnIds)) {
                    this.visSettings = new SpecifiedVisSettings(this.props.visSettings, this.props.dataTable.selectedColumnIds, visualizer.type)
                    this.lastViewSettings = this.props.visSettings
                    this.lastVisType = visualizer.type
                    this.lastColumnIds = this.props.dataTable.selectedColumnIds
                }
                this.report(PaneState.VisualizerShown, visualizer)
                return (
                    <React.Fragment>
                        <AppBar position="static" color="default" elevation={0} style={{
                            minHeight: 49,
                            maxHeight: 49,
                            backgroundColor: colors.grey.c100
                        }}>
                            <Tabs value={tabIndex ?? 0}
                                  indicatorColor="primary"
                                  textColor="primary"
                                  variant="scrollable"
                                  scrollButtons="auto"
                                  css={AnalyticsPane.tabStyle}
                                  onChange={this.handleTabChange}
                            > {
                                this.props.visualizers.map(v =>
                                    <Tab data-test={TM.visIcon(v.type)}
                                         icon={v.icon(28, 28)}
                                         key={v.type}
                                         id={`tab-${v.type}`}/>)
                            }
                            </Tabs>
                        </AppBar>
                        <ScrollBar handleKeys={false} contentHeight={this.state.contentHeight} onScrollPosChanged={this.handleScrollPosChange}>
                            <div style={{
                                fontFamily: "-apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', 'Segoe UI', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                                padding: 10
                            }}>
                                <AnalyticsPane.SizeAwareVisContainer
                                    vis={visualizer}
                                    visSettings={this.visSettings}
                                    selectedColumnsSnapshot={this.props.selectedColumnsSnapshot}
                                    dataTable={this.props.dataTable}
                                    onHeightChanged={this.handleContentHeightChange}
                                />
                            </div>
                        </ScrollBar>
                    </React.Fragment>
                )
            } else {
                this.report(PaneState.NoVisualizer)
                return <MessageContainer>
                        <h2 style={{margin: 20}}>{$t('analytics.message.novis')}</h2>
                        <img style={{width:"100%", maxWidth: 500}} src={notFoundPicture} alt={$t('analytics.imgalt.novis')} />
                    </MessageContainer>
            }
        } else {
            this.report(PaneState.NoData)
            return <MessageContainer>
                    <h1 style={{margin: 20}}>{$t('analytics.message.nodata1')}<br/>{$t('analytics.message.nodata2')}</h1>
                    <img style={{width:"100%", maxWidth: 500}} src={dataImportPicture} alt={$t('analytics.imgalt.nodata')} />
                </MessageContainer>
        }
    }
}