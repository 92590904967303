import {ScatterPlotRawData} from "./Renderers/ScatterPlot"
import {ColumnId} from "../Basic"

export interface VisData {
    data: ScatterPlotRawData
}

export interface VisColData {
    columnIds: ColumnId[]
    categoricalColumnIds:ColumnId[]
    footer: string | undefined
}

export enum VisControls {
    categoriesColumn = 1
}
