import {alias, serializable} from "serializr"
import {CategoryValue} from "./Basic"

export class StandardColor {
    @serializable(alias('i')) readonly index:number
    @serializable(alias('l')) readonly light: string
    @serializable(alias('n')) readonly normal: string
    @serializable(alias('d')) readonly dark: string

    constructor(index:number, light: string, normal: string, dark: string) {
        this.index = index
        this.light = light
        this.normal = normal
        this.dark = dark
    }
}

export interface ColoredCategory {
    value: CategoryValue,
    color: StandardColor
}

export type CategoryToColor = Map<CategoryValue, StandardColor>