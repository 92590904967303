import {VisCtrlId, VisualizerType} from "../Concepts/Visualizer"

export type TestMarker = string & {readonly brand: unique symbol}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export function TestMarker (name: string) {
    return name as TestMarker
}

export class TM {
    static readonly combine = (...markers:(TestMarker|undefined)[]):string => markers.map (m => m ? m : '').join (' ')
    static readonly importPanelPaste = TestMarker("importpanel.paste")
    static readonly anyVisIcon = TestMarker("visicon.")
    static readonly chartElementWithTooltip = TestMarker("chart.tooltipavailable")
    static readonly firstRowTableCell = (index: number) => TestMarker("table.firstrow.cell." + index)
    static readonly visIcon = (visType: VisualizerType) => TestMarker(TM.anyVisIcon + visType)
    static readonly visControl = (ctrlId: VisCtrlId) => TestMarker("visctrl." + ctrlId)
    static readonly visualizerOutput = TestMarker("visualizer.output")
}

export class UniqueTestMarker {
    protected utilized = false

    constructor (protected marker: TestMarker) {}

    get value():TestMarker|undefined {
        if (this.utilized) {
            return undefined
        } else {
            this.utilized = true
            return this.marker
        }
    }
}