import * as React from "react"
import * as Colors from "../../Colors"
import * as RC from "./RendererCommon"
import {pushLabel} from "./RendererCommon"
import * as VC from "../VisCommon"
import {maxLabelFontSize, minHorizXLabelFontSize, Tooltip, TooltipRow} from "../VisCommon"
import * as U from "../../Utils"
import * as MTU from "../../MainThreadUtils"

import {CategoryValue, DataValue, INumberSummary, ITableColumn, OptionalString} from "../../Concepts/Basic"
import {SemanticType} from "../../Concepts/SemanticType"
import {Point, Rect} from "../../Concepts/Geometry"
import {StandardColor} from "../../Concepts/Colors"
import {PieChartData} from "../../Concepts/Visualizers/Renderers/PieChart"
import css from "../Visualizer.module.scss"
import * as STT from "../../StypeTools"
import {TestMarker} from "../../@testing/TestMarker"
import {ITooltipRow, LabelType, TooltipsById} from "../../Concepts/Visualizer";

export class Params {
    constructor(public readonly tooltips: TooltipsById, protected readonly _id: string, public readonly defs:JSX.Element[]=[]) {}

    append (suffix:string):Params {
        return new Params (this.tooltips, this._id + suffix, this.defs)
    }

    sfx (suffix:string|number):string {
        return this._id + suffix
    }
}

export abstract class ClipPath {
    protected elements:JSX.Element[] = []
    private readonly _clipPathId:string

    protected constructor(protected readonly p:Params) {
        this._clipPathId = this.p.sfx('d')
    }

    protected abstract fillElements ():void

    get def (): JSX.Element {
        if (this.elements.length === 0) {
            this.fillElements()
            if (this.elements.length === 0) {
                throw new Error("Clip path elements are not defined")
            }
        }
        return <clipPath key={this._clipPathId} id={this._clipPathId}>{this.elements}</clipPath>
    }

    get value():string {
        return `url(#${this._clipPathId})`
    }
}

export class RectClipPath extends ClipPath {
    constructor(params:Params, protected brick:ChartBrick) {
        super (params)
    }

    fillElements () {
        this.elements = [<rect key={this.p.sfx('rc')} x={this.brick.x} y={this.brick.y} width={this.brick.width} height={this.brick.height} />]
    }
}

export abstract class ChartBrick {
    private _width:number|undefined
    private _height:number|undefined
    private _x:number|undefined
    private _y:number|undefined

    protected constructor(protected readonly p:Params) {
    }

    abstract render (left: number, top: number, size?:number, clipPath?:ClipPath): JSX.Element[]

    get x():number {
        if (this._x !== undefined) {
            return this._x
        } else {
            throw new Error ("X is uninitialized")
        }
    }

    protected place (p:{x?:number, y?:number, width?:number, height?:number}) {
        this._x = p.x ?? this._x
        this._y = p.y ?? this._y
        this._width = p.width ?? this._width
        this._height = p.height ?? this._height
    }

    get y():number {
        if (this._y !== undefined) {
            return this._y
        } else {
            throw new Error ("Y is uninitialized")
        }
    }

    get width():number {
        if (this._width !== undefined) {
            return this._width
        } else {
            throw new Error ("Width is uninitialized")
        }
    }

    get height():number {
        if (this._height !== undefined) {
            return this._height
        } else {
            throw new Error ("Height is uninitialized")
        }
    }

    protected static stringWidths (strings:string[], fontSize:number):number[] {
        return strings.map (str => MTU.measureText(str, fontSize, VC.chartFontFamily))
    }

    protected static maxStringWidth (strings:string[], fontSize:number) {
        return Math.max (0, ...ChartBrick.stringWidths(strings, fontSize))
    }

    /*
     * Border and Grid params
     */

    protected get borderStrokeWidth() {
        return Math.min (Math.ceil(this.width / 500) + 0.5, 3)
    }

    protected get gridStrokeWidth() {
        return Math.min (Math.ceil(this.width / 500) + 0.25, 2)
    }

    protected readonly gridColor = Colors.colors.grey.c100
    protected readonly borderColor = Colors.colors.grey.c300
}

interface LabelParams {rotated:boolean, fontSize:number, widths:number[]}
type LabelParamAdjuster = (labels:string[], fontSize:number)=>LabelParams

export enum LabelDataType {
    NumericalRange,
    Categories,
    NumericalCategories
}

interface NumericalRange{
    type:LabelDataType.NumericalRange
    values:number[]
}

interface Categories{
    type:LabelDataType.Categories
    values:CategoryValue[]
}

interface NumericalCategories{
    type:LabelDataType.NumericalCategories
    values:number[]
    includesNull:boolean
}

export type LabelData = {stype:SemanticType} & (NumericalRange | Categories | NumericalCategories)

export abstract class BrickWithLabels extends ChartBrick {
    protected readonly fontSize: number
    protected readonly rotate: boolean
    protected readonly maxLabelWidth: number
    protected readonly labels: string[]
    protected readonly labelWidths: number[]
    protected readonly tickStrokeWidth = 2
    protected readonly tickColor = Colors.colors.grey.c300
    protected static readonly marginCoeff = 1.2
    protected readonly commonLabelPart = new OptionalString()
    protected readonly min: number
    protected readonly max: number
    protected readonly positiveAndNegative: boolean
    protected readonly absValueRange: number
    protected readonly absMax: number
    protected readonly absMin: number
    protected readonly zeroOffsetCoef: number

    public static readonly numAxisPartsBySize = (size: number | undefined) => (size ?? 200) > 100 ? 7 : 5

    get numericalValues(): ReadonlyArray<number> {
        return this.data.type === LabelDataType.NumericalRange || this.data.type === LabelDataType.NumericalCategories
            ? this.data.values
            : []
    }

    getFullLabel(index: number): string {
        return this.data.type === LabelDataType.NumericalCategories && this.data.includesNull
            ? (index === 0 ? U.nullText : STT.formatFullValue(this.data.stype, this.data.values[index - 1]))
            : STT.formatFullValue(this.data.stype, this.data.values[index])
    }

    static columnInfoToLabelData (columnInfo: ITableColumn | null | undefined, axisLength?: number, forLegend = false): LabelData {
        return columnInfo === null || columnInfo === undefined
        ? {
            type: LabelDataType.Categories,
            values: [],
            stype: SemanticType.Category
        }
        : STT.isCategorical(columnInfo.stype)
            ? {
                type: LabelDataType.Categories,
                values: [...columnInfo.categories.keys()],
                stype: SemanticType.Category
            }
            : columnInfo.summary.nullCount === columnInfo.summary.valueCount
                ? {
                    type: LabelDataType.NumericalCategories,
                    values: [],
                    stype: SemanticType.Number,
                    includesNull: true
                }
                : columnInfo.numberSummary.categories
                    ? {
                        type: LabelDataType.NumericalCategories,
                        values: (forLegend
                            ? [...U.def(columnInfo.numberSummary.categories.legendCategories).keys()]
                            : U.def(columnInfo.numberSummary.categories.axisCategories)),
                        stype: columnInfo.stype ?? SemanticType.Number,
                        includesNull: columnInfo.numberSummary.nullCount > 0
                    }
                    : {
                        type: LabelDataType.NumericalRange,
                        values: [...U.splitRange(columnInfo.numberSummary.min, columnInfo.numberSummary.max, BrickWithLabels.numAxisPartsBySize(axisLength))],
                        stype: columnInfo.stype ?? SemanticType.Number
                    }
    }

    protected constructor(params:Params,
                          protected readonly data:LabelData,
                          adjustLabelParams?:LabelParamAdjuster) {
        super(params)
        if (this.data.type === LabelDataType.NumericalCategories) {
            this.data.values = this.data.values.sort((a, b) => (a??0) - (b??0))
        }
        this.min = data.type === LabelDataType.NumericalRange || data.type === LabelDataType.NumericalCategories ? Math.min (...data.values) : 0
        this.max = data.type === LabelDataType.NumericalRange || data.type === LabelDataType.NumericalCategories ? Math.max (...data.values) : 0
        this.positiveAndNegative = this.min<0 && this.max>0
        this.absMax = Math.abs(this.max)
        this.absMin = Math.abs(this.min)
        this.absValueRange = this.absMax - this.absMin
        this.zeroOffsetCoef = this.absMax / (this.absMax + this.absMin)

        // build labels
        this.labels = STT.formatAxisLabels(data.stype, data.values, this.commonLabelPart)
        if (this.data.type === LabelDataType.NumericalCategories && this.data.includesNull) {
            this.labels.splice (0, 0, U.nullText)
        }

        if (adjustLabelParams !== undefined) {
            const {rotated, fontSize, widths} = adjustLabelParams(this.labels, VC.maxLabelFontSize)
            this.fontSize = fontSize
            this.rotate = rotated
            this.labelWidths = widths
        } else {
            this.fontSize = VC.maxLabelFontSize
            this.rotate = false
            this.labelWidths = ChartBrick.stringWidths(this.labels, this.fontSize)
        }
        this.maxLabelWidth = Math.max (0, ...this.labelWidths)
    }

    protected static adjustLabelColumnParams (startFontSize:number, labels:string[], height:number):LabelParams {
        let fontSize = startFontSize
        if (height < labels.length * fontSize * BrickWithLabels.marginCoeff) {
            fontSize = Math.max (VC.minFontSize, height / (labels.length * BrickWithLabels.marginCoeff))
        }
        return {
            fontSize,
            rotated:false,
            widths: ChartBrick.stringWidths(labels, fontSize)
        }
    }

    protected static adjustLabelRowParams(labels:string[], maxSpaceForALabel:number, startFontSize=maxLabelFontSize, minFontSize=minHorizXLabelFontSize):LabelParams {
        let rotated = true, widths:number[] = [], fontSize = startFontSize
        for (let currentSize = startFontSize; currentSize >= minFontSize; currentSize -= 0.2) {
            widths = ChartBrick.stringWidths(labels, currentSize)
            if (Math.max (0, ...widths) * BrickWithLabels.marginCoeff <= maxSpaceForALabel) {
                fontSize = currentSize
                rotated = false
                break
            }
        }
        if (rotated) {
            if (fontSize > maxSpaceForALabel) {
                fontSize = Math.floor(maxSpaceForALabel)
            }
            widths = ChartBrick.stringWidths(labels, fontSize)
        }
        return {rotated, fontSize, widths}
    }
}

abstract class Axis extends BrickWithLabels {
    protected readonly titleFontSize:number
    protected readonly titleMargin:number
    protected tickSize = 0
    protected tickMargin = 0
    protected title:string|undefined
    protected _tickOffsets:number[]|undefined

    protected constructor(params:Params, data:LabelData, adjustLabelParams?:LabelParamAdjuster, title?:string) {
        super(params, data, adjustLabelParams)
        this.title = title
        this.titleFontSize = Math.max (VC.minFontSize, this.fontSize * BrickWithLabels.marginCoeff)
        this.tickSize = this.fontSize / 2
        this.tickMargin = this.fontSize / 4
        this.titleMargin =  this.titleFontSize / 2
    }

    public get tickOffsets():number[] {
        if (this._tickOffsets !== undefined) {
            return this._tickOffsets
        } else {
            throw new Error ("tickOffsets are accessed before initialization (that tooks place usually during brick rendering)")
        }
    }

    public abstract valueToOffset (value:DataValue):number
}

export class YAxis extends Axis {

    constructor(params:Params, data:LabelData, height?:number, title?:string) {
        super(params, data,
            height === undefined ? undefined : (labels, startFontSize)=>BrickWithLabels.adjustLabelColumnParams (startFontSize, labels, height),
            title)
        this.place ({
            width: (this.title || this.commonLabelPart.value ? this.titleFontSize + this.titleMargin : 0) + this.maxLabelWidth + this.tickMargin + this.tickSize,
            height: height ?? this.fontSize * this.labels.length * BrickWithLabels.marginCoeff
        })
    }

    protected pushTitle (elements:JSX.Element[], left:number, top:number) {
        if (this.title !== undefined) {
            const titleY = top + this.height/2
            elements.push(<text key={this.p.sfx('yt')} x={left} y={titleY} fontSize={this.titleFontSize}
                                dominantBaseline="baseline" textAnchor="middle" fill="black"
                                transform={`rotate(-90 ${left + this.titleFontSize / 2}, ${titleY})`}>
                {this.title + (this.title && this.commonLabelPart.value ? ', ' : '') + (this.commonLabelPart.value ?? '')}
            </text>)
        }
    }

    protected pushTickAndLabel (elements:JSX.Element[], index:number, tickRight:number, tickY:number, label:string, category?:CategoryValue) {
        elements.push(<line key={this.p.sfx(index)} x1={tickRight} y1={tickY} x2={tickRight - this.tickSize} y2={tickY} strokeWidth={this.tickStrokeWidth} stroke={this.tickColor}/>)
        RC.pushLabel(LabelType.YAxis, elements, this.p.sfx('l'+index), label, new Rect (tickRight - this.tickSize - this.tickMargin - this.maxLabelWidth, tickY - this.fontSize / 2, this.maxLabelWidth, this.fontSize), this.fontSize, this.p.tooltips, label.endsWith(U.ellipsis) ? category : undefined)
    }

    render (left:number, top:number, height:number):JSX.Element[] {
        this.place ({x:left, y:top, height})
        const elements: JSX.Element[] = [<rect key={this.p.sfx('bg')} x={left} y={top} width={this.width} height={height} fill={"#FFFFFF00"}/>]
        switch (this.data.type) {
            case LabelDataType.NumericalRange:
                const valueToPixel = (this.max !== this.min ? height / (this.max - this.min) : 0),
                    values = this.data.values
                this._tickOffsets = this.labels.map((label, index) => U.fround(height - valueToPixel * (values[index] - this.min)))
                this.labels.forEach((label, index) => this.pushTickAndLabel(elements, index, left + this.width, top + this.tickOffsets[index], label))
                break
            case LabelDataType.NumericalCategories:
            case LabelDataType.Categories:
                const indexToPixel = height / Math.max(1, this.labels.length)
                this._tickOffsets = this.labels.map((category, index) => U.fround(height - indexToPixel * index - indexToPixel / 2))
                this.labels.forEach((category, index) => this.pushTickAndLabel(elements, index, left + this.width, top + this.tickOffsets[index], this.labels[index], category))
                break
        }
        this.pushTitle(elements, left, top)
        return elements
    }

    public valueToOffset (value:DataValue):number {
        switch (this.data.type) {
            case LabelDataType.NumericalRange:
                return typeof value === "number"
                    ? this.height - Math.floor (this.height / (this.max - this.min) * (value - this.min))
                    : 0
            case LabelDataType.NumericalCategories:
                return value === null
                    ? (this.data.includesNull ? this.tickOffsets[0] : 0)
                    : this.tickOffsets[U.findIndexOrThrow(this.data.values, value) + (this.data.includesNull ? 1 : 0)]
            case LabelDataType.Categories:
                return this.tickOffsets[U.findIndexOrThrow(this.data.values, value === null ? null : value.toString())]
        }
    }
}

export class XAxis extends Axis {
    protected readonly titleDelta:number

    constructor(params:Params, data:LabelData, width:number, title?:string) {
        super(params, data, (labels, startFontSize) =>
                BrickWithLabels.adjustLabelRowParams(labels, width / Math.max(1, STT.isCategorical(data.stype) ? labels.length : labels.length - 1), startFontSize),
            title)
        this.titleDelta = this.tickSize + this.tickMargin + (this.rotate ? this.maxLabelWidth : this.fontSize) + (this.title || this.commonLabelPart.value ? this.titleMargin : 0)
        this.place ({height:this.titleDelta + (this.title || this.commonLabelPart.value ? this.titleFontSize : 0)})
    }

    protected pushTitle (elements:JSX.Element[], left:number, top:number) {
        if (this.title !== undefined) {
            elements.push(<text key={this.p.sfx('xt')} x={left + this.width/2} y={top + this.titleDelta} fontSize={this.titleFontSize} dominantBaseline="hanging" textAnchor="middle" fill="black">
                {this.title + (this.title && this.commonLabelPart.value ? ', ' : '') + (this.commonLabelPart.value ?? '')}
            </text>)
        }
    }

    protected pushTickAndLabel (elements:JSX.Element[], index:number, tickX:number, tickTop:number, width:number, label:string, category?:CategoryValue) {
        elements.push(<line key={this.p.sfx(index)} x1={tickX} y1={tickTop} x2={tickX} y2={tickTop + this.tickSize} strokeWidth={this.tickStrokeWidth} stroke={this.tickColor}/>)
        RC.pushLabel(
            this.rotate ? LabelType.XAxisRotated : LabelType.XAxis,
            elements,
            this.p.sfx('l'+index),
            label,
            new Rect (tickX - width/2, tickTop + this.tickSize + this.tickMargin, width, this.fontSize),
            this.fontSize,
            this.p.tooltips,
            label.endsWith(U.ellipsis) ? category : undefined
        )
    }

    render (left:number, top:number, width:number):JSX.Element[] {
        this.place ({x:left, y:top, width})
        const elements: JSX.Element[] = [<rect key={this.p.sfx('bg')} x={left} y={top} width={width} height={this.height} fill={"#FFFFFF00"}/>]

        switch (this.data.type) {
            case LabelDataType.NumericalRange:
                const valueToPixel = (this.max !== this.min ? width / (this.max - this.min) : 0),
                    values = this.data.values
                this._tickOffsets = this.labels.map((label, index) => U.fround(valueToPixel * (values[index] - this.min)))
                this.labels.forEach((label, index) => this.pushTickAndLabel(elements, index, left + this.tickOffsets[index], top, valueToPixel, label))
                break
            case LabelDataType.NumericalCategories:
            case LabelDataType.Categories:
                const indexToPixel = width / Math.max(1, this.labels.length)
                this._tickOffsets = this.labels.map((category, index) => U.fround(indexToPixel * index + indexToPixel / 2))
                this.labels.forEach((category, index) => this.pushTickAndLabel(elements, index, left + this.tickOffsets[index], top, indexToPixel, category))
                break
        }

        this.pushTitle (elements, left, top)
        return elements
    }

    public valueToOffset (value:DataValue):number {
        switch (this.data.type) {
            case LabelDataType.NumericalRange:
                return typeof value === "number"
                    ? Math.floor (this.width / (this.max - this.min) * (value - this.min))
                    : 0
            case LabelDataType.NumericalCategories:
                return value === null
                    ? (this.data.includesNull ? this.tickOffsets[0] : 0)
                    : this.tickOffsets[U.findIndexOrThrow(this.data.values, value) + (this.data.includesNull ? 1 : 0)]
            case LabelDataType.Categories:
                return this.tickOffsets[U.findIndexOrThrow(this.data.values, value === null ? null : value.toString())]
        }
    }
}

abstract class ColoredBrickWithLabels extends BrickWithLabels {

    // includes NULL color at index=0 if data contain NULL
    protected readonly colors: StandardColor[]

    protected constructor(params:Params,
                          data:LabelData,
                          categoryColors?:StandardColor[],
                          adjustLabelParams?:LabelParamAdjuster) {
        super(params, data, adjustLabelParams)
        this.colors = (() => {
            switch (data.type) {
                case LabelDataType.NumericalRange:
                    const colorSteps = 100
                    return this.positiveAndNegative
                        ? Colors.getStandard3ColorRange(Colors.triGradientColorGreen, Colors.triGradientColorYellow, Colors.triGradientColorRed, Math.round(colorSteps * this.zeroOffsetCoef), colorSteps - Math.round(colorSteps * this.zeroOffsetCoef))
                        : Colors.getStandard2ColorRange(Colors.duGradientColorYellow, Colors.duGradientColorBlue, colorSteps)
                case LabelDataType.NumericalCategories:
                    const positiveAndZeroCount = Math.round(data.values.length * this.zeroOffsetCoef)
                    const colors = categoryColors ?? (this.positiveAndNegative
                        ? Colors.getStandard3ColorRange(Colors.triGradientColorGreen, Colors.triGradientColorYellow, Colors.triGradientColorRed, positiveAndZeroCount, data.values.length - positiveAndZeroCount)
                        : Colors.getStandard2ColorRange(Colors.duGradientColorYellow, Colors.duGradientColorBlue, data.values.length)
                    )
                    if (data.includesNull) {
                        colors.splice(0, 0, Colors.nullColor)
                    }
                    return colors
                case LabelDataType.Categories:
                    return categoryColors ?? U.times(data.values.length, i => Colors.getSeriesColor(i))
            }
        })()
    }

    public valueToColor (value:DataValue):StandardColor {
        switch (this.data.type) {
            case LabelDataType.NumericalCategories:
            case LabelDataType.Categories:
                if (value === null) {
                    return Colors.nullColor
                } else {
                    const index = (this.data.values as DataValue[]).indexOf(value) //.findIndex(cat => cat === value)
                    return index >= 0
                        ? this.colors[index + (this.data.type === LabelDataType.NumericalCategories && this.data.includesNull ? 1 : 0)]
                        : Colors.noValueColor
                }
            case LabelDataType.NumericalRange:
                return typeof value === "number"
                    ? this.positiveAndNegative
                        ? value > 0
                            ? this.colors[Math.round((this.colors.length - 1) * this.zeroOffsetCoef * (this.absMax - value) / this.absMax)]
                            : this.colors[Math.round((this.colors.length - 1) * (this.zeroOffsetCoef + (1 - this.zeroOffsetCoef) * -value / this.absMin))]
                        : this.colors[Math.round((this.colors.length - 1) * (this.absMax - Math.abs(value)) / this.absValueRange)]
                    : Colors.nullColor
        }
    }
}

export class VerticalLegend extends ColoredBrickWithLabels {
    protected readonly labelMargin:number
    protected readonly titleBottomMargin:number
    protected readonly legendTopMargin:number
    protected readonly title:string|undefined
    protected readonly titleWidth:number
    protected readonly boxSize: number
    protected readonly tickSize: number
    protected readonly lineHeight: number

    constructor(params:Params,
                data:LabelData,
                title?:string,
                height?:number,
                categoryColors?:StandardColor[],
                protected readonly categoryTooltips?:Map<CategoryValue|number, ITooltipRow[]>) {
        super(params, data, categoryColors, height === undefined
            ? undefined
            : (labels, startFontSize) => BrickWithLabels.adjustLabelColumnParams (startFontSize, labels, height)
        )
        this.labelMargin = this.fontSize / 2
        this.titleBottomMargin = this.fontSize * 0.3
        this.legendTopMargin = this.fontSize * 0.6
        this.title = title
        this.titleWidth = Math.max (
            0,
            this.title ? MTU.measureText(this.title, this.fontSize, VC.chartFontFamily) : 0,
            this.commonLabelPart.value ? MTU.measureText(this.commonLabelPart.value, this.fontSize, VC.chartFontFamily) : 0
        )
        this.boxSize = this.fontSize * BrickWithLabels.marginCoeff
        this.lineHeight = this.boxSize + this.fontSize / 2
        this.tickSize = this.fontSize / 3

        // set legend size
        switch (data.type) {
            case LabelDataType.NumericalRange:
                this.place({
                    width: Math.max (this.boxSize + this.tickSize + this.labelMargin + this.maxLabelWidth, this.titleWidth)
                })
                break
            case LabelDataType.NumericalCategories:
            case LabelDataType.Categories:
                this.place({
                    width: Math.max (this.boxSize + this.labelMargin + this.maxLabelWidth, this.titleWidth),
                    height: this.titleHeight + this.labels.length * this.lineHeight
                })
                break
        }
    }

    render (left:number, top:number, height?:number):JSX.Element[] {
        this.place({x:left, y:top})

        if (height !== undefined) {
            this.place({height})
        }

        const elements:JSX.Element[] = [<rect key={this.p.sfx('bg')} x={left} y={top} width={this.width} height={height} fill={"#FFFFFF00"}/>],
            labelX = this.data.type === LabelDataType.NumericalRange
                ? left + this.boxSize + this.tickSize + this.labelMargin
                : left + this.boxSize + this.labelMargin

        // title
        this.pushTitle(elements, left, top)
        top += this.titleHeight +  this.legendTopMargin

        // legend
        switch (this.data.type) {
            case LabelDataType.Categories:
            case LabelDataType.NumericalCategories:
                this.labels.forEach((label: string, index:number) => {
                    const y = top + (this.labels.length - 1 - index) * this.lineHeight

                    // box
                    const boxId = this.p.sfx('b' + index)
                    elements.push(<rect key={boxId} id={boxId} x={left} y={y} width={this.boxSize} height={this.boxSize}
                                        stroke={this.colors[index].dark}
                                        fill={this.colors[index].normal}/>)

                    // tooltip
                    const category = this.data.type === LabelDataType.NumericalCategories && this.data.includesNull
                            ? (index === 0 ? null : this.data.values[index - 1])
                            : this.data.values[index]

                    if (this.categoryTooltips !== undefined && this.categoryTooltips.has(category)) {
                        this.p.tooltips.set(boxId, new Tooltip({
                            x: left + this.boxSize / 2,
                            y: y + this.boxSize / 2
                        }, U.get(this.categoryTooltips, category)))
                    }

                    // label
                    pushLabel(LabelType.Legend, elements, this.p.sfx(index), label, new Rect(labelX, y, this.width, this.boxSize), this.fontSize, this.p.tooltips,
                        this.commonLabelPart.value || label.endsWith(U.ellipsis) ? this.getFullLabel(index) : undefined)
                })
                break

            case LabelDataType.NumericalRange:
                // gradient
                const gradientId = this.p.sfx('g'),
                    boxHeight = (height ?? this.titleHeight + 100) - this.titleHeight - this.legendTopMargin

                this.p.defs.push(
                    <linearGradient key={gradientId} id={gradientId} x1="0" x2="0" y1="0" y2="1">
                        <stop offset="0%"
                              stopColor={this.positiveAndNegative ? Colors.triGradientColorGreen.normal : Colors.duGradientColorYellow.normal}/>
                        {
                            this.positiveAndNegative &&
                            <stop
                                offset={`${Math.round(this.zeroOffsetCoef * 100)}%`}
                                stopColor={Colors.triGradientColorYellow.normal}/>
                        }
                        <stop offset="100%"
                              stopColor={this.positiveAndNegative ? Colors.triGradientColorRed.normal : Colors.duGradientColorBlue.normal}/>
                    </linearGradient>
                )

                // box
                elements.push (<rect key={this.p.sfx('b')} x={left} y={top} width={this.boxSize} height={boxHeight} fill={`url(#${gradientId})`} strokeWidth={this.tickStrokeWidth} stroke={this.tickColor}/>)

                // ticks & labels
                const valueToPixel = this.max === this.min ? boxHeight : boxHeight / (this.max - this.min)
                for (let i = 0; i < this.data.values.length; i++) {
                    const y = top + boxHeight - (this.data.values[i] - this.min) * valueToPixel
                    elements.push(<line key={this.p.sfx('t' + i)} x1={left + this.boxSize} y1={y}
                                        x2={left + this.boxSize + this.tickSize} y2={y} strokeWidth={this.tickStrokeWidth}
                                        stroke={this.tickColor}/>)
                    pushLabel(LabelType.Legend, elements, this.p.sfx('l' + i), this.labels[i], new Rect(labelX, y - this.fontSize / 2, this.width - this.boxSize - this.tickSize - this.labelMargin, this.fontSize), this.fontSize, this.p.tooltips,
                        this.commonLabelPart.value || this.labels[i].endsWith(U.ellipsis) ? this.getFullLabel(i) : undefined)
                }
                break
        }

        return elements
    }

    protected get titleHeight () {
        return (this.title ? this.fontSize + this.titleBottomMargin : 0) + (this.commonLabelPart.value ? this.fontSize : 0)
    }

    protected pushTitle (elements:JSX.Element[], x:number, y:number) {
        if (this.title) {
            elements.push(<text key={this.p.sfx('t')} x={x} y={y} fontSize={this.fontSize} fontWeight={500}
                                dominantBaseline="hanging" textAnchor="start" fill="black">{this.title}</text>)
        }
        if (this.commonLabelPart.value) {
            elements.push(<text key={this.p.sfx('c')} x={x} y={y + this.fontSize + this.titleBottomMargin}
                                fontSize={this.fontSize} fontWeight={400}
                                dominantBaseline="hanging" textAnchor="start" fill="black">{this.commonLabelPart.value}</text>)
        }
    }
}

export class CategoricalHorizontalLegend extends ColoredBrickWithLabels {
    protected readonly labelMargin:number
    protected readonly itemMargin:number
    protected readonly boxSize: number
    protected readonly lineHeight: number

    constructor(params:Params,
                data:{stype:SemanticType} & Categories,
                width:number,
                categoryColors?:StandardColor[],
                protected readonly categoryTooltips?:Map<CategoryValue|number, ITooltipRow[]>) {
        super(params, data, categoryColors)
        this.labelMargin = this.fontSize / 2
        this.itemMargin = this.fontSize
        this.boxSize = this.fontSize * BrickWithLabels.marginCoeff
        this.lineHeight = this.boxSize + this.fontSize / 2
        this.place({width})
    }

    render (left:number, top:number):JSX.Element[] {
        this.place({x:left, y:top})

        const elements:JSX.Element[] = []

        let y = top, curLabelIndex = 0, curLineWidth = 0, indexOfFirstLabelInLine = 0
        while (this.labelWidths.length) {
            const newWidth = curLabelIndex < this.labelWidths.length
                ? (curLineWidth === 0 ? 0 : curLineWidth + this.itemMargin) + this.boxSize + this.labelMargin + this.labelWidths[curLabelIndex]
                : Infinity

            if (newWidth > this.width && curLineWidth > 0) {
                // create elements for the current line
                let x = (this.width - curLineWidth) / 2 + left
                for (let i=indexOfFirstLabelInLine; i<curLabelIndex; i++) {

                    // box
                    const boxId = this.p.sfx('b' + i),
                        value = this.data.values[i],
                        label = this.labels[i]
                    elements.push(<rect key={boxId} id={boxId} x={x} y={y} width={this.boxSize} height={this.boxSize}
                                        stroke={this.colors[i].dark} fill={this.colors[i].normal}/>)

                    x += this.boxSize + this.labelMargin

                    // tooltip
                    if (this.categoryTooltips !== undefined && this.categoryTooltips.has(value)) {
                        this.p.tooltips.set(boxId, new Tooltip({
                            x: left + this.boxSize / 2,
                            y: y + this.boxSize / 2
                        }, U.get(this.categoryTooltips, value)))
                    }

                    // label
                    pushLabel(LabelType.Legend, elements, this.p.sfx(i), label, new Rect(x, y, this.labelWidths[i], this.boxSize), this.fontSize, this.p.tooltips,
                        this.commonLabelPart.value || label.endsWith(U.ellipsis) ? this.getFullLabel(i) : undefined)

                    x += this.labelWidths[i] + this.itemMargin
                }

                y += this.lineHeight

                // start the new line if necessary
                if (curLabelIndex < this.labelWidths.length) {
                    indexOfFirstLabelInLine = curLabelIndex
                    curLineWidth = 0
                } else {
                    break
                }
            } else {
                curLineWidth = newWidth
                curLabelIndex += 1
            }
        }

        this.place({height:y - top})
        return elements
    }
}

export class PieChart extends ChartBrick{
    constructor(params:Params, protected readonly data:PieChartData, protected readonly categoryTooltips:Map<CategoryValue, ITooltipRow[]>) {
        super(params)
    }

    render (left:number, top:number, diameter:number):JSX.Element[] {
        const radius = diameter / 2, cx = left + radius, cy = top + radius
        this.place ({x:cx-radius, y:cy-radius, width:radius*2, height:radius*2})
        const elements:JSX.Element[] = [],
            data = this.data,
            singleton:(typeof data[0]) | undefined = [...this.data.values()].filter(d => d.percent > 1 - 1e-6)[0],
            strokeWidth = radius > 250 ? 2 : 1,
            strokeColor = 'white'
        let lastX = cx + radius, lastY = cy, lastPercent = 0, n = 0
        if (singleton) {
            // one value is ~100%, draw a circle
            const segmentId = 'circle'
            elements.push(<circle id={segmentId} key={segmentId} className={css.lighterOnHover}
                                  cx={cx} cy={cy} r={radius}
                                  strokeWidth={strokeWidth} stroke={strokeColor}
                                  fill={singleton.category.color.normal} />)

            // tooltip
            this.p.tooltips.set(segmentId, new Tooltip({x: cx, y: cy}, U.get(this.categoryTooltips, singleton.category.value)))
        } else {
            // draw segments
            for (const d of data) {
                // segment
                const segmentId = 'segment_' + n
                const x = Math.cos(2 * Math.PI * (d.percent + lastPercent)) * radius + cx,
                    y = cy - Math.sin(2 * Math.PI * (d.percent + lastPercent)) * radius,
                    largeArc = d.percent > 0.5 ? 1 : 0

                elements.push(<path id={segmentId} key={segmentId} className={css.lighterOnHover}
                                    strokeWidth={strokeWidth} stroke={strokeColor} fill={d.category.color.normal}
                                    d={`M ${cx} ${cy} L ${lastX} ${lastY} A ${radius} ${radius} 0 ${largeArc} 0 ${x} ${y} Z`} />)

                // tooltip
                this.p.tooltips.set(segmentId, new Tooltip({
                    x: Math.cos(2 * Math.PI * (d.percent / 2 + lastPercent)) * radius / 2 + cx,
                    y: cy - Math.sin(2 * Math.PI * (d.percent / 2 + lastPercent)) * radius / 2
                }, U.get(this.categoryTooltips, d.category.value)))

                // save last segment point and percent
                lastX = x
                lastY = y
                lastPercent += d.percent

                // category counter
                n += 1
            }
        }
        return elements
    }
}

export class Grid extends ChartBrick{

    constructor(params:Params, protected readonly vertLineDX:number[], protected readonly horizLineDY:number[], width:number, height:number, protected readonly withBorder:boolean) {
        super(params)
        this.place({width, height})
    }

    render (left:number, top:number):JSX.Element[] {
        this.place({x:left, y:top})
        const elements = [<path key={this.p.sfx('g')} d={[
            ...this.vertLineDX.map(dx => `M ${left + dx},${top} v ${this.height}`),
            ...this.horizLineDY.map(dy => `M ${left},${top + dy} h ${this.width}`)
        ].join(' ')} stroke={this.gridColor} strokeWidth={this.gridStrokeWidth} />]

        if (this.withBorder) {
            elements.push(<rect key={this.p.sfx('b')} x={left} y={top} width={this.width} height={this.height}
                                fill="none" stroke={this.borderColor} strokeWidth={this.borderStrokeWidth} />)
        }

        return elements
    }
}

export class BoxWithWhiskers extends ChartBrick{
    protected readonly strokeWidth = 2
    protected readonly boxMargin:number
    protected readonly boxHeight:number
    protected readonly boxTickHeight:number
    protected readonly outlierRadius:number

    constructor(params:Params,
                readonly summary:INumberSummary,
                protected readonly color:StandardColor,
                protected readonly valToPixel:(value:number)=>number,
                width:number,
                height:number,
                protected readonly stype:SemanticType,
                protected testMarker?:TestMarker) {
        super(params)
        this.place({width, height})
        this.boxHeight = height / 2
        this.boxMargin = (height - this.boxHeight) / 2
        this.boxTickHeight = this.boxHeight / 2
        this.outlierRadius = this.boxHeight / 6
    }

    render (left:number, top:number, size?:number, clipPath?:ClipPath):JSX.Element[] {
        if (size !== undefined) {
            throw new Error ("Size argument is not used here")
        }

        const whiskerTickY1 = top + this.boxMargin + this.boxHeight / 2 - this.boxTickHeight / 2,
            whiskerTickY2 = top + this.boxMargin + this.boxHeight / 2 + this.boxTickHeight / 2,
            whiskerY = top + this.boxMargin + this.boxHeight / 2,
            whiskerTickX1 = left + this.valToPixel(this.summary.whiskerMin),
            whiskerTickX2 = left + this.valToPixel(this.summary.whiskerMax),
            boxX = left + this.valToPixel(this.summary.q1),
            boxY = top + this.boxMargin,
            boxWidth = this.valToPixel(this.summary.q3) - boxX + left,
            medianX = left + this.valToPixel(this.summary.median),
            boxId = this.p.sfx('b')
        this.p.tooltips.set (boxId, new Tooltip({x: boxX + boxWidth / 2, y: boxY}, [
            new TooltipRow(this.summary.whiskerMax, 'max', this.stype),
            new TooltipRow(this.summary.q3, 'q3', this.stype),
            new TooltipRow(this.summary.median, 'median', this.stype),
            new TooltipRow(this.summary.mean, 'mean', this.stype),
            new TooltipRow(this.summary.q1, 'q1', this.stype),
            new TooltipRow(this.summary.whiskerMin, 'min', this.stype)
        ]))
        const wiskerId1 = this.p.sfx('w1')
        const wiskerId2 = this.p.sfx('w2')
        const boxTooltip = U.get(this.p.tooltips, boxId)
        this.p.tooltips.set (wiskerId1, boxTooltip)
        this.p.tooltips.set (wiskerId2, boxTooltip)

        return [<g key={this.p.sfx('bp')} fill={this.color.light} stroke={this.color.dark} strokeWidth={this.strokeWidth} clipPath={clipPath?.value}>
                <line key={wiskerId1+'_t1'} x1={whiskerTickX1} x2={whiskerTickX1} y1={whiskerTickY1} y2={whiskerTickY2}/>
                <line key={wiskerId1+'_t2'} x1={whiskerTickX2} x2={whiskerTickX2} y1={whiskerTickY1} y2={whiskerTickY2}/>
                <line key={wiskerId1} id={wiskerId1} x1={whiskerTickX1} x2={boxX} y1={whiskerY} y2={whiskerY}/>
                <line key={wiskerId2} id={wiskerId2} x1={boxX + boxWidth} x2={whiskerTickX2} y1={whiskerY} y2={whiskerY}/>
                <rect key={boxId} id={boxId} className={css.lighterOnHover} x={boxX} y={boxY} width={boxWidth} height={this.boxHeight} data-test={this.testMarker}/>
                <line key={boxId+'m'} x1={medianX} y1={boxY} x2={medianX} y2={boxY + this.boxHeight} style={{pointerEvents: "none"}}/>
                {
                    this.summary.outliers.map((o, i) => {
                        const outlierId = this.p.sfx('o'+i),
                            cx = left + this.valToPixel(o),
                            cy = whiskerY
                        this.p.tooltips.set (outlierId, new Tooltip({
                            x: cx,
                            y: cy
                        }, [new TooltipRow(o, 'outlier', this.stype)]))
                        return <circle key={boxId+'_o'+i} id={outlierId} cx={cx} cy={cy} r={this.outlierRadius}/>
                    })
                }
            </g>]

    }
}

export class Spline extends ChartBrick{
    protected readonly strokeWidth = 2
    protected readonly backWidth = 4
    protected readonly backColor = Colors.colors.grey.c50

    constructor(params:Params, readonly points:Point[], protected readonly color:StandardColor) {
        super(params)
    }

    render (left:number, top:number, size?:number, clipPath?:ClipPath):JSX.Element[] {
        if (size !== undefined) {
            throw new Error ("Size argument is not used here")
        }

        const path = RC.makePath(this.points)
        return [
            <g key={this.p.sfx('g')} clipPath={clipPath?.value}>
                <path key={this.p.sfx('b')} d={path} fill="none" stroke={this.backColor} strokeWidth={this.backWidth} />,
                <path key={this.p.sfx('f')} d={path} fill="none" stroke={this.color.normal} strokeWidth={this.strokeWidth} />
            </g>
        ]
    }
}

export class Histogram extends ChartBrick{
    protected readonly strokeWidth = 2

    constructor(params:Params,
                protected readonly values:number[],
                protected readonly color:StandardColor,
                protected readonly tooltipRows:ITooltipRow[][],
                protected readonly maxValue:number,
                width:number,
                height:number) {
        super(params)
        this.place ({width, height})
    }

    render (left:number, top:number):JSX.Element[] {
        const binWidth = this.width / Math.max (this.values.length, 1),
            freqToHeight = this.height / this.maxValue
        return [<g key={this.p.sfx('g')} fill={this.color.light} stroke={this.color.dark} strokeWidth={this.strokeWidth}>{
            this.values.map((v,b) => {
                if (v) {
                    const id = this.p.sfx('b' + b)
                    this.p.tooltips.set(id, new Tooltip({
                            x: (b + 0.5) * binWidth + left,
                            y: top + this.height - v * freqToHeight
                        }, this.tooltipRows[b]
                    ))
                    return <rect key={b}
                                 id={id}
                                 className={css.lighterOnHover}
                                 x={b * binWidth + left}
                                 y={top + this.height - v * freqToHeight}
                                 width={binWidth}
                                 height={v * freqToHeight} />
                } else {
                    return undefined
                }
            })
        }</g>]
    }
}

export class Title extends ChartBrick{
    protected readonly color = Colors.colors.grey.c900

    constructor(params:Params,
                readonly text:string,
                width:number,
                height:number) {
        super(params)
        this.place ({width, height})
    }

    render (left:number, top:number):JSX.Element[] {
        return [<text key={this.p.sfx('')}
                      x={left + this.width/2}
                      y={top + this.height/2}
                      fontSize={Math.max(this.height/2, VC.minFontSize)}
                      dominantBaseline="middle"
                      textAnchor="middle"
                      fontWeight={500}
                      color={this.color}>
            {this.text}
        </text>]
    }
}

export interface ScatterPlotDot {
    dx: number,
    dy: number,
    radius: number,
    color: string,
    tooltipRows: (ITooltipRow|undefined)[],
    testMarker?: TestMarker
}

export interface ScatterPlotLine {
    dotIndices: number[],
    color?: StandardColor
}

export class ScatterPlot extends ChartBrick{
    protected readonly pathColor = Colors.defaultPlotColor.light

    constructor(params:Params,
                width:number,
                height:number,
                protected readonly dots:ScatterPlotDot[],
                protected readonly dotOpacity:number,
                protected readonly lines:ScatterPlotLine[]=[],
                protected readonly whiteBorderAroundDot=false) {
        super(params)
        this.place ({width, height})
    }

    protected linePathData = (left:number, top:number, line:ScatterPlotLine):string => {
        const d:string[] = []
        line.dotIndices.forEach((index, order) => {
            d.push (order===0 ? "M" : "L")
            d.push ((this.dots[index].dx + left).toString())
            d.push ((this.dots[index].dy + top).toString())
        })
        return d.join(' ')
    }

    render (left:number, top:number):JSX.Element[] {
        const clipPathId = this.p.sfx('cp'),
            borderId = this.p.sfx('b'),
            dotId = this.p.sfx('d')
        this.dots.forEach((dot, i) => this.p.tooltips.set (dotId+i, new Tooltip({x:left+dot.dx, y:top+dot.dy}, dot.tooltipRows)))
        return [
                <rect key={borderId} id={borderId} x={left} y={top} width={this.width} height={this.height} strokeWidth={this.borderStrokeWidth} fill="none" stroke={this.borderColor}/>,
                <clipPath key={clipPathId} id={clipPathId}>
                    <use xlinkHref={"#"+borderId} />
                </clipPath>,
                <g key={this.p.sfx('g')} clipPath={`url(#${clipPathId})`}>
                    {this.lines.map ((line,index) => <path key={this.p.sfx('l'+index)}
                        d={this.linePathData(left, top, line)}
                        strokeWidth={Math.ceil(this.width / 250)}
                        stroke={line.color === undefined ? this.pathColor : line.color.light}
                        fill="none"/>)
                    }
                    {this.dots.map ((dot, i) =>
                        <circle key={dotId+i} id={dotId+i} cx={left+dot.dx} cy={top+dot.dy} r={dot.radius}
                                fill={dot.color} strokeWidth={this.whiteBorderAroundDot ? dot.radius / 5 : 0}
                                stroke={"white"} opacity={this.dotOpacity}
                                data-test={dot.testMarker}/>
                        )}
                </g>
        ]
    }
}
