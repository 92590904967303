// https://github.com/que-etc/resize-observer-polyfill
import React, {RefObject} from 'react'
import css from './TableGrid.module.scss'
import * as U from '../Utils'
import * as MTU from '../MainThreadUtils'
import ResizeObserver from 'resize-observer-polyfill'
import {ColumnUIIndex} from "../Concepts/Basic"
import {CellValue, ColumnColoring, ColumnColoringMode, DataRow} from "./TableGrid"
import * as Colors from "../Colors"
import event from "../EventSender/Events"
import {TM} from "../@testing/TestMarker"
import {IDataTable} from "../Concepts/DataTable"

const DEFAULT_ROW_HEIGHT = 50,
      MOUSE_SCROLL_DEBOUNCE_TIME = 100,
      TOUCH_SCROLL_DEBOUNCE_TIME = 50,
      TOUCH_END_TIMEOUT = 200,
      RESIZE_DEBOUNCE_TIME = 100;

interface Props {
    table: IDataTable
    columnConfigSpanshot: string
    getRows: (start:number, end:number) => DataRow[]
    rowCount: number
    hoveredColumn: number | null
    scrollColumns: number
    cacheVer: number
    columnWidthChange: {column: number | null, width: number}
    onColumnHover: (columnIndex:ColumnUIIndex | null) => void
    onColumnSelect: (columnIndex:ColumnUIIndex, exclusive:boolean) => void
    nonPinnedColumns: number
    columnColoring: ColumnColoring
    listenKeyboard: boolean
    userSettingVersion: number
}

interface State {
    table?: IDataTable
    offset: number
    startRow: number
    scrollThumbPos: number
    scrollThumbHeight: number
    scrollVisible: boolean
    isScrolling: boolean
}

export default class TableGridBody extends React.PureComponent<Props, State> {
    override readonly state:State = {
        offset: 0,
        startRow: 0,
        scrollThumbPos: 0,
        scrollThumbHeight:0,
        scrollVisible:false,
        isScrolling:false,
    }

    protected bodyRef: RefObject<HTMLDivElement> = React.createRef()
    protected rowHeight = DEFAULT_ROW_HEIGHT
    protected bodyHeight = 900
    protected scrollBarHeight = 890
    protected scrollDragStartY = 0
    protected scrollDragStartOffset = 0
    protected scrollDragStartRow = 0
    protected scrollDragLastTime = 0
    protected lastReportedStartRow = 0
    protected isTouchScrolling = false
    protected touchEndTime: number | null = null
    protected observer: ResizeObserver | null = null

    static getDerivedStateFromProps(props:Props, state:State):State | null {
        if (props.table !== state.table) {
            return {
                table: props.table,
                offset: 0,
                startRow: 0,
                scrollThumbPos: 0,
                scrollThumbHeight: 0,
                scrollVisible:false,
                isScrolling:false,
            }
        }
        else {
            return null
        }
    }

    protected dataColumn = (target: EventTarget): ColumnUIIndex | null => {
        const dataColumn = (target as HTMLDivElement).getAttribute('data-colindex')
        return dataColumn !== null ? ColumnUIIndex(U.parseIntNotNan(dataColumn)) : null
    }

    protected updateScrollPos (newOffset:number = this.state.offset, newStartRow:number = this.state.startRow) {
        let newScrollThumbPos = 0, newScrollThumbHeight = 15, newScrollVisible = false;

        if (!this.rowHeight || !this.bodyHeight || !this.props.rowCount) {
            newOffset = 0;
            newStartRow = 0;
        } else {
            const rowsPerPage = Math.floor(this.bodyHeight / this.rowHeight);

            if (newOffset > this.rowHeight) {
                // keep offset small by increasing the start row
                const rowsToRemove = Math.floor(newOffset / this.rowHeight);
                if (rowsToRemove > 1) {
                    newOffset -= rowsToRemove * this.rowHeight;
                    newStartRow += rowsToRemove;
                }
            } else {
                // keep offset bigger than row's height decreasing the start row
                const rowsToAdd = Math.min(Math.ceil(Math.abs(newOffset / this.rowHeight - 1)), newStartRow);
                if (rowsToAdd) {
                    newOffset += rowsToAdd * this.rowHeight;
                    newStartRow -= rowsToAdd;
                }
            }

            const rowCount = this.props.rowCount;

            // prevent scrolling if the end of the table is reached
            if (Math.floor((newOffset + this.bodyHeight) / this.rowHeight) + newStartRow > rowCount) {
                newStartRow = Math.max(rowCount - rowsPerPage, 0);
                newOffset = (rowCount - newStartRow) * this.rowHeight - this.bodyHeight;
            }

            newOffset = Math.max(Math.round(newOffset), 0);
            newScrollVisible = rowCount > rowsPerPage;
            newScrollThumbHeight = Math.max(Math.round(this.scrollBarHeight / ((rowCount / (rowsPerPage || 1)) || 1)), 15);
            newScrollThumbPos = Math.round((this.scrollBarHeight - newScrollThumbHeight) * (newStartRow + newOffset / this.rowHeight) / ((rowCount - rowsPerPage) || -1));

            if (newStartRow !== this.lastReportedStartRow) {
                event.table.rows.scroll(newStartRow, this.props.rowCount, rowsPerPage)
                this.lastReportedStartRow = newStartRow
            }
        }

        this.setState({
            offset: newOffset,
            startRow: newStartRow,
            scrollThumbPos: newScrollThumbPos,
            scrollThumbHeight: newScrollThumbHeight,
            scrollVisible: newScrollVisible
        });
    }

    protected handleWheel = (event:React.WheelEvent) => {
        let delta = event.deltaY;
        if (event.deltaMode === 1) // The delta values are specified in lines
            delta *= this.rowHeight;
        else if (event.deltaMode === 2) // The delta values are specified in pages
            delta *= this.bodyHeight;
        this.updateScrollPos(this.state.offset+delta);
    }

    protected handleKeyDown = (event:KeyboardEvent) => {
        if (this.props.listenKeyboard && !event.altKey && !event.ctrlKey && !event.shiftKey) {
            const delta = {
                ArrowUp: -this.rowHeight,
                ArrowDown: this.rowHeight,
                PageUp: -this.bodyHeight,
                PageDown: this.bodyHeight,
            }[event.key];

            if (delta) {
                event.preventDefault();
                event.stopPropagation();
                this.updateScrollPos(this.state.offset + delta);
            } else if (event.key === 'Home') {
                this.updateScrollPos(0, 0);
            } else if (event.key === 'End') {
                this.updateScrollPos(this.rowHeight * this.props.rowCount - this.bodyHeight, 0);
            }
        }
    }

    protected updateElementSizes() {
        // noinspection SillyAssignmentJS
        [
            this.rowHeight = 0,
            this.bodyHeight = this.bodyHeight,
            this.scrollBarHeight = this.scrollBarHeight
        ] = [css.row, css.body, css.scrollBar]
            .map (cls => document.getElementsByClassName(cls)[0])
            .map (element => element ? element.clientHeight : undefined);

        if (this.rowHeight === 0) {
            this.rowHeight = DEFAULT_ROW_HEIGHT;
        }

        //console.log ({bodyHeight: this.bodyHeight, rowHeight: this.rowHeight, scrollBarHeight: this.scrollBarHeight });
        this.updateScrollPos()
    }

    protected handleScrollThumbMouseDown = (event:React.MouseEvent | React.TouchEvent) => {
        this.scrollDragStartY = event.nativeEvent instanceof TouchEvent ? (event as React.TouchEvent).touches[0].clientY : (event as React.MouseEvent).clientY;
        this.scrollDragStartOffset = this.state.offset;
        this.scrollDragStartRow = this.state.startRow;
        this.setState ( {isScrolling: true});
        this.scrollDragLastTime = Date.now();
    }

    protected handleRowsTouchStart = (event:React.TouchEvent) => {
        this.scrollDragStartY = event.touches[0].clientY;
        this.scrollDragStartOffset = this.state.offset;
        this.scrollDragStartRow = this.state.startRow;
        this.isTouchScrolling = true;
        this.touchEndTime = null;
    }

    protected handleDocumentMouseMove = (event:MouseEvent | TouchEvent) => {
        if (this.state.isScrolling) {
            if (event instanceof MouseEvent && event.buttons !== 1)
                this.setState ( {isScrolling: false});
            else if (event instanceof MouseEvent || (event instanceof TouchEvent && event.touches)) {
                const clientY = (event instanceof TouchEvent ? event.touches[0] : event).clientY;
                if (event instanceof MouseEvent) {
                    event.preventDefault();
                }
                event.stopPropagation();
                if (Date.now() - this.scrollDragLastTime > MOUSE_SCROLL_DEBOUNCE_TIME) {
                    const rowsPerPage = Math.floor(this.bodyHeight / this.rowHeight);
                    const delta = (this.props.rowCount - rowsPerPage) * this.rowHeight * ((clientY - this.scrollDragStartY) / ((this.scrollBarHeight - this.state.scrollThumbHeight) || 1));
                    this.scrollDragLastTime = Date.now();
                    this.updateScrollPos(this.scrollDragStartOffset + delta, this.scrollDragStartRow);
                }
            }
        } else if (this.isTouchScrolling && event instanceof TouchEvent && event.touches && Date.now() - this.scrollDragLastTime > TOUCH_SCROLL_DEBOUNCE_TIME) {
            this.updateScrollPos(this.scrollDragStartOffset + this.scrollDragStartY - event.touches[0].clientY, this.scrollDragStartRow);
            this.scrollDragLastTime = Date.now();
        }
    }

    protected handleTouchEnd = () => {
        if (this.state.isScrolling) {
            this.setState({isScrolling: false});
        }
        this.isTouchScrolling = false;
        this.props.onColumnHover(null);
        this.touchEndTime = Date.now();
    }

    protected handleCellClick = (event:React.MouseEvent) => {
        const isTouch = this.touchEndTime && Date.now() - this.touchEndTime < TOUCH_END_TIMEOUT;
        if (event.target !== null) {
            const dataColumn = this.dataColumn(event.target)
            if (dataColumn !== null) {
                this.props.onColumnSelect(dataColumn, !event.shiftKey && !event.ctrlKey && !isTouch);
            }
        }
    }

    protected handleScrollBarClick = (event:React.MouseEvent) => {
        const thumbRect = document.getElementsByClassName(css.scrollThumb)[0].getBoundingClientRect();
        if (event.clientY < thumbRect.top || event.clientY > thumbRect.bottom) {
            const sign = Math.sign(event.clientY - thumbRect.top);
            this.updateScrollPos(this.scrollDragStartOffset + sign * this.bodyHeight);
            event.preventDefault();
            event.stopPropagation();
        }
    }

    override componentDidMount() {
        this.observer = new ResizeObserver(MTU.debounce(this.updateElementSizes.bind(this), RESIZE_DEBOUNCE_TIME).bind(this));

        if (U.mustNotBeNull(this.bodyRef.current)) {
            this.observer.observe(this.bodyRef.current);
        }

        document.addEventListener('mousemove', this.handleDocumentMouseMove, true);
        document.addEventListener("touchmove", this.handleDocumentMouseMove, false);
        document.addEventListener("touchend", this.handleTouchEnd, false);
        document.addEventListener('keydown', this.handleKeyDown, true);
    }

    override componentWillUnmount() {
        if (U.mustNotBeNull(this.observer)) {
            this.observer.disconnect();
        }
        document.removeEventListener('mousemove', this.handleDocumentMouseMove, true);
        document.removeEventListener('touchmove', this.handleDocumentMouseMove, true);
        document.removeEventListener("touchend", this.handleTouchEnd, false);
        document.removeEventListener('keydown', this.handleKeyDown, true);
    }

    override render() {
        const bodyCells = []
        const rowsPerPage = Math.ceil(this.bodyHeight / this.rowHeight) + 2

        const rows = this.props.getRows(this.state.startRow, this.state.startRow + rowsPerPage),
            columns = this.props.table.filteredColumnsByUiIndex(()=>true)

        for (let j = 0; j < rowsPerPage; j++) {
            const row = rows[j]
            if (row === undefined) {
                break
            }

            const rowCells = [],
                  loading = !Array.isArray(row)
            let   colsToScroll = this.props.scrollColumns,
                  nonPinned=0

            for (let i = 0; i < columns.length; i++) {
                const col = columns[i]
                const isColumnHovered = i === this.props.hoveredColumn && this.props.columnWidthChange.column === null
                if ((col.pinned || (colsToScroll -= 1) < 0) && nonPinned <= this.props.nonPinnedColumns) {
                    nonPinned += col.pinned ? 0 : 1;
                    const color = loading || !col.selected || this.props.columnColoring.mode === ColumnColoringMode.None
                        ? Colors.noValueColor.normal
                        : this.props.columnColoring.mode === ColumnColoringMode.ByColumnColor
                            ? (
                                this.props.columnColoring.excludeColumnIds !== undefined && this.props.columnColoring.excludeColumnIds.indexOf(col.id) >=0
                                    ? Colors.noValueColor.normal
                                    : col.color.dark
                            )
                            : columns[i].id === this.props.columnColoring.categoryColumnId
                                ? (row as CellValue[])[i].color || Colors.noValueColor.normal
                                : Colors.noValueColor.normal

                    rowCells.push(<div key={columns[i].id}
                                       style={{
                                           width: this.props.columnWidthChange.column === i ? this.props.columnWidthChange.width : col.width,
                                           color
                                       }}
                                       className={U.cls(
                                           css.bodyCell, true,
                                           css.selectedColumn, col.selected && !isColumnHovered,
                                           css.hoveredSelectedColumn, col.selected && isColumnHovered,
                                           css.pinnedColumn, col.pinned && !col.selected && !isColumnHovered,
                                           css.hoveredColumn, isColumnHovered
                                       )}
                                       data-colindex={i}
                                       data-test={j === 0 ? TM.firstRowTableCell(i) : undefined}
                                       onClick={this.handleCellClick}
                    >{loading ? <div className={css.loader}/> : ((row as CellValue[])[i].str || U.nbsp)}</div>)

                    const fullHeightColumnDivider = this.props.columnWidthChange.column === i
                        || (col.pinned && i < columns.length - 1 && !columns[i + 1].pinned)

                    rowCells.push(<div key={columns[i].id + '_'}
                                       className={U.cls(
                                           css.columnDivider, true,
                                           css.nonSelectable, true,
                                           css.pinnedColumn, col.pinned && !fullHeightColumnDivider,
                                           css.fullHeightColDivider, fullHeightColumnDivider
                                       )}
                                       data-colindex={i}
                    >&nbsp;</div>)
                }
            }
            const rowId = this.state.startRow + j
            bodyCells.push(<div key={rowId} className={css.row}>{rowCells}</div>)
        }

        return (
            <div ref={this.bodyRef}
                 className={U.cls(
                     css.body, true,
                     css.nonSelectable, this.state.isScrolling || this.props.columnWidthChange.column !== null
                 )}
                 onWheel={this.handleWheel}>
                <div className={css.content}
                     style={{top: -this.state.offset}}
                     onTouchStart={this.handleRowsTouchStart}>
                    {bodyCells}
                </div>
                <div className={U.cls(css.scrollBar, true, css.scrollingBar, this.state.isScrolling)}
                     onClick={this.handleScrollBarClick}>
                    <div className={css.scrollThumb}
                         style={{
                             top: this.state.scrollThumbPos,
                             height: this.state.scrollThumbHeight,
                             opacity: this.state.scrollVisible ? 1 : 0,
                         }}
                         onMouseDown={this.handleScrollThumbMouseDown}
                         onTouchStart={this.handleScrollThumbMouseDown}
                    />
                </div>
            </div>
        );
    }
}
