import css from "../Visualizer.module.scss"
import * as React from "react"
import {
    chartFontFamily,
    maxLabelFontSize,
    minFontSize,
    minHorizXLabelFontSize,
    Tooltip,
    TooltipRow,
} from "../VisCommon"
import * as U from "../../Utils"
import * as MTU from "../../MainThreadUtils"
import {Point, Rect} from "../../Concepts/Geometry"
import * as STT from "../../StypeTools"
import {LabelType, RenderResult, TooltipsById} from "../../Concepts/Visualizer";
import {SemanticType} from "../../Concepts/SemanticType";

export function pushLabel (alignment:LabelType, elements:JSX.Element[], id:string, labelText:string, rect:Rect, fontSize:number, tooltips:TooltipsById, fullValue?:string|null) {
    const {textX, textY, textAnchor, dominantBaseline, transform, box, tooltipX} = (():{textX:number, textY:number, textAnchor:string, dominantBaseline:string, transform:string|undefined, box:Rect, tooltipX:number} => {
        switch (alignment) {
            case LabelType.XAxis:
                return {textX: rect.xCenter, textY: rect.top, textAnchor: "middle", dominantBaseline:"hanging", transform:undefined, box:rect, tooltipX: rect.xCenter}
            case LabelType.XAxisRotated:
                return {
                    textX: rect.xCenter,
                    textY: rect.top,
                    textAnchor: "end",
                    dominantBaseline:"middle",
                    transform:`rotate(-90 ${rect.xCenter},${rect.top})`,
                    box: new Rect(rect.xCenter - rect.height/2, rect.y, rect.height, MTU.measureText(labelText, fontSize, chartFontFamily)),
                    tooltipX: rect.xCenter
                }
            case LabelType.YAxis:
                return {textX: rect.right, textY: rect.yCenter, textAnchor: "end", dominantBaseline:"middle", transform:undefined, box:rect, tooltipX: rect.right}
            case LabelType.Legend:
                return {textX: rect.left, textY: rect.yCenter, textAnchor: "start", dominantBaseline:"middle", transform:undefined, box:rect, tooltipX: rect.left}
        }
    })()

    elements.push (<text key={id+'t'} x={textX} y={textY} className={css.noPointerEvents}
                         fontSize={fontSize} fill='black'
                         dominantBaseline={dominantBaseline} textAnchor={textAnchor} transform={transform}>{labelText}</text>)

    elements.push (<rect key={id} id={id} x={box.x} y={box.y} width={box.width} height={box.height}
                         fill='yellow' fillOpacity={0} stroke='none' />)

    if (fullValue !== undefined) {
        tooltips.set (id, new Tooltip({x: tooltipX, y: box.y}, [new TooltipRow(fullValue)]))
    }
}

export function getAngle (a:Point, b:Point) {
    return Math.atan2(a.x * b.y - a.y * b.x, a.x * b.x + a.y * b.y) * 180 / Math.PI;
}

// source: https://advancedweb.hu/plotting-charts-with-svg/
export function catmullRom2bezier (points:Point[]): Point[][] {
    const result:Point[][] = [];
    for (let i = 0; i < points.length - 1; i++) {
        const p = [];

        p.push({
            x: points[Math.max(i - 1, 0)].x,
            y: points[Math.max(i - 1, 0)].y
        });
        p.push({
            x: points[i].x,
            y: points[i].y
        });
        p.push({
            x: points[i + 1].x,
            y: points[i + 1].y
        });
        p.push({
            x: points[Math.min(i + 2, points.length - 1)].x,
            y: points[Math.min(i + 2, points.length - 1)].y
        });

        // Catmull-Rom to Cubic Bezier conversion matrix
        //    0       1       0       0
        //  -1/6      1      1/6      0
        //    0      1/6      1     -1/6
        //    0       0       1       0

        const bp = [];
        bp.push({
            x: ((-p[0].x + 6 * p[1].x + p[2].x) / 6),
            y: ((-p[0].y + 6 * p[1].y + p[2].y) / 6)
        });
        bp.push({
            x: ((p[1].x + 6 * p[2].x - p[3].x) / 6),
            y: ((p[1].y + 6 * p[2].y - p[3].y) / 6)
        });
        bp.push({
            x: p[2].x,
            y: p[2].y
        });
        result.push(bp);
    }

    return result;
}

export function makePath (points:Point[]):string {
    const path:string[] = []
    path.push ("M" + points[0].x + "," + points[0].y);
    path.push (...catmullRom2bezier(points).map (c => {
        const angle = getAngle({x:c[0].x-c[1].x, y:c[0].y-c[1].y}, {x:c[2].x-c[1].x, y:c[2].y-c[1].y})
        return Math.abs(angle) > 90 // draw a stright line where bezier can't do it nicely
            ? "C" + c[0].x + "," + c[0].y + " " + c[1].x + "," + c[1].y + " " + c[2].x + "," + c[2].y
            : "L" + c[2].x + "," + c[2].y
    }))
    return path.join(" ");
}

export function getPositiveAxisTicks (maxValue:number, possibleTickCounts=[9,8,7,6,5]):{limit:number, tickCount:number} {
    const limit = +maxValue.toPrecision(2)
    const pow = Math.floor(Math.log10(limit)) - 1
    let mantissa = U.fround(limit/Math.pow (10, pow)) - (maxValue === limit ? 1 : 0)

    if (Number.isNaN(mantissa)) {
        return {limit: maxValue, tickCount: 1}
    }

    let tickCount = 0
    for (let cycleCount = 0; cycleCount < 100 && tickCount === 0 && possibleTickCounts.length; cycleCount++) {
        mantissa += 1
        // find the largest possible number of ticks that divides the mantissa without remainder
        // eslint-disable-next-line no-loop-func
        tickCount = possibleTickCounts.reduce ((res, ticks) => {
            return mantissa % ticks === 0 && ticks > res ? ticks : res
        }, 0)
    }

    return tickCount === 0 ? {limit:maxValue, tickCount:1} : {limit:U.fround(mantissa * Math.pow (10, pow)), tickCount}
}

export function getNegPosAxisTicks (maxNegative:number, maxPositive:number, possibleNegTickCounts:number[]=[9,8,7,6,5,4,3], possiblePosTickCounts:number[]=[9,8,7,6,5,4,3]): {value:number, text:string}[] {
    const result = []
    const numberType = SemanticType.Number

    if (maxNegative > 0) {
        const ticks = getPositiveAxisTicks (maxNegative, possibleNegTickCounts)
        maxNegative = ticks.limit
        const vals = []
        for (let val = -maxNegative; U.fround(val) < 0; val += maxNegative / ticks.tickCount) {
            vals.push(U.fround(val))
        }
        const txtVals = STT.formatAxisLabels(numberType, vals)
        result.push(...vals.map ((v,i) => ({value: v, text: txtVals[i]})))
    }

    result.push({value: 0, text: '0'})

    if (maxPositive > 0) {
        const ticks = getPositiveAxisTicks (maxPositive, possiblePosTickCounts)
        maxPositive = ticks.limit
        const vals = []
        for (let val = 0; val <= maxPositive; val += maxPositive / ticks.tickCount) {
            if (val !== 0 || maxNegative === 0) {
                vals.push(U.fround(val))
            }
        }
        const txtVals = STT.formatAxisLabels(numberType, vals)
        result.push(...vals.map ((v,i) => ({value: v, text: txtVals[i]})))
    }

    return result
}

export function getRenderResult (width:number, height:number, elements:JSX.Element[], defs?:JSX.Element[]):RenderResult {
    const margin = Math.ceil(width / 30),
        vbHeight = height + margin * 2
    return {
        svg: <svg viewBox={`-${margin} -${margin} ${width + margin * 2} ${vbHeight}`} width={width} xmlns="http://www.w3.org/2000/svg">
            {defs && <defs>{defs}</defs>}
            <g fontFamily={chartFontFamily}>{elements}</g>
        </svg>,
        height: vbHeight
    }
}

export function fitLabelFontSize (labels:string[], isOk:(fontSize:number, maxLabelWidth:number, labelCount:number)=>boolean):{fontSize:number, maxLabelWidth:number}  {
    let fontSize = maxLabelFontSize, maxLabelWidth = 0
    while (true) {
        // eslint-disable-next-line no-loop-func
        maxLabelWidth = U.max(labels.map(label => MTU.measureText(label, fontSize, chartFontFamily)))
        if (isOk(fontSize, maxLabelWidth, labels.length) || fontSize <= minFontSize) {
            break
        }
        fontSize -= 1
    }
    return {fontSize, maxLabelWidth}
}

export function adjustXLabelFontSize(labels:string[], maxSpaceForALabel:number, startFontSize=maxLabelFontSize, minFontSize=minHorizXLabelFontSize):{rotated:boolean, fontSize:number, maxWidth:number} {
    let rotated = true, maxWidth = 0, fontSize = startFontSize
    for (let currentSize = startFontSize; currentSize >= minFontSize; currentSize -= 0.2) {
        maxWidth = Math.max (...labels.map (label => MTU.measureText(label, currentSize, chartFontFamily)))
        if (maxWidth * 1.2 <= maxSpaceForALabel) {
            fontSize = currentSize
            rotated = false
            break
        }
    }
    if (rotated) {
        if (fontSize > maxSpaceForALabel) {
            fontSize = Math.max (Math.floor(maxSpaceForALabel), minFontSize)
        }
        maxWidth = Math.max (...labels.map (label => MTU.measureText(label, fontSize, chartFontFamily)))
    }
    return {rotated, fontSize, maxWidth}
}