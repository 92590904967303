import * as React from "react"
import {FunctionComponent} from "react"
import {Box} from "@material-ui/core"

interface ProgressRingProps {
    radius: number
    color: string
    percent?: number
}

const ProgressRing: FunctionComponent<ProgressRingProps> = (props: ProgressRingProps) => {
    const stroke = Math.round(props.radius / 7),
        normalizedRadius = props.radius - stroke * 2,
        circumference = normalizedRadius * 2 * Math.PI

    return (
        <Box position="relative" display="inline-flex">
            <svg height={props.radius * 2} width={props.radius * 2}>
                <circle
                    stroke={props.color}
                    fill="transparent"
                    strokeLinecap="round"
                    strokeWidth={stroke}
                    strokeDasharray={circumference+' '+circumference}
                    strokeDashoffset={circumference - (props.percent ?? 0) / 100 * circumference}
                    r={normalizedRadius}
                    cx={props.radius}
                    cy={props.radius}
                    transform={`rotate(-90,${props.radius},${props.radius})`}
                >
                    {
                        props.percent === undefined ? [
                            <animate key='1' attributeName="stroke-dashoffset" from={circumference} to={0} dur="0.7s" repeatCount="indefinite" />,
                            <animate key='2' attributeName="stroke-dasharray" values={`${circumference} 0;0 ${circumference};${circumference} 0`} dur="1.7s" keyTimes="0; 0.5; 1" repeatCount="indefinite" />
                        ] : undefined
                    }
                </circle>
            </svg>
            {
                props.percent !== undefined
                    ? <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
                        <div style={{fontSize: props.radius/2.5}}>{Math.round(props.percent ?? 0)+'%'}</div>
                    </Box>
                    : undefined
            }
        </Box>
    )
}

export default ProgressRing