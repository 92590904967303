import {alias, list, primitive, serializable} from "serializr"
import {mapOfObjects} from "../CustomSerializr"
import {CategoryToColor, StandardColor} from "./Colors"
import {Point} from "./Geometry"
import {CatFreq, NumFreq} from "./FrequencyOfValue"
import {SemanticType} from "./SemanticType"

//<editor-fold desc="Basics">

export type DataValue = string | number | null

export type CategoryValue = string | null

export class OptionalString {
    public value:string|undefined
}

export type ColumnOfValues = DataValue[]

export type RowOfValues = DataValue[]

// a number from 0 to 100
export type Percentage = number & {readonly brand: unique symbol}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export function Percentage (percentage: number):Percentage {
    if (percentage < 0 || percentage > 100) {
        throw new Error ("Percentage value should be between 0 and 100")
    }
    return percentage as Percentage
}

export type NumInclusiveRange = {min:number, max:number}

//</editor-fold>

//<editor-fold desc="ColumnUIIndex">

// column's visual index as it's shown on the ui
export type ColumnUIIndex = number & {readonly brand: unique symbol}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export function ColumnUIIndex (index: number) {
    return index as ColumnUIIndex
}

//</editor-fold>

//<editor-fold desc="ColumnDBIndex">

// column's original index as it's got from the database
export type ColumnDBIndex = number & {readonly brand: unique symbol}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export function ColumnDBIndex (index: number) {
    return index as ColumnDBIndex
}

//</editor-fold>

//<editor-fold desc="ColumnId">

// column's unique identifier
export type ColumnId = number & {readonly brand: unique symbol}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export function ColumnId (id: number) {
    if (!id) {
        throw Error ("ColumnId can't be 0")
    }
    return id as ColumnId
}

export const ZeroColumnId = 0 as ColumnId

//</editor-fold>

export type ColumnsById = Map<ColumnId, ColumnOfValues>

export class NumericalCategories {
    @serializable(alias('vgs')) readonly valueGridStep: number
    @serializable(alias('axiscat', list(primitive()))) readonly axisCategories: number[]
    @serializable(alias('legendcat', mapOfObjects(StandardColor))) readonly legendCategories: Map<number, StandardColor>

    constructor(valueGridStep: number, axisCategories: number[], legendCategories: Map<number, StandardColor>) {
        this.valueGridStep = valueGridStep
        this.axisCategories = axisCategories
        this.legendCategories = legendCategories
    }
}

export interface IValueDistribution {
    meanValueFreq: NumFreq[]
    length: number
}

export interface ICategoryDistribution extends IValueDistribution {
    entries: CatFreq[]
    map: <T>(f: (value: CatFreq, index: number, array: CatFreq[]) => T, excludeNulls: boolean) => T[]
}

export interface INumberSummary {
    min: number
    max: number
    whiskerMin: number
    whiskerMax: number
    q1: number
    q3: number
    iqr: number
    mean: number
    median: number
    std: number
    outliers: number[]
    nullCount: number
    distribution: IValueDistribution
    kde: Point[]
    categories: NumericalCategories | undefined
}

export enum ColumnSummaryType {
    Number = "Number",
    Category = "Category",
    Text = "Text"
}

export interface IColumnSummary {
    type: ColumnSummaryType
    // includes null values
    valueCount: number
    nullCount: number
    // throws exception on an inappropriate column summary type
    numberSummary: INumberSummary
    // throws exception on an inappropriate column summary type
    categoryDistribution: ICategoryDistribution
}

export interface ITableColumn {
    readonly id: ColumnId
    readonly stype: SemanticType
    readonly title: string
    readonly units: string | null
    readonly color: StandardColor
    readonly categories: CategoryToColor
    readonly summary: IColumnSummary
    readonly selected: boolean
    readonly numberSummary: INumberSummary
    readonly categoryDistribution: ICategoryDistribution
    width: number
    order: number
    pinned: boolean
    selectionTime: Date | null
}

//<editor-fold desc="Other">

export type BackdropResponse = {cancelled:boolean}

export enum MenuAction {
    OpenFileAuto = 1,
    OpenFileManual,
    PasteAuto,
    PasteManual,
    LoadPreset,
    SaveWorkspace,
    Settings,
    Telegram
}

//</editor-fold>