import {DataValue} from "./Basic"

export type ObjectWithId = { id: number }
export type WorkspaceStoreId = number

export const DB_PREFIX = "ws_"
const tableNames = ['vissettings', 'columns', 'data'] as const
export type TableName = typeof tableNames[number]
export type DatabaseSchema = Record<TableName, string | null>

export interface IObjectStore<T extends ObjectWithId> {
    retrieveAll():Promise<T[]>
    bulkAdd(objects: T[]):Promise<void>
    put(object: T):Promise<void>
}

export interface IDataStore {
    retrieveAll():Promise<DataValue[][]>
    add(firstRow:number, values: DataValue[][]):Promise<void>
}

export interface IWorkspaceStore {
    readonly id: WorkspaceStoreId
    readonly data: IDataStore
    createVisSettingsStore<T extends ObjectWithId>(ctor: new (...args: any[]) => T):IObjectStore<T>
    createTableColumnStore<T extends ObjectWithId>(ctor: new (...args: any[]) => T):IObjectStore<T>
}
