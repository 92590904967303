import React, {ErrorInfo} from 'react'
import {Workspace} from "./Workspace/Workspace"
import {Redirect, Route, Switch} from "react-router-dom"
import {UserSetting, userSettings} from "./LocalSettings"
import * as U from './Utils'
import {eventSender} from "./WebWorkers"
import event from "./EventSender/Events"
import * as SizeAware from "./Components/SizeAware"
import {createTheme, StyledEngineProvider, ThemeProvider} from '@material-ui/core/styles'
import {colors} from './Colors'
import {RouteTracker, Tracker} from "./YM"
import $t from "./i18n/i18n"

const theme = createTheme({
    palette: {
        primary: {
            light: colors.pink.c400,
            main: colors.pink.c500,
            dark: colors.pink.c600,
        },
        secondary: {
            light: colors.amber.c400,
            main: colors.amber.c500,
            dark: colors.amber.c600,
        },
        error: {
            light: colors.red.c400,
            main: colors.red.c500,
            dark: colors.red.c600,
        },
        warning: {
            light: colors.orange.c400,
            main: colors.orange.c500,
            dark: colors.orange.c600,
        },
        info: {
            light: colors.blue.c400,
            main: colors.blue.c500,
            dark: colors.blue.c600,
        },
        success: {
            light: colors.green.c400,
            main: colors.green.c500,
            dark: colors.green.c600,
        },
    },
})

interface ErrorBoundaryState {
    hasError: boolean
}

class ErrorBoundary extends React.PureComponent<unknown, ErrorBoundaryState> {
    constructor(props:unknown) {
        super(props)
        this.state = { hasError: false }
    }

    override componentDidCatch(error:Error, info:ErrorInfo) {
        this.setState({ hasError: true })
        event.app.error.unexpected(error.stack ?? error.message)
        Workspace.unselectAllColumns()
    }

    override render() {
        return this.state.hasError
            ? <h1 style={{textAlign: "center", marginTop: "100px", lineHeight:"150%"}}>{$t('app.error_line1')}<br/>{$t('app.error_line2')}</h1>
            : this.props.children
    }
}

export default class App extends React.Component<unknown> {

    static readonly SizeAwareWorkspace = SizeAware.make(Workspace, false, true)

    override componentDidMount() {
        if (!window.File) {
            throw Error('window.File is not supported in this browser')
        }
        if (!window.FileReader) {
            throw Error('window.FileReader is not supported in this browser')
        }
        if (!window.FileList) {
            throw Error('window.FileList is not supported in this browser')
        }
        if (!window.Worker) {
            throw Error('window.Worker is not supported in this browser.')
        }
        if (!window.isSecureContext) {
            throw Error('The page is not in a secure context')
        }

        U.settings.locale = userSettings.getString("locale")
        U.settings.uuid = userSettings.getString("uuid")
        U.settings.timeZone = userSettings.getString("timezone")
        U.settings.rebuildFormatters()

        userSettings.subscribe((setting: string) => {
            if (setting as UserSetting === "locale") {
                U.settings.locale = userSettings.getString("locale")
            } else if (setting as UserSetting === "timezone") {
                U.settings.timeZone = userSettings.getString("timezone")
            } else if (setting as UserSetting === "numToCat") {
                U.settings.numToCat = U.parseIntNotNan(userSettings.getString("numToCat"))
            }
            U.settings.rebuildFormatters()
        })

        eventSender.init(U.settings.uuid).then(() => {
            event.app.start(navigator.userAgent, Tracker.getQueryString(), process.env.REACT_APP_VERSION ?? '', navigator.language)
        });

        // (window as any).userGuiding.identify(U.settings.uuid)
    }

    override render() {
        return (
            <ThemeProvider theme={theme}>
                <ErrorBoundary>
                    <RouteTracker />
                    <StyledEngineProvider injectFirst>
                        <Switch>
                            <Route path="/w/:lochash?" component={App.SizeAwareWorkspace}/>
                            <Route path="/preset/:preset?" component={App.SizeAwareWorkspace}/>
                            <Route path="/">
                                <Redirect to={'/w/'}/>
                            </Route>
                        </Switch>
                    </StyledEngineProvider>
                </ErrorBoundary>
            </ThemeProvider>
        );
    }
}