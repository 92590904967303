import {ColumnId, INumberSummary} from "../Basic"
import {SemanticType} from "../SemanticType"

export interface VisData {
    data: Map<ColumnId, INumberSummary>
    categories: number
    gridMin: number
    gridMax: number
}

export interface VisColData {
    columnIds: ColumnId[]
    title: string
    footer: string | undefined
    stype: SemanticType
}

export enum VisControls {
    bins = 1,
    probability = 2,
    range = 3
}

export enum YAxisType {
    probability = "prob",
    frequency = "freq"
}