import {Clazz, custom, deserialize, serialize} from "serializr"

function mapSerializer(sourcePropertyValue: any) {
    if (sourcePropertyValue) {
        const entries = []
        for (const entry of (sourcePropertyValue as Map<any, any>).entries()) {
            entries.push([entry[0], typeof entry[1] === "object" ? serialize(entry[1]) : entry[1]])
        }
        return entries
    } else {
        return {SKIP: true}
    }
}

// eslint-disable-next-line @typescript-eslint/ban-types
function mapDeserializer<T extends object>(clazz:Clazz<T>|undefined, jsonValue: any) {
    if (jsonValue.SKIP !== true) {
        if (clazz) {
            for (const entry of jsonValue) {
                entry[1] = deserialize(clazz, entry[1])
            }
        }
        return new Map (jsonValue)
    } else {
        return undefined
    }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function mapOfObjects<T extends object>(clazz?:Clazz<T>) {
    return custom(mapSerializer, mapDeserializer.bind(null, clazz))
}

