// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react'
import * as U from '../../Utils'
import * as RC from './RendererCommon'
import * as Bricks from './Bricks'
import {BrickWithLabels, LabelData} from './Bricks'
import {TooltipRow} from '../VisCommon'
import * as Colors from '../../Colors'
import * as STT from "../../StypeTools"
import {Link} from "../../Workspace/WorkspaceLocation"
import {sampleScatterPlotData} from "../../Data/DataSampling"
import {CategoryValue, ColumnSummaryType} from "../../Concepts/Basic"
import {TM, UniqueTestMarker} from "../../@testing/TestMarker"
import {ScatterPlotAxes, ScatterPlotOptions, ScatterPlotRawData} from "../../Concepts/Visualizers/Renderers/ScatterPlot"
import {RenderResult, TooltipsById, VisualizerType} from "../../Concepts/Visualizer"

type NoUndefinedField<T> = { [P in keyof T]-?: NoUndefinedField<NonNullable<T[P]>> }

export function render (
    sourceData: ScatterPlotRawData,
    axes: ScatterPlotAxes,
    width: number,
    // from 0 to 1, applied to the plot side
    origMaxRadiusCoeff: number|null,
    opacity: number,
    tooltips: TooltipsById,
    oneLinePerCategory: boolean,
    options:ScatterPlotOptions = {}):RenderResult {

    U.assert(axes.x !== undefined, "X axis must be defined")
    U.assert(axes.y !== undefined, "Y axis must be defined")
    U.assert(sourceData.get(axes.x.id) !== undefined, "X axis must be in the data set")
    U.assert(sourceData.get(axes.y.id) !== undefined, "Y axis must be in the data set")
    U.assert(axes.color === null || sourceData.get(axes.color.id) !== undefined, "Color axis must be in the data set")
    U.assert(axes.size === null || sourceData.get(axes.size.id) !== undefined, "Size axis must be in the data set")
    U.assert(axes.time === null || sourceData.get(axes.time.id) !== undefined, "Time axis must be in the data set")

    const

        samplingThreshold = 2000,

        maxRadiusCoeff = origMaxRadiusCoeff ?? 0.02,

        minRadiusCoeff = maxRadiusCoeff / 5,

        sizeValues = axes.size ? sourceData.get(axes.size.id) as number[] : null,

        minSize = axes.size && STT.isNumeric(axes.size.stype) ? axes.size.numberSummary.min : 0,

        maxSize = axes.size && STT.isNumeric(axes.size.stype) ? axes.size.numberSummary.max : 0,

        xCategorical = STT.isCategorical(axes.x.stype) || axes.x.numberSummary.categories || axes.x.summary.nullCount === axes.x.summary.valueCount,

        yCategorical = STT.isCategorical(axes.y.stype) || axes.y.numberSummary.categories || axes.y.summary.nullCount === axes.y.summary.valueCount,

        dotRadius = (sizeValue:number|null, axisSize:number) => Math.round ((sizeValue !== null && maxSize > minSize
                ? ((sizeValue - minSize) / (maxSize - minSize) * (maxRadiusCoeff - minRadiusCoeff)) + minRadiusCoeff
                : maxRadiusCoeff
            ) * 10 * axisSize / 2) / 10 + (origMaxRadiusCoeff === null ? 2 : 0),

        sampledData = () => {
            const segmentsPerContinuesAxis = width / Math.max (15, dotRadius(maxSize, width)),
                maxDotsInSquareSegment = xCategorical ? 3 : (yCategorical ? 6 : 9)
                return sampleScatterPlotData (sourceData, axes.x, axes.y, segmentsPerContinuesAxis, maxDotsInSquareSegment)
        },

        data = U.get(sourceData, axes.x.id).length < samplingThreshold  ? sourceData : sampledData(),

        opt = ((o:ScatterPlotOptions):NoUndefinedField<ScatterPlotOptions> => ({
            uniqueId: o.uniqueId ?? '',
            plotOnly: o.plotOnly ?? false,
            whiteBorderAroundDot: o.whiteBorderAroundDot ?? false,
            grid: o.grid ?? false,
            widthToHeightRatio: o.widthToHeightRatio ?? 1
        }))(options),

        colorMapMargin = width / 20,

        plotY = 0,

        bp = new Bricks.Params(tooltips,'sp' + opt.uniqueId ?? ''),

        axisXTitle = axes.x.title + (axes.x.units ? ', ' + axes.x.units : ''),

        axisYTitle = axes.y.title + (axes.y.units ? ', ' + axes.y.units : ''),

        axisColorTitle = axes.color ? (axes.color.title + (axes.color.units ? ', ' + axes.color.units : '')) : '',

        yLabelData = (axisHeight:number|undefined):LabelData => BrickWithLabels.columnInfoToLabelData(axes.y, axisHeight),

        preliminaryYAxis = new Bricks.YAxis (bp.append('y'), yLabelData (undefined), undefined, axisYTitle),

        preliminaryPlotX = preliminaryYAxis.width,

        colorLabelData = (axisHeight:number|undefined):LabelData => BrickWithLabels.columnInfoToLabelData(axes.color, axisHeight, true),

        getColorLegend = (legendHeight:number|undefined) => axes.color
            ? new Bricks.VerticalLegend(bp.append('l'), colorLabelData(legendHeight), axisColorTitle, legendHeight,
                STT.isCategorical(axes.color.stype)
                    ? [...axes.color.categories.values ()]
                    : axes.color.summary.type === ColumnSummaryType.Number && axes.color.numberSummary.categories
                        ? [...axes.color.numberSummary.categories.legendCategories.values()]
                        : undefined)
            : null,

        preliminaryColorLegend = getColorLegend(undefined),

        plotWidth = opt.plotOnly? width : width - preliminaryPlotX - colorMapMargin - (preliminaryColorLegend?.width ?? 0),

        plotHeight = plotWidth * opt.widthToHeightRatio,

        yAxis = new Bricks.YAxis (bp.append('y'), yLabelData (plotHeight), plotHeight, axisYTitle),

        plotX = opt.plotOnly ? 0 : yAxis.width,

        yAxisElements = yAxis.render (0, plotY, plotHeight),

        xAxis:Bricks.XAxis = new Bricks.XAxis (bp.append('x'), BrickWithLabels.columnInfoToLabelData(axes.x, plotWidth), plotWidth, axisXTitle),

        xAxisElements = xAxis.render (plotX, plotY + plotHeight, plotWidth),

        colorLegend = getColorLegend(plotHeight),

        colorLegendElements = colorLegend !== null ? colorLegend.render(plotX + plotWidth + colorMapMargin, plotY, plotHeight) : [],

        height = opt.plotOnly ? plotHeight : Math.max (plotHeight + xAxis.height, colorLegend?.height ?? 0),

        xValues = U.get(data, axes.x.id),

        yValues = U.get(data, axes.y.id),

        colorValues = axes.color ? U.get(data, axes.color.id) : null,

        xCatToColor = colorValues === null && STT.isCategorical(axes.x.stype) && !STT.isCategorical(axes.y.stype)
            ? axes.x.categories
            : null,

        yCatToColor = colorValues === null && !STT.isCategorical(axes.x.stype) && STT.isCategorical(axes.y.stype)
            ? axes.y.categories
            : null,

        timeValues = axes.time ? data.get(axes.time.id) as number[] : null,

        colorCategorical = axes.color && (STT.isCategorical(axes.color.stype) || axes.color.numberSummary.categories || axes.color.summary.nullCount === axes.color.summary.valueCount),

        testMarker = new UniqueTestMarker (TM.chartElementWithTooltip),

        dots = [...U.range(xValues.length)]
            .filter (rowIndex => (xValues[rowIndex] !== null || xCategorical) && (yValues[rowIndex] !== null || yCategorical))
            .map (rowIndex => ({
                rowIndex,
                dx: xAxis.valueToOffset(xValues[rowIndex]),
                dy: yAxis.valueToOffset(yValues[rowIndex]),
                radius: dotRadius (sizeValues ? sizeValues[rowIndex] : null, plotWidth),
                color: (colorValues
                    ? U.notNull(colorLegend).valueToColor(colorValues[rowIndex])
                    : xCatToColor !== null
                        ? xCatToColor.get(xValues[rowIndex] as (string | null)) ?? Colors.defaultPlotColor
                        : yCatToColor !== null
                            ? yCatToColor.get(yValues[rowIndex] as (string | null)) ?? Colors.defaultPlotColor
                            : Colors.defaultPlotColor).normal,
                tooltipRows: [
                    new TooltipRow(xValues[rowIndex], axes.x.title, axes.x.stype, axes.x.units ?? undefined),
                    new TooltipRow(yValues[rowIndex], axes.y.title, axes.y.stype, axes.y.units ?? undefined),
                    axes.color ? new TooltipRow(U.notNull(colorValues)[rowIndex], axes.color.title, axes.color.stype, axes.color.units ?? undefined) : undefined,
                    axes.size ? new TooltipRow(U.notNull(sizeValues)[rowIndex], axes.size.title, axes.size.stype, axes.size.units ?? undefined) : undefined
                ],
                testMarker: testMarker.value
            })),

        dotIndicesOrderedByTime = (timeValues:(number|null)[], category?:CategoryValue) => dots
            .filter (dot =>
                (category === undefined || U.notNull(colorValues)[dot.rowIndex] === category)
                && timeValues[dot.rowIndex] !== null)
            .map ((dot, dotIndex) => [timeValues[dot.rowIndex] as number, dotIndex])
            .sort ((a,b) => a[0] - b[0])
            .map (tupple => tupple[1]),

        lines = axes.time === null
            ? undefined
            : oneLinePerCategory && axes.color !== null && STT.isCategorical(axes.color.stype)
                ? [...axes.color.categories.entries()].map ((catColor) => ({dotIndices: dotIndicesOrderedByTime(U.notNull(timeValues), catColor[0]), color: catColor[1]}))
                : [{ dotIndices: dotIndicesOrderedByTime(U.notNull(timeValues)) }],

        scatterPlotElements = new Bricks.ScatterPlot(bp.append('p'), plotWidth, plotHeight, dots, opacity, lines, opt.whiteBorderAroundDot).render(plotX, plotY),

        gridElements = opt.grid ? new Bricks.Grid(bp.append('gr'), xAxis.tickOffsets, yAxis.tickOffsets, plotWidth, plotHeight, false).render(plotX, plotY) : [],

        renderResult = RC.getRenderResult (width, height, opt.plotOnly
                ? scatterPlotElements
                : [
                    ...gridElements,
                    ...scatterPlotElements,
                    <Link key={'x-axis'} columnIds={[axes.x.id]} visType={xCategorical ? VisualizerType.PieBarChart : VisualizerType.NumFreqHistSharedX}>{xAxisElements}</Link>,
                    <Link key={'y-axis'} columnIds={[axes.y.id]} visType={yCategorical ? VisualizerType.PieBarChart : VisualizerType.NumFreqHistSharedX}>{yAxisElements}</Link>,
                    <Link key={'legend'} columnIds={axes.color ? [axes.color.id] : []} visType={colorCategorical ? VisualizerType.PieBarChart : VisualizerType.NumFreqHistSharedX}>{colorLegendElements}</Link>
                ],
                bp.defs
        )

    if (U.get (sourceData, axes.x.id).length > U.get (data, axes.x.id).length) {
        renderResult.samplingApplied = true
    }

    return  renderResult
}
