import React from 'react'
import {
    GetActionsEvent,
    GetActionsEventType,
    MatchDegree,
    TooltipsById, UserActionInfo, UserActionResponse,
    VisContent,
    VisCtrlAction,
    VisCtrlId,
    VisCtrlInfo,
    VisCtrlValues,
    VisualizerColumnData,
    VisualizerData,
    VisualizerType
} from "../Concepts/Visualizer"
import {Visualizer} from "./Visualizer"
import DataTable from "../Data/DataTable"
import NumberSummary from "../Data/NumberSummary"
import DataColumn from "../Data/DataColumn"
import {render} from './Renderers/LineChart'
import Icon from './VisIcons/LineChart.svg'
import * as U from '../Utils'
import * as STT from "../StypeTools"
import {addSamplingMessage, sampleLineChartData} from "../Data/DataSampling"
import {ColumnId, ColumnSummaryType, ITableColumn} from "../Concepts/Basic"
import {AxisType, LineChartInfo, LineChartSeries} from "../Concepts/Visualizers/Renderers/LineChart"
import {VisColData, VisData} from "../Concepts/Visualizers/LineChart"
import {IDataTable} from "../Concepts/DataTable";
import $t from "../i18n/i18n";

export default class LineChart extends Visualizer {

    override get type () {return VisualizerType.LineChart}

    matchDegree(columns: ITableColumn[]): MatchDegree {
        const ordinalColumns = columns.filter(c => STT.isOrdinal(c.stype))
        if (columns.length > 0 && ordinalColumns.length < 2) {
            return (ordinalColumns.length === 0 || ordinalColumns[0].summary.nullCount !== ordinalColumns[0].summary.valueCount)
                && columns.every (c => STT.isCategorical(c.stype) || STT.isContinuous(c.stype))
                ? (columns.length <= 2 ? MatchDegree.BestFit : MatchDegree.Ordinary)
                : MatchDegree.NoMatch
        } else {
            return MatchDegree.NoMatch
        }
    }

    getTitle() {
        return $t("linechart.name")
    }

    getIconUrl(): string {
        return Icon
    }

    getColumnData(dataTable:IDataTable): VisColData {
        const columnIds = dataTable.selectedColumnIds,
            yColumnIds = columnIds.length === 1 ? columnIds : columnIds.filter(id => !STT.isOrdinal(dataTable.getStype(id))),
            firstColumnInfo = dataTable.getColumnInfo(yColumnIds[0]),
            ordinalColumnId:ColumnId|undefined = columnIds.length === 1 ? undefined : columnIds.filter(id => STT.isOrdinal(dataTable.getStype(id)))[0]
        return {
            xInfo: ordinalColumnId
                ? { axisType:AxisType.ExistingColumn, column: dataTable.getColumnInfo(ordinalColumnId) }
                : { axisType:AxisType.NaturalNumbers, count: firstColumnInfo.summary.valueCount },
            yColumnIds,
            yCatToColor: yColumnIds.length === 1 && STT.isCategorical(firstColumnInfo.stype) ? firstColumnInfo.categories : null,
            footer: undefined
        }
    }

    getControls(dataTable:IDataTable, columnData: VisualizerColumnData): Map<VisCtrlId, VisCtrlInfo> {
        return new Map()
    }

    getData (requestId:number, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues):Promise<{data:VisData, requestId:number}> {
        const cd = columnData as VisColData,
            columnIds = cd.xInfo.axisType === AxisType.ExistingColumn ? [cd.xInfo.column.id, ...cd.yColumnIds] : cd.yColumnIds

        return dataTable.getTuples(columnIds).then (tuples => {
            const chartInfo:LineChartInfo[] = [],
                xData = cd.xInfo.axisType === AxisType.ExistingColumn ? tuples.get(cd.xInfo.column.id) as (number|null)[] : [...U.range (cd.xInfo.count)],
                rowIndicesOrderedByNonNullX = U.times(xData.length, i => [xData[i], i] as [number|null, number])
                    .filter (tupple => tupple[0] !== null)
                    .sort ((a,b) => a[0] && b[0] ? a[0] - b[0] : 1)
                    .map (tupple => tupple[1])

            // distribute y-columns among charts (combining similar data in one chart)
            cd.yColumnIds.forEach(colId => {
                const column = dataTable.getColumnInfo(colId),
                    data = U.get(tuples, colId),
                    series:LineChartSeries = {
                        name: column.title,
                        color: column.color
                    },
                    values = rowIndicesOrderedByNonNullX.map (i => data[i])

                const matchedCharts = chartInfo.filter (chart =>
                    chart.stype === column.stype
                    && chart.units === column.units
                    && chart.summary.type === ColumnSummaryType.Number
                    && column.summary.type === ColumnSummaryType.Number
                    && NumberSummary.isComparable(chart.summary.numberSummary, column.summary.numberSummary))

                if (matchedCharts.length>0) {
                    matchedCharts[0].serInfo.push (series)
                    matchedCharts[0].serValues.push (values)
                } else {
                    chartInfo.push ({
                        serInfo: [series],
                        serValues: [values],
                        stype: column.stype,
                        summary: column.summary,
                        units: column.units
                    })
                }
            })

            // update summaries for charts with more than one series
            chartInfo
                .filter(chart => chart.serInfo.length > 1)
                .forEach(chart => {
                    const allData = chart.serValues.flat().map (v => [v])
                    chart.summary = DataTable.getColumnSummary(new DataColumn (allData, 0, chart.stype), true)
                })

            return {
                data: {
                    chartInfo,
                    xValues: rowIndicesOrderedByNonNullX.map (i => xData[i]) as number[]
                },
                requestId
            }
        })
    }

    getActionsOnControls (event:GetActionsEvent, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues):VisCtrlAction[] {
        const cd = columnData as VisColData
        if (event.type === GetActionsEventType.DataLoadedWithSuggestedControlValues
            || event.type === GetActionsEventType.DataLoadedWithUserControlValues) {
            const categoryColumnId = cd.yCatToColor ? cd.yColumnIds[0] : null,
                xInfo = (columnData as VisColData).xInfo
            this.sendColoringEvent(
                categoryColumnId,
                categoryColumnId === null,
                xInfo.axisType === AxisType.ExistingColumn ? [xInfo.column.id] : undefined
            )
        }
        return []
    }

    getContent (data:VisualizerData, width:number, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues, tooltips:TooltipsById):VisContent {
        const d = data as VisData,
            cd = columnData as VisColData

        let samplingApplied = false
        const renderResults = d.chartInfo.map ((chartInfo, chartIndex) => {
            const data = sampleLineChartData(d.xValues, chartInfo, Math.floor(width / 6) + 100)
            if (data.length < d.xValues.length) {
                samplingApplied = true
            }
            return render(
                    data,
                    cd.xInfo,
                    chartInfo.serInfo,
                    chartInfo.stype,
                    chartInfo.summary,
                    chartInfo.units,
                    cd.yCatToColor,
                    width,
                    tooltips,
                    chartIndex.toString()
                )
            }
        )

        return {
            node: <div>{renderResults.map ((result, i) => <div key={i}>{result.svg}</div>)}</div>,
            title: $t("linechart.against_title", {
                    ycol: U.joinWithAnd(cd.yColumnIds.map (id => dataTable.getColumnInfo(id).title), true),
                    xcol: cd.xInfo.axisType === AxisType.ExistingColumn ? '"'+cd.xInfo.column.title+'"' : $t("linechart.row_number.gen")
                }),
            footer: addSamplingMessage (cd.footer, samplingApplied, d.chartInfo.length > 1 ? $t("linechart.in_one_or_more_charts") : ""),
            height: renderResults.reduce((sum, result) => sum + result.height, 0)
        }
    }

    handleUserAction (userActionInfo: UserActionInfo, data:VisualizerData, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues):UserActionResponse|undefined {
        return undefined
    }
}