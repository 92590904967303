import * as Colors from "./Colors"
import {NIL as uuidnil, v4 as uuidv4} from 'uuid' // https://github.com/uuidjs/uuid

export type UserSetting =
    // user uuid
    "uuid"
    // units of columns should be the same in order for the columns get into the same chart
    | "unitsMatter"
    // the maximum number of unique numerical values in a column that makes them look like categories on a chart
    | "numToCat"
    // the maximum number of histogram bins
    | "histBins"
    // UI timesone
    | "timezone"
    // UI locale
    | "locale"
    // timezone used in import dialog
    | "importTimezone"
    // locale used in import dialog
    | "importLocale"

export type LocalSettingsSubscriber = (setting:string)=>void

export class LocalSettings<Enum extends string> {

    protected _version = 1
    protected readonly subscribers:Set<LocalSettingsSubscriber> = new Set<LocalSettingsSubscriber>()

    constructor(protected readonly defaultValues: Map<Enum, string|number>, protected readonly prefix:string) {
    }

    subscribe (subscriber:LocalSettingsSubscriber) {
        this.subscribers.add(subscriber)
    }

    unsubscribe (subscriber:LocalSettingsSubscriber) {
        this.subscribers.delete(subscriber)
    }

    get version ():number {
        return this._version
    }

    getString (setting:Enum):string {
        const defValue = this.defaultValues.get (setting)
        if (defValue !== undefined) {
            const value = window.localStorage.getItem(this.prefix+setting)
            if (value !== null) {
                return value
            } else {
                const defaultValue = defValue.toString()
                this.set (setting, defaultValue)
                return defaultValue
            }
        } else {
            throw new Error (`Unexpected setting "${setting}". It should be in the defaultValues map.`)
        }
    }

    getNumber (setting:Enum):number {
        const defValue = this.defaultValues.get (setting)
        if (defValue !== undefined) {
            const value = window.localStorage.getItem(this.prefix+setting)
            if (value !== null && !Number.isNaN(parseFloat(value))) {
                return parseFloat(value)
            } else {
                const defaultValue = parseFloat(defValue.toString())
                this.set (setting, defaultValue.toString())
                return defaultValue
            }
        } else {
            throw new Error (`Unexpected setting "${setting}". It should be in the defaultValues map.`)
        }
    }

    set (setting:Enum, value:string|number) {
        if (this.defaultValues.has(setting)) {
            window.localStorage.setItem(this.prefix+setting, value.toString())
            this._version += 1
            this.subscribers.forEach(s=>s(setting))
        } else {
            throw new Error (`Unexpected setting "${setting}". It should be in the defaultValues map.`)
        }
    }
}

export const userSettings = new LocalSettings(new Map<UserSetting, number|string> ([
    ["uuid", window.location.host.startsWith('localhost') ? uuidnil : uuidv4()],
    ["unitsMatter", 1],
    ["numToCat", Colors.seriesColorCount],
    ["histBins", 32],
    ["timezone", Intl.DateTimeFormat().resolvedOptions().timeZone], // 'Europe/Kaliningrad'
    ["locale", navigator.languages && navigator.languages.length ? navigator.languages[0] : "en-US"],
    ["importTimezone", Intl.DateTimeFormat().resolvedOptions().timeZone], // 'Europe/Kaliningrad'
    ["importLocale", navigator.languages && navigator.languages.length ? navigator.languages[0] : "en-US"]
]), "u_")