// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react'
import * as U from "../../Utils"
import * as RC from './RendererCommon'
import * as Bricks from './Bricks'
import {LabelDataType} from './Bricks'
import {TooltipRow} from '../VisCommon'
import {ICategoryDistribution} from "../../Concepts/Basic"
import {StandardColor} from "../../Concepts/Colors"
import {RenderResult, TooltipsById} from "../../Concepts/Visualizer"
import {SemanticType} from '../../Concepts/SemanticType'

export function render (
    distr:ICategoryDistribution,
    width:number,
    probability:boolean,
    yTitle:string,
    color: StandardColor,
    tooltips:TooltipsById,
    uniqueId= '',
    plotOnly = false
):RenderResult {

    const
        
        maxChartHeight = 300,
        
        chartHeight = plotOnly ? width : Math.min(width / 1.618, maxChartHeight),

        bp = new Bricks.Params(tooltips,'cfh'+uniqueId),
        
        barValues = (()=> {
            let values = distr.map (vf => vf.freq, false)
            if (probability) {
                const sum = values.reduce((sum, f) => sum + f, 0)
                values = values.map(f => sum === 0 ? 0 : f / sum)
            }
            return values
        })(),

        yTicks = RC.getPositiveAxisTicks(U.max(barValues)),

        yAxis:Bricks.YAxis = new Bricks.YAxis(bp.append('y'),{
            type: LabelDataType.NumericalRange,
            values: [...U.splitRange(0, yTicks.limit, yTicks.tickCount)],
                stype: SemanticType.Number
            }, chartHeight, yTitle),

        xAxisLeft = plotOnly ? 0 : yAxis.width,

        xAxis = new Bricks.XAxis(bp.append('x'), {
            type: LabelDataType.Categories,
            values: distr.map(vf => vf.value, false),
            stype: SemanticType.Category
        }, width - xAxisLeft),

        xAxisHeight = plotOnly ? 0 : xAxis.height,

        height = chartHeight + xAxisHeight,

        xAxisElements = xAxis.render (xAxisLeft, height - xAxisHeight, width - xAxisLeft),

        binWidth = (width - xAxisLeft) / distr.length,

        freqToHeight = chartHeight / yTicks.limit,

        gridElements = new Bricks.Grid(bp.append('g'),
            U.times(distr.length, b => (b+1) * binWidth),
            yAxis.numericalValues.map (val => chartHeight - val * freqToHeight),
            width - xAxisLeft,
            height - xAxisHeight,
            true).render(xAxisLeft, 0),

        yAxisElements:JSX.Element[] = yAxis.render(xAxisLeft - yAxis.width, 0, chartHeight),

        histogramElements = new Bricks.Histogram(bp.append('h'), barValues, color, distr.map ((vf,index) =>[
                new TooltipRow(vf.value, 'category'),
                new TooltipRow(barValues[index], probability ? 'probability' : 'frequency')
            ], false), yTicks.limit, width - xAxisLeft, chartHeight)
                .render(xAxisLeft, 0)

    return RC.getRenderResult(width, height, plotOnly
        ? [
            ...gridElements,
            ...histogramElements,
        ]
        : [
            ...xAxisElements,
            ...yAxisElements,
            ...gridElements,
            ...histogramElements
        ])
}
