/** @jsxImportSource @emotion/react */
import * as React from 'react'
import css from './Visualizer.module.scss'
import {Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Select, Slider} from "@material-ui/core"
import {
    CursorType,
    GetActionsEvent,
    GetActionsEventType,
    ITooltip,
    IVisualizer,
    SliderInfo,
    TooltipsById,
    UserActionInfo,
    UserActionType,
    VisCtrlAction,
    VisCtrlActionType,
    VisCtrlId,
    VisCtrlInfo,
    VisCtrlStates,
    VisCtrlType,
    VisCtrlValue,
    VisCtrlValues,
    visTypeToString,
    VisualizerColumnData,
    VisualizerData,
} from "../Concepts/Visualizer"
import {userSettings} from "../LocalSettings"
import * as U from "../Utils"
import * as MTU from "../MainThreadUtils"
import event from "../EventSender/Events"
import {SelectChangeEvent} from "@material-ui/core/Select/Select"
import {Icon} from "../Icons"
import dom2image from 'dom-to-image'
import {Workspace} from "../Workspace/Workspace"
import {TM} from "../@testing/TestMarker"
import {IDataTable} from "../Concepts/DataTable"
import {ISpecifiedVisSettings} from "../Concepts/VisSettings"
import $t from "../i18n/i18n"
import {Size} from "../Concepts/Geometry"

const TOOLTIP_PIVOT_OFFSET_X = 30,
      TOOLTIP_PIVOT_OFFSET_Y = 14,
      TOOLTIP_OPACITY = "0.9",
      MIN_WIDTH = 30

interface Props {
    width: number
    height: number
    vis: IVisualizer
    visSettings: ISpecifiedVisSettings
    selectedColumnsSnapshot: string
    dataTable: IDataTable
    onHeightChanged: (height:number)=>void
}

interface State {
    visualizer?: IVisualizer
    columnConfig: string
    data: VisualizerData | null
    columnData: VisualizerColumnData | null
    controlValues: VisCtrlValues
    controlStates: VisCtrlStates
    visCursor: CursorType
}

type EventHandler = (...args:any[])=>void

export class VisContainer extends React.PureComponent<Props, State> {
    override readonly state:State = {
        columnConfig: '',
        controlValues: new Map (),
        controlStates: new Map(),
        data: null,
        columnData: null,
        visCursor: "default"
    }
    protected contentRef: React.RefObject<HTMLDivElement> = React.createRef()
    protected tooltipRef: React.RefObject<HTMLDivElement> = React.createRef()
    protected tooltips: TooltipsById = new Map ()
    protected tooltip: ITooltip | undefined
    protected eventHandlers: Map<VisCtrlId, Map<string, EventHandler>> = new Map ()
    protected controls: Map<VisCtrlId, VisCtrlInfo> = new Map ()
    protected contentHeight = 0
    protected lastContentHeight = 0
    protected dataRequestId = 0
    protected title: string|undefined
    protected lastHoveredElementId?: string|null
    protected svgPoint: DOMPoint|undefined
    protected svgSize: Size = {height:0, width:0}
    protected screenCTM: DOMMatrix|null = null
    protected lastClientSize: Size = {width: 0, height:0}

    static readonly xPadding = 8;

    static getDerivedStateFromProps(props:Props, state:State):State | null {
        const columnConfig = props.dataTable.visualConfigSnapshot
        return props.vis !== state.visualizer || columnConfig !== state.columnConfig ? {
            visualizer: props.vis,
            columnConfig: columnConfig,
            controlValues: new Map (),
            controlStates: new Map(),
            data: null,
            columnData: null,
            visCursor: "default"
        } : null
    }

    override componentDidMount() {
        this.updateComponent()
        userSettings.subscribe(this.handleUserSettingsChanged)
    }

    override componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        this.updateComponent()
    }

    override componentWillUnmount() {
        userSettings.unsubscribe(this.handleUserSettingsChanged)
        this.dataRequestId = 0
    }

    protected applyActions (actions:VisCtrlAction[], controlValues:VisCtrlValues, controlStates:VisCtrlStates, other:{visCursor:CursorType}): {reloadData:boolean} {
        const result = {reloadData:false}
        for (const action of actions) {
            const controlState = action.controlId !== undefined ? controlStates.get(action.controlId) : undefined
            if (controlState !== undefined) {
                const newHiddenOptions = new Set(controlState.hiddenOptions) // for the sake of deep copy
                switch (action.action) {
                    case VisCtrlActionType.EnableControl:
                        controlState.enabled = true
                        break
                    case VisCtrlActionType.DisableControl:
                        controlState.enabled = false
                        break
                    case VisCtrlActionType.ShowControl:
                        controlState.visible = true
                        break
                    case VisCtrlActionType.HideControl:
                        controlState.visible = false
                        break
                    case VisCtrlActionType.HideOption:
                        if (action.controlValue !== undefined) {
                            newHiddenOptions.add(action.controlValue)
                            controlState.hiddenOptions = newHiddenOptions
                        }
                        break
                    case VisCtrlActionType.ShowOption:
                        if (action.controlValue !== undefined) {
                            newHiddenOptions.delete(action.controlValue)
                            controlState.hiddenOptions = newHiddenOptions
                        }
                        break
                    case VisCtrlActionType.SetValue:
                        if (action.controlId !== undefined && action.controlValue !== undefined) {
                            controlValues.set(action.controlId, action.controlValue)
                        }
                        break
                    case VisCtrlActionType.SetSliderMarks:
                        if (action.controlId !== undefined && action.sliderMarks !== undefined && this.controls.has(action.controlId)) {
                            (this.controls.get(action.controlId) as SliderInfo).marks = action.sliderMarks
                        }
                        break
                    case VisCtrlActionType.SetSliderMinMax:
                        if (action.controlId !== undefined && action.sliderMinMax !== undefined && this.controls.has(action.controlId)) {
                            const sliderInfo = this.controls.get(action.controlId) as SliderInfo
                            [sliderInfo.min, sliderInfo.max] = action.sliderMinMax
                        }
                        break
                    case VisCtrlActionType.SetVisCursor:
                    case VisCtrlActionType.ReloadData:
                        break
                    default:
                        U.shouldNeverGetHere(action.action)
                }
            } else {
                switch (action.action) {
                    case VisCtrlActionType.ReloadData:
                        result.reloadData = true
                        break
                    case VisCtrlActionType.SetVisCursor:
                        other.visCursor = action.cursor ?? "default"
                        break

                }
            }
        }
        return result
    }

    protected updateComponent () {
        let columnData = this.state.columnData,
            data = this.state.data,
            controlValues = this.state.controlValues,
            controlStates = this.state.controlStates,
            other = {visCursor: this.state.visCursor}

        this.svgPoint = undefined
        this.svgSize = {width: 0, height:0}

        if (this.contentHeight !== this.lastContentHeight) {
            this.lastContentHeight = this.contentHeight
            this.props.onHeightChanged(this.contentHeight)
        }

        if (columnData === null || data === null) {
            columnData = this.props.vis.getColumnData(this.props.dataTable)
            this.controls = this.props.vis.getControls(this.props.dataTable, columnData)
            this.eventHandlers.clear()
            const controls = [...this.controls.entries()]
            controlStates = new Map(controls.map (entry => [entry[0], {enabled:true, visible:true, hiddenOptions:new Set()}]))
            controlValues = new Map(controls.map (entry => [entry[0], this.props.visSettings.get(entry[0], entry[1].defaultValue)]))
            other = {visCursor: "default"}

            this.applyActions(
                this.props.vis.getActionsOnControls({type: GetActionsEventType.BeforeDataLoaded}, this.props.dataTable, columnData, controlValues),
                controlValues,
                controlStates,
                other
            )

            this.dataRequestId = Date.now()
            this.props.vis.getData(this.dataRequestId, this.props.dataTable, columnData, controlValues).then(res => {
                if (this.dataRequestId === res.requestId) {
                    data = res.data
                    const event:GetActionsEvent = {type: this.props.visSettings.isSuggested
                            ? GetActionsEventType.DataLoadedWithSuggestedControlValues
                            : GetActionsEventType.DataLoadedWithUserControlValues,
                        data
                    }

                    this.applyActions(
                        this.props.vis.getActionsOnControls(event, this.props.dataTable, U.notNull(columnData), controlValues),
                        controlValues,
                        controlStates,
                        other
                    )

                    this.dataRequestId = 0
                    this.setState({
                        columnConfig: this.props.dataTable.visualConfigSnapshot,
                        controlValues: controlValues,
                        controlStates: controlStates,
                        columnData,
                        data,
                        visCursor: other.visCursor
                    })
                }
            })
        } else if (columnData !== this.state.columnData) {
            this.setState({
                columnConfig: this.props.dataTable.visualConfigSnapshot,
                controlValues: controlValues,
                controlStates: controlStates,
                columnData: columnData,
                data: data,
                visCursor: other.visCursor
            })
        }
    }

    protected toClientCoord = (x:number, y:number, ctm:DOMMatrix | null) => ctm !== null
        ? {x: ctm.e + x * ctm.a + y * ctm.c, y: ctm.f + x * ctm.b + y * ctm.d}
        : {x: x, y: y}

    protected client2svg = (x:number, y:number):DOMPoint|undefined => {
        if (!this.svgPoint || this.lastClientSize.width !== this.props.width || this.lastClientSize.height !== this.props.height) {
            this.lastClientSize.width = this.props.width
            this.lastClientSize.height = this.props.height
            const chartDiv = this.contentRef.current
            if (chartDiv && chartDiv.children[0]?.tagName === "svg") {
                const svg = chartDiv.children[0] as SVGSVGElement
                this.svgPoint = svg.createSVGPoint()
                this.screenCTM = svg.getScreenCTM()
                this.svgSize = {width: svg.width.baseVal.value, height:svg.height.baseVal.value}
            }
        }

        if (this.svgPoint && this.screenCTM) {
            this.svgPoint.x = x
            this.svgPoint.y = y
            return this.svgPoint.matrixTransform(this.screenCTM.inverse())
        }

        return undefined
    }

    protected handleDownload = () => {
        event.analytics.visualizer.menu.download.png (visTypeToString(this.props.vis.type))
        const chartDiv = this.contentRef.current
        if (chartDiv) {
            Workspace.backdrop($t("viscontainer.creating_png"))
            setTimeout(() => dom2image
                // had to multiply the height by 1.05 to work around a strange bug cutting image's bottom off
                .toPng(chartDiv, {bgcolor: "white", height: chartDiv.offsetHeight * 1.05})
                .then(data => MTU.downloadDataURLAsFile(data, (this.title ?? "chart").replace(/[^\s\w_\-()~@]/gi, '') + ".png"))
                .finally(() => Workspace.backdrop(null)),
            500)
        }
    }

    protected processUserAction = (type: UserActionType, evt:React.MouseEvent | React.TouchEvent) => {
        let processed = false

        function isMouseEvent(evt: React.MouseEvent | React.TouchEvent): evt is React.MouseEvent {
            return (evt as React.MouseEvent).clientX !== undefined;
        }

        let userActionInfo:UserActionInfo|undefined

        if (isMouseEvent(evt)) {
            const point = this.client2svg (evt.clientX, evt.clientY)
            if (point && point.x >=0 && point.y >=0 && point.x < this.svgSize.width && point.y < this.svgSize.width) {
                if (type !== UserActionType.Wheel) {
                    evt.preventDefault()
                }
                evt.stopPropagation()
                userActionInfo = {
                    type,
                    svgX: point.x,
                    svgY: point.y,
                    wheelDelta: type === UserActionType.Wheel ? (evt as React.WheelEvent).deltaY : undefined
                }
            }
        } else {
            const point1 = evt.touches.length > 0 ? this.client2svg (evt.touches[0].clientX, evt.touches[0].clientY) : undefined
            const point2 = evt.touches.length > 1 ? this.client2svg (evt.touches[1].clientX, evt.touches[1].clientY) : undefined

            if (type !== UserActionType.TouchStart && type !== UserActionType.TouchMove) {
                evt.preventDefault()
            }
            evt.stopPropagation()

            userActionInfo = {
                type,
                svgX: point1?.x ?? NaN,
                svgY: point1?.y ?? NaN,
                svgX2: point2?.x,
                svgY2: point2?.y
            }
        }

        if (userActionInfo) {
            const response = this.props.vis.handleUserAction(
                userActionInfo,
                this.state.data,
                this.props.dataTable,
                this.state.columnData,
                this.state.controlValues)

            if (response && response.visControlActions.length) {
                const newControlValues = new Map(this.state.controlValues),
                    newControlStates = new Map([...this.state.controlStates.entries()].map(entry => [entry[0], U.shallowCopy(entry[1])])),
                    other = {visCursor: this.state.visCursor},
                    data = this.applyActions(response.visControlActions, newControlValues, newControlStates, other).reloadData ? null : this.state.data

                this.setState({controlValues: newControlValues, controlStates: newControlStates, data, visCursor: other.visCursor})

                if (this.controls !== undefined) {
                    for (const controlId of this.controls.keys()) {
                        const value = newControlValues.get(controlId)
                        if (value !== undefined) {
                            this.props.visSettings.set(controlId, value)
                        }
                    }
                }

                processed = true
            }
        }
        return processed
    }

    protected handleContentMenu = (evt:React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        this.processUserAction (UserActionType.RightClick, evt)
        return false
    }

    protected handleClick = (evt:React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        this.processUserAction (UserActionType.Click, evt)
    }

    protected handleDoubleClick = (evt:React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        this.processUserAction (UserActionType.DoubleClick, evt)
    }

    protected handleMouseDown = (evt:React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        this.processUserAction (UserActionType.MouseDown, evt)
    }

    protected handleMouseUp = (evt:React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        this.processUserAction (UserActionType.MouseUp, evt)
    }

    protected handleWheel = (evt:React.WheelEvent) => {
        this.processUserAction (UserActionType.Wheel, evt)
    }

    protected handleTouchStart = (evt:React.TouchEvent<HTMLDivElement>) => {
        this.processUserAction (UserActionType.TouchStart, evt)
    }

    protected handleTouchEnd = (evt:React.TouchEvent<HTMLDivElement>) => {
        this.processUserAction (UserActionType.TouchEnd, evt)
    }

    protected handleTouchCancel = (evt:React.TouchEvent<HTMLDivElement>) => {
        this.processUserAction (UserActionType.TouchCancel, evt)
    }

    protected handleTouchMove = (evt:React.TouchEvent<HTMLDivElement>) => {
        this.processUserAction (UserActionType.TouchMove, evt)
    }

    protected handleMouseMove = (evt:React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        this.processUserAction (UserActionType.MouseMove, evt)

        const element = document.elementFromPoint(evt.clientX, evt.clientY)
        const id = element?.getAttribute('id')
        if (element && id !== 'current-tooltip' && id !== this.lastHoveredElementId) {
            this.lastHoveredElementId = id
            const tooltip = id ? this.tooltips.get(id) : undefined
            const tooltipDiv = this.tooltipRef.current
            if (tooltip !== this.tooltip && tooltipDiv) {
                this.tooltip = tooltip
                if (tooltip) {
                    const svgElement = element.closest("svg")
                    const containerElement = element.closest('.' + css.visContainer)
                    if (svgElement && containerElement) {
                        const sr = svgElement.getBoundingClientRect(), cr = containerElement.getBoundingClientRect()
                        const pivot = this.toClientCoord(tooltip.pivot.x, tooltip.pivot.y, (element as SVGSVGElement).getCTM())
                        tooltipDiv.innerHTML = tooltip.html
                        tooltipDiv.style.display = "block";
                        tooltipDiv.style.opacity = TOOLTIP_OPACITY
                        tooltipDiv.style.left = (pivot.x - TOOLTIP_PIVOT_OFFSET_X + sr.x - cr.x) + 'px'
                        tooltipDiv.style.top = (pivot.y - tooltipDiv.clientHeight - TOOLTIP_PIVOT_OFFSET_Y + sr.y - cr.y) + 'px'
                    }
                } else {
                    tooltipDiv.style.opacity = "0"
                }
            }
        }
    }

    protected handleMouseOut = () => {
        const tooltipDiv = this.tooltipRef.current
        if (tooltipDiv) {
            this.tooltip = undefined
            tooltipDiv.style.opacity = "0"
        }
    }

    protected handleTooltipTransitionEnd = () => {
        const tooltipDiv = this.tooltipRef.current
        if (tooltipDiv && tooltipDiv.style.opacity === "0") {
            tooltipDiv.style.display = "none";
        }
    }

    protected askForActionsAndSetValue = (key:VisCtrlId, value:VisCtrlValue) => {
        if (this.state.data != null && this.state.columnData !== null && this.state.controlValues !== null && this.state.controlValues.get(key) !== value) {
            event.analytics.visualizer.setting(visTypeToString(this.props.vis.type), key, value)

            // create an updated set of control values
            const newControlValues = new Map(this.state.controlValues)
            newControlValues.set(key, value)

            const other = {visCursor: this.state.visCursor}

            // create a deeper copy of control states
            const newControlStates = new Map([...this.state.controlStates.entries()].map(entry => [entry[0], U.shallowCopy(entry[1])]))

            // ask visualizer for possible actions on controls
            const evt = {type:GetActionsEventType.ControlValueChangedByUser, controlId:key, newControlValue:value, data:this.state.data}
            const actions = this.props.vis.getActionsOnControls(evt, this.props.dataTable, this.state.columnData, newControlValues)
            const data = this.applyActions(actions, newControlValues, newControlStates, other).reloadData ? null : this.state.data
            this.setState({controlValues: newControlValues, controlStates: newControlStates, data, visCursor: other.visCursor})

            if (this.controls !== undefined) {
                for (const controlId of this.controls.keys()) {
                    const value = newControlValues.get(controlId)
                    if (value !== undefined) {
                        this.props.visSettings.set(controlId, value)
                    }
                }
            }
        }
    }

    protected cacheHandler = (key:VisCtrlId, event:string, handler:EventHandler):EventHandler => {
        let handlersForKey = this.eventHandlers.get (key)
        if (handlersForKey === undefined) {
            handlersForKey = new Map ()
            this.eventHandlers.set (key, handlersForKey)
        }
        let changeHandler = handlersForKey.get(event)
        if (changeHandler === undefined) {
            changeHandler = handler.bind(this, key)
            handlersForKey.set (event, changeHandler)
        }
        return changeHandler
    }

    protected handleUserSettingsChanged = () => {
        this.setState ({data:null, columnData:null})
    }

    protected handleSliderChange = (key:VisCtrlId, event:Event, value:number | number[]) => {
        this.askForActionsAndSetValue(key, Array.isArray(value) ? value.map (val => val.toString()).join(';') : value.toString())
    }

    protected handleRadioChange = (key:VisCtrlId, event:React.ChangeEvent<HTMLInputElement>, value: string) => {
        this.askForActionsAndSetValue(key, value)
    }

    protected handleSelectChange = (key:VisCtrlId, evt:SelectChangeEvent) => {
        this.askForActionsAndSetValue(key, evt.target.value)
    }

    protected getControl = (key:VisCtrlId, info: VisCtrlInfo, hiddenOptions:Set<string>, disabled: boolean): React.ReactNode => {
        const value = U.get(this.state.controlValues, key);
        switch (info.type) {
            case VisCtrlType.Slider:
            case VisCtrlType.RangeSlider:
                return <Box width={200}>
                        <Slider key={`control${key}`}
                            data-test={TM.visControl(key)}
                            value={
                                info.type === VisCtrlType.Slider
                                    ? U.parseIntNotNan(value)
                                    : (value.indexOf(';') < 0 ? [info.min ?? 0, info.max] : value.split(';').map (val => U.parseIntNotNan(val)))
                            }
                            valueLabelDisplay={info.valueLabelDisplay ?? "auto"}
                            step={info.step ?? 1}
                            min={info.min ?? 0}
                            marks={info.marks}
                            max={info.max}
                            css={{paddingTop: 24}}
                            disableSwap
                            disabled={disabled}
                            onChange={this.cacheHandler(key, 'onChange', this.handleSliderChange) as (event: Event, value: number | number[]) => void}
                    />
                </Box>

            case VisCtrlType.Radio:
                return <RadioGroup key={`control${key}`}
                                   row
                                   data-test={TM.visControl(key)}
                                   name={key.toString()}
                                   value={value}
                                   onChange={this.cacheHandler(key, 'onChange', this.handleRadioChange) as (event: React.ChangeEvent<HTMLInputElement>, value: string) => void}>
                    {
                        [...info.options.keys()]
                            .filter(optKey => !hiddenOptions.has(optKey))
                            .map(optKey => <FormControlLabel key={optKey} value={optKey} control={<Radio/>}
                                                             label={info.options.get(optKey)}/>)
                    }
                </RadioGroup>

            case VisCtrlType.Select:
                return (
                    <Select
                        key={`control${key}`}
                        native
                        data-test={TM.visControl(key)}
                        name={key.toString()}
                        value={value}
                        disabled={disabled}
                        onChange={this.cacheHandler(key, 'onChange', this.handleSelectChange) as (evt: SelectChangeEvent) => void}
                        variant="standard">
                        {
                            [...info.options.keys()]
                                .filter(optKey => !hiddenOptions.has(optKey))
                                .map(optKey => <option key={optKey} value={optKey}>{info.options.get(optKey)}</option>)
                        }
                    </Select>
                );

                default:
                    U.shouldNeverGetHere(info)
        }
    }

    override render() {
        if (this.props.width >= MIN_WIDTH && this.state.data !== null && this.state.columnData !== null) {
            const content = this.props.vis.getContent(
                this.state.data,
                this.props.width - VisContainer.xPadding * 2,
                this.props.dataTable,
                this.state.columnData,
                this.state.controlValues,
                this.tooltips)

            this.contentHeight = content.height

            const controlBlocks:JSX.Element[] = []
            if (this.controls !== undefined) {
                for (const [key, info] of this.controls.entries()) {
                    if (this.state.controlStates.get (key)?.visible !== false) {
                        const disabled = this.state.controlStates.get (key)?.enabled === false
                        controlBlocks.push(
                            <Box key={key} p={2}>
                                <Grid key={'title'} item>
                                    <Box fontWeight="fontWeightMedium">
                                        {info.title}
                                    </Box>
                                </Grid>
                                <Grid key={'control'} item>
                                    <FormControl
                                        disabled={disabled}
                                        variant="standard">
                                        {this.getControl(key, info, this.state.controlStates.get (key)?.hiddenOptions ?? new Set(), disabled)}
                                    </FormControl>
                                </Grid>
                            </Box>
                        )
                    }
                }
            }

            this.title = content.title

            return (
                <div>
                    <ControlPanel controls={controlBlocks} />
                    <div className={css.chartContainer} onMouseOut={this.handleMouseOut}>
                        <ChartMenu onDownload={this.handleDownload} />
                        <Title text={content.title} width={this.props.width} />
                        <div id="vis-container" style={{paddingLeft:VisContainer.xPadding, paddingRight:VisContainer.xPadding, cursor:this.state.visCursor}} className={css.visContainer}
                             onContextMenu={this.handleContentMenu}
                             onMouseMove={this.handleMouseMove}
                             onClick={this.handleClick}
                             onDoubleClick={this.handleDoubleClick}
                             onMouseDown={this.handleMouseDown}
                             onMouseUp={this.handleMouseUp}
                             onWheel={this.handleWheel}
                             onTouchStart={this.handleTouchStart}
                             onTouchEnd={this.handleTouchEnd}
                             onTouchCancel={this.handleTouchCancel}
                             onTouchMove={this.handleTouchMove}
                        >
                            <div data-test={TM.visualizerOutput} ref={this.contentRef}>{ content.node }</div>
                            <TooltipPlaceholder ref={this.tooltipRef} onTransitionEnd={this.handleTooltipTransitionEnd} />
                        </div>
                        <Footer text={content.footer} width={this.props.width} />
                    </div>
                </div>
            )
        } else {
            return <div />
        }
    }
}

function ControlPanel (props: {controls:JSX.Element[]}) {
    return props.controls.length
        ? <div className={css.controlPanel}>{props.controls}</div>
        : null
}

function Title (props: {text:string|undefined, width:number}) {
    return props.text
        ? <div className={css.title} style={{fontSize: 10 * (props.width / 1000) + 14}}>{props.text}</div>
        : null
}

function Footer (props: {text:string|undefined, width:number}) {
    return props.text
        ? <div className={css.footer} style={{fontSize: 10 * (props.width / 1000) + 12}}>{'* ' + props.text}</div>
        : null
}

function ChartMenu (props: {onDownload: ()=>void}) {
    return <div className={css.chartMenu}>
        <div title={$t('viscontainer.download_png')} onClick={props.onDownload}>
            <Icon type="download" className={css.actionIcon}/>
        </div>
    </div>
}

const TooltipPlaceholder = React.forwardRef((props: {onTransitionEnd: ()=>void}, ref:React.ForwardedRef<HTMLDivElement>) => <div
    ref={ref}
    style={{display: "none"}}
    id='current-tooltip'
    className={U.cls(css.callOut, true,  css.bottom, true)}
    onTransitionEnd={props.onTransitionEnd}
/>)