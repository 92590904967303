/** @jsxImportSource @emotion/react */
import React from 'react'
import {TransitionProps} from '@material-ui/core/transitions'
import {
    Button, Card, CardActionArea, CardContent, CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    Typography
} from "@material-ui/core";
import event from "../../EventSender/Events"
import $t from "../../i18n/i18n"

interface Props {
    open: boolean
    onSelection: (preset: string) => void
    onClose: (changed: boolean)=>void
}

interface State {
    opened: boolean
}

interface PresetInfo {
    name: string
    title: string
    description: string
}

const presets: PresetInfo[] = [
    {
        name: "scatterplot",
        title: $t('presets.scatterplot.title'),
        description: $t('presets.scatterplot.descr')
    },
    {
        name: "histogram",
        title: $t('presets.histogram.title'),
        description: $t('presets.histogram.descr')
    },
    {
        name: "linegraph",
        title: $t('presets.linegraph.title'),
        description: $t('presets.linegraph.descr')
    }

]

export class PresetsDialog extends React.PureComponent<Props, State> {

    override readonly state:State = {
        opened: false
    }

    protected readonly transition = React.forwardRef(function Transition(
        props: TransitionProps & { children?: React.ReactElement<any, any> },
        ref: React.Ref<unknown>,
    ) {
        return <Slide direction="right" ref={ref} {...props} />;
    })

    static getDerivedStateFromProps(props:Props, state:State):State | null {
        if (props.open !== state.opened) {
            (props.open ? event.app.preset.list : event.ui.dialog.close)()
            return { opened: props.open }
        } else {
            return null
        }
    }

    protected handleClose = () => {
        this.props.onClose(false)
    }

    override render () {
        return (
            <Dialog open={this.props.open}
                    onClose={this.handleClose}
                    aria-labelledby="settings-dialog-title"
                    TransitionComponent={this.transition}
                    fullWidth={true}
                    maxWidth={'lg'}
            >
                <DialogTitle id="settings-dialog-title">{$t('presetsdialog.title')}</DialogTitle>
                <DialogContent>
                    {
                        presets.map (preset => <Card key={preset.name}
                                                     sx={{ maxWidth: 345, m: 2, display: "inline-block" }} variant="outlined">
                            <CardActionArea onClick={() => this.props.onSelection(preset.name)}>
                                <CardMedia
                                    component="img"
                                    height="215"
                                    image={`/presets/${preset.name}.png`}
                                    alt={preset.title}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {preset.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {preset.description}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>)
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                        {$t("dialog.button.close")}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}
