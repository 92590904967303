import {CategoryValue, ColumnId} from "./Basic"
import {FirstMoment} from "./DateTime"

export enum AggregationType {Sum = "Sum", Mean = "Mean", Min = "Min", Max = "Max", NullCount = "NullCount", NonNullCount = "NonNullCount"}

export type Aggregation<BreakDownType> = Map<BreakDownType, Map<ColumnId, number> | null>

export type AggregationByCategory = Aggregation<CategoryValue>

export type AggregationByInterval = Aggregation<FirstMoment | null>
