import * as U from "../Utils"
import * as STT from "../StypeTools"
import {ColumnId, ColumnOfValues, ColumnsById, ColumnSummaryType, DataValue, ITableColumn,} from "../Concepts/Basic"
import {LineChartDataEntry, LineChartInfo} from "../Concepts/Visualizers/Renderers/LineChart"
import $t from "../i18n/i18n";

export function sampleLineChartData (xValues:number[], chartInfo:LineChartInfo, targetNumberOfEntries:number):LineChartDataEntry[] {
    const entries: LineChartDataEntry[] = xValues.map((x, xIndex) => ({
        x,
        series: chartInfo.serValues.map(values => values[xIndex])
    })),
        numberOfValuesToExclude = entries.length - targetNumberOfEntries

    if (numberOfValuesToExclude <= 0) {
        return entries
    } else {
        const rate = numberOfValuesToExclude / entries.length
        // true => drop one of candidates, false => leave one of candidates
        const doDrop = rate < 0.5
        const dropRate = doDrop ? rate : 1 - rate

        if (STT.isCategorical(chartInfo.stype)) {
            if (entries[0].series.length>1) {
                throw Error ("Didn't expect multiple series for categorical values")
            }

            const sampledEntries: LineChartDataEntry[] = [],
                catValueFreqs = new Map<Exclude<DataValue, number>, number> ()

            let dropCandidates: LineChartDataEntry[] = [], prevFraction = 0

            for (let i = 0; i < entries.length && sampledEntries.length < targetNumberOfEntries; i++) {
                const fraction = dropRate * i - Math.floor(dropRate * i),
                    entry = entries[i],
                    value = entry.series[0] as Exclude<typeof entry.series[0], number>,
                    count = catValueFreqs.get (value)
                catValueFreqs.set (value, (count ?? 0) + 1)
                dropCandidates.push(entries[i])

                if (fraction < prevFraction || i === entries.length - 1) {
                    let leastTipicalValue: Exclude<DataValue, number> | undefined = undefined,
                        mostTipicalValue: Exclude<DataValue, number> | undefined = undefined,
                        leastTipicalValueFreq = Infinity,
                        mostTipicalValueFreq = -Infinity

                    for (const freqEntry of catValueFreqs.entries()) {
                        if (freqEntry[1] > mostTipicalValueFreq) {
                            mostTipicalValueFreq = freqEntry[1]
                            mostTipicalValue = freqEntry[0]
                        }

                        if (freqEntry[1] < leastTipicalValueFreq) {
                            leastTipicalValueFreq = freqEntry[1]
                            leastTipicalValue = freqEntry[0]
                        }
                    }

                    catValueFreqs.clear()

                    if (U.mustBeDefined(leastTipicalValue) && U.mustBeDefined(mostTipicalValue)) {
                        if (doDrop) {
                            // drop one candidate having the least tipical value
                            const indexOfCandidateWithLeastTipicalValue = dropCandidates.findIndex(candidate => candidate.series[0] === leastTipicalValue)
                            if (indexOfCandidateWithLeastTipicalValue < 0) {
                                throw new Error(`Could not find the least tipical value (${leastTipicalValue}) among drop candidates`)
                            }
                            for (let i = 0; i < dropCandidates.length && sampledEntries.length < targetNumberOfEntries; i++) {
                                if (doDrop && i !== indexOfCandidateWithLeastTipicalValue) {
                                    sampledEntries.push(dropCandidates[i])
                                }
                            }
                        } else {
                            // leave only one candidate having the most tipical value
                            const indexOfCandidateWithMostTipicalValue = dropCandidates.findIndex(candidate => candidate.series[0] === mostTipicalValue)
                            if (indexOfCandidateWithMostTipicalValue < 0) {
                                throw new Error(`Could not find the most tipical value (${mostTipicalValue}) among drop candidates`)
                            }
                            sampledEntries.push(dropCandidates[indexOfCandidateWithMostTipicalValue])
                        }
                        dropCandidates = []
                    }
                }

                /*
                if (doDrop && (fraction >= prevFraction || i === entries.length - 1)) {
                    sampledEntries.push(entries[i])
                } else if (!doDrop && (fraction < prevFraction || i === entries.length - 1)) {
                    sampledEntries.push(entries[i])
                }
                */
                prevFraction = fraction
            }
            return sampledEntries
        }
        else if (STT.isContinuous(chartInfo.stype)) {
            const sampledEntries: LineChartDataEntry[] = [],
                sumsOfSeries = entries.reduce((result, entry, index) =>
                        index > 0
                            ? result.map((v, i) => v + ((entry.series[i] as Exclude<typeof entry.series[0], string>) ?? 0))
                            : result,
                    entries[0].series.map(v => (v as Exclude<typeof v, string>) ?? 0)
                ),
                meansOfSeries = sumsOfSeries.map(s => s / entries.length)

            let dropCandidates: LineChartDataEntry[] = [], prevFraction = 0

            for (let i = 0; i < entries.length && sampledEntries.length < targetNumberOfEntries; i++) {
                const fraction = dropRate * i - Math.floor(dropRate * i)
                dropCandidates.push(entries[i])

                if (fraction < prevFraction || i === entries.length - 1) {

                    let minRelativeDeviation = Infinity, indexOfCandidateWithMinDeviation = 0
                    let maxRelativeDeviation = -Infinity, indexOfCandidateWithMaxDeviation = 0
                    for (let candidateIndex = 0; candidateIndex < dropCandidates.length; candidateIndex++) {
                        const candidateSeries = dropCandidates[candidateIndex].series
                        const avgRelativeDeviation = U.sum(U.times(chartInfo.serInfo.length, seriesIndex => {
                            const value = (candidateSeries[seriesIndex] ?? meansOfSeries[seriesIndex]) as Exclude<typeof candidateSeries[0], string>
                            return value === null ? 0 : Math.abs(meansOfSeries[seriesIndex] - value) / Math.max(1e-6, Math.abs(meansOfSeries[seriesIndex]))
                        })) / chartInfo.serInfo.length

                        if (avgRelativeDeviation <= minRelativeDeviation) {
                            indexOfCandidateWithMinDeviation = candidateIndex
                            minRelativeDeviation = avgRelativeDeviation
                        }

                        if (avgRelativeDeviation > maxRelativeDeviation) {
                            indexOfCandidateWithMaxDeviation = candidateIndex
                            maxRelativeDeviation = avgRelativeDeviation
                        }
                    }

                    if (doDrop) {
                        // drop one candidate having the minimum relative deviation from the average value
                        for (let i = 0; i < dropCandidates.length && sampledEntries.length < targetNumberOfEntries; i++) {
                            if (doDrop && i !== indexOfCandidateWithMinDeviation) {
                                sampledEntries.push(dropCandidates[i])
                            }
                        }
                    } else {
                        // leave only one candidate having the maximum relative deviation from the average value
                        sampledEntries.push(dropCandidates[indexOfCandidateWithMaxDeviation])
                    }

                    dropCandidates = []
                }

                prevFraction = fraction
            }
            return sampledEntries
        }
        else {
            throw Error(`${chartInfo.stype} is not expected here`)
        }
    }
}

export function sampleScatterPlotData (data:ColumnsById, xColumn:ITableColumn, yColumn:ITableColumn, segmentsPerContinuesAxis:number, maxDotsInSquareSegment:number):ColumnsById {
    const xValues = U.get(data, xColumn.id),
        yValues = U.get(data, yColumn.id),
        rowCount = xValues.length,
        newData = new Map<ColumnId, ColumnOfValues>(),
        valueCountByCoord: { [index: string]: { [index: string]: number } } = {},
        coordinate = (value: DataValue, column: ITableColumn): string => {
            return column.summary.type === ColumnSummaryType.Number && !column.numberSummary.categories
                ? Math.min (segmentsPerContinuesAxis - 1, Math.floor(
                    ((value as number) - column.summary.numberSummary.min)
                    / ((column.summary.numberSummary.max - column.summary.numberSummary.min) / segmentsPerContinuesAxis)
                )).toString()
                : U.deNull(value)
        }

    for (const columnId of data.keys()) {
        newData.set(columnId, [])
    }

    for (let i = 0; i < rowCount; i++) {
        const x = coordinate(xValues[i], xColumn)
        const y = coordinate(yValues[i], yColumn),
            row = valueCountByCoord[y] ?? {},
            count = row[x] ?? 0

        if (count < maxDotsInSquareSegment) {
            row[x] = count + 1
            valueCountByCoord[y] = row
            for (const columnId of data.keys()) {
                U.get(newData, columnId).push(U.get(data, columnId)[i])
            }
        }
    }

    return newData
}

export function addSamplingMessage(footer:string|undefined, samplingApplied:boolean, addition?:string):string {
    return [footer, samplingApplied ? $t('data_sampling_applied') + (addition ?? "") : ""].filter (part => part).join(', ')
}