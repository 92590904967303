const measureTextContext = document.createElement('canvas').getContext("2d") as CanvasRenderingContext2D

export function measureText (text: string, fontSize: number, fontFamily:string) {
    measureTextContext.font = `${fontSize}px ${fontFamily}`
    return measureTextContext.measureText(text).width
}

export function debounce(fn:()=>void, ms:number) {
    let timer: number | undefined;
    return () => {
        window.clearTimeout(timer)
        timer = window.setTimeout(() => {
            timer = undefined
            fn()
        }, ms);
    };
}

export function downloadDataURLAsFile(dataURL:string, fileName:string){
    const downloadAnchorNode = document.createElement('a')
    downloadAnchorNode.setAttribute("href", dataURL)
    downloadAnchorNode.setAttribute("download", fileName)
    document.body.appendChild(downloadAnchorNode) // required for firefox
    downloadAnchorNode.click()
    downloadAnchorNode.remove()
}

export function downloadJsonAsFile(text:string, exportName:string) {
    downloadDataURLAsFile("data:text/json;charset=utf-8," + encodeURIComponent(text), exportName + ".json")
}




