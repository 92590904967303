import React, {RefObject} from 'react'
import TableGridHeader from './TableGridHeader'
import TableGridBody from './TableGridBody'
import css from './TableGrid.module.scss'
import ResizeObserver from 'resize-observer-polyfill' // https://github.com/que-etc/resize-observer-polyfill
import * as U from "../Utils"
import * as MTU from "../MainThreadUtils"
import {measureText} from "../MainThreadUtils"
import * as Colors from "../Colors"
import * as STT from "../StypeTools"
import {ColumnDBIndex, ColumnId, ColumnSummaryType, ColumnUIIndex, DataValue} from "../Concepts/Basic"
import {userSettings} from "../LocalSettings"
import DropZone from "./DropZone"
import event from "../EventSender/Events"
import {IDataTable} from "../Concepts/DataTable"
import {WorkspaceStoreId} from "../Concepts/DataBase"

const WIDTH_DRAG_DEBOUNCE_TIME = 100,
      RESIZE_DEBOUNCE_TIME = 10;

export interface CellValue {
    val: string | number | null,
    str: string,
    color: string | null
}

export type DataRow = CellValue[] | symbol;

export enum ColumnColoringMode {
    None,
    ByColumnColor,
    ByCategoryColor
}

export type ColumnColoring =
    {mode: ColumnColoringMode.None}
    | {mode: ColumnColoringMode.ByColumnColor, excludeColumnIds?:ColumnId[]}
    | {mode: ColumnColoringMode.ByCategoryColor, categoryColumnId:ColumnId}

interface Props {
    columnConfigSpanshot: string,
    columnColoring: ColumnColoring
    dataTable: IDataTable
    onColumnWidthChange: (columnIndex:ColumnUIIndex, width:number) => void
    onColumnMove: (columnIndex:ColumnUIIndex, delta:1|-1) => void
    onColumnPin: (columnIndex:ColumnUIIndex) => void
    onColumnSelect: (columnIndex:ColumnUIIndex, exclusive:boolean) => void
    onDataDropped: (file:File|string)=>void
    listenKeyboard: boolean,
}

interface State {
    hoveredColumn: number | null
    widthDragColumn: ColumnUIIndex | null
    widthDragValue: number
    scrollColumns: number
    showPager: boolean
    nonPinnedColumns: number
    cacheVer: number
}

class RowCache {
    protected cache: DataRow[] = []
    protected _version = 0
    protected wsStoreId:WorkspaceStoreId|undefined

    reset () {
        this.cache = []
    }

    get version () {
        return this._version
    }

    get hasRows () {
        return this.cache.length > 0
    }

    maxValueLength (columnIndex:number) {
        let maxLength=0
        for (const row of this.cache) {
            if (typeof row !== 'symbol') {
                maxLength = Math.max (maxLength, row[columnIndex].str.length)
            }
        }
        return maxLength
    }

    has (wsStoreId: number, rowNumber:number) {
        this.complyWithStore(wsStoreId)
        return this.cache[rowNumber] !== undefined
    }

    set (wsStoreId: number, rowNumber:number, value:DataRow) {
        this.complyWithStore(wsStoreId)
        this.cache[rowNumber] = value
        this._version += 1
    }

    get (wsStoreId: number, startRow:number, nextAfterLastRow:number) {
        this.complyWithStore(wsStoreId)
        return this.cache.slice(startRow, nextAfterLastRow)
    }

    protected complyWithStore(wsStoreId: number) {
        if (this.wsStoreId !== wsStoreId) {
            this.reset()
            this.wsStoreId = wsStoreId
        }
    }
}

export class TableGrid extends React.PureComponent<Props,State> {
    protected widthDragTimer: number| undefined
    protected clientWidth = 0
    protected readonly containerRef: RefObject<HTMLDivElement> = React.createRef()
    protected readonly colMargin: number = (U.parseIntNotNan(css.columnDividerWidth) + U.parseIntNotNan(css.cellXPadding) * 2)
    protected observer: ResizeObserver | undefined
    protected readonly rowCache = new RowCache()

    override readonly state:State

    static readonly ROW_LOADING = Symbol();
    static readonly CHUNK_SIZE_ROWS = 70;

    constructor(props:Props) {
        super(props);
        this.state = {
            hoveredColumn: null,
            widthDragColumn: null,
            widthDragValue: 0,
            scrollColumns: 0,
            showPager: false,
            nonPinnedColumns: this.fullyVisibleNonPinnedColumns(0).count,
            cacheVer: 0
        }
    }

    protected handleWidthDragDone = () => {
        if (this.state.widthDragColumn !== null) {
            this.props.onColumnWidthChange(this.state.widthDragColumn, this.state.widthDragValue)
            this.setState({widthDragColumn: null, widthDragValue: 0})
            this.updateSizes()
        }
    }

    protected handleWidthDragging = (columnIndex:ColumnUIIndex, width:number) => {
        this.setState({widthDragColumn:columnIndex, widthDragValue: width})
        this.updateSizes(true)
    }

    protected handleColumnHover = (column:number | null) => {
        window.clearTimeout(this.widthDragTimer);
        if (column !== this.state.hoveredColumn) {
            if (column === null) {
                this.widthDragTimer = window.setTimeout(() => {
                    this.widthDragTimer = undefined;
                    this.setState({hoveredColumn: column});
                }, WIDTH_DRAG_DEBOUNCE_TIME);
            } else {
                this.setState({hoveredColumn: column});
            }
        }
    }

    protected handleColumnScroll = (delta:number) => {
        if (this.props.dataTable) {
            event.table.column.scroll(delta > 0)
            const val = Math.min(Math.max(0, this.state.scrollColumns + delta),
                this.props.dataTable.numberOfColumns - this.props.dataTable.numberOfPinnedColumns - this.state.nonPinnedColumns)
            this.setState({scrollColumns: val, nonPinnedColumns: this.fullyVisibleNonPinnedColumns(val).count})
        }
    }

    protected handleGetRows = (start:number, end:number):DataRow[] => {
        if (this.props.dataTable) {
            const dt = this.props.dataTable,
                rows = this.getRows(start, end),
                columnDbIndexes = dt.buildIndexToOrigIndexMap()
            return rows.map(r => Array.isArray(r) ? columnDbIndexes.map(i => r[i]) : r)
        } else {
            throw new Error ("this.props.dataTable should not be defined here")
        }
    }

    protected handleUserSettingsChanged = () => {
        this.rowCache.reset()
        this.setState({cacheVer: this.rowCache.version})
    }

    protected formatValue = (columnId:ColumnId, value:DataValue):CellValue => {
        if (this.props.dataTable) {
            const colInfo = this.props.dataTable.getColumnInfo(columnId)
            return {
                val: value,
                str: STT.formatTableCellValue(colInfo.stype, value),
                color: value === null
                    ? Colors.nullColor.normal
                    : STT.isCategorical(colInfo.stype)
                        ? (this.props.dataTable.getColumnInfo(columnId).categories.get(value.toString()) ?? Colors.noValueColor).dark
                        : colInfo.summary.type === ColumnSummaryType.Number && colInfo.numberSummary.categories
                            ? (colInfo.numberSummary.categories.legendCategories.get(value as number) ?? Colors.noValueColor).dark
                            : null
            }
        } else {
            throw new Error ("this.props.dataTable should not be defined here")
        }
    }

    protected spreadColumnWidths = () => {
        if (this.props.dataTable) {
            const dt = this.props.dataTable,
                padding = U.parseIntNotNan(css.cellXPadding),
                columnWidths = dt.columnWidthsByVisualIndex.map(width => width + padding * 2),
                freeSpace = this.clientWidth - U.sum(columnWidths) - columnWidths.length * U.parseIntNotNan(css.columnDividerWidth)
            if (freeSpace > 0) {
                const optimalColumnWidth = 100,
                    desirableSpace = U.sum(columnWidths.filter (w => w < optimalColumnWidth).map (w => optimalColumnWidth - w))
                if (desirableSpace > 0) {
                    const expandCoeff = Math.min(1, freeSpace / desirableSpace)
                    for (let columnIndex = 0; columnIndex < columnWidths.length; columnIndex++) {
                        if (columnWidths[columnIndex] < optimalColumnWidth) {
                            const newWidth = columnWidths[columnIndex] + Math.floor((optimalColumnWidth - columnWidths[columnIndex]) * expandCoeff)
                            this.props.onColumnWidthChange(ColumnUIIndex(columnIndex), newWidth - padding * 2)
                        }
                    }
                    this.updateSizes()
                }
            }
        }
    }

    protected handleFitColumnWidth = (columnIndex:ColumnUIIndex, automatic=false) => {
        if (this.props.dataTable) {
            event.table.column.autowidth(automatic)
            const maxValueLength = Math.max (3, this.rowCache.maxValueLength(columnIndex)),
                width = Math.floor(measureText('W'.repeat(maxValueLength), U.parseIntNotNan(css.tableBodyFontSize), css.tableBodyFontFamily)) + 5 // +5px to avoid ellipses
            this.props.onColumnWidthChange(columnIndex, Math.min (300, width))
            this.updateSizes()
        }
    }

    protected getRows (start: number, end: number):DataRow[] {
        if (this.props.dataTable) {
            const dt = this.props.dataTable
            start = Math.max(0, start)
            end = Math.max(0, end)
            const chunkStart = Math.min(dt.rowCount, Math.floor(start / TableGrid.CHUNK_SIZE_ROWS) * TableGrid.CHUNK_SIZE_ROWS)
            const chunkEnd = Math.min(dt.rowCount, Math.ceil(end / TableGrid.CHUNK_SIZE_ROWS) * TableGrid.CHUNK_SIZE_ROWS)
            for (let s = chunkStart; s < chunkEnd; s++) {
                if (!this.rowCache.has(dt.wsStoreId, s)) {
                    for (let j = s; j < chunkEnd; j++) {
                        if (!this.rowCache.has(dt.wsStoreId, j)) {
                            this.rowCache.set (dt.wsStoreId, j, TableGrid.ROW_LOADING);
                        }
                    }
                    dt.getRows(s, chunkEnd)
                        .then(result => {
                            for (const [i, row] of result.data.entries()) {
                                const cellValues = row.map((value, index) => this.formatValue(dt.columnIdByDBIndex(ColumnDBIndex(index)), value))
                                this.rowCache.set (dt.wsStoreId, result.start + i, cellValues)
                            }
                            if (this.props.dataTable && this.rowCache.hasRows) {
                                let found = false
                                this.props.dataTable.columnIndexesWithUnknownWidth.forEach(index => {
                                    found = true
                                    this.handleFitColumnWidth(ColumnUIIndex(index), true)
                                })
                                if (found) {
                                    this.spreadColumnWidths()
                                }
                            }
                            this.setState({cacheVer: this.rowCache.version})
                        })
                    break
                }
            }

            return this.rowCache.get(
                dt.wsStoreId,
                Math.min(this.props.dataTable.rowCount, start),
                Math.min(this.props.dataTable.rowCount, end)
            )
        } else {
            throw new Error ("this.props.dataTable should not be defined here")
        }
    }

    protected fullyVisibleNonPinnedColumns = (scroll:number = this.state.scrollColumns):{count:number, widthRest:number} => {
        if (this.props.dataTable) {
            if (this.containerRef.current) {
                const containerWidth = this.containerRef.current.clientWidth;
                let cnt = 0, width = 0;
                for (let i = 0; i < this.props.dataTable.numberOfColumns; i++) {
                    const col = this.props.dataTable.columnByIndex(ColumnUIIndex(i))
                    scroll -= col.pinned ? 0 : 1; // if scroll < 0 them the current column is visible
                    const colWidth = i === this.state.widthDragColumn ? this.state.widthDragValue : col.width
                    width += (col.pinned || scroll < 0) ? this.colMargin + colWidth : 0
                    if (width > containerWidth) {
                        break;
                    }
                    cnt += (col.pinned || scroll >= 0) ? 0 : 1;
                }
                return {count: cnt, widthRest: containerWidth - width};
            } else {
                return {
                    count: this.props.dataTable.numberOfColumns
                        - this.props.dataTable.numberOfPinnedColumns
                        - scroll,
                    widthRest: 0
                }
            }
        } else {
            return {count:0, widthRest:0}
        }
    }

    protected updateSizes(suppressScrolling = false) {
        this.setState(this.getColumnScrollingParams(suppressScrolling))
    }

    protected getColumnScrollingParams(suppressScrolling = false) {
        if (this.containerRef.current !== null) {
            this.clientWidth = this.containerRef.current.clientWidth
            if (this.props.dataTable) {
                const showPager = this.clientWidth < this.props.dataTable.numberOfColumns * this.colMargin + this.props.dataTable.totalColumnWidth - this.props.dataTable.lastColumn.width / 3
                const {count: nonPinned, widthRest} = this.fullyVisibleNonPinnedColumns()

                // decrease scrollColumns if we have enough space after the last visible column
                let scrollColumns = this.state.scrollColumns
                while (!suppressScrolling && scrollColumns && widthRest > 0) {
                    if (this.fullyVisibleNonPinnedColumns(scrollColumns - 1).widthRest > 0)
                        scrollColumns -= 1
                    else
                        break;
                }
                return {scrollColumns: scrollColumns, showPager: showPager, nonPinnedColumns: nonPinned}
            }
        }
        return {scrollColumns: 0, showPager: false, nonPinnedColumns: 0}
    }

    override componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (prevProps.dataTable !== this.props.dataTable) {
            this.rowCache.reset()
            this.setState({
                hoveredColumn: null,
                widthDragColumn: null,
                widthDragValue: 0,
                cacheVer: this.state.cacheVer + 1,
                ...this.getColumnScrollingParams(true)
            })
        }
    }

    override componentDidMount() {
        if (U.mustNotBeNull(this.containerRef.current)) {
            this.observer = new ResizeObserver(MTU.debounce(this.updateSizes.bind(this), RESIZE_DEBOUNCE_TIME).bind(this));
            this.observer.observe(this.containerRef.current);
        }
        userSettings.subscribe(this.handleUserSettingsChanged)
    }

    override componentWillUnmount() {
        if (this.observer) {
            this.observer.disconnect();
        }
        userSettings.unsubscribe(this.handleUserSettingsChanged)
    }

    override render() {
        const columnWidthChange = {column: this.state.widthDragColumn, width: this.state.widthDragValue};
        return (
            <DropZone onDataDropped={this.props.onDataDropped}>
                <div ref={this.containerRef} className={css.container}>
                    <TableGridHeader columns={this.props.dataTable.filteredColumnsByUiIndex(()=>true)}
                                     hoveredColumn={this.state.hoveredColumn}
                                     columnWidthChange={columnWidthChange}
                                     scrollColumns={this.state.scrollColumns}
                                     showPager={this.state.showPager}
                                     onWidthDragDone={this.handleWidthDragDone}
                                     onWidthDragging={this.handleWidthDragging}
                                     onColumnHover={this.handleColumnHover}
                                     onColumnScroll={this.handleColumnScroll}
                                     onColumnMove={this.props.onColumnMove}
                                     onColumnPin={this.props.onColumnPin}
                                     onColumnSelect={this.props.onColumnSelect}
                                     onFitColumnWidth={this.handleFitColumnWidth}
                                     nonPinnedColumns = {this.state.nonPinnedColumns}
                                     pinnedColumns = {this.props.dataTable.numberOfPinnedColumns}
                                     listenKeyboard={this.props.listenKeyboard}
                                     userSettingVersion={userSettings.version}
                    />
                    <TableGridBody table={this.props.dataTable}
                                   columnConfigSpanshot={this.props.columnConfigSpanshot}
                                   getRows={this.handleGetRows}
                                   rowCount={this.props.dataTable.rowCount}
                                   hoveredColumn={this.state.hoveredColumn}
                                   scrollColumns={this.state.scrollColumns}
                                   cacheVer={this.state.cacheVer}
                                   columnWidthChange={columnWidthChange}
                                   onColumnHover={this.handleColumnHover}
                                   onColumnSelect={this.props.onColumnSelect}
                                   nonPinnedColumns = {this.state.nonPinnedColumns}
                                   columnColoring = {this.props.columnColoring}
                                   listenKeyboard={this.props.listenKeyboard}
                                   userSettingVersion={userSettings.version}
                    />
                </div>
            </DropZone>
        )
    }
}