import {alias, createModelSchema, serializable} from "serializr"
import {CategoryValue, DataValue} from "./Basic"

export abstract class FrequencyOfValue<T extends DataValue | [number, number]> {
    @serializable(alias('v')) readonly value: T
    @serializable(alias('f')) freq: number

    constructor(value: T, freq: number) {
        this.value = value
        this.freq = freq
    }
}

export class NumFreq extends FrequencyOfValue<number> {}

export class CatFreq extends FrequencyOfValue<CategoryValue> {
}

export class RangeFreq extends FrequencyOfValue<[a: number, b: number]> {
    constructor(a: number, b: number, freq: number) {
        super([a, b], freq)
    }

    get a() {
        return this.value[0]
    }

    get b() {
        return this.value[1]
    }
}

// the following lines are necessary since some classes don't own any @serializable field that could make them visible for serializr
createModelSchema(NumFreq, {})
createModelSchema(CatFreq, {})
createModelSchema(RangeFreq, {})
