import Dexie from 'dexie'
import {deserialize, serialize} from "serializr"
import {DataValue} from "./Concepts/Basic"
import {EventRecord} from "./Concepts/Events"
import {
    DatabaseSchema,
    DB_PREFIX,
    IDataStore,
    IObjectStore,
    IWorkspaceStore,
    ObjectWithId,
    TableName,
    WorkspaceStoreId
} from "./Concepts/DataBase"

type DataBlock = {
    firstRow: number,
    lastRow: number,
    json: string
}

export class ObjectStore<T extends ObjectWithId> implements IObjectStore<T> {
    constructor(
        protected readonly table: Dexie.Table,
        protected readonly ctor: new (...args: any[]) => T
    ) {}

    async retrieveAll() {
        return (await this.table.toArray()).map(obj => deserialize(this.ctor, JSON.parse(obj.json) as Record<string, unknown>))
    }

    async bulkAdd(objects: T[]) {
        await this.table.bulkAdd(objects.map(object => ({
            id: object.id,
            json: JSON.stringify(serialize(object))
        })))
    }

    async put(object: T) {
        this.table.put({
            id: object.id,
            json: JSON.stringify(serialize(object))
        })
    }
}

export class DataStore implements IDataStore {
    constructor(protected readonly table: Dexie.Table) {
    }

    async retrieveAll() {
        return ([] as DataValue[][]).concat (...((await this.table.orderBy("firstRow").toArray()) as DataBlock[]).map(dataBlock => JSON.parse(dataBlock.json) as DataValue[][]))
    }

    async add(firstRow:number, values: DataValue[][]) {
        const dataBlock:DataBlock = {
            firstRow,
            lastRow: firstRow + values.length - 1,
            json: JSON.stringify(values)
        }
        return this.table.add(dataBlock)
    }
}

export class WorkspaceStore implements IWorkspaceStore {
    protected static readonly instances: IWorkspaceStore[] = []
    protected db:Dexie
    readonly data: IDataStore

    protected static schema: DatabaseSchema = {
        vissettings: 'id',
        columns: 'id',
        data: '++id,firstRow,lastRow'
    }

    constructor(public readonly id: WorkspaceStoreId) {
        this.db = new Dexie(DB_PREFIX + id)
        this.db.version(1).stores(WorkspaceStore.schema)
        this.db.on("versionchange", () => {
            this.db.close ()
            return false
        })
        WorkspaceStore.instances.push(this)
        this.data = new DataStore(this.table('data'))
    }

    static create(): IWorkspaceStore {
        return new WorkspaceStore(Date.now())
    }

    static async delete(id:WorkspaceStoreId): Promise<void> {
        await Dexie.delete(DB_PREFIX + id)
    }

    static get(id: WorkspaceStoreId): IWorkspaceStore {
        const foundStores = WorkspaceStore.instances.filter(ws => ws.id === id)
        return foundStores.length ? foundStores[0] : new WorkspaceStore(id)
    }

    protected table = (name:TableName) => this.db.table(name)

    createVisSettingsStore<T extends ObjectWithId>(ctor: new (...args: any[]) => T) {
        return new ObjectStore(this.table('vissettings'), ctor)
    }

    createTableColumnStore<T extends ObjectWithId>(ctor: new (...args: any[]) => T) {
        return new ObjectStore(this.table('columns'), ctor)
    }
}

export class EventStore {
    protected static _db:Dexie|undefined

    protected static get db():Dexie {
        if (EventStore._db && !EventStore._db.isOpen()) {
            EventStore._db = undefined
        }

        if (EventStore._db === undefined) {
            const db = new Dexie('dataisclear')
            db.version(1).stores({events: '++id'})
            db.on("versionchange", () => {
                db.close()
                return false
            })
            EventStore._db = db
        }

        return EventStore._db
    }

    static async add(record:EventRecord) {
        await EventStore.db.table<EventRecord>('events').add(record)
    }

    static async get(limit=100): Promise<(EventRecord & {id:number})[]> {
        return EventStore.db.table<EventRecord & {id:number}>('events').limit(limit).toArray()
    }

    static async delete(recordIds:number[]): Promise<void> {
        return EventStore.db.table('events').bulkDelete(recordIds)
    }
}