// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react'
import {ColumnId, INumberSummary} from "../../Concepts/Basic"
import {SemanticType} from "../../Concepts/SemanticType"
import {StandardColor} from "../../Concepts/Colors"
import {PercRange, RenderResult, TooltipsById} from "../../Concepts/Visualizer"
import * as U from "../../Utils"
import * as RC from './RendererCommon'
import * as Bricks from './Bricks'
import {LabelDataType, RectClipPath} from './Bricks'
import * as STT from "../../StypeTools"
import {TooltipRow} from '../VisCommon'
import {TM, UniqueTestMarker} from "../../@testing/TestMarker"

export function render (
    data:Map<ColumnId, INumberSummary>,
    range:PercRange,
    binCount:number,
    width:number,
    probability:boolean,
    yTitle:string,
    colors:Map<number, StandardColor>,
    seriesLabels:Map<number, string> | null,
    units:string,
    stype: SemanticType,
    tooltips:TooltipsById,
    showAsCategories = false,
    uniqueId= '',
    plotOnly = false
):RenderResult {

    const colIds = [...data.keys()],

        colCount = colIds.length,

        min = Math.min (...[...data.values()].map (summary => summary.min)),

        max = Math.max (...[...data.values()].map (summary => summary.max)),

        minValue = U.equal(min, max) ? min - 0.5 : (showAsCategories ? min : min + (max - min) * range.from / 100.0),

        maxValue = U.equal(min, max) ? max + 0.5 : (showAsCategories ? max : min + (max - min) * range.to / 100.0),

        multivaluedColIds = colIds.filter(colId => U.get(data, colId).min !== U.get(data, colId).max),

        maxChartHeight = 300,

        binValWidth = (maxValue - minValue) / binCount,

        chartHeight = plotOnly ? width : Math.min(width / (1.618 * Math.min (Math.max (colCount/2, 1), 2)), maxChartHeight),

        chartHeaderAreaHeight = chartHeight / (seriesLabels ? 6 : 30),

        boxPlotAreaHeight = chartHeight / 6,

        barChartAreaHeight = chartHeight - boxPlotAreaHeight,

        bp = new Bricks.Params(tooltips,'nfhsx'+uniqueId),

        histInfo:{values:number[], maximum:number, ticks:number}[] = U.times(colCount, c => {
            let values: number[] = []
            const summary = U.get(data, colIds[c])
            for (let b = 0; b < binCount; b++) {
                const n = binValWidth * b + minValue, m = binValWidth * (b + 1) + minValue
                values.push(summary.distribution.meanValueFreq
                    .filter(vf => vf.value >= n && (vf.value < m || (vf.value === m && b === binCount - 1)))
                    .reduce((sum, vf) => sum + vf.freq, 0)
                )
            }
            if (probability) {
                const sum = summary.distribution.meanValueFreq.reduce((sum, vf) => sum + vf.freq, 0)
                values = values.map(f => sum === 0 ? 0 : f / sum)
            }
            const ticks = RC.getPositiveAxisTicks(U.max(values))
            return {values, maximum:ticks.limit, ticks:ticks.tickCount}
        }),

        yAxisHeight = barChartAreaHeight - chartHeaderAreaHeight,

        yAxes:Bricks.YAxis[] = histInfo.map((info, index) =>
            new Bricks.YAxis(bp.append('y'+index),{
                type: LabelDataType.NumericalRange,
                values: [...U.splitRange(0, info.maximum, info.ticks)],
                stype: SemanticType.Number
            }, yAxisHeight, yTitle)),

        xAxisLeft = plotOnly ? 0 : Math.max (...yAxes.map (axis => axis.width)),

        xAxis = new Bricks.XAxis(bp.append('x'), {
            type: showAsCategories ? LabelDataType.NumericalCategories : LabelDataType.NumericalRange,
            values: showAsCategories
                ? [...U.range(minValue, maxValue, (maxValue-minValue)/(binCount - 1)), maxValue]
                : [...U.splitRange(minValue, maxValue, binCount)],
            includesNull: false,
            stype
        }, width - xAxisLeft, units),

        xAxisHeight = plotOnly ? 0 : xAxis.height,

        height = chartHeight * colCount + xAxisHeight,

        xAxisElements = xAxis.render (xAxisLeft, height - xAxisHeight, width - xAxisLeft),

        binWidth = (width - xAxisLeft) / binCount,

        valToPixel = (width - (showAsCategories ? binWidth : 0) - xAxisLeft) / (maxValue === minValue ? 1 : maxValue - minValue),

        chartTop = (chartIndex:number) => chartHeight * chartIndex,

        freqToHeight = (chartIndex:number) => histInfo[chartIndex].maximum ? yAxisHeight / histInfo[chartIndex].maximum : 1,

        chartIndex = (id:ColumnId) => colIds.indexOf(id),

        grid = new Bricks.Grid(bp.append('g'),
            U.times(binCount, b => (b+1) * binWidth),
            yAxes.map ((yAxis, chartIndex) => yAxis.numericalValues.map (val => chartTop(chartIndex) + barChartAreaHeight - val * freqToHeight(chartIndex))).flat(),
            width - xAxisLeft,
            height - xAxisHeight,
            true),

        gridElements = grid.render(xAxisLeft, 0),

        clipPath = new RectClipPath (bp.append('gridclp'), grid),

        yAxisElements:JSX.Element[] = yAxes.map ((yAxis, index) =>
            yAxis.render(xAxisLeft - yAxis.width, chartHeight * index + chartHeaderAreaHeight, yAxisHeight)
        ).flat(),

        tooltipTestMarker = new UniqueTestMarker(TM.chartElementWithTooltip),

        boxPlotElements = multivaluedColIds.map (colId =>
            new Bricks.BoxWithWhiskers(bp.append('b'+colId), U.get(data, colId), U.get(colors, colId),
                (value:number) => (value - minValue) * valToPixel + (showAsCategories ? binWidth / 2 : 0),
                width - xAxisLeft, boxPlotAreaHeight, stype, tooltipTestMarker.value)
                .render(xAxisLeft, chartTop(chartIndex(colId)+1) - boxPlotAreaHeight, undefined, clipPath)
        ).flat(),

        kdeElements = showAsCategories
            ? []
            : multivaluedColIds.map (colId =>
                new Bricks.Spline(bp.append('k'+colId), U.get(data, colId).kde.map(p => {
                    const kdeMax = U.get(data, colId).kde.reduce((max, p) => p.y > max ? p.y : max, 0),
                        kdeToHeight = yAxisHeight / (kdeMax === 0 ? 1 : kdeMax)
                    return {
                        x: (p.x - minValue) * valToPixel + xAxisLeft + (showAsCategories ? binWidth / 2 : 0),
                        y: chartTop(chartIndex(colId)) + barChartAreaHeight - p.y * kdeToHeight
                    }}), U.get(colors, colId))
                    .render(xAxisLeft, chartTop(chartIndex(colId))+chartHeaderAreaHeight, undefined, clipPath)
            ).flat(),

        histogramElements = colIds.map (colId =>
            new Bricks.Histogram(bp.append('h'+colId), histInfo[chartIndex(colId)].values, U.get(colors, colId), U.times(binCount, b =>[
                showAsCategories || multivaluedColIds.indexOf(colId) < 0
                    ? new TooltipRow(multivaluedColIds.indexOf(colId) < 0 ? U.get(data, colId).min : xAxis.getFullLabel(b), 'value')
                    : new TooltipRow(`[${STT.formatTooltipValue(stype, xAxis.numericalValues[b])}; ${STT.formatTooltipValue(stype, xAxis.numericalValues[b+1])}${b === binCount - 1 ? ']' : ')'}`, 'range'),
                new TooltipRow(histInfo[chartIndex(colId)].values[b], probability ? 'probability' : 'frequency')
            ]), histInfo[chartIndex(colId)].maximum, width - xAxisLeft, yAxisHeight)
                .render(xAxisLeft, chartTop(chartIndex(colId))+chartHeaderAreaHeight)).flat(),

        titleElements = colIds.filter (colId => seriesLabels !== null && seriesLabels.has(colId)).map ((colId, chartIndex) =>
            new Bricks.Title(bp.append('t'+colId), U.get(U.notNull(seriesLabels), colId),width - xAxisLeft, chartHeaderAreaHeight)
                .render(xAxisLeft, chartTop(chartIndex))).flat()

    const renderResult = RC.getRenderResult(width, height, plotOnly
            ? [
                ...gridElements,
                ...boxPlotElements,
                ...histogramElements,
                ...kdeElements,
            ]
            : [
                ...xAxisElements,
                ...yAxisElements,
                ...gridElements,
                ...boxPlotElements,
                ...histogramElements,
                ...kdeElements,
                ...titleElements
            ],
        [clipPath.def]
    )

    renderResult.xSVGToUnitsMapper = (svgX:number) => (svgX - grid.x) * (maxValue - minValue) / grid.width + minValue

    return renderResult
}
