import React, {RefObject} from 'react';
import css from './ScrollBar.module.scss';
import * as U from '../Utils'
import * as MTU from "../MainThreadUtils"
import ResizeObserver from 'resize-observer-polyfill'; // https://github.com/que-etc/resize-observer-polyfill

const MOUSE_SCROLL_DEBOUNCE_TIME = 100,
      MOUSE_WHEEL_LINE_HEIGHT = 30,
      TOUCH_SCROLL_DEBOUNCE_TIME = 50,
      RESIZE_DEBOUNCE_TIME = 100;

interface Props {
    handleKeys: boolean
    // the actual value of this prop is not used, it just needs be different in order to trigger control update
    contentHeight?: number
    onScrollPosChanged?: (scrollPos:number, contentHeight:number, containerHeight:number)=>void
}

interface State {
    offset: number
    scrollThumbPos: number
    scrollThumbHeight: number
    scrollVisible: boolean
    isScrolling: boolean
}

export default class ScrollBar extends React.PureComponent<Props, State> {
    override readonly state:State = {
        offset: 0,
        scrollThumbPos: 0,
        scrollThumbHeight:0,
        scrollVisible:false,
        isScrolling:false,
    }

    protected bodyRef: RefObject<HTMLDivElement> = React.createRef();
    protected contentRef: RefObject<HTMLDivElement> = React.createRef();
    protected scrollbarRef: RefObject<HTMLDivElement> = React.createRef();
    protected contentHeight = 1200;
    protected bodyHeight = 900;
    protected scrollBarHeight = 890;
    protected scrollDragStartY = 0;
    protected scrollDragStartOffset = 0;
    protected scrollDragLastTime = 0;
    protected isTouchScrolling = false;
    protected touchEndTime: number | null = null;
    protected observer: ResizeObserver | null = null;
    protected updateTimeoutID:number|undefined

    protected updateScrollPos (newOffset:number = this.state.offset) {
        let newScrollThumbPos = 0, newScrollThumbHeight = 15, newScrollVisible = false;

        if (this.contentHeight < this.bodyHeight) {
            newOffset = 0;
        } else {
            newOffset = Math.max(Math.min (newOffset, this.contentHeight - this.bodyHeight), 0);
            newScrollVisible = this.bodyHeight < this.contentHeight;
            newScrollThumbHeight = Math.max(Math.round(this.scrollBarHeight * (this.bodyHeight / (this.contentHeight || 1))), 15);
            newScrollThumbPos = Math.round((this.scrollBarHeight - newScrollThumbHeight) * (newOffset / ((this.contentHeight - this.bodyHeight) || 1)));
        }

        if (newOffset !== this.state.offset && this.props.onScrollPosChanged) {
            this.props.onScrollPosChanged (newOffset, this.contentHeight, this.bodyHeight)
        }

       this.setState({
            offset: newOffset,
            scrollThumbPos: newScrollThumbPos,
            scrollThumbHeight: newScrollThumbHeight,
            scrollVisible: newScrollVisible
        });
    }

    protected handleWheel = (event:React.WheelEvent) => {
        let delta = event.deltaY;
        if (event.deltaMode === 1) // The delta values are specified in lines
            delta *= MOUSE_WHEEL_LINE_HEIGHT
        else if (event.deltaMode === 2) // The delta values are specified in pages
            delta *= this.bodyHeight
        this.updateScrollPos(this.state.offset+delta)
    }

    protected handleKeyDown = (event:KeyboardEvent) => {
        if (this.props.handleKeys && !event.altKey && !event.ctrlKey && !event.shiftKey) {
            const delta = {
                ArrowUp: -MOUSE_WHEEL_LINE_HEIGHT,
                ArrowDown: MOUSE_WHEEL_LINE_HEIGHT,
                PageUp: -this.bodyHeight,
                PageDown: this.bodyHeight,
            }[event.key];

            if (delta) {
                event.preventDefault();
                event.stopPropagation();
                this.updateScrollPos(this.state.offset + delta);
            } else if (event.key === 'Home') {
                this.updateScrollPos(0);
            } else if (event.key === 'End') {
                this.updateScrollPos(this.contentHeight - this.bodyHeight);
            }
        }
    }

    protected updateElementSizes = () => {
        // noinspection SillyAssignmentJS
        [
            this.contentHeight = this.contentHeight,
            this.bodyHeight = this.bodyHeight,
            this.scrollBarHeight = this.scrollBarHeight
        ] = [this.contentRef, this.bodyRef, this.scrollbarRef]
            .map (ref => ref.current)
            .map (element => element ? element.clientHeight : undefined);
        this.updateScrollPos();
    }

    protected handleScrollThumbMouseDown = (event:React.MouseEvent | React.TouchEvent) => {
        this.scrollDragStartY = event.nativeEvent instanceof TouchEvent ? (event as React.TouchEvent).touches[0].clientY : (event as React.MouseEvent).clientY;
        this.scrollDragStartOffset = this.state.offset;
        this.setState ( {isScrolling: true});
        this.scrollDragLastTime = Date.now();
    }

    protected handleContentTouchStart = (event:React.TouchEvent) => {
        this.scrollDragStartY = event.touches[0].clientY;
        this.scrollDragStartOffset = this.state.offset;
        this.isTouchScrolling = true;
        this.touchEndTime = null;
    }

    protected handleDocumentMouseMove = (event:MouseEvent | TouchEvent) => {
        if (this.state.isScrolling) {
            if (event instanceof MouseEvent && event.buttons !== 1)
                this.setState ( {isScrolling: false});
            else if (event instanceof MouseEvent || (event instanceof TouchEvent && event.touches)) {
                const clientY = (event instanceof TouchEvent ? event.touches[0] : event).clientY;
                if (event instanceof MouseEvent) {
                    event.preventDefault();
                }
                event.stopPropagation();
                if (Date.now() - this.scrollDragLastTime > MOUSE_SCROLL_DEBOUNCE_TIME) {
                    const delta = (this.contentHeight - this.bodyHeight) * ((clientY - this.scrollDragStartY) / ((this.scrollBarHeight - this.state.scrollThumbHeight) || 1));
                    this.scrollDragLastTime = Date.now();
                    this.updateScrollPos(this.scrollDragStartOffset + delta);
                }
            }
        } else if (this.isTouchScrolling && event instanceof TouchEvent && event.touches && Date.now() - this.scrollDragLastTime > TOUCH_SCROLL_DEBOUNCE_TIME) {
            this.updateScrollPos(this.scrollDragStartOffset + this.scrollDragStartY - event.touches[0].clientY);
            this.scrollDragLastTime = Date.now();
        }
    }

    protected handleTouchEnd = () => {
        if (this.state.isScrolling) {
            this.setState({isScrolling: false});
        }
        this.isTouchScrolling = false;
        this.touchEndTime = Date.now();
    }

    protected handleScrollBarClick = (event:React.MouseEvent) => {
        const thumbRect = document.getElementsByClassName(css.scrollThumb)[0].getBoundingClientRect();
        if (event.clientY < thumbRect.top || event.clientY > thumbRect.bottom) {
            const sign = Math.sign(event.clientY - thumbRect.top);
            this.updateScrollPos(this.scrollDragStartOffset + sign * this.bodyHeight);
            event.preventDefault();
            event.stopPropagation();
        }
    }

    override componentDidMount() {
        this.observer = new ResizeObserver(MTU.debounce(this.updateElementSizes, RESIZE_DEBOUNCE_TIME).bind(this));

        if (U.mustNotBeNull(this.bodyRef.current)) {
            this.observer.observe(this.bodyRef.current);
        }

        document.addEventListener('mousemove', this.handleDocumentMouseMove, true);
        document.addEventListener("touchmove", this.handleDocumentMouseMove, false);
        document.addEventListener("touchend", this.handleTouchEnd, false);
        document.addEventListener('keydown', this.handleKeyDown, true);
    }

    override componentWillUnmount() {
        window.clearTimeout(this.updateTimeoutID)
        this.updateTimeoutID = undefined

        if (U.mustNotBeNull(this.observer)) {
            this.observer.disconnect()
        }

        document.removeEventListener('mousemove', this.handleDocumentMouseMove, true)
        document.removeEventListener('touchmove', this.handleDocumentMouseMove, true)
        document.removeEventListener("touchend", this.handleTouchEnd, false)
        document.removeEventListener('keydown', this.handleKeyDown, true)
    }

    override componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        this.updateTimeoutID = window.setTimeout (() => {
            if (this.updateTimeoutID !== undefined) {
                this.updateTimeoutID = undefined
                this.updateElementSizes()
            }
        }, RESIZE_DEBOUNCE_TIME)
    }

    override render() {
        return (
            <div ref={this.bodyRef}
                 className={U.cls(
                     css.body, true,
                     css.nonSelectable, this.state.isScrolling
                 )}
                 onWheel={this.handleWheel}>
                <div ref={this.contentRef}
                     className={css.content}
                     style={{top: -this.state.offset}}
                     onTouchStart={this.handleContentTouchStart}>
                    {this.props.children}
                </div>
                <div ref={this.scrollbarRef}
                     className={U.cls(css.scrollBar, true, css.scrollingBar, this.state.isScrolling)}
                     onClick={this.handleScrollBarClick}>
                    <div className={css.scrollThumb}
                         style={{
                             top: this.state.scrollThumbPos,
                             height: this.state.scrollThumbHeight,
                             opacity: this.state.scrollVisible ? 1 : 0,
                         }}
                         onMouseDown={this.handleScrollThumbMouseDown}
                         onTouchStart={this.handleScrollThumbMouseDown}
                    />
                </div>
            </div>
        );
    }
}
