import {DataValue, RowOfValues} from "../Concepts/Basic"
import {SemanticType} from "../Concepts/SemanticType"
import {MsSinceEpoch} from "../Concepts/DateTime"
import * as U from "../Utils"
import * as STT from "../StypeTools"

type DataColumnCacheRecord = {
    values: NonNullable<DataValue>[]
    nullCount: number
    lastAccess: MsSinceEpoch
}

const cache = new Map<RowOfValues[], Map<number, DataColumnCacheRecord>>()

/***
 * Represents a column in a array of rows of values.
 * Keeps the give array of rows, extracts, caches and returns on demand a column from this array.
 */
export default class DataColumn {
    protected static retrieveOrRememberColumn(rows: RowOfValues[], columnIndex: number): DataColumnCacheRecord {
        const columnRecord = cache.get(rows)?.get(columnIndex)
        if (columnRecord) {
            return columnRecord
        } else {
            if (!cache.has(rows)) {
                cache.set(rows, new Map())
            }
            let nullCount = 0
            const values: NonNullable<DataValue>[] = []
            for (let row = 0; row < rows.length; row++) {
                const value = rows[row][columnIndex]
                if (value === undefined) {
                    throw Error (`Value of column with UI index=${columnIndex} in row=${row} is not found`)
                } else if (value === null) {
                    nullCount += 1
                } else {
                    values.push(value)
                }
            }
            const newRecord = {values, nullCount, lastAccess: Date.now()}
            U.get(cache, rows).set(columnIndex, newRecord)
            DataColumn.collectGarbage()
            return newRecord
        }
    }

    public static collectGarbage(force = false): void {
        const now = Date.now()
        for (const dataCache of cache.values()) {
            for (const [columnIndex, columnRecord] of dataCache.entries()) {
                if (force || now - columnRecord.lastAccess > 600000) {
                    dataCache.delete(columnIndex)
                }
            }
        }
    }

    public static get cacheSize(): number {
        return [...cache.values()].map(dataCache => dataCache.size).reduce((a, b) => a + b, 0)
    }

    constructor(protected rows: RowOfValues[], protected columnIndex: number, public readonly stype: SemanticType) {
        DataColumn.retrieveOrRememberColumn(rows, columnIndex)
    }

    protected get column():DataColumnCacheRecord {
        const columnRecord = DataColumn.retrieveOrRememberColumn(this.rows, this.columnIndex)
        columnRecord.lastAccess = Date.now()
        return columnRecord
    }

    get numbers(): number[] {
        if (STT.isContinuous(this.stype)) {
            return this.column.values as number[]
        } else {
            throw new Error(`Can't return numbers having ${this.stype} values`)
        }
    }

    get strings(): string[] {
        if (STT.isTextual(this.stype) || STT.isCategorical(this.stype)) {
            return this.column.values as string[]
        } else {
            throw new Error(`Can't return strings having ${this.stype} values`)
        }
    }

    get nonNullValues(): NonNullable<DataValue>[] {
        return this.column.values
    }

    get nullCount(): number {
        return this.column.nullCount
    }
}
