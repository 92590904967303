/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import React from 'react'
import {TransitionProps} from '@material-ui/core/transitions'
import {LocalSettings, UserSetting, userSettings} from "../../LocalSettings"
import {WorkspaceSetting} from "../Workspace"
import {timeZones} from './Timezones'
import {locales} from './Locales'
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormHelperText,
    Slide,
    Slider,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@material-ui/core";
import Autocomplete, {AutocompleteChangeReason} from '@material-ui/core/Autocomplete'
import * as U from "../../Utils"
import event from "../../EventSender/Events"
import {colors} from "../../Colors";
import $t from "../../i18n/i18n";

type SettingControl = () => JSX.Element

interface Props {
    open: boolean
    workspaceSettings: LocalSettings<WorkspaceSetting>
    onClose: (changed: boolean)=>void
}

interface State {
    currentTab: number,
    changes: number,
    opened: boolean
}

const maxNumToCat = 50,
    maxHistBins = 100

export class SettingsDialog extends React.PureComponent<Props, State> {

    override readonly state:State = {
        currentTab: 0,
        changes: 0,
        opened: false
    }

    protected readonly transition = React.forwardRef(function Transition(
        props: TransitionProps & { children?: React.ReactElement<any, any> },
        ref: React.Ref<unknown>,
    ) {
        return <Slide direction="right" ref={ref} {...props} />;
    })

    static getDerivedStateFromProps(props:Props, state:State):State | null {
        if (props.open !== state.opened) {
            (props.open ? event.settings.open : event.ui.dialog.close)()
            return {
                currentTab: state.currentTab,
                changes: state.changes,
                opened: props.open
            }
        } else {
            return null
        }
    }

    protected handleClose = () => {
        this.props.onClose(this.state.changes > 0)
    }

    protected changeSetting (name:UserSetting, value:string|number, save=true) {
        this.setState ({changes: this.state.changes + 1})
        if (save) {
            userSettings.set(name, value)
            event.settings.change(name, value.toString())
        }
    }

    protected handleUnitsMatterChange = (event:React.ChangeEvent<unknown>, checked:boolean) => {
        this.changeSetting("unitsMatter", checked ? 1 : 0)
    }

    protected handleNumToCatChange = (event: Event, value: number | number[]) => {
        this.changeSetting ("numToCat", value as number)
    }

    protected handleHistBinsChange = (event: Event, value: number | number[]) => {
        this.changeSetting ("histBins", value as number)
    }

    protected handleTimezoneChange = (event:React.ChangeEvent<unknown>, value:string|null, reason: AutocompleteChangeReason) => {
        this.changeSetting("timezone", value ?? '', reason === "selectOption" && value !== null)
    }

    protected handleLocaleChange = (event:React.ChangeEvent<unknown>, value:string|null, reason: AutocompleteChangeReason) => {
        this.changeSetting ("locale", value ?? '', reason === "selectOption" && value !== null)
    }

    protected unitsMatter = () => <React.Fragment>
        <FormControlLabel
            value="unitsMatter"
            checked={userSettings.getNumber("unitsMatter") > 0}
            onChange={this.handleUnitsMatterChange}
            control={<Checkbox color="primary"/>}
            label={$t('settingsdialog.units_matter.label')}
            labelPlacement="end"
        />
        <FormHelperText>{$t('settingsdialog.units_matter.tip')}  </FormHelperText>
    </React.Fragment>

    protected numToCat = () => <React.Fragment>
        <Typography id="num-to-cat-title" gutterBottom>
            {$t('settingsdialog.num2cat.text')}
        </Typography>
        <Slider
            value={userSettings.getNumber("numToCat")}
            onChange={this.handleNumToCatChange}
            defaultValue={5}
            aria-labelledby="num-to-cat-title"
            max={maxNumToCat}
            valueLabelDisplay="auto"
            marks={
                [...U.range(0, maxNumToCat+1)]
                    .filter(n => n === 0 || n === maxNumToCat || (n % 10) === 0)
                    .map (n => ({value: n, label:n.toString()}))
            }
        />
        <FormHelperText>{$t('settingsdialog.num2cat.tip')}</FormHelperText>
    </React.Fragment>

    protected histBins = () => <React.Fragment>
        <Typography id="hist-bins-title" gutterBottom>
            {$t('settingsdialog.hist_bins.text')}
        </Typography>
        <Slider
            value={userSettings.getNumber("histBins")}
            onChange={this.handleHistBinsChange}
            defaultValue={5}
            aria-labelledby="hist-bins-title"
            max={maxHistBins}
            valueLabelDisplay="auto"
            marks={
                [...U.range(0, maxHistBins+1)]
                    .filter(n => n === 0 || n === maxHistBins || (n % 10) === 0)
                    .map (n => ({value: n, label:n.toString()}))
            }
        />
        <FormHelperText>{$t('settingsdialog.hist_bins.tip')}</FormHelperText>
    </React.Fragment>

    protected timeZone = () => <React.Fragment>
        <Typography id="timezone-title" gutterBottom>
            {$t('settingsdialog.timezone.text')}
        </Typography>
        <Autocomplete
            disableClearable
            value={userSettings.getString("timezone")}
            onChange={this.handleTimezoneChange}
            options={timeZones}
            aria-labelledby="timezone-title"
            id="timezone-autocomplete"
            style={{ maxWidth: 300 }}
            renderInput={(params) => <TextField {...params} margin="dense" variant="standard" />}
        />
        <FormHelperText>{$t('settingsdialog.timezone.tip')}</FormHelperText>
    </React.Fragment>

    protected locale = () => <React.Fragment>
        <Typography id="locale-title" gutterBottom>
            {$t('settingsdialog.locale.text')}
        </Typography>
        <Autocomplete
            disableClearable
            value={userSettings.getString("locale")}
            onChange={this.handleLocaleChange}
            options={Object.keys(locales)}
            getOptionLabel={(option) => locales[option]}
            aria-labelledby="locale-title"
            id="locale-autocomplete"
            style={{ maxWidth: 300 }}
            renderInput={(params) => <TextField {...params} margin="dense" variant="standard" />}
        />
        <FormHelperText>{$t('settingsdialog.locale.tip')}</FormHelperText>
    </React.Fragment>

    protected readonly config:{[index:string]: SettingControl[]} = {
        [$t("settingsdialog.tab.region")]: [this.locale, this.timeZone],
        [$t("settingsdialog.tab.charts")]: [this.unitsMatter, this.numToCat, this.histBins]
    }

    protected handleTabChange = (evt: React.ChangeEvent<unknown>, newIndex: number) => {
        this.setState({currentTab: newIndex})
        event.settings.tab (Object.keys(this.config)[newIndex])
    }

    override render () {
        return (
            <Dialog open={this.props.open}
                    onClose={this.handleClose}
                    aria-labelledby="settings-dialog-title"
                    TransitionComponent={this.transition}
                    fullWidth={true}
                    maxWidth={'md'}
            >
                <DialogTitle id="settings-dialog-title">{$t('settingsdialog.title')}</DialogTitle>
                <DialogContent>
                    <Box css={css`display: flex; flex-grow: 1; min-height: 500px`}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={this.state.currentTab}
                            onChange={this.handleTabChange}
                            style={{borderRight: `'1px solid ${colors.grey.c400}`, minWidth: 160}}
                            css={css`& .MuiTab-wrapper {align-items: flex-end /* todo */}`}
                        >
                            {
                                Object.keys(this.config)
                                    .map ((section, index) =>
                                        <Tab label={section} key={index} id={`settings-tab-${index}`} aria-controls={`settings-tabpanel-${index}`} />
                                        )
                            }
                        </Tabs>
                        {
                            Object.keys(this.config)
                                .map ((section, index) =>
                                    <Box role="tabpanel" key={index} hidden={this.state.currentTab !== index} id={`settings-tabpanel-${index}`} aria-labelledby={`settings-tab-${index}`}>
                                        {this.config[section].map ((controlFun, index) => <Box key={index} p={3}>{controlFun()}</Box>)}
                                    </Box>
                                )
                        }
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                        {$t("dialog.button.close")}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}
