// regex for string search: (?<!\$t\()('|"|`)\w+

import en from './en.json'
import ru from './ru.json'
import {TimeIntervalType} from "../Concepts/DateTime"

export const isRussian: boolean = navigator.languages && navigator.languages.length > 0 && navigator.languages[0].toLowerCase().startsWith('ru')

const s = (isRussian ? ru : en) as {[key:string]:string}

export default function $t (key:string, params?:{[index:string]:string|number}) {
    const str = s[key] as string
    return params
        ? str.replace(/\{\{\w+\}\}/g, (match:string) => (params[match.slice(2,-2)] ?? '').toString())
        : str
}

const rusQuarters = ['I', 'II', 'III', 'IV']
export function $tQuarter (quarter:number, year?:number) {
    if (isRussian) {
        return year ? `${rusQuarters[quarter-1]} ${year}` : rusQuarters[quarter-1]
    } else {
        return year ? `Q${quarter} ${year}` : `Q${quarter}`
    }
}

export enum IntervalTypeNameForm {
    SingGen = 0,
    PlurGen = 1,
    PlurDat = 2
}

export function $tIntervalTypeName (type:TimeIntervalType, form:IntervalTypeNameForm):string {
    switch (type) {
        case TimeIntervalType.Year: return isRussian ? ["года", "лет", "годам"][form] : (form === IntervalTypeNameForm.SingGen ? "year" : "years")
        case TimeIntervalType.Quarter: return isRussian ? ["квартала", "кварталов", "кварталам"][form] : (form === IntervalTypeNameForm.SingGen ? "quarter" : "quarters")
        case TimeIntervalType.Month: return isRussian ? ["месяца", "месяцев", "месяцам"][form] : (form === IntervalTypeNameForm.SingGen ? "month" : "months")
        case TimeIntervalType.Week: return isRussian ? ["недели", "недель", "неделям"][form] : (form === IntervalTypeNameForm.SingGen ? "week" : "weeks")
        case TimeIntervalType.Day: return isRussian ? ["дня", "дней", "дням"][form] : (form === IntervalTypeNameForm.SingGen ? "day" : "days")
        case TimeIntervalType.Hour: return isRussian ? ["часа", "часов", "часам"][form] : (form === IntervalTypeNameForm.SingGen ? "hour" : "hours")
        case TimeIntervalType.Minute: return isRussian ? ["минуты", "минут", "минутам"][form] : (form === IntervalTypeNameForm.SingGen ? "minute" : "minutes")
        default: throw new Error ("Should not get here, type = " + type)
    }
}

/*
export enum Word {
    change
}

export function $tWordForm (word: Word, plural:boolean) {
    switch (word) {
        case Word.change:
            return word.toString()
        default:
            U.shouldNeverGetHere(word)
    }
}
*/