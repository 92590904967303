import {ColumnDBIndex, ColumnId, ColumnsById, ColumnUIIndex, DataValue, ITableColumn,} from "./Basic"
import {AggregationByCategory, AggregationByInterval, AggregationType} from "./Aggregation"
import {SemanticType} from "./SemanticType"
import {IVisSettings} from "./VisSettings"
import {WorkspaceStoreId} from "./DataBase"
import {ITimeInterval} from "./DateTime"

export const UnknownColumnWidth = 0

export interface IDataTable {
    wsStoreId: number
    rowCount: number
    visualConfigSnapshot:string
    columnOrigIndexById (id:ColumnId):ColumnDBIndex
    columnIdByUIIndex (index:ColumnUIIndex):ColumnId
    columnIdByDBIndex (index:ColumnDBIndex):ColumnId
    buildIndexToOrigIndexMap():ColumnDBIndex[]
    getColumnInfo (id: ColumnId):ITableColumn
    getStype (id: ColumnId):SemanticType
    getRows (start: number, end: number):Promise<{start:number, data:DataValue[][]}>
    changeWorkspaceStore (visSettings?: IVisSettings):Promise<WorkspaceStoreId>

    /**
     * If valueColumnIds specified and not empty the function returns an aggregation of their values grouped by the category column values,
     * otherwise it returns counts of different category column values
     */
    aggregateByCategory (aggrType:AggregationType, categoryColumnId: ColumnId, valueColumnIds?: ColumnId[]): Promise<AggregationByCategory>

    /**
     * If valueColumnIds specified and not empty the function returns an aggregation of their values grouped by the category column values,
     * otherwise it returns how often values of time column get into each time interval
     */
    aggregateByInterval (aggrType:AggregationType, interval:ITimeInterval, timeColumnId: ColumnId, valueColumnIds: ColumnId[]): Promise<AggregationByInterval>

    getTuples (columnIds: ColumnId[]): Promise<ColumnsById>

    /*** Column Information ***/

    columnByIndex (index:ColumnUIIndex):ITableColumn
    lastColumn:ITableColumn

    // returns columns that pass the test, ordered by ui index
    filteredColumnsByUiIndex (predicate:(col:ITableColumn)=>boolean):ITableColumn[]

    numberOfPinnedColumns: number
    totalColumnWidth: number
    numberOfColumns: number
    selectedColumns: ITableColumn[]
    selectedColumnIds: ColumnId[]
    columnWidthsByVisualIndex: number[]
    columnIndexesWithUnknownWidth: ColumnUIIndex[]
    changeColumnWidth (columnIndex: ColumnUIIndex, width: number):string
    swapColumns (columnIndex: ColumnUIIndex, direction: 1 | -1):string
    toggleColumnPinning (columnIndex: ColumnUIIndex):{pinned: boolean, snapshot: string}
    selectColumn (columnIndex: ColumnUIIndex, exclusive:boolean):string
    selectColumns(columnIds: ColumnId[]):string
}