import {alias, date, identifier, object, serializable} from "serializr"
import {
    ColumnId,
    ColumnSummaryType,
    ICategoryDistribution,
    IColumnSummary,
    INumberSummary,
    ITableColumn,
} from "../Concepts/Basic"
import {CategoryToColor, StandardColor} from "../Concepts/Colors"
import {mapOfObjects} from "../CustomSerializr"
import ColumnSummary from "./ColumnSummary"
import {IObjectStore} from "../Concepts/DataBase"
import {SemanticType} from "../Concepts/SemanticType"

/***
 * Represents a column in a user's table of source data
 */
export default class TableColumn implements ITableColumn {
    @serializable(alias('id', identifier())) readonly id: ColumnId
    @serializable(alias('stype')) protected _stype: SemanticType
    @serializable(alias('title')) protected _title: string
    @serializable(alias('units')) protected _units: string | null
    @serializable(alias('width')) protected _width: number
    @serializable(alias('order')) protected _order: number
    @serializable(alias('pinned')) protected _pinned: boolean
    @serializable(alias('selected', date())) protected _selectionTime: Date | null
    @serializable(alias('color', object(StandardColor))) protected _color: StandardColor
    @serializable(alias('categories', mapOfObjects(StandardColor))) protected _categories: CategoryToColor
    @serializable(alias('summary', object(ColumnSummary))) protected _summary: IColumnSummary
    protected store:IObjectStore<TableColumn>|undefined

    constructor(id: ColumnId, stype: SemanticType, title: string, units: string | null, width: number, order: number,
                pinned: boolean, selectionTime: Date | null, color: StandardColor, categories: CategoryToColor, summary: IColumnSummary
    ) {
        [this.id, this._stype, this._title, this._units, this._width, this._order, this._pinned, this._selectionTime, this._color, this._categories, this._summary] =
            [id, stype, title, units, width, order, pinned, selectionTime, color, categories, summary]
    }

    bindToStore(store:IObjectStore<TableColumn>) {
        this.store = store
    }

    protected saveToDB () {
        if (this.store) {
            this.store.put(this).then()
        } else {
            throw Error ("Can't save TableColumn object as it is not bound to any ObjectStore")
        }
    }

    get stype() {return this._stype}
    get title() {return this._title}
    get units() {return this._units}

    get width() {return this._width}
    set width(v) {
        if (this._width !== v) {
            this._width=v
            this.saveToDB ()
        }
    }

    get order() {return this._order}
    set order(v) {
        if (this._order !== v) {
            this._order=v
            this.saveToDB ()
        }
    }

    get pinned() {return this._pinned}
    set pinned(v) {
        if (this._pinned !== v) {
            this._pinned = v
            this.saveToDB ()
        }
    }

    get selectionTime() {return this._selectionTime}
    set selectionTime(v) {
        if (this._selectionTime !== v) {
            this._selectionTime = v
            this.saveToDB ()
        }
    }

    get color() {return this._color}
    get categories() {return this._categories}
    get summary() {return this._summary}
    get selected(): boolean {return this.selectionTime !== null}

    get numberSummary ():INumberSummary {
        if (this.summary.type === ColumnSummaryType.Number || this.summary.type === ColumnSummaryType.Text) {
            return this.summary.numberSummary
        } else {
            throw new Error (`"ColumnSummaryType.Number expected here, but got ${this.summary.type}`)
        }
    }

    get categoryDistribution ():ICategoryDistribution {
        if (this.summary.type === ColumnSummaryType.Category) {
            return this.summary.categoryDistribution
        } else {
            throw new Error ("ColumnSummaryType.Category expected here")
        }
    }
}
