import {
    GetActionsEvent,
    GetActionsEventType,
    MatchDegree,
    TooltipsById, UserActionInfo, UserActionResponse,
    VisContent,
    VisCtrlAction,
    VisCtrlActionType,
    VisCtrlId,
    VisCtrlInfo,
    VisCtrlType,
    VisCtrlValue,
    VisCtrlValues,
    VisualizerColumnData,
    VisualizerData,
    VisualizerType
} from "../Concepts/Visualizer"
import {Visualizer} from "./Visualizer"
import {render} from './Renderers/ScatterPlot'
import Icon from './VisIcons/ScatterPlot.svg'
import * as U from '../Utils'
import {addSamplingMessage} from "../Data/DataSampling"
import {ColumnId, ColumnSummaryType, ITableColumn} from "../Concepts/Basic"
import {SemanticType} from "../Concepts/SemanticType"
import {TimeLineMode, VisColData, VisControls, VisData} from "../Concepts/Visualizers/ScatterPlot"
import {IDataTable} from "../Concepts/DataTable"
import * as STT from "../StypeTools"
import $t from "../i18n/i18n";

export default class ScatterPlot extends Visualizer {

    protected extractColumnIds = (controlValues:VisCtrlValues) => ({
        y: controlValues.get(VisControls.yAxis) ? ColumnId(U.parseIntNotNan(U.get(controlValues, VisControls.yAxis))) : null,
        x: controlValues.get(VisControls.xAxis) ? ColumnId(U.parseIntNotNan(U.get(controlValues, VisControls.xAxis))) : null,
        color: controlValues.get(VisControls.colorAxis) ? ColumnId(U.parseIntNotNan(U.get(controlValues, VisControls.colorAxis))) : null,
        size: controlValues.get(VisControls.sizeAxis) ? ColumnId(U.parseIntNotNan(U.get(controlValues, VisControls.sizeAxis))) : null,
        time: controlValues.get(VisControls.timeAxis) ? ColumnId(U.parseIntNotNan(U.get(controlValues, VisControls.timeAxis))) : null
    })

    override get type () {return VisualizerType.ScatterPlot}

    matchDegree(columns:ITableColumn[]): MatchDegree {
        return columns.length > 1
                && columns.every(c => STT.isOrdinal(c.stype) || STT.isCategorical(c.stype) || STT.isContinuous(c.stype))
                && columns.filter(c => c.summary.nullCount < c.summary.valueCount).length > 1
            ? MatchDegree.Ordinary
            : MatchDegree.NoMatch
    }

    getTitle() {
        return $t('scatterplot.name')
    }

    getIconUrl(): string {
        return Icon
    }

    getColumnData(dataTable:IDataTable): VisColData {
        return this.getColumnIdsTitleFooter(dataTable, $t('scatterplot.title'))
    }

    getControls(dataTable:IDataTable, columnData: VisualizerColumnData): Map<VisCtrlId, VisCtrlInfo> {
        const cd = columnData as VisColData

        return new Map<VisCtrlId, VisCtrlInfo>([
            [VisControls.xAxis, {
                title: $t('scatterplot.ctrl.x.title'),
                type: VisCtrlType.Select,
                options: new Map(cd.columnIds.map(id =>
                    [id.toString(), dataTable.getColumnInfo(id).title]
                )),
                defaultValue: '',
            }],
            [VisControls.yAxis, {
                title: $t('scatterplot.ctrl.y.title'),
                type: VisCtrlType.Select,
                options: new Map(cd.columnIds.map(id =>
                    [id.toString(), dataTable.getColumnInfo(id).title]
                )),
                defaultValue: '',
            }],
            [VisControls.colorAxis, {
                title: $t('scatterplot.ctrl.color.title'),
                type: VisCtrlType.Select,
                options: new Map([['', $t('ctrl.value.none')] as [string, string]].concat(
                    cd.columnIds.map(id =>
                        [id.toString(), dataTable.getColumnInfo(id).title]
                    )
                )),
                defaultValue: '',
            }],
            [VisControls.sizeAxis, {
                title: $t('scatterplot.ctrl.size.title'),
                type: VisCtrlType.Select,
                options: new Map([['', $t('ctrl.value.none')] as [string, string]].concat(
                    [...cd.columnIds.values()]
                        .filter(id => STT.isNumeric(dataTable.getStype(id)))
                        .map (id => [id.toString(), dataTable.getColumnInfo(id).title])
                )),
                defaultValue: '',
            }],
            [VisControls.dotSize, {
                title: $t('scatterplot.ctrl.dotsize.title'),
                type: VisCtrlType.Slider,
                marks: [],
                min: 1,
                max: 100,
                defaultValue: "20",
            }],
            /* uncomment for dot opacity UI
            [Ctrl.dotOpacity, {
                title: 'Dot opacity',
                type: CtrlType.Slider,
                marks: [{value:50}],
                min: 1,
                max: 100,
                defaultValue: "50",
            }]
            */
            [VisControls.timeAxis, {
                title: $t('scatterplot.ctrl.timeline.title'),
                type: VisCtrlType.Select,
                options: new Map([['', $t('ctrl.value.none')] as [string, string]].concat(
                    [...cd.columnIds.values()]
                        .filter(id => STT.isOrdinal(dataTable.getStype(id)))
                        .map(id => [id.toString(), dataTable.getColumnInfo(id).title])
                )),
                defaultValue: '',
            }],
            [VisControls.timeLineMode, {
                title: $t('scatterplot.ctrl.timelinemode.title'),
                type: VisCtrlType.Select,
                options: new Map([[TimeLineMode.single, $t('scatterplot.ctrl.timelinemode.single')], [TimeLineMode.byCategory, $t('scatterplot.ctrl.timelinemode.percat')]]),
                defaultValue: TimeLineMode.single
            }],

        ])
    }

    getData (requestId:number, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues):Promise<{data:VisData, requestId:number}> {
        const cd = columnData as VisColData,
            approvedSizeColumnIds = new Set<number>(),
            approvedTimeColumnIds = new Set<number>()

        return dataTable.getTuples(cd.columnIds).then (data => {
            for (const columnId of cd.columnIds) {
                const columnInfo = dataTable.getColumnInfo(columnId)
                if (STT.isContinuous(columnInfo.stype)) {

                    // if the value is numeric the column might be suitable for the size axis
                    if (STT.isNumeric(columnInfo.stype)) {
                        approvedSizeColumnIds.add(columnId)
                    }

                    // if the value is ordinal the column is suitable for the time axis
                    if (STT.isOrdinal(columnInfo.stype)) {
                        approvedTimeColumnIds.add(columnId)
                    }

                    // since the column contains some negative values it's not suitable for the size axis
                    if (columnInfo.numberSummary.min < 0) {
                        approvedSizeColumnIds.delete(columnId)
                    }
                }
            }
            return {data: {data, approvedSizeColumnIds, approvedTimeColumnIds}, requestId}
        })
    }

    getActionsOnControls (event:GetActionsEvent, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues):VisCtrlAction[] {
        const cd = columnData as VisColData,
            controlIds = [VisControls.xAxis, VisControls.yAxis, VisControls.colorAxis, VisControls.sizeAxis],
            actions:VisCtrlAction[] = [],
            cv = this.getCurrentControlValues (event, controlValues)

        let columnIds = this.extractColumnIds(cv)

        const setValue = (controlId:VisCtrlId, controlValue:VisCtrlValue) => {
            actions.push ({action: VisCtrlActionType.SetValue, controlId: controlId, controlValue})
            cv.set (controlId, controlValue)
            columnIds = this.extractColumnIds(cv)
        }

        // if the cond is defined it looks for the available columns among those whose cond()===true
        const availableColumnIds = (cond?:(stype:SemanticType)=>boolean) =>
            cd.columnIds.filter (id =>
                ![...cv.entries()].some (entry => controlIds.indexOf(entry[0] as VisControls) >= 0 && entry[1] === id.toString())
                && (cond === undefined || cond(dataTable.getStype(id)))
            )

        const makeSureOneColumnIsAvailable = (condition?:(stype:SemanticType)=>boolean):boolean => {
            for (const cond of condition ? [condition, undefined] : [undefined]) {
                const ac = availableColumnIds(cond)
                if (ac.length === 0) {
                    if (columnIds.size !== null && (cond ? cond(dataTable.getStype(columnIds.size)) : true)) {
                        setValue(VisControls.sizeAxis, '')
                        return true
                    } else if (columnIds.color !== null && (cond ? cond(dataTable.getStype(columnIds.color)) : true)) {
                        setValue(VisControls.colorAxis, '')
                        return true
                    }
                } else {
                    return true
                }
            }
            return false
        }

        if (event.type === GetActionsEventType.DataLoadedWithSuggestedControlValues || event.type === GetActionsEventType.DataLoadedWithUserControlValues) {

            const d = event.data as VisData

                // if control values are merely suggested (not saved in db) try to apply some patterns
            let patternApplied = false
            if (event.type === GetActionsEventType.DataLoadedWithSuggestedControlValues) {
                const numberColumns = cd.columnIds.filter(colId => STT.isNumeric(dataTable.getStype(colId))),
                    categoryColumns = cd.columnIds.filter(colId => STT.isCategorical(dataTable.getStype(colId))),
                    timeColumns = cd.columnIds.filter(colId => STT.isOrdinal(dataTable.getStype(colId)) && numberColumns.indexOf(colId)<0),
                    cfg = (num: number, cat: number, time: number) => num + cat + time >= 3
                        ? numberColumns.length >= num && categoryColumns.length >= cat && timeColumns.length >= time
                        : numberColumns.length === num && categoryColumns.length === cat && timeColumns.length === time


                let xAxisColId: number | undefined,
                    yAxisColId: number | undefined,
                    colorAxisColId: number | undefined,
                    sizeAxisColId: number | undefined,
                    timeAxisColId: number | undefined

                // patterns:
                if (cfg(1, 0, 1)) {
                    // x = time, y = number
                    xAxisColId = timeColumns[0]
                    yAxisColId = numberColumns[0]
                    timeAxisColId = xAxisColId
                } else if (cfg(1, 1, 1)) {
                    // x = time, y = number
                    xAxisColId = timeColumns[0]
                    yAxisColId = numberColumns[0]
                    colorAxisColId = categoryColumns[0]
                    timeAxisColId = xAxisColId
                } else if (cfg(1, 1, 0)) {
                    // x = number, y = category
                    xAxisColId = numberColumns[0]
                    yAxisColId = categoryColumns[0]
                } else if (cfg(1, 2, 0)) {
                    // x = category, y = category, size = number
                    xAxisColId = categoryColumns[0]
                    yAxisColId = categoryColumns[11]
                    sizeAxisColId = numberColumns[0]
                } else if (cfg(3, 1, 0)) {
                    // x = number, y = number, color = category, size = number
                    xAxisColId = numberColumns[0]
                    yAxisColId = numberColumns[1]
                    colorAxisColId = categoryColumns[0]
                    sizeAxisColId = numberColumns[2]
                } else if (cfg(2, 1, 0)) {
                    // x = number, y = number, color = category
                    xAxisColId = numberColumns[0]
                    yAxisColId = numberColumns[1]
                    colorAxisColId = categoryColumns[0]
                }

                // setup the pattern (if any)
                if (xAxisColId !== undefined && yAxisColId !== undefined) {
                    cv.clear()
                    setValue(VisControls.xAxis, xAxisColId?.toString() ?? '')
                    setValue(VisControls.yAxis, yAxisColId?.toString() ?? '')
                    setValue(VisControls.colorAxis, colorAxisColId?.toString() ?? '')
                    setValue(VisControls.sizeAxis, sizeAxisColId?.toString() ?? '')
                    setValue(VisControls.timeAxis, timeAxisColId?.toString() ?? '')
                    patternApplied = true
                }
            }

            // if no pattern applied distribute columns according to general rules
            if (!patternApplied) {
                const timeColumn = (stype: SemanticType) => STT.isOrdinal(stype),
                    categoryColumn = (stype: SemanticType) => STT.isCategorical(stype),
                    mutuallyExclusiveFields = new Map<VisCtrlId, ColumnId | null>([
                        [VisControls.sizeAxis, columnIds.size],
                        [VisControls.colorAxis, columnIds.color],
                        [VisControls.yAxis, columnIds.y],
                        [VisControls.xAxis, columnIds.x]
                    ]),
                    combinableFields = new Map<VisCtrlId, ColumnId | null>([
                        [VisControls.timeAxis, columnIds.time]
                    ])

                // unassign duplicated and non-existent (non-selected) columns from the axes
                for (const columnKey of [...mutuallyExclusiveFields.keys(), ...combinableFields.keys()]) {
                    const columnId = mutuallyExclusiveFields.get(columnKey) ?? combinableFields.get(columnKey) ?? null
                    if (columnId !== null) {
                        let worthyOfUnassignment = cd.columnIds.indexOf(columnId) < 0
                        if (!worthyOfUnassignment && !combinableFields.has(columnKey)) {
                            for (const otherColumnKey of mutuallyExclusiveFields.keys()) {
                                if (otherColumnKey !== columnKey && mutuallyExclusiveFields.get(otherColumnKey) === columnId) {
                                    worthyOfUnassignment = true
                                    break
                                }
                            }
                        }
                        if (worthyOfUnassignment) {
                            setValue(columnKey, '')
                        }
                    }
                }

                // make sure X axis has a column assigned
                if (columnIds.x === null) {
                    for (const condition of [timeColumn, undefined]) {
                        if (makeSureOneColumnIsAvailable(condition)) {
                            const availColumnId = availableColumnIds(condition)[0]
                            if (availColumnId !== undefined) {
                                setValue(VisControls.xAxis, availColumnId.toString())
                                break
                            }
                        }
                    }
                }

                // make sure Y axis has a column assigned
                if (columnIds.y === null) {
                    if (makeSureOneColumnIsAvailable()) {
                        setValue(VisControls.yAxis, availableColumnIds()[0].toString())
                    } else {
                        throw Error('There is no available column for Y axis')
                    }
                }

                if (event.type === GetActionsEventType.DataLoadedWithSuggestedControlValues) {

                    // assign available column (if any) to Color axis, preferably categorical
                    if (columnIds.color === null || cd.columnIds.indexOf(columnIds.color) < 0) {
                        for (const condition of [categoryColumn, undefined]) {
                            const availColumnId = availableColumnIds(condition)[0]
                            if (availColumnId !== undefined) {
                                setValue(VisControls.colorAxis, availColumnId.toString())
                                break
                            }
                        }
                    }

                    // assign available and appropriate column (if any) to Size axis
                    const availColumnIds = availableColumnIds()
                    if (availColumnIds.length > 0 && (columnIds.size === null || cd.columnIds.indexOf(columnIds.size) < 0)
                        && availColumnIds.reduce((result, id) => result || d.approvedSizeColumnIds.has(id), false)) {
                        setValue(VisControls.sizeAxis, availColumnIds.filter(id => d.approvedSizeColumnIds.has(id))[0].toString())
                    }

                    // assign a date/time column (if single) to time axis
                    if (d.approvedTimeColumnIds.size === 1) {
                        setValue(VisControls.timeAxis, [...d.approvedTimeColumnIds][0].toString())
                    }
                }
            }

            // remove inappropriate columns from size axis options
            actions.push(...cd.columnIds
                .filter(id => !d.approvedSizeColumnIds.has(id))
                .map(id => ({
                    action: VisCtrlActionType.HideOption,
                    controlId: VisControls.sizeAxis,
                    controlValue: id.toString()
                }))
            )

            // remove inappropriate columns from time axis options
            actions.push(...cd.columnIds
                .filter(id => !d.approvedTimeColumnIds.has(id))
                .map(id => ({
                    action: VisCtrlActionType.HideOption,
                    controlId: VisControls.timeAxis,
                    controlValue: id.toString()
                }))
            )

            if (cd.columnIds.length < 3) {
                actions.push({action: VisCtrlActionType.HideControl, controlId: VisControls.colorAxis})
                actions.push({action: VisCtrlActionType.HideControl, controlId: VisControls.sizeAxis})
            }

            if (d.approvedSizeColumnIds.size === 0) {
                actions.push({action: VisCtrlActionType.HideControl, controlId: VisControls.sizeAxis})
            }

            if (d.approvedTimeColumnIds.size === 0) {
                actions.push({action: VisCtrlActionType.HideControl, controlId: VisControls.timeAxis})
            }
        }
        else if (event.type === GetActionsEventType.ControlValueChangedByUser) {
            const d = event.data as VisData
            // process control value chage
            // prevent column duplication, time axis can be duplicated
            if (event.controlId !== VisControls.timeAxis) {
                // swap columns in controls if selected column is already selected in other control
                controlIds.forEach(otherControlId => {
                    if (otherControlId !== event.controlId && controlValues.get(otherControlId) === event.newControlValue) {
                        // found a control having this value already
                        let curColumnId = U.get(controlValues, event.controlId)
                        if (!curColumnId && (otherControlId === VisControls.xAxis || otherControlId === VisControls.yAxis)) {
                            // we're going to assign None to x or y which is no-no, let's find some other free column instead
                            let ac = availableColumnIds()
                            if (ac.length > 0) {
                                curColumnId = ac[0].toString()
                            } else {
                                // there is no available column let's take it from size or color axis
                                if (event.controlId === VisControls.colorAxis) {
                                    setValue(VisControls.sizeAxis, '')
                                    ac = availableColumnIds()
                                    if (ac.length > 0) {
                                        curColumnId = ac[0].toString()
                                    } else {
                                        throw new Error(`Can't find free column for ${otherControlId} axis`)
                                    }
                                } else if (event.controlId === VisControls.sizeAxis) {
                                    setValue(VisControls.colorAxis, '')
                                    ac = availableColumnIds()
                                    if (ac.length > 0) {
                                        curColumnId = ac[0].toString()
                                    } else {
                                        throw new Error(`Can't find free column for ${otherControlId} axis`)
                                    }
                                } else {
                                    throw new Error(`Can't find free column for ${otherControlId} axis`)
                                }
                            }
                        }
                        setValue(otherControlId,
                            otherControlId !== VisControls.sizeAxis || d.approvedSizeColumnIds.has(parseInt(curColumnId))
                                ? curColumnId
                                : '')
                    }
                })
            }
        }

        if (event.type !== GetActionsEventType.BeforeDataLoaded) {
            // hide "max dot size" control if there is no column assigned to size axis
            /*
            actions.push({
                action: columnIds.size !== null ? ActionType.ShowControl : ActionType.HideControl,
                controlId: Ctrl.dotSize
            })
            */

            // hide "time line mode" control if color column is not assigned or not categorical
            actions.push({
                action: columnIds.time !== null && !STT.isCategorical(dataTable.getStype(columnIds.time))
                    ? VisCtrlActionType.ShowControl
                    : VisCtrlActionType.HideControl,
                controlId: VisControls.timeLineMode
            })

            const colorColumnInfo = columnIds.color !== null ? dataTable.getColumnInfo(columnIds.color) : null,
                xStype = dataTable.getStype(U.notNull(columnIds.x)),
                yStype = dataTable.getStype(U.notNull(columnIds.y))

            this.sendColoringEvent(
                colorColumnInfo !== null && (STT.isCategorical(colorColumnInfo.stype) || (colorColumnInfo.summary.type === ColumnSummaryType.Number && colorColumnInfo.numberSummary.categories))
                    ? U.notNull(columnIds.color)
                    : columnIds.color === null && STT.isCategorical(xStype) && !STT.isCategorical(xStype)
                    ? columnIds.x
                    : columnIds.color === null && !STT.isCategorical(xStype) && STT.isCategorical(yStype)
                        ? columnIds.y
                        : null,
                false)
        }

        return actions
    }

    getContent (data:VisualizerData, width:number, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues, tooltips:TooltipsById):VisContent {
        const columnIds = this.extractColumnIds(controlValues),
            d = data as VisData,
            cd = columnData as VisColData,
            xColumn = dataTable.getColumnInfo(U.notNull(columnIds.x)),
            yColumn = dataTable.getColumnInfo(U.notNull(columnIds.y)),
            colorColumn = columnIds.color === null ? null : dataTable.getColumnInfo(columnIds.color),
            sizeColumn = columnIds.size === null ? null : dataTable.getColumnInfo(columnIds.size),
            timeColumn = columnIds.time === null ? null : dataTable.getColumnInfo(columnIds.time)

        const renderResult = render(
            d.data,
            {x: xColumn, y: yColumn, color: colorColumn, size: sizeColumn, time: timeColumn},
            width,
            parseFloat(U.get(controlValues, VisControls.dotSize)) / 1000,
            0.5, // uncomment for dot opacity UI // (controlValues.get(Ctrl.dotOpacity) as number) / 100,
            tooltips,
            columnIds.color !== null
                && STT.isCategorical(dataTable.getStype(columnIds.color))
                && controlValues.get(VisControls.timeLineMode) === TimeLineMode.byCategory,
            {grid: true}
        )
        const titles = U.joinWithAnd(U.excludeNullOrUndefined([xColumn.title, yColumn.title, colorColumn?.title, sizeColumn?.title]), true)

        return {
            node: renderResult.svg,
            title: $t("scatterplot.title") + ' ' + titles,
            footer: addSamplingMessage (cd.footer, renderResult.samplingApplied ?? false),
            height: renderResult.height
        }
    }

    handleUserAction (userActionInfo: UserActionInfo, data:VisualizerData, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues):UserActionResponse|undefined {
        return undefined
    }
}