/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import * as React from 'react'
import ProgressRing from "../Components/ProgressRing"
import {Backdrop, Button} from "@material-ui/core"
import {colors} from "../Colors"
import $t from "../i18n/i18n"

const cancelButton = css`
    border-color: white;
    color: white;
    margin-top: 20px;
    //background-color: ${colors.grey.c700};
    &:hover {
      border-color: white;
      color: white;
      background-color: ${colors.grey.c800};
    }
`

export function WorkspaceBackdrop (props: {visible:boolean, percent?:number, message?:string, onCancel?:()=>void}) {
    return <Backdrop style={{zIndex: 99999, color: "white"}} open={props.visible} transitionDuration={500}>
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <ProgressRing radius={35} color="white" percent={props.percent}/>
                <span style={{marginLeft:15, fontSize:24}}>{props.message}</span>
            </div>
            {props.onCancel && <Button variant="outlined" css={cancelButton} onClick={props.onCancel}>{$t("dialog.button.cancel")}</Button>}
        </div>
    </Backdrop>

}