import React, {CSSProperties, FunctionComponent} from 'react'
import {SemanticType} from "./Concepts/SemanticType"
import * as U from "./Utils"

// icons are taken from https://fontawesome.com/v6.0/icons

const icons = {
    "folder-open": {viewBox: "0 0 576 512", d:"M572.6 270.3l-96 192C471.2 473.2 460.1 480 447.1 480H64c-35.35 0-64-28.66-64-64V96c0-35.34 28.65-64 64-64h117.5c16.97 0 33.25 6.742 45.26 18.75L275.9 96H416c35.35 0 64 28.66 64 64v32h-48V160c0-8.824-7.178-16-16-16H256L192.8 84.69C189.8 81.66 185.8 80 181.5 80H64C55.18 80 48 87.18 48 96v288l71.16-142.3C124.6 230.8 135.7 224 147.8 224h396.2C567.7 224 583.2 249 572.6 270.3z"},
    "floppy-disk": {viewBox: "0 0 448 512", d:"M224 256c-35.2 0-64 28.8-64 64c0 35.2 28.8 64 64 64c35.2 0 64-28.8 64-64C288 284.8 259.2 256 224 256zM433.1 129.1l-83.9-83.9C341.1 37.06 328.8 32 316.1 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V163.9C448 151.2 442.9 138.9 433.1 129.1zM128 80h144V160H128V80zM400 416c0 8.836-7.164 16-16 16H64c-8.836 0-16-7.164-16-16V96c0-8.838 7.164-16 16-16h16v104c0 13.25 10.75 24 24 24h192C309.3 208 320 197.3 320 184V83.88l78.25 78.25C399.4 163.2 400 164.8 400 166.3V416z"},
    "clipboard": {viewBox: "0 0 384 512", d:"M320 64h-49.61C262.1 27.48 230.7 0 192 0S121 27.48 113.6 64H64C28.65 64 0 92.66 0 128v320c0 35.34 28.65 64 64 64h256c35.35 0 64-28.66 64-64V128C384 92.66 355.3 64 320 64zM192 48c13.23 0 24 10.77 24 24S205.2 96 192 96S168 85.23 168 72S178.8 48 192 48zM336 448c0 8.82-7.178 16-16 16H64c-8.822 0-16-7.18-16-16V128c0-8.82 7.178-16 16-16h18.26C80.93 117.1 80 122.4 80 128v16C80 152.8 87.16 160 96 160h192c8.836 0 16-7.164 16-16V128c0-5.559-.9316-10.86-2.264-16H320c8.822 0 16 7.18 16 16V448z"},
    "books": {viewBox: "0 0 512 512", d:"M510.4 435.4L402.7 35.5C396.9 14.08 377.5 0 356.4 0c-4.111 0-8.294.531-12.46 1.641l-61.82 16.48c-5.885 1.57-11.14 4.258-15.78 7.633C258.3 10.51 242.4 0 224 0h-64c-8.8 0-16.9 2.531-24 6.656C128.9 2.531 120.8 0 112 0H48C21.49 0 0 21.49 0 48v416c0 26.5 21.49 48 48 48h64c8.787 0 16.92-2.531 24-6.656 7.1 4.156 15.2 6.656 24 6.656h64c26.51 0 48-21.49 48-48V165.3l83.8 311.3c5.8 21.3 25.1 35.4 46.3 35.4 4.111 0 8.293-.531 12.46-1.641l61.82-16.48C502 487 517.2 460.8 510.4 435.4zM224 48v48h-64V48h64zm-64 96h64v224h-64V144zm-48 224H48V144h64v224zm0-320v48H48V48h64zM48 464v-48h64v48H48zm112 0l.001-47.1L224 416v48h-64zM294.4 64.5l61.83-16.48 12.47 45.91-61.9 16.47-12.4-45.9zm24.9 92.1l61.91-16.51 58.04 215.3-61.91 16.51-58.04-215.3zm82.9 307.5l-12.41-46.04 61.91-16.51 12.38 45.94-61.88 16.61z"},
    "gear": {viewBox: "0 0 512 512", d:"M504.1 315.1c0-8.652-4.607-16.84-12.36-21.39l-32.91-18.97C459.5 269.1 459.8 262.5 459.8 256s-.3228-13.1-.9683-19.62l32.91-18.97c7.752-4.548 12.36-12.74 12.36-21.39c0-21.27-49.32-128.2-84.52-128.2c-4.244 0-8.51 1.094-12.37 3.357l-32.78 18.97c-10.71-7.742-22.07-14.32-34.07-19.74V32.49c0-11.23-7.484-21.04-18.33-23.88C300.5 2.871 278.3 0 256 0C233.8 0 211.5 2.871 189.9 8.613C179.1 11.45 171.6 21.26 171.6 32.49v37.94c-12 5.42-23.36 12-34.07 19.74l-32.78-18.97C100.9 68.94 96.63 67.85 92.38 67.85c-.0025 0 .0025 0 0 0c-32.46 0-84.52 101.7-84.52 128.2c0 8.652 4.607 16.84 12.36 21.39l32.91 18.97C52.49 242.9 52.17 249.5 52.17 256s.3228 13.1 .9683 19.62L20.23 294.6C12.47 299.1 7.867 307.3 7.867 315.1c0 21.27 49.32 128.2 84.52 128.2c4.244 0 8.51-1.094 12.37-3.357l32.78-18.97c10.71 7.742 22.07 14.32 34.07 19.74v37.94c0 11.23 7.484 21.04 18.33 23.88C211.5 509.1 233.7 512 255.1 512c22.25 0 44.47-2.871 66.08-8.613c10.84-2.84 18.33-12.65 18.33-23.88v-37.94c12-5.42 23.36-12 34.07-19.74l32.78 18.97c3.855 2.264 8.123 3.357 12.37 3.357C452.1 444.2 504.1 342.4 504.1 315.1zM415.2 389.1l-43.66-25.26c-42.06 30.39-32.33 24.73-79.17 45.89v50.24c-13.29 2.341-25.58 3.18-36.44 3.18c-15.42 0-27.95-1.693-36.36-3.176v-50.24c-46.95-21.21-37.18-15.54-79.17-45.89l-43.64 25.25c-15.74-18.69-28.07-40.05-36.41-63.11L103.1 301.7C101.4 276.1 100.1 266.1 100.1 256c0-10.02 1.268-20.08 3.81-45.76L60.37 185.2C68.69 162.1 81.05 140.7 96.77 122l43.66 25.26c42.06-30.39 32.33-24.73 79.17-45.89V51.18c13.29-2.341 25.58-3.18 36.44-3.18c15.42 0 27.95 1.693 36.36 3.176v50.24c46.95 21.21 37.18 15.54 79.17 45.89l43.64-25.25c15.74 18.69 28.07 40.05 36.4 63.11L408 210.3c2.538 25.64 3.81 35.64 3.81 45.68c0 10.02-1.268 20.08-3.81 45.76l43.58 25.12C443.3 349.9 430.9 371.3 415.2 389.1zM256 159.1c-52.88 0-96 43.13-96 96S203.1 351.1 256 351.1s96-43.13 96-96S308.9 159.1 256 159.1zM256 304C229.5 304 208 282.5 208 256S229.5 208 256 208s48 21.53 48 48S282.5 304 256 304z"},
    "hand": {viewBox: "0 0 512 512", d:"M408 80c-3.994 0-7.91 .3262-11.73 .9551c-9.586-28.51-36.57-49.11-68.27-49.11c-6.457 0-12.72 .8555-18.68 2.457C296.6 13.73 273.9 0 248 0C222.1 0 199.3 13.79 186.6 34.44C180.7 32.85 174.5 32 168.1 32C128.4 32 96.01 64.3 96.01 104v121.6C90.77 224.6 85.41 224 80.01 224c-.0026 0 .0026 0 0 0C36.43 224 0 259.2 0 304.1c0 20.29 7.558 39.52 21.46 54.45l81.25 87.24C141.9 487.9 197.4 512 254.9 512h33.08C393.9 512 480 425.9 480 320V152C480 112.3 447.7 80 408 80zM432 320c0 79.41-64.59 144-143.1 144H254.9c-44.41 0-86.83-18.46-117.1-50.96l-79.76-85.63c-6.202-6.659-9.406-15.4-9.406-23.1c0-22.16 18.53-31.4 31.35-31.4c8.56 0 17.1 3.416 23.42 10.18l26.72 28.69C131.8 312.7 133.9 313.4 135.9 313.4c4.106 0 8.064-3.172 8.064-8.016V104c0-13.25 10.75-24 23.1-24c13.25 0 23.1 10.75 23.1 24v152C192 264.8 199.2 272 208 272s15.1-7.163 15.1-15.1L224 72c0-13.25 10.75-24 23.1-24c13.25 0 23.1 10.75 23.1 24v184C272 264.8 279.2 272 288 272s15.99-7.164 15.99-15.1l.0077-152.2c0-13.25 10.75-24 23.1-24c13.25 0 23.1 10.75 23.1 24v152.2C352 264.8 359.2 272 368 272s15.1-7.163 15.1-15.1V152c0-13.25 10.75-24 23.1-24c13.25 0 23.1 10.75 23.1 24V320z"},
    "hashtag": {viewBox:"0 0 448 512", d:"M424 136l-74.01-.0254l13.63-75.76c2.344-13.03-6.312-25.53-19.38-27.88c-13-2.188-25.5 6.344-27.88 19.38l-15.16 84.26h-111.2l13.63-75.76c2.344-13.03-6.312-25.53-19.38-27.88C171.2 30.15 158.7 38.69 156.4 51.72l-15.16 84.26H56c-13.25 0-24 10.78-24 24.03c0 13.25 10.75 23.97 24 23.97h76.57l-25.92 144H24c-13.25 0-24 10.76-24 24.01C0 365.3 10.75 376 24 376l74.01-.0078l-13.63 75.76c-2.344 13.03 6.312 25.53 19.38 27.88C105.2 479.9 106.6 480 108 480c11.38 0 21.5-8.158 23.59-19.75l15.16-84.26h111.2l-13.63 75.76c-2.344 13.03 6.312 25.53 19.38 27.88C265.2 479.9 266.6 480 268 480c11.38 0 21.5-8.158 23.59-19.75l15.16-84.26L392 376c13.25 0 24-10.75 24-23.1c0-13.25-10.75-24.01-24-24.01h-76.57l25.92-144L424 184c13.25 0 24-10.75 24-23.1C448 146.8 437.3 136 424 136zM266.7 327.1h-111.2l25.92-144h111.2L266.7 327.1z"},
    "1": {viewBox:"0 0 256 512", d:"M256 456c0 13.25-10.75 24-24 24h-208C10.75 480 0 469.3 0 456s10.75-24 24-24h80V98.95L36.58 140.4C25.3 147.4 10.53 143.9 3.563 132.6C-3.391 121.3 .1406 106.5 11.42 99.56l104-64c7.391-4.562 16.7-4.734 24.28-.5156C147.3 39.3 152 47.31 152 56V432h80C245.3 432 256 442.8 256 456z"},
    "ban": {viewBox:"0 0 512 512", d:"M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM48 256c0-48.71 16.95-93.47 45.11-128.1l291.9 291.9C349.5 447 304.7 464 256 464C141.3 464 48 370.7 48 256zM418.9 384.1L127 93.11C162.5 64.95 207.3 48 256 48c114.7 0 208 93.31 208 208C464 304.7 447 349.5 418.9 384.1z"},
    "calendar-clock": {viewBox:"0 0 576 512", d:"M432 224C352.4 224 288 288.4 288 368s64.38 144 144 144S576 447.6 576 368S511.6 224 432 224zM480 384h-54.25C420.4 384 416 379.6 416 374.3V304C416 295.2 423.2 288 432 288C440.8 288 448 295.2 448 304V352h32c8.838 0 16 7.164 16 16S488.8 384 480 384zM284.6 464H64c-8.822 0-16-7.176-16-16V192H448V128c0-35.35-28.65-64-64-64h-40V24c0-13.25-10.75-24-23.1-24S296 10.75 296 24V64h-144V24C152 10.75 141.3 0 128 0S104 10.75 104 24V64H64C28.65 64 0 92.65 0 128v320c0 35.35 28.65 64 64 64h267C312.6 499.1 296.9 482.8 284.6 464z"},
    "calendar-day": {viewBox:"0 0 448 512", d:"M384 64h-40V24c0-13.25-10.75-24-23.1-24S296 10.75 296 24V64h-144V24C152 10.75 141.3 0 128 0S104 10.75 104 24V64H64C28.65 64 0 92.65 0 128v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V128C448 92.65 419.3 64 384 64zM400 448c0 8.824-7.178 16-16 16H64c-8.822 0-16-7.176-16-16V192h352V448zM112 384h96c8.75 0 16-7.25 16-16v-96C224 263.3 216.8 256 208 256h-96C103.3 256 96 263.3 96 272v96C96 376.8 103.3 384 112 384z"},
    "clock": {viewBox:"0 0 512 512", d:"M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256c141.4 0 256-114.6 256-256C512 114.6 397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM358.1 287.2L280 242.1V120C280 106.8 269.3 96 256 96S232 106.8 232 120V256c0 8.578 4.578 16.5 12 20.78l90.06 52C337.8 330.1 341.1 332 346 332c8.281 0 16.36-4.297 20.8-12C373.5 308.5 369.5 293.8 358.1 287.2z"},
    "fingerprint": {viewBox:"0 0 512 512", d:"M256.1 246c-13.25 0-23.1 10.75-23.1 23.1c1.125 72.25-8.124 141.9-27.75 211.5C201.7 491.3 206.6 512 227.5 512c10.5 0 20.12-6.875 23.12-17.5c13.5-47.87 30.1-125.4 29.5-224.5C280.1 256.8 269.4 246 256.1 246zM255.2 164.3C193.1 164.1 151.2 211.3 152.1 265.4c.75 47.87-3.75 95.87-13.37 142.5c-2.75 12.1 5.624 25.62 18.62 28.37c12.1 2.625 25.62-5.625 28.37-18.62c10.37-50.12 15.12-101.6 14.37-152.1C199.7 238.6 219.1 212.1 254.5 212.3c31.37 .5 57.24 25.37 57.62 55.5c.8749 47.1-2.75 96.25-10.62 143.5c-2.125 12.1 6.749 25.37 19.87 27.62c19.87 3.25 26.75-15.12 27.5-19.87c8.249-49.1 12.12-101.1 11.25-151.1C359.2 211.1 312.2 165.1 255.2 164.3zM144.6 144.5C134.2 136.1 119.2 137.6 110.7 147.9C85.25 179.4 71.38 219.3 72 259.9c.6249 37.62-2.375 75.37-8.999 112.1c-2.375 12.1 6.249 25.5 19.25 27.87c20.12 3.5 27.12-14.87 27.1-19.37c7.124-39.87 10.5-80.62 9.749-121.4C119.6 229.3 129.2 201.3 147.1 178.3C156.4 167.9 154.9 152.9 144.6 144.5zM253.1 82.14C238.6 81.77 223.1 83.52 208.2 87.14c-12.87 2.1-20.87 15.1-17.87 28.87c3.125 12.87 15.1 20.75 28.1 17.75C230.4 131.3 241.7 130 253.4 130.1c75.37 1.125 137.6 61.5 138.9 134.6c.5 37.87-1.375 75.1-5.624 113.6c-1.5 13.12 7.999 24.1 21.12 26.5c16.75 1.1 25.5-11.87 26.5-21.12c4.625-39.75 6.624-79.75 5.999-119.7C438.6 165.3 355.1 83.64 253.1 82.14zM506.1 203.6c-2.875-12.1-15.51-21.25-28.63-18.38c-12.1 2.875-21.12 15.75-18.25 28.62c4.75 21.5 4.875 37.5 4.75 61.62c-.1249 13.25 10.5 24.12 23.75 24.25c13.12 0 24.12-10.62 24.25-23.87C512.1 253.8 512.3 231.8 506.1 203.6zM465.1 112.9c-48.75-69.37-128.4-111.7-213.3-112.9c-69.74-.875-134.2 24.84-182.2 72.96c-46.37 46.37-71.34 108-70.34 173.6l-.125 21.5C-.3651 281.4 10.01 292.4 23.26 292.8C23.51 292.9 23.76 292.9 24.01 292.9c12.1 0 23.62-10.37 23.1-23.37l.125-23.62C47.38 193.4 67.25 144 104.4 106.9c38.87-38.75 91.37-59.62 147.7-58.87c69.37 .1 134.7 35.62 174.6 92.37c7.624 10.87 22.5 13.5 33.37 5.875C470.1 138.6 473.6 123.8 465.1 112.9z"},
    "shapes": {viewBox:"0 0 512 512", d:"M128 256c-70.75 0-128 57.25-128 128s57.25 128 128 128s128-57.25 128-128S198.8 256 128 256zM128 464c-44.11 0-80-35.89-80-80c0-44.11 35.89-80 80-80c44.11 0 80 35.89 80 80C208 428.1 172.1 464 128 464zM193 224h189.1c25.33 0 41.22-26.69 28.55-48.02L316.6 15.97C310.6 6.125 299.7 0 288 0s-22.55 6.125-28.55 15.97L164.5 175.1C151.8 197.3 167.7 224 193 224zM288 61.9L355.7 176H220.3L288 61.9zM480 272h-160c-17.62 0-32 14.38-32 32v160c0 17.62 14.38 32 32 32h160c17.62 0 32-14.38 32-32v-160C512 286.4 497.6 272 480 272zM464 448h-128v-128h128V448z"},
    "text": {viewBox:"0 0 448 512", d:"M448 56v80C448 149.3 437.3 160 424 160S400 149.3 400 136V80h-152v352h48c13.25 0 24 10.75 24 24S309.3 480 296 480h-144C138.8 480 128 469.3 128 456s10.75-24 24-24h48v-352H48v56C48 149.3 37.25 160 24 160S0 149.3 0 136v-80C0 42.75 10.75 32 24 32h400C437.3 32 448 42.75 448 56z"},
    "xmark": {viewBox:"0 0 320 512", d:"M312.1 375c9.369 9.369 9.369 24.57 0 33.94s-24.57 9.369-33.94 0L160 289.9l-119 119c-9.369 9.369-24.57 9.369-33.94 0s-9.369-24.57 0-33.94L126.1 256L7.027 136.1c-9.369-9.369-9.369-24.57 0-33.94s24.57-9.369 33.94 0L160 222.1l119-119c9.369-9.369 24.57-9.369 33.94 0s9.369 24.57 0 33.94L193.9 256L312.1 375z"},
    "arrow-left": {viewBox:"0 0 448 512", d:"M447.1 256c0 13.25-10.76 24.01-24.01 24.01H83.9l132.7 126.6c9.625 9.156 9.969 24.41 .8125 33.94c-9.156 9.594-24.34 9.938-33.94 .8125l-176-168C2.695 268.9 .0078 262.6 .0078 256S2.695 243.2 7.445 238.6l176-168C193 61.51 208.2 61.85 217.4 71.45c9.156 9.5 8.812 24.75-.8125 33.94l-132.7 126.6h340.1C437.2 232 447.1 242.8 447.1 256z"},
    "arrow-left-to-line": {viewBox:"0 0 448 512", d:"M48.03 424V88c0-13.25-10.76-24-24.02-24S0 74.75 0 88v336C0 437.3 10.76 448 24.02 448S48.03 437.3 48.03 424zM272.3 366.5L180.3 280h243.6C437.2 280 448 269.3 448 256s-10.76-23.1-24.02-23.1H180.3l91.98-86.53c5.035-4.719 7.568-11.09 7.568-17.47c0-5.906-2.158-11.81-6.536-16.44c-9.069-9.656-24.27-10.12-33.93-1.031l-136.1 128c-9.694 9.062-9.694 25.87 0 34.94l136.1 128c9.663 9.094 24.86 8.625 33.93-1.031C282.5 390.8 282 375.6 272.3 366.5z"},
    "arrow-right": {viewBox:"0 0 448 512", d:"M264.6 70.63l176 168c4.75 4.531 7.438 10.81 7.438 17.38s-2.688 12.84-7.438 17.38l-176 168c-9.594 9.125-24.78 8.781-33.94-.8125c-9.156-9.5-8.812-24.75 .8125-33.94l132.7-126.6H24.01c-13.25 0-24.01-10.76-24.01-24.01s10.76-23.99 24.01-23.99h340.1l-132.7-126.6C221.8 96.23 221.5 80.98 230.6 71.45C239.8 61.85 254.1 61.51 264.6 70.63z"},
    "arrow-right-to-line": {viewBox:"0 0 448 512", d:"M399.1 88v336c0 13.25 10.76 24 24.02 24C437.2 448 448 437.3 448 424V88C448 74.75 437.2 64 423.1 64C410.7 64 399.1 74.75 399.1 88zM175.7 145.5l91.98 86.53h-243.6C10.76 232 0 242.8 0 256s10.76 24 24.02 24h243.6l-91.98 86.53C170.6 371.3 168.1 377.6 168.1 384c0 5.906 2.158 11.81 6.536 16.44c9.069 9.656 24.27 10.12 33.93 1.031l136.1-128c9.694-9.062 9.694-25.87 0-34.94l-136.1-128C198.9 101.4 183.7 101.9 174.6 111.6C165.5 121.2 165.1 136.4 175.7 145.5z"},
    "caret-down-solid": {viewBox:"0 0 320 512", d:"M310.6 246.6l-127.1 128C176.4 380.9 168.2 384 160 384s-16.38-3.125-22.63-9.375l-127.1-128C.2244 237.5-2.516 223.7 2.438 211.8S19.07 192 32 192h255.1c12.94 0 24.62 7.781 29.58 19.75S319.8 237.5 310.6 246.6z"},
    "angle-down": {viewBox:"0 0 384 512", d:"M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"},
    "facebook": {viewBox:"0 0 24 24", d:"M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"},
    "twitter": {viewBox:"0 0 24 24", d:"M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"},
    "telegram": {viewBox:"0 0 496 512", d:"M248,8C111.033,8,0,119.033,0,256S111.033,504,248,504,496,392.967,496,256,384.967,8,248,8ZM362.952,176.66c-3.732,39.215-19.881,134.378-28.1,178.3-3.476,18.584-10.322,24.816-16.948,25.425-14.4,1.326-25.338-9.517-39.287-18.661-21.827-14.308-34.158-23.215-55.346-37.177-24.485-16.135-8.612-25,5.342-39.5,3.652-3.793,67.107-61.51,68.335-66.746.153-.655.3-3.1-1.154-4.384s-3.59-.849-5.135-.5q-3.283.746-104.608,69.142-14.845,10.194-26.894,9.934c-8.855-.191-25.888-5.006-38.551-9.123-15.531-5.048-27.875-7.717-26.8-16.291q.84-6.7,18.45-13.7,108.446-47.248,144.628-62.3c68.872-28.647,83.183-33.623,92.511-33.789,2.052-.034,6.639.474,9.61,2.885a10.452,10.452,0,0,1,3.53,6.716A43.765,43.765,0,0,1,362.952,176.66Z"},
    "download": {viewBox:"0 0 512 512", d:"M448 304h-53.5l-48 48H448c8.822 0 16 7.178 16 16V448c0 8.822-7.178 16-16 16H64c-8.822 0-16-7.178-16-16v-80C48 359.2 55.18 352 64 352h101.5l-48-48H64c-35.35 0-64 28.65-64 64V448c0 35.35 28.65 64 64 64h384c35.35 0 64-28.65 64-64v-80C512 332.7 483.3 304 448 304zM432 408c0-13.26-10.75-24-24-24S384 394.7 384 408c0 13.25 10.75 24 24 24S432 421.3 432 408zM239 368.1C243.7 373.7 249.8 376 256 376s12.28-2.344 16.97-7.031l136-136c9.375-9.375 9.375-24.56 0-33.94s-24.56-9.375-33.94 0L280 294.1V24C280 10.75 269.3 0 256 0S232 10.75 232 24v270.1L136.1 199c-9.375-9.375-24.56-9.375-33.94 0s-9.375 24.56 0 33.94L239 368.1z"},
}

export type IconType = keyof typeof icons

interface IconProps {
    className?: string
    style?: CSSProperties
    type: IconType
}

export const Icon: FunctionComponent<IconProps> = (props: IconProps) =>
    <svg className={props.className} style={props.style} viewBox={icons[props.type].viewBox}>
        <path fill="currentColor" d={icons[props.type].d}/>
    </svg>

export function innerTypeIcon(type:SemanticType, style?: CSSProperties): JSX.Element {
    switch (type) {
        case SemanticType.Skip:
            return <Icon type="ban" style={style} key={type.toString() + 'Icon'}/>
        case SemanticType.Number:
            return <Icon type="1" style={style} key={type.toString() + 'Icon'}/>
        case SemanticType.Category:
            return <Icon type="shapes" style={style} key={type.toString() + 'Icon'}/>
        case SemanticType.Text:
            return <Icon type="text" style={style} key={type.toString() + 'Icon'}/>
        case SemanticType.DateTime:
            return <Icon type="calendar-clock" style={style} key={type.toString() + 'Icon'}/>
        case SemanticType.Date:
            return <Icon type="calendar-day" style={style} key={type.toString() + 'Icon'}/>
        case SemanticType.Time:
            return <Icon type="clock" style={style} key={type.toString() + 'Icon'}/>
        case SemanticType.Identifier:
            return <Icon type="fingerprint" style={style} key={type.toString() + 'Icon'}/>
        case SemanticType.OrdinalNumber:
            return <Icon type="hashtag" style={style} key={type.toString() + 'Icon'}/>
        default:
            U.shouldNeverGetHere(type)
    }
}

