import * as U from "../Utils"
import {alias, list, object, primitive, serializable} from "serializr"
import {mapOfObjects} from "../CustomSerializr"
import {ColumnId} from "../Concepts/Basic"
import {VisualizerType} from "../Concepts/Visualizer"
import {
    ISpecifiedVisSettings,
    IVisSettings,
    VisSettingId,
    VisSettingsForColumnSet,
    VisSettingValue,
    VisTypeId
} from "../Concepts/VisSettings"
import {IObjectStore, IWorkspaceStore} from "../Concepts/DataBase"

// Set of data columns (their ids)
export class ColumnSet {
    @serializable(alias('c', list(primitive()))) protected columnIds: ColumnId[] = []

    constructor(columnIds: ColumnId[]=[]) {
        this.columnIds = columnIds
    }

    supersetOf (columnIds:ColumnId[]) {
        return !columnIds.some(id => this.columnIds.indexOf(id) < 0)
    }

    subsetOf (columnIds:ColumnId[]) {
        return !this.columnIds.some(id => columnIds.indexOf(id) < 0)
    }

    equalTo(columnIds:ColumnId[]) {
        return this.subsetOf(columnIds) && this.supersetOf(columnIds)
    }

    get length () {
        return this.columnIds.length
    }
}

// A (column set) + (visualizer settings) pair
export class VisSettingsByColumnSet {
    @serializable(alias('c', object(ColumnSet))) readonly columns: ColumnSet
    @serializable(alias('s', mapOfObjects())) protected readonly settings: VisSettingsForColumnSet

    constructor(columns?: ColumnSet, settings?: VisSettingsForColumnSet) {
        this.columns = columns ?? new ColumnSet()
        this.settings = settings ?? new Map()
    }

    get(id:VisSettingId) {
        return this.settings.get(id)
    }

    getDefined(id:VisSettingId) {
        return U.get (this.settings, id)
    }

    set(id:VisSettingId, value:VisSettingValue) {
        return this.settings.set(id, value)
    }

}

// A set of visualizer view settings by column sets related to a specific visualizer class
export class VisSettingsByVisType {
    @serializable(alias('i', primitive())) readonly id:VisTypeId
    @serializable(alias('s', list(object(VisSettingsByColumnSet)))) protected readonly settings:VisSettingsByColumnSet[] = []

    constructor(visType:VisualizerType) {
        this.id = visType
    }

    filter(predicate: (value: VisSettingsByColumnSet, index: number, array: VisSettingsByColumnSet[]) => boolean, thisArg?: any): VisSettingsByColumnSet[] {
        return this.settings.filter(predicate, thisArg)
    }

    findByColumnIds(columnIds: ColumnId[]): VisSettingsByColumnSet|undefined {
        for (const set of this.settings) {
            if (set.columns.equalTo(columnIds)) {
                return set
            }
        }
    }

    findOrCreateByColumnIds(columnIds: ColumnId[]): VisSettingsByColumnSet {
        let setting = this.findByColumnIds(columnIds)
        if (!setting) {
            setting = new VisSettingsByColumnSet(new ColumnSet(columnIds))
            this.settings.push(setting)
        }
        return setting
    }
}

// User congirable settings defining how a chart looks like in the context of (visualizer type + column set)
export class VisSettings implements IVisSettings {
    protected static SAVE_DELAY_MS = 500
    protected readonly saveTimers: Map<VisTypeId, number | undefined> = new Map()
    @serializable(alias('s', list(object(VisSettingsByVisType)))) protected readonly settings: VisSettingsByVisType[]

    static async loadFromStore(wsStore:IWorkspaceStore): Promise<IVisSettings> {
        const store = wsStore.createVisSettingsStore(VisSettingsByVisType),
            settings = await store.retrieveAll()
        return new VisSettings(settings, store)
    }

    constructor(settings?: VisSettingsByVisType[], protected store?:IObjectStore<VisSettingsByVisType>) {
        this.settings = settings ?? []
    }

    async putToStore(wsStore:IWorkspaceStore) {
        this.store = wsStore.createVisSettingsStore(VisSettingsByVisType)
        await this.store.bulkAdd(this.settings)
    }

    protected has (visTypeId:VisTypeId) {
        return this.settings.some (s => s.id === visTypeId)
    }

    protected find (visTypeId:VisTypeId) {
        for (const s of this.settings) {
            if (s.id === visTypeId) {
                return s
            }
        }
        throw Error (`Didn't find any settings for visTypeId=${visTypeId}. Consider using has(VisTypeId) method first.`)
    }

    protected save(settings: VisSettingsByVisType, immediately = false) {
        // make a delay to save the final settings and avoid racing
        window.clearTimeout(this.saveTimers.get(settings.id))
        this.saveTimers.set(settings.id, window.setTimeout(() => {
            this.saveTimers.set(settings.id, undefined)
            if (this.store) {
                this.store.put(settings).then()
            } else {
                throw Error ("Can't save VisSetings object as it is not bound to any ObjectStore")
            }
        }, immediately ? 0 : VisSettings.SAVE_DELAY_MS))
    }

    availableFor (visTypeId: VisTypeId, columnIds: ColumnId[]): boolean {
        return this.has(visTypeId) && this.find(visTypeId).findByColumnIds(columnIds) !== undefined
    }

    get (visTypeId: VisTypeId, columnIds: ColumnId[], settingId: VisSettingId, defVal: VisSettingValue): VisSettingValue {
        // check if we have any settings for this visualizer
        if (this.has(visTypeId)) {
            const settingsForVisualizer = this.find(visTypeId)
            // check if we have this setting for given column set
            const val = settingsForVisualizer.findByColumnIds(columnIds)?.get(settingId)
            if (val !== undefined) {
                return val
            } else {
                // since we don't have this setting for given column set
                // find column subsets and supersets having this setting and choose the best one
                const subsets = settingsForVisualizer.filter(s => s.columns.subsetOf(columnIds) && s.get(settingId) !== undefined)
                const supersets = settingsForVisualizer.filter(s => s.columns.supersetOf(columnIds) && s.get(settingId) !== undefined)
                const maxSubsetSize = U.max(subsets.map(s => s.columns.length), 0)
                const minSupersetSize = U.min(supersets.map(s => s.columns.length), 1000)
                const maxSubset = subsets.filter(s => s.columns.length === maxSubsetSize)[0]
                const minSuperset = supersets.filter(s => s.columns.length === minSupersetSize)[0]
                if (subsets.length && supersets.length) {
                    // check if the biggest subset is closer to the given column set than the smallest superset
                    return columnIds.length - maxSubsetSize < minSupersetSize - columnIds.length
                        ? maxSubset.getDefined(settingId)
                        : minSuperset.getDefined(settingId)
                } else if (subsets.length) {
                    return maxSubset.getDefined(settingId)
                } else if (supersets.length) {
                    return minSuperset.getDefined(settingId)
                }
            }
        }
        return defVal
    }

    set (visTypeId: VisTypeId, columnIds: ColumnId[], settingId: VisSettingId, value: VisSettingValue): void {
        if (!this.has(visTypeId)) {
            this.settings.push(new VisSettingsByVisType(visTypeId))
        }

        const settings = this.find(visTypeId)
        const settingsForColumns = settings.findOrCreateByColumnIds(columnIds)
        settingsForColumns.set(settingId, value)
        this.save(settings)
    }
}

// Visualizer settings for specific visualizer and column set
export class SpecifiedVisSettings implements ISpecifiedVisSettings {
    constructor(protected visSettings: IVisSettings, protected columns: ColumnId[], protected visTypeId: VisTypeId) {
    }

    get = (setting: VisSettingId, defVal: VisSettingValue): VisSettingValue => this.visSettings.get(this.visTypeId, this.columns, setting, defVal)
    set = (setting: VisSettingId, value: VisSettingValue): void => this.visSettings.set(this.visTypeId, this.columns, setting, value)

    // true if these settings are not stored in the db but suggested
    get isSuggested(): boolean {
        return !this.visSettings.availableFor(this.visTypeId, this.columns)
    }
}