import React from "react"
import * as U from "../Utils"
import {MenuAction} from "../Concepts/Basic"
import {Icon, IconType} from "../Icons"
import css from "./SideMenu.module.scss"
import {Menu, MenuItem} from "@material-ui/core"
import $t from "../i18n/i18n"

export interface SideMenuItemInfo {
    text: string
    icon: IconType
    action: MenuAction
    hide?: boolean
    options?: {text: string, action: MenuAction}[]
}

interface Props {
    item: SideMenuItemInfo
    disabled: boolean
    dropdownMenuAnchor?: HTMLElement
    onAction: (action: MenuAction) => void
    onDropdownShow: (action: MenuAction, anchor: HTMLElement) => void
    onDropdownClose: () => void
}

export function SideMenuItem (props:Props) {
    const hasDropdown = props.item.options && props.item.options.length && !props.disabled
    return <div>
        <div className={css.iconBox}>
            <span title={props.item.text} onClick={props.disabled ? undefined : () => props.onAction(props.item.action)}>
                <Icon className={U.cls(css.icon, true, css.disabled, props.disabled)} type={props.item.icon} />
            </span>
            {
                hasDropdown && <span className={css.dropiconContainer} title={$t('menu.dropdown.title')}
                                     onClick={props.disabled ? undefined : (evt) => props.onDropdownShow(props.item.action, evt.currentTarget)}>
                  <Icon className={U.cls(css.dropicon, true, css.disabled, props.disabled)} type={'angle-down'}/>
              </span>
            }
        </div>
        {
            hasDropdown && props.item.options
            && <Menu open={props.dropdownMenuAnchor !== undefined}
                     anchorEl={props.dropdownMenuAnchor}
                     onClose={props.onDropdownClose}
                     autoFocus={false}>
                {
                    props.item.options.map (option =>
                        <MenuItem key={option.action} onClick={()=>{props.onAction(option.action);props.onDropdownClose()}}>{option.text}</MenuItem>
                    )
                }
            </Menu>
        }
    </div>
}
