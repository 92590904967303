import * as React from 'react'
import * as U from "../../Utils"
import * as MTU from "../../MainThreadUtils"
import * as RC from './RendererCommon'
import * as VC from '../VisCommon'
import {Tooltip, TooltipRow} from '../VisCommon'
import css from '../Visualizer.module.scss'
import * as STT from "../../StypeTools"
import {Link} from "../../Workspace/WorkspaceLocation"
import {colors} from "../../Colors"
import {TM, UniqueTestMarker} from "../../@testing/TestMarker"
import {LabelType, RenderResult, TooltipsById, VisualizerType} from "../../Concepts/Visualizer"
import {CategoryValue, ITableColumn} from "../../Concepts/Basic"
import {AggregationType} from "../../Concepts/Aggregation"
import {Rect} from "../../Concepts/Geometry"
import {BarChartData} from "../../Concepts/Visualizers/Renderers/BarChart"
import {SemanticType} from "../../Concepts/SemanticType";
import $t from "../../i18n/i18n";

export function render (
    categoricalColumn: ITableColumn,
    numericalColumns: ITableColumn[],
    data: BarChartData,
    aggrType: AggregationType,
    singAggrPrefix:string,
    width: number,
    stacked: boolean,
    sumPosAndNeg: boolean,
    tooltips: TooltipsById,
    yTitle?:string):RenderResult {

    const axisUnits = VC.getAxisUnitsByAggrType(numericalColumns.length > 0 ? numericalColumns[0]?.units : $t('barchart.frequency'), aggrType)

    const categories = [...data.keys()]

    const yLabelNames = STT.formatAxisLabels(SemanticType.Category, categories)

    const yLabels = new Map (categories.map ((cat, i) => [cat, yLabelNames[i]]))

    // calculate maximum absolute values on both sides of x=0 for each category and some onther info
    const valueStats:Map<CategoryValue, {maxPositive:number, maxNegative:number, margins:number, hasPosAndNeg:boolean}> = new Map();
    if (stacked) {
        if (sumPosAndNeg) {
            // when positive and negative values are summed together
            [...data.entries()].forEach(d => {
                const columnValues =  d[1]??[]
                let cur = 0, maxPos = 0, maxNeg = 0, hasPos = false, hasNeg = false
                for (const valData of columnValues) {
                    cur += valData.value
                    hasPos = hasPos || valData.value >= 0
                    hasNeg = hasNeg || valData.value < 0
                    if (cur > 0 && cur > maxPos) {
                        maxPos = cur
                    }
                    if (cur < 0 && -cur > maxNeg) {
                        maxNeg = -cur
                    }
                }
                valueStats.set(d[0], {
                    maxPositive: maxPos,
                    maxNegative: maxNeg,
                    margins: maxNeg > 0 ? 0 : columnValues.length,
                    hasPosAndNeg: hasPos && hasNeg
                })
            })
        }
        else {
            // when positive and negative values are shown separately around axis Y
            [...data.entries()].forEach(d => {
                const columnValues =  d[1]??[]
                let totalPos = 0, totalNeg = 0, hasPos = false, hasNeg = false
                for (const valData of columnValues) {
                    if (valData.value >=0) {
                        totalPos += valData.value
                        hasPos = true
                    } else {
                        totalNeg += -valData.value
                        hasNeg = true
                    }
                }
                valueStats.set(d[0], {maxPositive: totalPos, maxNegative: totalNeg, margins: columnValues.length, hasPosAndNeg: hasPos && hasNeg})
            })
        }
    } else {
        // when all values shown on its own column (not stacked)
        [...data.entries()].forEach(d => valueStats.set(d[0], {
            maxPositive: U.max((d[1]??[]).filter(v => v.value >= 0).map(v => v.value), 0),
            maxNegative: U.max((d[1]??[]).filter(v => v.value < 0).map(v => -v.value), 0),
            margins: 1,
            hasPosAndNeg: (d[1]??[]).filter(v => v.value >= 0).length > 0 && (d[1]??[]).filter(v => v.value < 0).length > 0
        }))
    }

   // same figures but for all the categories
    let maxPositive = Math.max (...[...valueStats.values()].map(v => v.maxPositive))
    const maxNegative = Math.max (...[...valueStats.values()].map(v => v.maxNegative))
    const maxMargins = Math.max (...[...valueStats.values()].map(v => v.margins))

    if (maxPositive + maxNegative === 0) {
        maxPositive = 1
    }

    // build x labels
    const xLabels = RC.getNegPosAxisTicks (maxNegative, maxPositive, [5,4,3,2], [7,6,5,4,3,2].filter (n => numericalColumns.length > 0 || n <= maxPositive)),
        xNegLabels = xLabels.filter(l => l.value<0),
        xPosLabels = xLabels.filter(l => l.value>0),
        minXLabel = U.max(xNegLabels.map (l => -l.value), 0),
        maxXLabel = U.max(xPosLabels.map (l => l.value), 0),
        xAxisRange =  minXLabel + maxXLabel,
        labelAreaWidthMaxCoeff = 0.3,
        labelMargin = width / 60,
        fLabelAreaWidth = (maxLabelWidth:number) => maxLabelWidth + labelMargin * 2,
        labels = [...categories].map (cat => U.get(yLabels, cat)),
        {fontSize:labelFontSize, maxLabelWidth} = RC.fitLabelFontSize(labels, (fontSize, maxLabelWidth) =>
            fLabelAreaWidth(maxLabelWidth) <= labelAreaWidthMaxCoeff * width),
        yTitleFontSize = Math.floor (width / 150 + VC.minFontSize),
        yTitleWidth = yTitle ? yTitleFontSize : 0,
        labelAreaWidth = fLabelAreaWidth(maxLabelWidth) + yTitleWidth,
        chartAreaWidth = width - labelAreaWidth,
        singleBarHeight = labelFontSize * 1.3,
        barSpaceY = labelFontSize / 3,
        strokeWidth = labelFontSize > 16 ? 2 : 1,
        barMargin = strokeWidth * 2,
        valueToPixel = (chartAreaWidth - maxMargins * barMargin) / xAxisRange,
        zeroX = labelAreaWidth + minXLabel * valueToPixel

    /**********************************************************************************************************
    /* BUILDING ***********************************************************************************************
    /**********************************************************************************************************/

    // labels & bars
    const labelTexts:JSX.Element[] = [], backStripes=[], bars = [], testMarker = new UniqueTestMarker (TM.chartElementWithTooltip)
    let i = 0, catTop = 0, categoryHeight:number|undefined
    for (const category of categories) {
        const values = data.get(category)
        const bridgeChart = stacked && sumPosAndNeg && (valueStats.get(category)?.hasPosAndNeg ?? false)
        if (values && values.length > 1 && (!stacked || bridgeChart))
        {
            // multi bar mode
            const lineCount = bridgeChart ? values.length : Math.max (values.filter(v=>v.value>=0).length, values.filter(v=>v.value<0).length)
            const smallBarHeight = singleBarHeight / Math.min (lineCount, 3)
            let curX = zeroX, curPosY = catTop + barMargin, curNegY = catTop + barMargin, j = 0
            for (const value of values) {
                const curWidth = Math.max (1, Math.abs (value.value * valueToPixel)),
                    rectId = `barchart-bar-${i}-${j}`,
                    tooltipRows = value.name === category
                        ? [new TooltipRow(value.value, category)]
                        : [
                            new TooltipRow(category, categoricalColumn.title),
                            new TooltipRow(value.value, singAggrPrefix+' '+U.deNull(value.name), undefined, axisUnits)
                        ],
                    commonProps = {
                        id: rectId,
                        className: css.lighterOnHover,
                        width: curWidth,
                        height: smallBarHeight,
                        stroke: value.color.dark,
                        strokeWidth: strokeWidth,
                        fill: value.color.normal,
                        'data-test': value.name ? testMarker.value : undefined,
                    }
                if (value.value >= 0) {
                    const x = bridgeChart ? curX : zeroX
                    bars.push(<Link key={`pos${i}-${j}`} columnIds={value.columnId ? [value.columnId] : []} visType={VisualizerType.NumFreqHistSharedX}>
                        <rect x={x} y={curPosY} {...commonProps} />
                    </Link>)
                    tooltips.set (rectId, new VC.Tooltip({x: x + curWidth / 2, y: curPosY}, tooltipRows))
                    curX += curWidth
                    curPosY += smallBarHeight + barMargin
                } else {
                    const x = (bridgeChart ? curX : zeroX) - curWidth, y = bridgeChart ? curPosY : curNegY
                    bars.push(<Link key={`neg${i}-${j}`} columnIds={value.columnId ? [value.columnId] : []} visType={VisualizerType.NumFreqHistSharedX}>
                        <rect x={x} y={y} {...commonProps} />
                    </Link>)
                    tooltips.set (rectId, new VC.Tooltip({x: x + curWidth / 2, y: y}, tooltipRows))
                    curX -= curWidth
                    if (bridgeChart) {
                        curPosY += smallBarHeight + barMargin
                    } else {
                        curNegY += smallBarHeight + barMargin
                    }
                }
                j += 1
            }
            categoryHeight = Math.max (singleBarHeight + barMargin * 2, lineCount * (smallBarHeight + barMargin) + barMargin)

            // add a total line for positive bars in bridgeChart mode
            if (bridgeChart) {
                const lineId = `barchart-total-line-${i}`
                bars.push(<line id={lineId} key={`l${i}-${j}`} className={css.lighterOnHover+' '+css.totalLine}
                                x1={curX} y1={catTop} x2={curX} y2={catTop + categoryHeight}
                                strokeWidth={strokeWidth * 2} />);
                const tooltipRows = [
                    new TooltipRow(category, categoricalColumn.title),
                    new TooltipRow(
                        values.reduce((sum, val) => sum + val.value, 0),
                        values.reduce((txt, val, i) => `${txt}${i === 0 ? '' : '+ '}${singAggrPrefix} ${U.deNull(val.name)} `, ''),
                    undefined,
                        axisUnits)
                ]
                tooltips.set (lineId, new VC.Tooltip({x: curX + strokeWidth, y: catTop}, tooltipRows))
            }
        }
        else
        {
            // single bar mode
            let curPosX = zeroX, curNegX = zeroX, j = 0, posCount =0 , negCount = 0
            for (const value of values ?? []) {
                const curWidth = Math.max (1, Math.abs (value.value * valueToPixel)),
                    rectId = `barchart-bar-${i}-${j}`,
                    tooltipRows = value.name === category
                      ? [new TooltipRow(value.value, category)]
                      : [
                          new TooltipRow(category, categoricalColumn.title),
                          new TooltipRow(value.value, singAggrPrefix+' '+U.deNull(value.name), undefined, axisUnits)
                      ],
                    commonProps = {
                        id: rectId,
                        className: css.lighterOnHover,
                        y: catTop + barMargin,
                        width: curWidth,
                        height: singleBarHeight,
                        stroke: value.color.dark,
                        strokeWidth: strokeWidth,
                        fill: value.color.normal,
                        'data-test':  value.name ? testMarker.value : undefined,
                    }
                if (value.value >= 0) {
                    bars.push(<Link key={`pos${i}-${j}`} columnIds={value.columnId ? [value.columnId] : []} visType={VisualizerType.NumFreqHistSharedX}>
                        <rect x={curPosX} {...commonProps} />
                    </Link>)
                    tooltips.set (rectId, new Tooltip({x: curPosX + curWidth / 2, y: catTop + barMargin}, tooltipRows))
                    curPosX += curWidth + barMargin
                    posCount += 1
                } else {
                    bars.push(<Link key={`neg${i}-${j}`} columnIds={value.columnId ? [value.columnId] : []} visType={VisualizerType.NumFreqHistSharedX}>
                        <rect x={curNegX - curWidth} {...commonProps} />
                    </Link>)
                    tooltips.set (rectId, new Tooltip({x: curNegX - curWidth / 2, y: catTop + barMargin}, tooltipRows))
                    curNegX -= curWidth + barMargin
                    negCount += 1
                }
                j += 1
            }
            categoryHeight = singleBarHeight + barMargin * 2

            // add a total line for positive bars
            if (posCount > 1) {
                const lineId = `barchart-total-pos-line-${i}`
                bars.push(<line id={lineId} key={`pl${i}-${j}`} className={css.lighterOnHover+' '+css.totalLine}
                                x1={curPosX} y1={catTop} x2={curPosX} y2={catTop + categoryHeight}
                                strokeWidth={strokeWidth * 2} />);
                const tooltipRows = [
                    new TooltipRow(category, categoricalColumn.title),
                    values
                        ? new TooltipRow(values.filter(v=>v.value>=0).reduce((sum, val) => sum + val.value, 0),
                            values.filter(v=>v.value>=0).reduce((txt, val, i) => `${txt}${i === 0 ? '' : '+ '}${singAggrPrefix} ${U.deNull(val.name)} `, ''),
                            undefined, axisUnits)
                        : undefined
                ]
                tooltips.set (lineId, new Tooltip({x: curPosX + strokeWidth, y: catTop}, tooltipRows))
            }

            // add a total line for negative bars
            if (negCount > 1) {
                const lineId = `barchart-total-neg-line-${i}`
                bars.push(<line id={lineId} key={`nl${i}-${j}`} className={css.lighterOnHover+' '+css.totalLine}
                                x1={curNegX} y1={catTop} x2={curNegX} y2={catTop + categoryHeight}
                                strokeWidth={strokeWidth * 2} />);
                const tooltipRows = [
                    new TooltipRow(category, categoricalColumn.title),
                    values
                        ? new TooltipRow(
                            values.filter(v=>v.value<0).reduce((sum, val) => sum + val.value, 0),
                            values.filter(v=>v.value<0).reduce((txt, val, i) => `${txt}${i === 0 ? '' : '+ '}${singAggrPrefix} ${U.deNull(val.name)} `, ''),
                            undefined, axisUnits)
                        : undefined
                ]
                tooltips.set (lineId, new Tooltip({x: curNegX - strokeWidth, y: catTop}, tooltipRows))
            }
        }

        backStripes.push (<rect key={i} x={yTitleWidth} y={catTop} width={width} height={categoryHeight} className={css.backStripe} />)

        // category label
        const labelText = yLabels.get(category) ?? ''
        RC.pushLabel(LabelType.Legend, labelTexts, `barLabelBox${i}`, labelText, new Rect (yTitleWidth + labelMargin, catTop, labelAreaWidth - labelMargin, categoryHeight), labelFontSize, tooltips, labelText.endsWith(U.ellipsis) ? category : undefined)

        catTop += categoryHeight + barSpaceY
        i += 1
    }

    // y-title
    const yTitleHeight = yTitle ? MTU.measureText(yTitle, yTitleFontSize, VC.chartFontFamily) : 0
    if (yTitle) {
        labelTexts.push(<text key={'ytitle'} x={0} y={catTop / 2} fontSize={yTitleFontSize}
                              dominantBaseline="baseline" textAnchor="middle" fill="black"
                              transform={`rotate(-90 ${yTitleFontSize / 2}, ${catTop / 2})`}>{yTitle}</text>)
    }

    // adjust x label font size and draw x axis
    const posXLabelSpace = maxXLabel * valueToPixel / Math.max(1, xPosLabels.length),
        negXLabelSpace = minXLabel * valueToPixel / Math.max(1, xNegLabels.length),
        neg = RC.adjustXLabelFontSize(xNegLabels.map (l => l.text), negXLabelSpace),
        pos = RC.adjustXLabelFontSize(xPosLabels.map (l => l.text), posXLabelSpace),
        rotateXLabels = (neg.rotated && xNegLabels.length > 0) || (pos.rotated && xPosLabels.length > 0),
        xLabelFontSize = xNegLabels.length > 0 ? (xPosLabels.length > 0 ? Math.min (neg.fontSize, pos.fontSize) : neg.fontSize) : pos.fontSize,
        axisY = catTop - barSpaceY,
        grid:JSX.Element[] = [],
        grid2:JSX.Element[] = [],
        unitsFontSize = Math.max (labelFontSize / 1.2, VC.minFontSize),
        axisTickHeight = xLabelFontSize / 2,
        axisTickMargin = xLabelFontSize / 2,
        xLabelHeight = rotateXLabels ? Math.max (...xLabels.map (l => MTU.measureText(l.text, xLabelFontSize, VC.chartFontFamily))) : xLabelFontSize,
        height = Math.max (yTitleHeight, axisY + axisTickHeight + axisTickMargin + xLabelHeight + (axisUnits ? unitsFontSize + axisTickMargin : 0))

    // a axis
    grid.push (<line key={'xaxis'} x1={labelAreaWidth} x2={width} y1={axisY} y2={axisY} stroke={colors.grey.c200} strokeWidth={strokeWidth} />)

    // x labels
    let prevLabelEnds = 0
    xLabels.forEach((xLabel, i) => {
        const x = zeroX + xLabel.value * valueToPixel,
            y = axisY + axisTickHeight + axisTickMargin,
            width = xLabel.value > 0 ? posXLabelSpace : negXLabelSpace,
            thisLabelStarts = x - xLabelFontSize / 2;

        // x grid
        (xLabel.value === 0 ? grid2 : grid).push(<line key={`bcxg${i}`} x1={x} x2={x} y1={0} y2={axisY + axisTickHeight} stroke={colors.grey.c200} strokeWidth={strokeWidth}/>)
        // x label
        if (!rotateXLabels || xLabel.value === 0 || prevLabelEnds < thisLabelStarts) {
            RC.pushLabel(rotateXLabels ? LabelType.XAxisRotated : LabelType.XAxis, grid, `bcxl${i}`, xLabel.text, new Rect(x - width / 2, y, width, xLabelFontSize), xLabelFontSize, tooltips)
            prevLabelEnds = thisLabelStarts + xLabelFontSize
        }
    })

    // final output
    return RC.getRenderResult(width, height,
        [
            <g key={1} strokeWidth={0}>{backStripes}</g>,
            <g key={2}>{grid}</g>,
            <g key={3}>{labelTexts}</g>,
            <g key={4}>{bars}</g>,
            <g key={5}>{grid2}</g>,
            axisUnits
                ? <text key={6} x={(width - labelAreaWidth) / 2 + labelAreaWidth}
                        y={axisY + axisTickHeight + axisTickMargin * 2 + xLabelHeight}
                        fontSize={unitsFontSize}
                        fill={"black"}
                        dominantBaseline={"hanging"}
                        textAnchor={"middle"}>{axisUnits}</text>
                : <g key={6}/>
        ])
}
