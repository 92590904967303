import * as NumColumnAggregator from "./NumColumnAggregator"
import {BarData} from "./Renderers/BarChart"
import {AggregationByInterval} from "../Aggregation"
import {FirstMoment, TimeIntervalType} from "../DateTime"

export interface VisData extends NumColumnAggregator.VisData {
    chartData: Map<number|null, BarData[]|null>
    aggrResult: AggregationByInterval
    yLabels: Map<FirstMoment | null, string | null>
    yLabelCommonPart: string | undefined
}

export interface VisColData extends NumColumnAggregator.VisColData {
    intervals:TimeIntervalType[]
}

export enum VisControls {
    interval = 30
}
