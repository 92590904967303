import {ITableColumn} from "../Concepts/Basic"
import {BarChartData} from "../Concepts/Visualizers/Renderers/BarChart"
import {Aggregation, AggregationType} from "../Concepts/Aggregation"
import {
    GetActionsEvent,
    GetActionsEventType,
    MatchDegree,
    VisCtrlAction,
    VisCtrlActionType,
    VisCtrlId,
    VisCtrlInfo,
    VisCtrlType,
    VisCtrlValues
} from "../Concepts/Visualizer"
import {
    BarType,
    PosNeg,
    SingPlur,
    SortBy,
    SortOrder,
    VisColData,
    VisControls,
    VisData
} from "../Concepts/Visualizers/NumColumnAggregator"
import * as U from "../Utils"
import * as STT from "../StypeTools"
import {Visualizer} from "./Visualizer"
import $t from "../i18n/i18n"

export default abstract class AbstractNumColumnAggregator<ColDataType extends VisColData, DataType extends VisData> extends Visualizer {

    protected abstract doesFitForBreakColumn (info: ITableColumn):boolean
    protected abstract isProperBreakColumn (info: ITableColumn):boolean

    protected recognizeColumns (columns:ITableColumn[]):{numericalColumns:ITableColumn[], breakColumn:ITableColumn} | null {
        let numericalColumns:ITableColumn[]=[]
        const breakColumns = columns.filter (c => this.doesFitForBreakColumn(c))
        // make sure that there is only one breakdown column and it has at least one non-NULL value
        if (breakColumns.length === 1 && breakColumns[0].summary.nullCount < breakColumns[0].summary.valueCount) {
            const breakColumn = breakColumns[0]
            if (breakColumn !== undefined) {
                // make sure that all other columns are numerical
                if (!columns.some(c => c.id !== breakColumn.id && !STT.isNumeric(c.stype)) && this.isProperBreakColumn(breakColumn)) {
                    // make sure that all numerical have same units
                    numericalColumns = columns.filter(c => c.id !== breakColumn.id)
                    if (!numericalColumns.some(c => c.units !== numericalColumns[0].units)) {
                        return {numericalColumns, breakColumn}
                    }
                }
            }
        }
        return null
    }

    matchDegree(columns:ITableColumn[]): MatchDegree {
        const rec = this.recognizeColumns(columns)
        return rec === null
            ? MatchDegree.NoMatch
            : (rec.numericalColumns.length === 0
                ? MatchDegree.AsALastResort
                : (rec.numericalColumns.length > 1
                    ? MatchDegree.Ordinary
                    : MatchDegree.BestFit)
            )
    }

    protected extractAggrType (controlValues:VisCtrlValues, cd:ColDataType):AggregationType {
        const value = controlValues.get(VisControls.aggrType) as AggregationType
        return cd.numericalColumns.length === 0 ? AggregationType.Sum : value
    }

    protected buildTitle (aggrType:AggregationType, numColumns:ITableColumn[], breakColumn:ITableColumn, altBreakDownBy?:string):{title: string, aggrPrefix:SingPlur} {
        const aggrPrefix = (():SingPlur => {
            switch (aggrType) {
                case AggregationType.Sum:
                    return {plur: $t('numaggr.title.sum.plur'), sing: $t('numaggr.title.sum.sing')}
                case AggregationType.Mean:
                    return {plur: $t('numaggr.title.mean.plur'), sing: $t('numaggr.title.mean.sing')}
                case AggregationType.Min:
                    return {plur: $t('numaggr.title.min.plur'), sing: $t('numaggr.title.min.sing')}
                case AggregationType.Max:
                    return {plur: $t('numaggr.title.max.plur'), sing: $t('numaggr.title.max.sing')}
                case AggregationType.NullCount:
                    return {plur: $t('numaggr.title.null'), sing: $t('numaggr.title.null')}
                case AggregationType.NonNullCount:
                    return {plur: $t('numaggr.title.nonnull'), sing: $t('numaggr.title.nonnull')}
            }
        })()

        return {
            title: numColumns.length === 0
                ? $t('numaggr.title.nonum') + ' ' + breakColumn.title + (altBreakDownBy ? ' ' + $t('numaggr.by') + ' '+ altBreakDownBy : '')
                : (numColumns.length > 1 ? aggrPrefix.plur : aggrPrefix.sing)
                + ' ' + U.joinWithAnd(numColumns.map (col => col.title), true)
                + ' ' + $t('numaggr.brokendownby') + ' '
                + (altBreakDownBy ?? '"'+breakColumn.title+'"'),
            aggrPrefix
        }
    }

    protected getCommonControls (breakColSortTitle:string, numColNumber:number):[VisCtrlId, VisCtrlInfo][] {
        return [
            [VisControls.aggrType, {
                title: $t('numaggr.ctrl.aggrtype.title'),
                type: VisCtrlType.Select,
                options: new Map([
                    [AggregationType.Sum, $t('numaggr.ctrl.aggrtype.sum')],
                    [AggregationType.Mean, $t('numaggr.ctrl.aggrtype.mean')],
                    [AggregationType.Min, $t('numaggr.ctrl.aggrtype.min')],
                    [AggregationType.Max, $t('numaggr.ctrl.aggrtype.max')],
                    [AggregationType.NullCount, $t('numaggr.ctrl.aggrtype.nulls')],
                    [AggregationType.NonNullCount, $t('numaggr.ctrl.aggrtype.nonnulls')]
                ]),
                defaultValue: AggregationType.Sum,
            }],
            [VisControls.stacked, {
                title: $t('numaggr.ctrl.stacked.title'),
                type: VisCtrlType.Radio,
                options: new Map([[BarType.separated, $t('numaggr.ctrl.stacked.separated')], [BarType.stacked, $t('numaggr.ctrl.stacked.stacked')]]),
                defaultValue: BarType.separated,
            }],
            [VisControls.sumPosAndNeg, {
                title: $t('numaggr.ctrl.posneg.title'),
                type: VisCtrlType.Radio,
                options: new Map([[PosNeg.separated, $t('numaggr.ctrl.posneg.separated')], [PosNeg.summed, $t('numaggr.ctrl.posneg.summedup')]]),
                defaultValue: PosNeg.summed
            }],
            [VisControls.sortBy, {
                title: $t('numaggr.ctrl.sortby.title'),
                type: VisCtrlType.Select,
                options: new Map([
                    [SortBy.breakingColumn, breakColSortTitle],
                    [SortBy.positiveValues, numColNumber > 0 ? $t('numaggr.ctrl.sortby.pos') : $t('numaggr.ctrl.sortby.freq')],
                    [SortBy.negativeValues, $t('numaggr.ctrl.sortby.neg')]
                ]),
                defaultValue: SortBy.positiveValues
            }],
            [VisControls.sortDir, {
                title: $t('numaggr.ctrl.sortdir.title'),
                type: VisCtrlType.Radio,
                options: new Map([[SortOrder.ascending, $t('numaggr.ctrl.sortdir.asc')], [SortOrder.descending, $t('numaggr.ctrl.sortdir.desc')]]),
                defaultValue: SortOrder.descending
            }]
        ]
    }

    protected sortByValues (barChartData:BarChartData, pos:boolean, inc:boolean, sumPosAndNeg:boolean):BarChartData {
        return new Map ([...barChartData.entries()]
            .sort((a, b) =>
                (((pos ? inc : !inc) ? a:b)[1]??[]).filter (d=>(pos?1:-1)*d.value>=0 || sumPosAndNeg).reduce((s,d)=>s + d.value, 0)
                - (((pos ? inc : !inc) ? b:a)[1]??[]).filter (d=>(pos?1:-1)*d.value>=0 || sumPosAndNeg).reduce((s,d)=>s + d.value, 0)))
    }

    protected determineNegPos (aggrResult:Aggregation<any>): {hasNegatives:boolean, hasPositives:boolean} {
        let hasNegatives = false, hasPositives = false
        for (const valuesForColumn of aggrResult.values()) {
            if (valuesForColumn !== null) {
                for (const value of valuesForColumn.values()) {
                    hasPositives = hasPositives || value >= 0
                    hasNegatives = hasNegatives || value < 0
                    if (hasPositives && hasNegatives) {
                        break
                    }
                }
                if (hasPositives && hasNegatives) {
                    break
                }
            }
        }
        return {hasNegatives, hasPositives}
    }

    protected determineNulls (numColumns:ITableColumn[]): {hasNULLs:boolean, hasNonNULLs:boolean} {
        return {
            hasNULLs: numColumns.filter(col => col.summary.nullCount > 0).length > 0,
            hasNonNULLs: numColumns.filter(col => col.summary.nullCount < col.summary.valueCount).length > 0
        }
    }

    protected buildActionsOnControls (event:GetActionsEvent, cd:ColDataType):VisCtrlAction[] {
        const actions: VisCtrlAction[] = []

        if (event.type === GetActionsEventType.DataLoadedWithSuggestedControlValues
            || event.type === GetActionsEventType.DataLoadedWithUserControlValues) {

            const d = event.data as unknown as DataType

            if (!d.hasPositives) {
                actions.push({action: VisCtrlActionType.HideOption, controlId: VisControls.sortBy, controlValue: SortBy.positiveValues})
            }
            if (!d.hasNegatives) {
                actions.push({action: VisCtrlActionType.HideOption, controlId: VisControls.sortBy, controlValue: SortBy.negativeValues})
            }
            if (d.aggrResult.size < 4) {
                actions.push({action: VisCtrlActionType.HideControl, controlId: VisControls.sortBy})
                actions.push({action: VisCtrlActionType.HideControl, controlId: VisControls.sortDir})
            }
            if (d.aggrResult.values().next().value.size < 2) {
                actions.push({action: VisCtrlActionType.HideControl, controlId: VisControls.stacked})
            }
            if (cd.numericalColumns.length === 0) {
                actions.push({action: VisCtrlActionType.HideControl, controlId: VisControls.aggrType})
            }
            if (!d.hasNULLs) {
                actions.push({
                    action: VisCtrlActionType.HideOption,
                    controlId: VisControls.aggrType,
                    controlValue: AggregationType.NullCount
                })
            }
            if (!d.hasNonNULLs) {
                actions.push({
                    action: VisCtrlActionType.HideOption,
                    controlId: VisControls.aggrType,
                    controlValue: AggregationType.NonNullCount
                })
            }
        }

        return actions
    }

    protected extractCommonParameters (controlValues:VisCtrlValues, columnData:ColDataType) {
        return {
            aggrType: this.extractAggrType(controlValues, columnData),
            sumPosAndNeg: controlValues.get(VisControls.sumPosAndNeg) === PosNeg.summed,
            sortAscending: controlValues.get(VisControls.sortDir) === SortOrder.ascending,
            sortBy: U.def(controlValues.get(VisControls.sortBy))
        }
    }
}