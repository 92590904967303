import React from 'react'
import {Box, Table, TableBody, TableCell, TableRow} from '@material-ui/core'
import {
    GetActionsEvent,
    GetActionsEventType,
    MatchDegree,
    RenderResult,
    TooltipsById, UserActionInfo, UserActionResponse,
    VisContent,
    VisCtrlAction,
    VisCtrlId,
    VisCtrlInfo,
    VisCtrlValues,
    VisualizerColumnData,
    VisualizerData,
    VisualizerType
} from "../Concepts/Visualizer"
import {Visualizer} from "./Visualizer"
import {render as renderFreqHist} from './Renderers/NumFreqHistSharedX'
import {render as renderPie} from './Renderers/PieChart'
import Icon from './VisIcons/ColumnProfile.svg'
import * as U from '../Utils'
import {ColumnSummaryType, INumberSummary, ITableColumn, Percentage} from "../Concepts/Basic"
import {SemanticType} from "../Concepts/SemanticType"
import * as STT from "../StypeTools"
import {userSettings} from "../LocalSettings"
import {Link} from "../Workspace/WorkspaceLocation"
import {innerTypeIcon} from "../Icons"
import {colors} from "../Colors"
import {StandardColor} from "../Concepts/Colors"
import {ProfileType, VisColData, VisData} from "../Concepts/Visualizers/ColumnProfile"
import {IDataTable} from "../Concepts/DataTable";
import $t from "../i18n/i18n";

interface SummaryTableRow {
    name:string
    value:JSX.Element
    bgColor?:string
    color?:StandardColor
}

export default class ColumnProfile extends Visualizer {

    override get type () {return VisualizerType.ColumnProfile}

    matchDegree(columns: ITableColumn[]): MatchDegree {
        return columns.length === 1 ? MatchDegree.MustBeFirst : MatchDegree.NoMatch
    }

    getTitle() {
        return $t('columnprofile.name')
    }

    getIconUrl(): string {
        return Icon
    }

    getColumnData(dataTable:IDataTable): VisColData {
        return {
            info:dataTable.getColumnInfo(dataTable.selectedColumnIds[0]),
        }
    }

    getControls(dataTable:IDataTable, columnData: VisualizerColumnData): Map<VisCtrlId, VisCtrlInfo> {
        return new Map<VisCtrlId, VisCtrlInfo>()
    }

    getData (requestId:number, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues):Promise<{data:VisData, requestId:number}> {
        const cd = columnData as VisColData

        let data:VisData|undefined = undefined
        if (STT.isNumeric(cd.info.stype)) {
            data = {type: ProfileType.Numerical}
        } else if (STT.isPointInTime(cd.info.stype)) {
            data = {type: ProfileType.Time}
        } else if (STT.isCategorical(cd.info.stype)) {
            data = {
                type: ProfileType.Categorical,
                pieData: cd.info.summary.type === ColumnSummaryType.Category
                    ? cd.info.summary.categoryDistribution.map (vf => ({
                        category: {value: vf.value, color: U.get(cd.info.categories, vf.value)},
                        percent: vf.freq / cd.info.summary.valueCount,
                        value: vf.freq
                    }), false)
                    : []
            }
        } else if (STT.isText(cd.info.stype)) {
            data = {type: ProfileType.Text}
        } else if (STT.isIdentifier(cd.info.stype)) {
            data = {type: ProfileType.Identifiers}
        }

        if (data) {
            return Promise.resolve({data, requestId})
        } else {
            throw new Error ("Profile type is not defined")
        }
    }

    getActionsOnControls (event:GetActionsEvent, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues):VisCtrlAction[] {
        const cd = columnData as VisColData
        if (event.type === GetActionsEventType.DataLoadedWithSuggestedControlValues || event.type === GetActionsEventType.DataLoadedWithUserControlValues) {
            const d = event.data as VisData
            this.sendColoringEvent(d.type === ProfileType.Categorical ? cd.info.id : null, d.type !== ProfileType.Categorical)
        }
        return []
    }

    protected typeWithIcon = (name:string, type:SemanticType) =>
        <div style={{display:"flex", flexDirection:"row", alignItems: "center", justifyContent:"right"}}>
            {innerTypeIcon(type, {
                width:14,
                height:14,
                color:colors.pink.c600,
                border:`1px solid ${colors.pink.c400}`,
                borderRadius: 3,
                padding: 3,
                marginRight:5,
                position: "relative",
                top: "-2px"
            })}
            {name}
        </div>

    protected getTableRows (d:VisData, cd:VisColData):SummaryTableRow[] {
        const rows:SummaryTableRow[] = [],
            add = (name:string, value:string|JSX.Element|null, bgColor?:string, color?:StandardColor) => {
                if (typeof value === "string") {
                    rows.push ({name, value: <span>{value}</span>, color, bgColor})
                } else if (value !== null) {
                    rows.push ({name, value, color, bgColor})
                }
            },
            numberWithPercent = (value:number, total:number) =>
                U.formatNumber(value) + (value ? ` (${U.formatNumber(value * 100 / total)}%)` : ''),
            addTotals = (bgColor?:string) => {
                add ($t("columnprofile.number_of_rows"), U.formatNumber(cd.info.summary.valueCount), bgColor)
                add ($t("columnprofile.null_values"), numberWithPercent(cd.info.summary.nullCount, cd.info.summary.valueCount + cd.info.summary.nullCount), bgColor)
            }

        switch (d.type) {
            case ProfileType.Numerical:
                const numSum = cd.info.numberSummary
                add ($t("columnprofile.coltype"), STT.isOrdinal(cd.info.stype)
                    ? this.typeWithIcon($t("columnprofile.coltype.ordinal"), SemanticType.OrdinalNumber)
                    : this.typeWithIcon($t("columnprofile.coltype.numerical"), SemanticType.Number), colors.lime.c50)
                add ($t("columnprofile.units"), cd.info.units, colors.lime.c50)
                addTotals(colors.lime.c50)
                add ($t("columnprofile.min"), U.formatNumber(numSum.min), colors.yellow.c50)
                add ($t("columnprofile.min99"), U.formatNumber(numSum.whiskerMin), colors.yellow.c50)
                add ($t("columnprofile.mean"), U.formatNumber(numSum.mean), colors.yellow.c50)
                add ($t("columnprofile.max99"), U.formatNumber(numSum.whiskerMax), colors.yellow.c50)
                add ($t("columnprofile.max"), U.formatNumber(numSum.max), colors.yellow.c50)
                add ($t("columnprofile.std"), U.formatNumber(numSum.std), colors.amber.c50)
                add ($t("columnprofile.q1"), U.formatNumber(numSum.q1), colors.orange.c50)
                add ($t("columnprofile.median"), U.formatNumber(numSum.median), colors.orange.c50)
                add ($t("columnprofile.q3"), U.formatNumber(numSum.q3), colors.orange.c50)
                add ($t("columnprofile.iqr"), U.formatNumber(numSum.iqr), colors.orange.c50)
                add ($t("columnprofile.outliers"), numberWithPercent(numSum.outliers.length, cd.info.summary.valueCount), colors.orange.c50)
                add ($t("columnprofile.gcd"), numSum.categories ? U.formatNumber(numSum.categories.valueGridStep) : null, colors.deepOrange.c50)
                break

            case ProfileType.Categorical:
                add ($t("columnprofile.coltype"), this.typeWithIcon($t("columnprofile.coltype.categorical"), SemanticType.Category))
                addTotals()
                const s = cd.info.summary
                if (s.type === ColumnSummaryType.Category) {
                    add($t("columnprofile.number_of_categories"), U.formatNumber(s.categoryDistribution.length - (s.nullCount > 0 ? 1 : 0)))
                    s.categoryDistribution
                        .entries
                        .forEach(vf => {
                            if (vf.value !== null) {
                                add (vf.value, numberWithPercent(vf.freq, s.valueCount), undefined, cd.info.categories.get(vf.value))
                            }
                        })
                }
                break

            case ProfileType.Time:
                const timeSum = cd.info.numberSummary
                add ($t("columnprofile.coltype"), STT.isTimeOnly(cd.info.stype)
                    ? this.typeWithIcon($t("columnprofile.coltype.time"), SemanticType.Time)
                    : STT.isDateOnly(cd.info.stype)
                        ? this.typeWithIcon($t("columnprofile.coltype.date"), SemanticType.Date)
                        : this.typeWithIcon($t("columnprofile.coltype.datetime"), SemanticType.DateTime))
                addTotals()
                add ($t("columnprofile.time.from"), STT.formatFullValue(cd.info.stype, timeSum.min))
                add ($t("columnprofile.time.to"), STT.formatFullValue(cd.info.stype, timeSum.max))
                break

            case ProfileType.Text:
                add ($t("columnprofile.coltype"), this.typeWithIcon($t("columnprofile.coltype.text"), SemanticType.Text))
                addTotals()
                break

            case ProfileType.Identifiers:
                add ($t("columnprofile.coltype"), this.typeWithIcon($t("columnprofile.coltype.identifier"), SemanticType.Identifier))
                addTotals()
                break

            default:
                U.shouldNeverGetHere(d)
        }
        return rows
    }

    protected getChart (d:VisData, cd:VisColData, width:number, tooltips:TooltipsById):{chart:RenderResult, title:string, visType:VisualizerType}|null {
        const freqDistrHist = (info:ITableColumn, numSum:INumberSummary, units?:string, stype?:SemanticType) => {
            const maxBinCount = Math.min (userSettings.getNumber("histBins"), Math.max (5, Math.floor (width / 30))),
                axisCategories = numSum.categories?.axisCategories.length ?? 0,
                binCount = axisCategories ? Math.min (maxBinCount, axisCategories) : maxBinCount

            return renderFreqHist(
                new Map ([[info.id, numSum]]),
                {from:Percentage(0), to:Percentage(100)},
                binCount,
                width,
                false,
                $t("columnprofile.chart.ytitle"),
                new Map([[info.id, info.color]]),
                null,
                units ?? (info.units ?? ''),
                stype ?? info.stype,
                tooltips,
                binCount === axisCategories
            )
        }

        switch (d.type) {
            case ProfileType.Numerical:
            case ProfileType.Time:
                return {
                    chart: freqDistrHist(cd.info, cd.info.numberSummary),
                    title: $t('columnprofile.frequency_distribution'),
                    visType: VisualizerType.NumFreqHistSharedX
                }

            case ProfileType.Categorical:
                return d.pieData.length > 1
                    ? {
                        chart: renderPie(d.pieData, Math.min(width, 400), cd.info.units ?? '', '', tooltips),
                        title: $t('columnprofile.categories'),
                        visType: VisualizerType.PieBarChart
                    }
                    : null

            case ProfileType.Text:
                return cd.info.summary.type === ColumnSummaryType.Text
                    ? {
                        chart: freqDistrHist(cd.info, cd.info.summary.numberSummary, $t('columnprofile.text_units'), SemanticType.Number),
                        title: $t('columnprofile.text_length_distribution'),
                        visType: VisualizerType.NumFreqHistSharedX
                    }
                    : null

            case ProfileType.Identifiers:
                return null
        }
    }

    getContent (data:VisualizerData, width:number, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues, tooltips:TooltipsById):VisContent {
        const d = data as VisData,

            cd = columnData as VisColData,

            oneTableThreshold = 3, // rows

            tableRows = this.getTableRows(d, cd),

            tableWidth = Math.min (width, tableRows.length > oneTableThreshold ? 300 : 500),

            tablePadding = 10,

            tableCount = tableRows.length > oneTableThreshold ? Math.min (tableRows.length, Math.floor (width / tableWidth)) : 1,

            rowsPerTable = Math.floor (tableRows.length / tableCount),

            restOfRows = tableRows.length - rowsPerTable * tableCount,

            chartInfo = this.getChart (d, cd, width, tooltips),

            firstRow = (tableIndex:number) => tableIndex * rowsPerTable + (tableIndex >= restOfRows ? restOfRows : tableIndex),

            tables = U.times(tableCount, tableIndex => <Table key={cd.info.id+'_'+tableIndex} style={{width: tableWidth - tablePadding*2, margin: `0 ${tablePadding}px`}}>
                <TableBody>{
                    U.fromTo (firstRow (tableIndex), firstRow (tableIndex + 1), i => (
                        <TableRow key={cd.info.id+'_'+i}>
                            <TableCell style={{fontWeight:500, color: tableRows[i].color?.dark ?? "black", backgroundColor: tableRows[i].bgColor ?? "transparent" }} component="th" scope="row">{tableRows[i].name}</TableCell>
                            <TableCell align="right" style={{color: tableRows[i].color?.dark ?? "black", backgroundColor: tableRows[i].bgColor ?? "transparent" }}>{tableRows[i].value}</TableCell>
                        </TableRow>))
                    }</TableBody>
            </Table>)

        return {
            node:
                <div style={{marginTop:30, display: "flex", alignItems: "center", flexDirection: "column"}}>
                    <div style={{display: "flex", justifyContent: "space-around", flexDirection: "row"}}>{tables}</div>
                    {chartInfo !== null
                        ? <React.Fragment>
                            <Box fontFamily="Roboto"
                                 fontSize={10 * (width / 1000) + 14}
                                 fontWeight="fontWeightMedium"
                                 textAlign="center"
                                 mt={4}
                                 mb={1}>{chartInfo.title}</Box>
                            <div style={{marginTop:10}}>
                                <Link columnIds={[cd.info.id]} visType={chartInfo.visType}>
                                    {chartInfo.chart.svg}
                                </Link>
                            </div>
                        </React.Fragment>
                        : undefined
                    }
                </div>,
            title: $t('columnprofile.title', {text: cd.info.title}),
            height: (chartInfo?.chart.height ?? 0) + (rowsPerTable + restOfRows ? 1 : 0) * 30
        }
    }

    handleUserAction (userActionInfo: UserActionInfo, data:VisualizerData, dataTable:IDataTable, columnData:VisualizerColumnData, controlValues:VisCtrlValues):UserActionResponse|undefined {
        return undefined
    }
}