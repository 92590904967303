import React from 'react'
import $t from "../i18n/i18n"

interface Props {
    onDataDropped: (file:File|string)=>void
}

interface State {
    dragging: boolean
}

export default class DropZone extends React.PureComponent<Props,State> {
    protected counter = 0
    override readonly state = {dragging: false}

    protected handleDrag = (e:React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
    }

    protected handleDragIn = (e:React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
        this.counter += 1
        this.setState({dragging: true})
    }

    protected handleDragOut = (e:React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({dragging: --this.counter > 0})
    }

    protected handleDrop = (e:React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({dragging: false})
        const file = e.dataTransfer.items[0].getAsFile()
        if (file) {
            this.props.onDataDropped(file)
        } else {
            const text = e.dataTransfer.getData("text/plain")
            if (text) {
                this.props.onDataDropped(text)
            }
        }
        this.counter = 0
    }

    override render() {
        return (
            <div style={{display: 'inline-block', position: 'relative', height:'100%', width:'100%'}}
                 onDrop={this.handleDrop}
                 onDragOver={this.handleDrag}
                 onDragEnter={this.handleDragIn}
                 onDragLeave={this.handleDragOut}
            >
                {this.state.dragging &&
                <div
                    style={{
                        border: 'dashed grey 4px',
                        backgroundColor: 'rgba(255,255,255,.8)',
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 9999
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: '50%',
                            right: 0,
                            left: 0,
                            textAlign: 'center',
                            color: 'grey',
                            fontSize: 36
                        }}
                    >
                        <div>{$t('dropzone.text')}</div>
                    </div>
                </div>
                }
                {this.props.children}
            </div>
        )
    }
}